import React from 'react';
import { Box } from '@mui/system';
import { flex } from '../../../../components/AvThemeProvider';
import { rebranding } from '../../../../rebranding';
import { iconSize } from '../../../../utils/Utils';
import { BarSubType, LineSubType, TileSubType } from '../../types';
import { PieSubType } from '../../types/PieWidget.types';
import { TableSubType } from '../../types/TableWidget.types';
import { ReactComponent as Warning } from '../../../../assets/colorful/Warning_full_orange.svg';
import { ReactComponent as WarningNew } from '../../../../assets/colorful2/Warning_full_orange.svg';
import { ReactComponent as Bars } from '../../../../assets/widgetTypes/noColors/Bars.svg';
import { ReactComponent as Donut } from '../../../../assets/widgetTypes/noColors/Donut.svg';
import { ReactComponent as HoritontalBarsStacked } from '../../../../assets/widgetTypes/noColors/Horitontal Bars Stacked.svg';
import { ReactComponent as HoritontalBars } from '../../../../assets/widgetTypes/noColors/Horitontal Bars.svg';
import { ReactComponent as HorizontalBarsStacked100 } from '../../../../assets/widgetTypes/noColors/Horizontal Bars Stacked 100.svg';
import { ReactComponent as Line2 } from '../../../../assets/widgetTypes/noColors/Line 2.svg';
import { ReactComponent as Line } from '../../../../assets/widgetTypes/noColors/Line.svg';
import { ReactComponent as Pie } from '../../../../assets/widgetTypes/noColors/Pie.svg';
import { ReactComponent as StatusBar } from '../../../../assets/widgetTypes/noColors/Status Bar.svg';
import { ReactComponent as Table } from '../../../../assets/widgetTypes/noColors/Table.svg';
import { ReactComponent as VerticalBarsStacked100 } from '../../../../assets/widgetTypes/noColors/Vertical Bars Stacked 100.svg';
import { ReactComponent as VerticalBarsStacked } from '../../../../assets/widgetTypes/noColors/Vertical Bars Stacked.svg';

const style = iconSize('40%' as any);
interface Props {
  icon: React.JSX.Element;
  dimMin?: number;
  dimMax?: number;
  metricsMin?: number;
  metricsMax?: number;
}
const Preview: React.FC<Props> = ({ icon, dimMin = 0, dimMax = 5, metricsMin = 0, metricsMax = 5 }) => (
  <Box sx={{ ...flex.colCenter, flexGrow: 1, gap: 2.5 }}>
    {icon}
    <Box sx={{ ...flex.colCenter, gap: 2 }}>
      <Box sx={{ fontSize: 24, fontWeight: 600, color: theme => theme.palette.colors.neutrals[600] }}>Start building your widget</Box>
      <Box sx={{ ...flex.center, gap: 1 }}>
        {rebranding ? <WarningNew /> : <Warning />}
        <Box sx={{ fontSize: 13, color: theme => theme.palette.colors.neutrals[600] }}>
          Add {!(dimMin === 0 && dimMax === 0) && <b>{dimMin === dimMax ? dimMax : `${dimMin} - ${dimMax}`} Dimensions and</b>}
          <b>{metricsMin === metricsMax ? metricsMax : `${metricsMin} - ${metricsMax}`} Measurements</b>
        </Box>
      </Box>
    </Box>
  </Box>
);

const RenderPreview = ({ type }) =>
  ({
    [TableSubType.Table]: <Preview icon={<Table style={style} />} />,
    [PieSubType.Pie]: <Preview icon={<Pie style={style} />} dimMin={0} dimMax={1} metricsMax={1} metricsMin={1} />,
    [PieSubType.Donut]: <Preview icon={<Donut style={style} />} dimMin={0} dimMax={1} metricsMax={1} metricsMin={0} />,
    [BarSubType.BarVertical]: <Preview icon={<Bars style={style} />} dimMin={1} dimMax={2} metricsMax={5} metricsMin={1} />,
    [BarSubType.BarHorizontal]: <Preview icon={<HoritontalBars style={style} />} dimMin={1} dimMax={2} metricsMax={5} metricsMin={1} />,
    [BarSubType.StackedVertical]: (
      <Preview icon={<VerticalBarsStacked style={style} />} dimMin={1} dimMax={2} metricsMax={5} metricsMin={1} />
    ),
    [BarSubType.StackedHorizontal]: (
      <Preview icon={<HoritontalBarsStacked style={style} />} dimMin={1} dimMax={2} metricsMax={5} metricsMin={1} />
    ),
    [BarSubType.Percentage100_Vertical]: (
      <Preview icon={<VerticalBarsStacked100 style={style} />} dimMin={1} dimMax={2} metricsMax={5} metricsMin={1} />
    ),
    [BarSubType.Percentage100_Horizontal]: (
      <Preview icon={<HorizontalBarsStacked100 style={style} />} dimMin={1} dimMax={2} metricsMax={5} metricsMin={1} />
    ),
    [LineSubType.Line]: <Preview icon={<Line style={style} />} dimMin={1} dimMax={1} metricsMin={1} />,
    [LineSubType.Spline]: <Preview icon={<Line2 style={style} />} dimMin={1} dimMax={1} metricsMin={1} />,
    [TileSubType.Trend]: <Preview icon={<StatusBar style={style} />} dimMin={0} dimMax={0} metricsMin={1} metricsMax={1} />,
    [TileSubType.Tile]: <Preview icon={<StatusBar style={style} />} dimMin={0} dimMax={0} metricsMin={1} metricsMax={1} />,
  })[type];

export default RenderPreview;
