import { useEffect, useMemo, useRef } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { IDisposable, languages } from 'monaco-editor';
import { useAvContext } from '../../../context/AvContextProvider';
import { QueryKey } from '../../../utils/queryKey';
import { orderByKey } from '../../../utils/Utils';
import { SubAutoComplete } from './types';

// eslint-disable-next-line import/prefer-default-export
export const useGroupingRulesSet = projectionName => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.GroupingRules, projectionName],
    queryFn: () =>
      api(GET_LIST, { options: { projectionName } }).then(data => orderByKey(data?.data?.getRuleSets || data?.data?.getRuleSetsV2, 'name')),
    gcTime: 0,
  });
};

const GET_LIST = gql`
  query getRuleSetsV2($projectionName: String!) {
    getRuleSetsV2(projectionName: $projectionName)
  }
`;

const isBetweenChars = (startChars, endChars, str, cursorPosition) => {
  // eslint-disable-next-line custom-rules/no-let
  let inside = false;
  // eslint-disable-next-line custom-rules/no-let
  for (let i = 0; i < str.length; i++) {
    if (i >= cursorPosition) {
      return inside;
    }
    if (str.slice(i, i + startChars.length) === startChars) {
      inside = true;
      i = i + startChars.length - 1;
    } else if (str.slice(i, i + endChars.length) === endChars) {
      inside = false;
      i = i + endChars.length - 1;
    }
  }
  return false;
};
export const isBetweenDoubleCurly = (model, position) =>
  isBetweenChars('{{', '}}', model.getLineContent(position.lineNumber), position.column - 1);

const createCompletionItems = completions =>
  completions
    .toSorted((a, b) => a.group.localeCompare(b.group))
    .map(item => ({
      label: item.title,
      kind: languages.CompletionItemKind.Field,
      insertText: item.value, // Use the 'value' property as the inserted value
      range: null, // Set the range if needed, else leave as null
    }));

export const useFieldSuggestions = (projectionName, usage, fieldOptions) => {
  const {
    accountEntities: { ingressProjs, aggProjs },
  } = useAvContext();

  const completions = useMemo(() => {
    if (fieldOptions) {
      return fieldOptions;
    }
    const subProj = SubAutoComplete[projectionName];
    const subTitleFilterOptions = (subProj && aggProjs[subProj].fieldList.SPF.filter(f => !f.visibilityConfig.config.hidden)) || [];
    const titleFilterOptions = (ingressProjs[projectionName] || aggProjs[projectionName]).fieldList[usage];
    return subProj ? [...titleFilterOptions, ...subTitleFilterOptions] : titleFilterOptions;
  }, [projectionName]);

  return createCompletionItems(completions);
};

export const useGetProjectionFieldCompletions: ({
  projectionName,
  isInteractive,
}: {
  projectionName: any;
  isInteractive: boolean;
}) => (editor, monaco) => void = ({ projectionName, isInteractive }) => {
  const disposeOptionsRef = useRef<IDisposable>();
  const { aggProjs } = useAvContext().accountEntities;
  const subProj = SubAutoComplete[projectionName];
  const subTitleFilterOptions = (subProj && aggProjs[subProj].fieldList.SPF.filter(f => !f.visibilityConfig.config.hidden)) || [];
  const fieldListType = isInteractive ? 'INTERACTIVE' : 'EVAL';

  const titleFilterOptions = aggProjs[projectionName].fieldList[fieldListType];

  useEffect(() => () => disposeOptionsRef.current?.dispose(), []);
  return (editor, monaco) => {
    const completions = subProj ? [...titleFilterOptions, ...subTitleFilterOptions] : titleFilterOptions;

    // Register the completion provider with Monaco Editor
    disposeOptionsRef.current = monaco.languages.registerCompletionItemProvider('python', {
      provideCompletionItems: (model, position) => {
        const lineContent = model.getLineContent(position.lineNumber);
        const prefix = lineContent.substring(0, position.column - 2);
        const suffix = lineContent.substring(position.column);

        return { suggestions: prefix.endsWith('{{') && suffix.startsWith('}') ? createCompletionItems(completions) : [] };
      },
    });
  };
};
