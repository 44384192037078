export const aggregateAllCounts = (counts, baseObj) =>
  Object.keys(counts).reduce((obj, key) => ({ ...obj, [key]: (obj[key] || 0) + counts[key] }), baseObj);

export function generateTopItemsPlusOthers(data, metricKey, numOfItems, customOthersHandler) {
  return data.reduce(
    (arr, { [metricKey]: name, total, ...counts }, i) =>
      i <= numOfItems
        ? [...arr, { [metricKey]: name, ...counts }]
        : [
            ...arr.slice(0, numOfItems),
            {
              ...arr.at(-1),
              ...(customOthersHandler ? customOthersHandler(counts, arr.at(-1), data) : aggregateAllCounts(counts, arr.at(-1))),
              [metricKey]: 'Others',
            },
          ],
    []
  );
}

export const getPieCustomLabelMx = ({ sign, innerRadius, showValue }) =>
  sign * Math.max(2.7 * innerRadius + (showValue ? 50 : 0), 3.7 * (100 - innerRadius));
