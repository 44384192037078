import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { FormatterType } from '../../../../components/Table/types';
import LineWidgetComponent from '../../../../components/Widgets/LineWidget';
import { generateInnerDefaultFormatter, uniqBy } from '../../../../utils/Utils';
import { NO_VALUE } from '../../constants';
import { getDateFormatFromWidget } from '../../EditCustomDashboard/utils';
import { useCustomPalette, useGetDisplayName, useGetFieldType } from '../../hooks';
import { LineCategoryWidgetTypes, LineLegendPosition, LineSubType } from '../../types/LineWidget.types';

interface LineWidgetProps {
  widget: LineCategoryWidgetTypes;
  data?: Record<string, any>[];
  isLoading?: boolean;
}
enum WidgetType {
  MultiDims,
  MultiMetrics,
  Normal,
  NotValid,
}
const useGetLineWidgetData = ({
  data,
  widget,
}: {
  widget: LineCategoryWidgetTypes;
  data?: Record<string, any>[];
}): { lineData: any[]; series: any[]; xAxisKey?: string } => {
  const {
    select: { dims, metrics },
    projectionId: { name: projName },
  } = widget.requests[0];
  const customPalette = useCustomPalette(data);
  const getDisplayName = useGetDisplayName(projName);

  const getHeadCellType = useGetFieldType(projName);
  const headCellType = getHeadCellType({ entityFieldKey: dims[0].name });

  const widgetType: WidgetType =
    dims.length === 2 && metrics.length === 1
      ? WidgetType.MultiDims
      : dims.length === 1 && metrics.length > 1
        ? WidgetType.MultiMetrics
        : metrics.length === 1 && dims.length === 1
          ? WidgetType.Normal
          : WidgetType.NotValid;
  switch (widgetType) {
    case WidgetType.MultiDims: {
      const createSeries = (data, secondDimKey) => {
        const uniqueValues = new Set(data.map(item => item[secondDimKey]));
        return Array.from(uniqueValues).map((value, i) => ({
          name: `${value}`,
          dataKey: value,
          color: customPalette[i],
          type: 'line',
        }));
      };
      const series = createSeries(data, dims[1].name);

      const transformData = (data, dims, metrics) =>
        Object.values(
          data.reduce((acc, item) => {
            const firstDimValue = item[dims[0].name]; // Group by the first dimension
            const secondDimValue = item[dims[1].name]; // Use the second dimension as keys
            const metricValue = item[metrics[0].name]; // Use the metric as the value

            acc[firstDimValue] = acc[firstDimValue]
              ? acc[firstDimValue]
              : {
                  name: generateInnerDefaultFormatter({
                    value: firstDimValue,
                    type: headCellType,
                    fieldName: dims[0].name,
                  }),
                };

            acc[firstDimValue][secondDimValue] = metricValue;
            return acc;
          }, {})
        );
      const lineData = transformData(data, dims, metrics);
      return { lineData, series, xAxisKey: 'name' };
    }
    case WidgetType.MultiMetrics:
    case WidgetType.Normal: {
      const xAxis0Values = [...new Set(metrics.map(metric => metric.alias || metric.name))];
      const series = uniqBy(
        xAxis0Values?.map((v, i) => ({
          name: getDisplayName(v) || NO_VALUE,
          dataKey: v || NO_VALUE,
          color: customPalette[i],
          type: 'line',
        })),
        v => v.name
      );
      const dataRow = data?.map(
        row =>
          data
            ?.filter(v => v[dims[0]?.name] === row[dims[0]?.name])
            .reduce(
              (acc, v) => ({
                date: v[dims[0]?.name],
                ...acc,
                ...metrics.reduce((acc, metric) => ({ ...acc, [metric.alias || 0]: v[metric.alias || 0] }), {}),
              }),
              {}
            )
      );
      const lineData = uniqBy(dataRow, v => v.date);
      return { lineData, series };
    }
    default:
      return { lineData: [], series: [] };
  }
};

const renderLineWidget = (widget: LineCategoryWidgetTypes, data, series, isDate, xAxisKey?: string) => ({
  [LineSubType.Spline]: () => (
    <LineWidgetComponent
      data={data}
      series={series}
      sx={{ flexDirection: flexDirectionMap[widget.definition.custom.legend.position], pb: 1, pt: 2 }}
      dateFormat={getDateFormatFromWidget(widget)}
      isDate={isDate}
      xAxisKey={xAxisKey}
    />
  ),
  [LineSubType.Line]: () => (
    <LineWidgetComponent
      data={data}
      series={series.map(serie => ({ ...serie, lineType: 'linear' }))}
      sx={{ flexDirection: flexDirectionMap[widget.definition.custom.legend.position], pb: 1, pt: 2 }}
      dateFormat={getDateFormatFromWidget(widget)}
      isDate={isDate}
      xAxisKey={xAxisKey}
    />
  ),
});

const flexDirectionMap = {
  [LineLegendPosition.Top]: 'column',
  [LineLegendPosition.Bottom]: 'column-reverse',
};

const LineWidget: React.FC<LineWidgetProps> = ({ widget, data, isLoading }) => {
  const customPalette = useCustomPalette(data);
  const getType = useGetFieldType(widget.requests[0].projectionId.name);
  const { lineData, series, xAxisKey } = useGetLineWidgetData({ data, widget });
  const lineTypeWidget = useMemo(
    () =>
      renderLineWidget(
        widget,
        lineData,
        series,
        [FormatterType.historicDate, FormatterType.date].includes(
          getType({ entityFieldKey: widget.requests[0].select.dims[0].name })! as FormatterType
        ),
        xAxisKey
      ),
    [data, customPalette, widget, isLoading]
  );
  return (
    <Box
      sx={{
        flex: 1,
        overflow: 'hidden',
        '.recharts-surface': {
          pointerEvents: 'auto',
          height: 'auto',
          width: 'auto',
          gap: 2,
          'text:not(.custom-chart-text)': {
            fontSize: '12px',
            fill: theme => theme.palette.colors.neutrals[500],
          },
          '.recharts-xAxis': {
            transform: 'translate(0,5px)',
          },
        },
      }}>
      {lineTypeWidget[widget.type]()}
    </Box>
  );
};

export default LineWidget;
