import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/system';
import { sanitize, SanitizeHTML } from '../../../../utils/Utils';
import AvRichText from '../../../Tickets/AvRichText';
import { CustomDashboardContext } from '../../CustomDashboardContext';
import { TextCategoryWidgetTypes } from '../../types';
import { TextSubType } from '../../types/TextWidget.types';

interface TextWidgetProps {
  widget: TextCategoryWidgetTypes;
  data?: Record<string, any>[];
  isLoading?: boolean;
  isEdit?: boolean;
}

const TextWidget: React.FC<TextWidgetProps> = ({ widget, data = [], isEdit = false }) => {
  const { setNewWidget } = useContext(CustomDashboardContext);
  const handleNewText = (text: string) => {
    if (widget.type !== TextSubType.Text) {
      return;
    }
    setNewWidget({
      ...widget,
      definition: {
        ...widget.definition,
        custom: {
          ...widget.definition.custom,
          text: sanitize(text),
        },
      },
    });
  };

  const renderTextWidget = useMemo(
    () => ({
      [TextSubType.Text]: () => (
        <Box>
          {isEdit ? (
            <AvRichText
              onClick={() => {}}
              onFocus={() => {}}
              showBar
              value={widget.definition.custom.text}
              onChange={handleNewText}
              placeholder="Add your text here..."
              // onKeyDown={onKeyDown}
              // @ts-ignore
              fileUploadProps={{ enable: false }}
            />
          ) : (
            <SanitizeHTML html={widget.definition.custom.text} />
          )}
        </Box>
      ),
    }),
    [data, widget]
  );
  return renderTextWidget[widget.type]();
};

export default TextWidget;
