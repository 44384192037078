import { PeriodBehavior } from '../../components/DatePicker/AvDateRangePicker.constants';
import { RelativeUnit } from '../../components/DatePicker/utils';
import { getDateFromFilter, getEmptyFilterExpression } from '../../components/filters/Utils';
import {
  CommonPolicyFilterConfig,
  ConflictingValuesPolicyFilterConfig,
  ExecutableUnit,
  ExecutionRuleType,
  FilterType,
  PolicyCategory,
  PolicyCategoryId,
  PolicyFilterConfig,
  PolicyMetadataType,
  PolicyScenarioType,
  PolicyType,
  ToolCoveragePolicyFilterConfig,
  TriggerType,
} from '../../types/executionRules.types';
import { ArrayResolution, DateCondition, Filter, OperatorType } from '../../types/filter.types';
import { Strategy } from '../../types/QueryObjectProto.types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { SeverityLabels } from '../../utils/severity.utils';
import { cleanFormattingConditions } from '../FormattingRules/hooks';
import { getIsCreate, NONE_ID } from '../FormattingRules/utils';

export const cmdbHygieneDefaultAssetPopulation: Filter = {
  and: {
    operands: [
      {
        expression: {
          fieldName: '',
          arrayCondition: {
            underlying: {
              fieldName: 'asset.source_names',
              stringCondition: {
                contains: 'ServiceNow',
              },
            },
            resolution: ArrayResolution.ANY,
          },
        },
      },
    ],
  },
};

export enum EditPageModes {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
}

export const toolCoverageDefaultAssetPopulation = {
  and: {
    operands: [
      {
        expression: {
          fieldName: 'asset.last_seen',
          dateCondition: {
            relative: {
              unit: RelativeUnit.DAYS,
              value: 7,
              periodBehaviour: PeriodBehavior.last,
            },
          },
        },
      },
      {
        expression: {
          fieldName: 'asset.type',
          stringCondition: {
            contains: '',
          },
        },
      },
      {
        expression: {
          arrayCondition: {
            underlying: {
              fieldName: 'asset.source_names',
              stringCondition: {
                contains: '',
              },
            },
            resolution: ArrayResolution.ANY,
          },
        },
      },
    ],
  },
};

// returns a Cmdb filter structure from the three selected fields
export const getConflictingValuesFilter = ({
  fieldName = '',
  uniqueValues = 0,
  excludedValues = [],
}: {
  fieldName?: string;
  uniqueValues?: number;
  excludedValues?: string[];
}) => ({
  filterType: FilterType.CONFLICTING_VALUES,
  fieldName,
  uniqueValues,
  excludedValues,
});

export const cmdbSimpleFilterDefaults: Partial<Record<FilterType, PolicyScenarioType>> = {
  [FilterType.MISSING_FIELD]: {
    filterType: FilterType.MISSING_FIELD,
    filter: {
      or: {
        operands: [
          {
            and: {
              operands: [
                {
                  expression: {
                    arrayCondition: {
                      underlying: {
                        fieldName: 'asset.sources.source_names',
                        stringCondition: {
                          contains: 'ServiceNow Assets',
                        },
                      },
                      resolution: ArrayResolution.ANY,
                    },
                  },
                },
                {
                  expression: {
                    fieldName: 'asset.sources.owner_id',
                    stringCondition: {
                      empty: {},
                    },
                  },
                },
              ],
            },
          },
          {
            and: {
              operands: [
                {
                  expression: {
                    arrayCondition: {
                      underlying: {
                        fieldName: 'asset.sources.source_names',
                        stringCondition: {
                          contains: 'ServiceNow Assets',
                        },
                      },
                      resolution: ArrayResolution.ANY,
                    },
                  },
                },
                {
                  expression: {
                    fieldName: 'asset.sources.owner_id',
                    stringCondition: {
                      contains: 'Unknown',
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
  },
  [FilterType.CONFLICTING_VALUES]: {
    filterType: FilterType.CONFLICTING_VALUES,
    fieldName: 'asset.sources.owner_id',
    uniqueValues: 1,
    excludedValues: [],
  },
};

export const defaultPolicyMetadataProps: PolicyMetadataType = {
  policy_name: '',
  policy_description: '',
  severity: SeverityLabels.Medium,
  severity_score: 5,
  type: PolicyCategory[PolicyCategoryId.NONE],
};
export const defaultExecutableUnitProps: ExecutableUnit = {
  executionConfig: {
    fields: [],
    evalStrategy: Strategy.CEL,
  },
  primaryQuery: {
    evaluatePerRow: true,
    query: {
      name: '',
      queryObject: {
        sourceProjection: { name: entityViewConfig.Asset.projectionName, builtIn: true },
        dims: [],
        metrics: [],
      },
    },
  },
  secondaryQueries: [],
};

export const defaultPolicyTypeProps: PolicyType = {
  executableUnits: [defaultExecutableUnitProps],
  preCheckUnits: [],
  type: ExecutionRuleType.ASSET_POLICY,
  metadata: defaultPolicyMetadataProps,
  executionRunConfiguration: {
    trigger: {
      type: TriggerType.SCHEDULED,
      value: '0 0 * * *',
    },
    outcomes: [],
    active: true,
  },
  clientConfig: {
    assetPopulationFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'asset.type',
              stringCondition: {
                contains: '',
              },
            },
          },
          {
            expression: {
              arrayCondition: {
                underlying: {
                  fieldName: 'asset.source_names',
                  stringCondition: {
                    contains: '',
                  },
                },
                resolution: ArrayResolution.ANY,
              },
            },
          },
        ],
      },
    },
    policyScenario: {
      filterType: FilterType.ADVANCED,
      filter: getEmptyFilterExpression(),
    },
  },
};

export const categoryToDefaultFilterMap: Partial<Record<PolicyCategoryId, PolicyFilterConfig>> = {
  [PolicyCategoryId.CMDB]: {
    assetPopulationFilter: cmdbHygieneDefaultAssetPopulation,
    policyScenario: cmdbSimpleFilterDefaults[FilterType.MISSING_FIELD]!,
  },
  [PolicyCategoryId.TOOL_COVERAGE]: {
    assetPopulationFilter: toolCoverageDefaultAssetPopulation,
    policyScenario: {
      filterType: FilterType.TOOL_COVERAGE,
      sources: [],
      numOfDays: 7,
      operand: OperatorType.OR,
    },
  },
};

export const getDistinctValuesObjects = (newData: PolicyType) => {
  const { fieldName, uniqueValues, excludedValues } = newData.clientConfig.policyScenario as ConflictingValuesPolicyFilterConfig;
  return { fieldName, uniqueValues, excludedValues };
};
const isCommonPolicyFilterConfig = (policyScenario: PolicyScenarioType): policyScenario is CommonPolicyFilterConfig =>
  ![FilterType.CONFLICTING_VALUES, FilterType.TOOL_COVERAGE].includes(policyScenario.filterType);

const isToolCoverageFilterConfig = (policyScenario: PolicyScenarioType): policyScenario is ToolCoveragePolicyFilterConfig =>
  policyScenario.filterType === FilterType.TOOL_COVERAGE;

const cleanToolCoverageFilter = (filter?: Filter) => (filter?.expression && filter.expression.fieldName ? filter : undefined);

export const clearEmptyPolicyScenarioFilter = (newData: PolicyType) =>
  isCommonPolicyFilterConfig(newData.clientConfig.policyScenario)
    ? {
        ...newData,
        clientConfig: {
          ...newData.clientConfig,
          policyScenario: {
            ...newData.clientConfig.policyScenario,
            filter:
              newData.clientConfig.policyScenario.filter.and?.operands[0].expression?.fieldName === null
                ? null
                : newData.clientConfig.policyScenario.filter,
          },
        },
      }
    : isToolCoverageFilterConfig(newData.clientConfig.policyScenario)
      ? {
          ...newData,
          clientConfig: {
            ...newData.clientConfig,
            policyScenario: {
              ...newData.clientConfig.policyScenario,
              additionalFilter: cleanToolCoverageFilter(newData.clientConfig.policyScenario.additionalFilter),
            },
          },
        }
      : newData;

export const dateOptions = [
  { title: 'Days', value: RelativeUnit.DAYS },
  { title: 'Weeks', value: RelativeUnit.WEEKS },
  { title: 'Months', value: RelativeUnit.MONTHS },
];

export const getTimeRangeAndDatePickerValue = (dateObject: DateCondition) => {
  const timeRange = JSON.parse(decodeURIComponent(dateObject as any));
  const { value: values } = getDateFromFilter(timeRange as DateCondition);
  const startDate = new Date(values.from);
  const endDate = values.to ? new Date(values.to) : new Date();
  return { timeRange, datePickerValue: { from: startDate, to: endDate } };
};

export const getCleanNewData = (newData: PolicyType) => {
  const {
    clientConfig: { formattingRule, ...restClientConfig },
    ...restNewData
  } = newData;

  if (!formattingRule) {
    return newData;
  }

  if (formattingRule.id === NONE_ID) {
    return { ...restNewData, clientConfig: restClientConfig };
  }

  const formattingConditions = cleanFormattingConditions(formattingRule);

  const { id, formattingConditions: x, ...restFormattingRule } = formattingRule;

  const updatedFormattingRule = getIsCreate(formattingRule)
    ? { ...restFormattingRule, formattingConditions }
    : { ...restFormattingRule, id, formattingConditions };

  return {
    ...restNewData,
    clientConfig: {
      ...restClientConfig,
      formattingRule: updatedFormattingRule,
    },
  };
};
