import React from 'react';
import { alpha, Box, useTheme } from '@mui/material';
import { rebranding } from '../../rebranding';
import { App } from '../../types/sideBar.types';
import { iconSize } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ActionButton } from '../Table/Utils';
import { ReactComponent as StarFull } from '../../assets/StarFull.svg';
import { ReactComponent as StarLine } from '../../assets/StarLine.svg';

const starFullIcon = theme => <StarFull style={{ color: theme.palette.colors.complementary[500], ...iconSize(20) }} />;
const starLineIcon = <StarLine style={iconSize(20)} />;

interface AppItemProps extends App {
  isSelected: boolean;
  isFavorite?: boolean;
  disableHover?: boolean;
  showDescription?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  toggleFavoriteApp?: () => void;
  appIconSize?: number;
}
export const AppItem: React.FC<AppItemProps> = ({
  name,
  icon,
  imageFull,
  appIconSize,
  isSelected,
  onClick,
  disableHover,
  showDescription,
  isFavorite = true,
  toggleFavoriteApp,
  description,
  backgroundColor,
}) => {
  const theme = useTheme();
  return (
    <Box sx={theme => appIconStyle(theme, isSelected, disableHover, backgroundColor, appIconSize, imageFull)} onClick={onClick}>
      {toggleFavoriteApp &&
        ActionButton(
          isFavorite ? 'Remove from favorite' : 'Add to Favorite',
          toggleFavoriteApp,
          isFavorite ? starFullIcon(theme) : starLineIcon
        )}
      <Box
        sx={{
          backgroundColor: theme => (rebranding ? 'rgba(255, 255, 255, 0.15)' : theme.palette.colors.neutrals[100]),
          borderRadius: '8px',
          position: 'relative',
        }}>
        <Box className="icon-container">{icon}</Box>
      </Box>
      <Box sx={{ ...flex.col }}>
        <Box
          sx={{
            fontWeight: 600,
            color: (theme: any) => (rebranding && !disableHover ? theme.palette.colors.white : theme.palette.colors.neutrals[800]),
          }}>
          {name === 'CAASM' ? 'Assets' : name}
        </Box>
        {showDescription && <Box sx={{ fontWeight: 400, color: (theme: any) => theme.palette.colors.neutrals[600] }}>{description}</Box>}
      </Box>
    </Box>
  );
};
export const appIconStyle = (theme, isSelected, disableHover, backgroundColor: string, appIconSize = 22, imageFull = undefined) => ({
  ...flex.center,
  pl: '4px',
  pr: '12px',
  py: '4px',
  gap: '8px',
  borderRadius: '10px',
  cursor: 'pointer',
  color: rebranding ? theme.palette.colors.neutrals[150] : theme.palette.colors.neutrals[600],
  border: `1px solid transparent`,
  transition: theme.transitions.create(['border-color'], {
    duration: theme.transitions.duration.shortest,
  }),
  svg: {
    ...iconSize(appIconSize),
  },
  ...(rebranding && opacityGradientBgColor(theme, imageFull)),
  '.icon-container': {
    p: '5px',
    ...(!rebranding && { background: backgroundColor }),
    borderRadius: '8px',
    ...flex.center,
  },
  ...(rebranding && disableHover && { '.icon-container::before': { opacity: 1 } }),
  ':hover': !disableHover && {
    borderColor: rebranding ? theme.palette.colors.primary[400] : theme.palette.colors.neutrals[350],
    ...(rebranding && { '.icon-container::before': { opacity: 1 } }),
  },
  ...(isSelected
    ? {
        ...(rebranding && { '.icon-container::before': { opacity: 1 } }),
        borderColor: rebranding ? 'none' : theme.palette.colors.neutrals[100],
        background: rebranding
          ? 'linear-gradient(45deg, rgba(148, 184, 255, 0.30) -3.85%, rgba(238, 243, 253, 0.30) 93.41%)'
          : 'linear-gradient(45.32deg, rgba(197, 198, 214, 0.4) -3.85%, rgba(238, 238, 243, 0.4) 93.41%)',
        ':hover': !disableHover && {
          background: 'linear-gradient(45deg, rgba(148, 184, 255, 0.15) -3.85%, rgba(238, 243, 253, 0.15) 93.41%)',
        },
      }
    : {}),
});

export const appBarItemSelectedStyle = theme =>
  rebranding
    ? {
        svg: {
          color: theme.palette.colors.neutrals[100],
          ...iconSize(22),
        },
        borderColor: 'none',
        background: 'linear-gradient(45deg, rgba(148, 184, 255, 0.30) -3.85%, rgba(238, 243, 253, 0.30) 93.41%)',
      }
    : {
        svg: {
          color: theme.palette.colors.primary[500],
          ...iconSize(22),
        },
        backgroundColor: alpha(theme.palette.colors.primary[500], 0.1),
        color: theme.palette.colors.neutrals[800],
        ':hover': {
          backgroundColor: alpha(theme.palette.colors.primary[500], 0.15),
          color: theme.palette.colors.neutrals[800],
        },
      };
export const appBarItemHoveredStyle = theme => ({
  ':hover': rebranding
    ? {
        borderColor: theme.palette.colors.primary[400],
        color: theme.palette.colors.neutrals[100],
      }
    : {
        backgroundColor: theme.palette.colors.neutrals[300],
        color: theme.palette.colors.neutrals[700],
        svg: {
          color: theme.palette.colors.neutrals[600],
        },
      },
});

const opacityGradientBgColor = (theme, fullImage) => ({
  '.icon-container:before': {
    background: `url("${fullImage}")`,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.medium,
    }),
    content: '" "',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '32px',
    height: '32px',
    opacity: 0,
  },
});
