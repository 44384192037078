import React, { forwardRef, useMemo, useRef } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Skeleton, useTheme } from '@mui/material';
import { ChartTooltip, Gradient, GradientStop, VennArc, VennDiagram, VennSeries } from 'reaviz';
import { flex } from '../../../../components/AvThemeProvider';
import CustomTooltip from '../../../../components/Widgets/CustomTooltip';
import { NoDataFoundWidget } from '../../../../components/Widgets/layout.components';
import { emptyArray, iconSize } from '../../../../utils/Utils';
import { getTextWidth } from '../../../Policies/ToolCoverageAndGap/utils';
import { calculateNonOverlapping, noOverlap, TooltipDataType, vennTheme } from '../../Utils';
import { ReactComponent as NoData } from '../../../../assets/WidgetNoData.svg';

const customToolTip = (dp: { x: string; y: number }, tooltipData?: TooltipDataType) => (
  <CustomTooltip
    payload={[
      {
        dataKey: dp.x,
        ...(tooltipData
          ? {
              [noOverlap]: tooltipData?.[noOverlap],
              ...tooltipData?.overlaps,
            }
          : { [dp.x]: dp.y }),
        payload: {
          payload: tooltipData
            ? {
                [noOverlap]: tooltipData?.[noOverlap],
                ...tooltipData?.overlaps,
              }
            : {},
        },
      },
    ]}
    metricKey={dp.x}
    maxTitleWidth={1000}
    tooltipFields={[dp.x, ...(tooltipData ? [noOverlap, ...(tooltipData?.overlaps ? Object.keys(tooltipData?.overlaps) : [])] : [])]}
    titleFormatter={title => (
      <Box sx={{ ...flex.justifyBetweenCenter, gap: 2, width: '100%' }}>
        <Box>{title}</Box> <Box>Total: {dp.y}</Box>
      </Box>
    )}
  />
);

const CustomLabel = forwardRef<SVGTextElement, any>(({ data, id, fill = 'black', fontSize = 12, fontFamily = 'Inter' }: any, ref) => {
  const { x, y } = data.text ?? { x: 0, y: 0 };
  const {
    data: { key, sets },
    circles,
  } = data;
  const keyWidth = getTextWidth(key, 13, fontFamily) || 0;
  const relevantRadius = circles?.find(circle => circle.set === key)?.radius;
  const keyLongerThanCircle = relevantRadius < keyWidth / 2;
  const textTooLong = keyLongerThanCircle || sets.length > 1;
  const labelValue = !textTooLong && (
    <tspan x={x} dy="0">
      {key}
    </tspan>
  );
  return (
    <text
      id={`${id}-custom-text`}
      ref={ref}
      x={x}
      y={y}
      fill={fill}
      fontSize={fontSize}
      fontFamily={fontFamily}
      textAnchor="middle"
      fontWeight={600}
      pointerEvents="none">
      {labelValue}
    </text>
  );
});
const VennDiagramWidget = ({
  data: vennData = emptyArray,
  height = 400,
  width = 400,
  series = emptyArray,
  loading,
  emptyStateComponent = () => (
    <Box sx={{ ...flex.colCenter, width: '100%' }}>
      <NoDataFoundWidget title="Get started by adding sources" icon={<NoData style={iconSize(50)} />} />
    </Box>
  ),
}: {
  data: { key: string[]; data: number }[];
  height?: number;
  width?: number;
  series?: { name: string; color: string; component: ReactJSXElement }[];
  loading?: boolean;
  emptyStateComponent?: () => ReactJSXElement;
}) => {
  const theme = useTheme();
  const labelRef = useRef(null);
  const colorScheme = series.map(item => item?.color);
  const keyColorMap = Object.fromEntries(series.map(({ name, color }) => [name, color]));
  const tooltipData = useMemo(() => calculateNonOverlapping(vennData, keyColorMap), [vennData, keyColorMap]);
  return loading ? (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Skeleton width="100%" height="100%" />
    </Box>
  ) : (
    <Box
      sx={{
        '& svg': {
          width,
          height,
          pointerEvents: 'all',
          outline: 'none',
        },
        '& g': {
          outline: 'none',
        },
        width: '100%',
        height: '100%',
        ...flex.center,
      }}>
      {vennData.length > 0 && series.length > 0 ? (
        <VennDiagram
          id="simple"
          width={width}
          height={height}
          type="euler"
          data={vennData}
          series={
            <VennSeries
              colorScheme={colorScheme}
              label={<CustomLabel fill={theme.palette.colors.neutrals[900]} ref={labelRef} />}
              arc={
                <VennArc
                  gradient={<Gradient stops={[<GradientStop offset="0%" key="start" />]} />}
                  initialStyle={{ opacity: 0.35 }}
                  activeStyle={{ opacity: 0.35 }}
                  inactiveStyle={{ opacity: 0.35 }}
                  tooltip={<ChartTooltip theme={vennTheme} placement="left" content={dp => customToolTip(dp, tooltipData[dp.x])} />}
                />
              }
            />
          }
        />
      ) : (
        emptyStateComponent()
      )}
    </Box>
  );
};
export default VennDiagramWidget;
