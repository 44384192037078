import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { FormatterType } from '../../../components/Table/types';
import TextInput from '../../../components/TextInput';
import { FieldListObject } from '../../../context/context.type';
import { rebranding } from '../../../rebranding';
import { dotStyle } from '../../../utils/Utils';
import { SeverityScoring, SeverityScoringExpression, SeverityScoringFactorAction, SeverityScoringFactorType } from './scoreSettings.types';
import { ReactComponent as Warning } from '../../../assets/Attention.svg';

export const numberInputProps = {
  actionIcons: [<Box sx={{ color: theme => (theme as any).palette.colors.neutrals[800] }}>%</Box>],
  size: 'small',
  disableClearButton: true,
  type: 'number',
  style: { width: 100 },
};
export const borderStyle = { borderRadius: 1, border: ({ palette }) => `1px solid ${palette.colors.neutrals[350]}` };
export const tableTitleStyle = { ...flex.itemsCenter, gap: 1, fontWeight: 600, pb: 2, svg: { width: 16, height: 16 } };
export const sumStyle = (isError = false, isWarning = false) => ({
  py: 1,
  px: 2,
  background: ({ palette }) =>
    isError
      ? palette.colors.negative[100]
      : isWarning
        ? rebranding
          ? palette.colors.purple[100]
          : palette.colors.orange[100]
        : palette.colors.neutrals[300],
  color: ({ palette }) => palette.colors.neutrals[600],
  borderRadius: 2,
  border: ({ palette }) =>
    `1px solid ${
      isError ? palette.error.main : isWarning ? (rebranding ? palette.colors.purple[500] : palette.colors.orange[500]) : 'transparent'
    }`,
  fontWeight: 600,
  fontSize: 18,
});

export const getRiskAndMitigatingRows = ({ data, fieldMap }: { data: SeverityScoring; fieldMap: Record<string, FieldListObject> }) =>
  (data.riskAndMitigationFactorScorings || []).reduce<{
    risk: any[];
    mitigating: any[];
  }>(
    (acc, row, index) => {
      if (row.fieldName) {
        const tableRow = {
          id: index,
          fieldName: row.fieldName,
          name: row.name,
          percentage: Math.max(
            ...row.severityScoringExpression.severityScoringFilters.map(r => r.percentage),
            row.severityScoringExpression.fallbackPercentage
          ),
          entity: fieldMap[row.fieldName].entityTypeId.name,
        };

        if (row.type === SeverityScoringFactorType.Risk) {
          acc.risk = [...acc.risk, tableRow];
        } else if (row.type === SeverityScoringFactorType.Mitigation) {
          acc.mitigating = [...acc.mitigating, tableRow];
        }
      }
      return acc;
    },
    { risk: [], mitigating: [] }
  );

export const getRiskMitigatingRaisingFactorsTotal = (data: SeverityScoring) =>
  (data.riskAndMitigationFactorScorings || []).reduce((sum, row) => {
    const maxRaising = Math.max(
      ...[row.severityScoringExpression.fallbackPercentage, ...row.severityScoringExpression.severityScoringFilters.map(r => r.percentage)]
    );

    return sum + maxRaising;
  }, 0);

const missingField = isVisible => (
  <AvTooltip title="Field is mandatory">
    <Box sx={{ ...flex.itemsCenter, opacity: isVisible ? 1 : 0 }}>
      <Warning />
    </Box>
  </AvTooltip>
);
export const baseScoringHeadcells = (colorMap, onPercentChange) => [
  { isKey: true, id: 'id', hidden: true },
  {
    id: 'name',
    label: 'Factor Name',
    formatter: (value, row) => (
      <Box sx={{ ...flex.itemsCenter, gap: 2, width: '100%' }}>
        <Box sx={dotStyle(colorMap[row.fieldName])} />
        <AvTooltip>{value}</AvTooltip>
      </Box>
    ),
  },
  {
    id: 'percentage',
    label: 'MIN %',
    type: FormatterType.number,
    formatter: (value, row) => (
      <Box sx={{ ...flex.justifyEnd }}>
        {/*  @ts-ignore */}
        <TextInput
          onClick={e => e.stopPropagation()}
          value={value}
          onChange={v => onPercentChange(row.id, v)}
          {...numberInputProps}
          style={{ width: 70 }}
        />
      </Box>
    ),
  },
];
export const riskAndMitigatingHeadCells = () => [
  { isKey: true, id: 'id', hidden: true, label: 'key' },
  {
    id: 'name',
    label: 'Factor Name',
    formatter: value => (
      <Box sx={{ maxWidth: 150, gap: 2 }}>
        <AvTooltip>{value}</AvTooltip>
      </Box>
    ),
  },
  {
    id: 'entity',
    label: 'Entity',
    formatter: v => <Box sx={{ width: 80 }}>{v ? <AvTooltip>{v}</AvTooltip> : missingField(true)}</Box>,
  },
  { id: 'percentage', label: 'MAX %', formatter: value => `${value}%`, type: FormatterType.number },
];

export const getRiskMitigatingLowerThan100Message = baseScoringSum =>
  `The sum weights should be ${
    100 - baseScoringSum
  }% or more In case their overall contribution from individual weights will sum to more than that, we will cap their influence at ${
    100 - baseScoringSum
  }%`;

export const getRiskMitigatingOver100Message = (
  totalRaisingRiskFactors,
  capping
) => `Overall contribution from individual weights is ${totalRaisingRiskFactors}%
we will cap their influence at ${capping}%. 
To allow them more influence on total score - reduce the weights of the base score factors accordingly`;
export const emptyBooleanExpression = (type: SeverityScoringFactorType): SeverityScoringExpression => ({
  fallbackPercentage: 0,
  fallbackAction: type === SeverityScoringFactorType.Risk ? SeverityScoringFactorAction.REDUCE : SeverityScoringFactorAction.RAISE,
  severityScoringFilters: [
    {
      percentage: 0,
      action: type === SeverityScoringFactorType.Risk ? SeverityScoringFactorAction.RAISE : SeverityScoringFactorAction.REDUCE,
      filter: {
        expression: {
          fieldName: '',
          stringCondition: {
            equals: 'TRUE',
          },
        },
      },
    },
    {
      percentage: 0,
      action: type === SeverityScoringFactorType.Risk ? SeverityScoringFactorAction.REDUCE : SeverityScoringFactorAction.RAISE,
      filter: {
        expression: {
          fieldName: '',
          stringCondition: {
            equals: 'FALSE',
          },
        },
      },
    },
  ],
});
