import React from 'react';
import { Box, Collapse, Divider, Skeleton, Typography } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { HistoricDataIndicator } from '../../../components/Widgets/layout.components';
import { Filter } from '../../../types/filter.types';
import { iconSize } from '../../../utils/Utils';
import ProgressTileWidget, { ProgressWidgetProps } from '../../CustomDashboards/components/Widgets/TileWidget/ProgressTileWidget';
import TrendTileWidget, { TrendWidgetProps } from '../../CustomDashboards/components/Widgets/TileWidget/TrendTileWidget';
import { SortDir } from '../../CustomDashboards/types';
import { Orientation, OrientationType } from '../../CustomDashboards/Utils';
import PolicyTableBreakdown from './PolicyTableBreakdown';
import { expandedSuffix } from './utils';
import { ReactComponent as ArrowDown } from '../../../assets/Arrow Down.svg';

const ArrowDownIcon = isCollapse => <ArrowDown style={{ ...iconSize(18), transform: `rotate(${isCollapse ? 0 : -90}deg)` }} />;

interface Props {
  trendConfig?: TrendWidgetProps;
  progressConfig: ProgressWidgetProps;
  policyName: string;
  isLoadingTrend: boolean;
  isLoadingProgress: boolean;
  expanded: boolean;
  setPolicyState: (policyName) => (field) => (val) => void;
  breakdown: string;
  formattingRuleId: string | undefined;
  filter?: Filter;
  sortField?: string;
  sortDir?: SortDir;
}
const tooltipWidth = {
  sm: 90,
  md: 150,
  lg: 200,
  xl: 300,
};
const PolicyComplianceWidget = ({
  policyName,
  formattingRuleId,
  trendConfig,
  progressConfig,
  isLoadingTrend,
  isLoadingProgress,
  expanded,
  breakdown,
  setPolicyState,
  filter,
  sortField,
  sortDir,
}: Props) => {
  const policyFilter = {
    expression: {
      fieldName: 'policy_population.policy_name',
      stringCondition: {
        equals: policyName,
      },
    },
  };
  const tableFilter = filter && Object.keys(filter).length ? { and: { operands: [filter, policyFilter] } } : policyFilter;

  return (
    <Box>
      <Box
        sx={{
          height: 80,
          bgcolor: theme => theme.palette.colors.neutrals[100],
          ...flex.justifyStartCenter,
          gap: 12,
          boxShadow: '0px 0px 7px 0px rgba(57, 58, 86, 0.04)',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0px 4px 15px 0px rgba(57, 58, 86, 0.2)',
          },
        }}
        onClick={() => setPolicyState(policyName)(expandedSuffix)(!expanded)}>
        <Box sx={{ ...flex.justifyStartCenter, gap: 2, pl: 3, width: '20%' }}>
          {ArrowDownIcon(expanded)}
          <Box>
            {isLoadingTrend && isLoadingProgress ? (
              <Skeleton width={50} />
            ) : (
              <Typography variant="h5" sx={{ width: tooltipWidth }}>
                <AvTooltip>{policyName}</AvTooltip>
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ ...flex.justifyBetweenCenter, width: '70%', height: '100%' }}>
          <Box sx={{ width: '40%', height: '100%', cursor: 'default' }} onClick={e => e.stopPropagation()}>
            {isLoadingProgress ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              <ProgressTileWidget
                {...progressConfig}
                orientation={OrientationType.Horizontal}
                titleTextSx={{
                  fontWeight: 400,
                  fontSize: '24px',
                  width: 70,
                  lineHeight: '48px',
                  ...flex.justifyEndCenter,
                }}
                barWidth={250}
              />
            )}
          </Box>
          <Divider
            orientation="vertical"
            sx={{ borderColor: theme => theme.palette.colors.neutrals[350], borderWidth: 1, height: '50%' }}
            variant="middle"
          />
          <Box sx={{ width: '40%', height: 60, cursor: 'default' }} onClick={e => e.stopPropagation()}>
            {isLoadingTrend ? (
              <Skeleton width="100%" height="100%" />
            ) : trendConfig && trendConfig?.data.length > 1 ? (
              <TrendTileWidget {...trendConfig} orientation={Orientation.Horizontal} showPercentage={false} trendWidth="60%" />
            ) : (
              <Box
                sx={{
                  height: '100%',
                  ...flex.justifyStartCenter,
                  fontSize: '14px',
                  fontWeight: 600,
                  color: theme => theme.palette.colors.neutrals[500],
                }}>
                No data yet
              </Box>
            )}
          </Box>
          <Box sx={{ ...flex.justifyEndCenter, width: 20, height: '100%', pr: 2, mb: 4 }}>
            <HistoricDataIndicator hideText />
          </Box>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <PolicyTableBreakdown
          filter={tableFilter}
          policyName={policyName}
          breakdown={breakdown}
          formattingRuleId={formattingRuleId}
          setPolicyState={setPolicyState}
          sortField={sortField}
          sortDir={sortDir}
        />
      </Collapse>
    </Box>
  );
};

export default PolicyComplianceWidget;
