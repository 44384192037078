import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { flex } from '../../components/AvThemeProvider';
import { PeriodBehavior } from '../../components/DatePicker/AvDateRangePicker.constants';
import { FormatterType } from '../../components/Table/types';
import { rebranding } from '../../rebranding';
import { APP_PATHS } from '../../types';
import { DateCondition } from '../../types/filter.types';
import { downloadBlob, generateID, generateInnerDefaultFormatter, generateUUID, getPercent, isNullOrUndefined } from '../../utils/Utils';
import { FormattingCondition } from '../FormattingRules/types';
import { getColorFromFormattingConditions } from '../FormattingRules/utils';
import { DeliveryMethodOptions, FileFormat, TimeBucket } from '../Reports/types';
import { formatTimeRange } from './components/TimeFilter/utils';
import { hasTimeBucket } from './EditCustomDashboard/TimeBucketBreakdown/utils';
import {
  BarAxisTickType,
  BarLegendPosition,
  BarSubType,
  BarVerticalTypeWidget,
  ColoringRules,
  ColumnFormatter,
  DisplayModeOptions,
  DonutTypeWidget,
  DwQueryRequest,
  Field,
  LineLegendPosition,
  LineSubType,
  LineWidget,
  publicId,
  TableTypeWidget,
  TableWidget,
  TextCategoryWidgetTypes,
  TextSubType,
  TileSubType,
  TileTypeWidget,
  TileWidgetVisualization,
} from './types';
import { WidgetCategory, WidgetDefinition } from './types/base.types';
import { AllTypeWidgets, CustomDashboardDto, PermissionType, ReportsTypes } from './types/combined.types';
import { PieBasicLegendPosition, PieLegendStyles, PieSubType, PieWidget } from './types/PieWidget.types';
import { dwQueryDefault } from './types/QueryObject.types';
import { TableRowSize, TableSubType } from './types/TableWidget.types';
import { SizeLimit } from './types/types';

const PeriodBehaviorCapitelized = {
  [PeriodBehavior.last]: 'Last',
  [PeriodBehavior.previous]: 'Previous',
};
export enum Orientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
export const widgetTempIdInit = 'temp_widget_';

export const getRepeatedValueToString = v => (Array.isArray(v) ? v.join(', ') : v);
const removeTempIdFromWidget = ({ id, ...rest }: AllTypeWidgets): AllTypeWidgets =>
  id?.startsWith(widgetTempIdInit) ? rest : { id, ...rest };

export const generateTempIdForWidget = () => `${widgetTempIdInit}${generateID()}`;
export const removeTempIdsFromDashboard = (dashboard: CustomDashboardDto): CustomDashboardDto => ({
  ...dashboard,
  widgets: dashboard.widgets.map(removeTempIdFromWidget),
});

export const filterTimeBucketFromDimensions = (dims: Field[]) => dims.filter(({ name }) => !hasTimeBucket(name));
export const isHistoricalQuery = (request: DwQueryRequest) => !!request.timeRange;
export const shouldShowDrillDown = (newWidget: AllTypeWidgets) =>
  [WidgetCategory.Pie, WidgetCategory.Table, WidgetCategory.Bar].includes(newWidget.category);

export const getTimeRangeDescription = (timeRange?: DateCondition): string => {
  if (!timeRange) {
    return '';
  }
  if (timeRange.relative) {
    const { value, unit, periodBehaviour } = timeRange.relative;
    const periodText = PeriodBehaviorCapitelized[periodBehaviour];
    return `${periodText} ${value} ${unit.toLowerCase()}`;
  }
  if (timeRange.between) {
    return `${formatTimeRange(timeRange)}`;
  }
  return '';
};

export const canRenderWidget = ({ widget }: { widget: AllTypeWidgets }) => {
  const {
    requests: [
      {
        select: { dims, metrics },
      },
    ],
  } = widget;
  if (widget.category === WidgetCategory.Pie) {
    return (dims.length === 1 && metrics.length === 1) || (dims.length === 0 && metrics.length === 1);
  }
  if (widget.category === WidgetCategory.Table) {
    return filterTimeBucketFromDimensions(dims).length > 0 || metrics.length > 0;
  }
  if (widget.category === WidgetCategory.Bar) {
    return (dims.length === 2 && metrics.length === 1) || (dims.length === 1 && metrics.length < 6 && metrics.length > 0);
  }
  if (widget.category === WidgetCategory.Line) {
    return (
      dims.length <= 2 && dims.length > 0 && filterTimeBucketFromDimensions(dims).length <= 1 && metrics.length < 6 && metrics.length > 0
    ); // supporting historical dim and another dim
  }
  if (widget.category === WidgetCategory.Tile) {
    if (widget.type === TileSubType.Trend) {
      return metrics.length === 1;
    }
    if (widget.type === TileSubType.Tile) {
      return dims.length === 0 && metrics.length === 1;
    }
  }
  return true;
};

export const isValidTimeRange = (timeRange?: DateCondition) => {
  if (!timeRange) {
    return true;
  }
  if (timeRange.relative) {
    const { periodBehaviour, value, unit } = timeRange.relative;
    return !!(periodBehaviour && value && unit);
  } // implement other cases when needed
  return true;
};

export const getPinToAppRows = (rows, selectedApp, allUsers, apps) => {
  const selectedAppName = apps.find(({ name }) => APP_PATHS[name] === selectedApp)?.name;
  return rows
    .filter(row => selectedAppName && !row.apps?.includes(selectedAppName) && !row.predefined)
    .map(row => ({
      ...row,
      createdByUserId: row.createdByUserId ? allUsers[row.createdByUserId] : '',
      sharedUsers:
        row.dashboardReportPermission?.editors.includes(publicId) || row.dashboardReportPermission?.viewers.includes(publicId)
          ? publicId
          : [...(row.dashboardReportPermission?.editors || []), ...(row.dashboardReportPermission?.viewers || [])].map(
              userId => allUsers[userId]
            ),
    }));
};

export const defaultDeliveryConfig = (fileFormat = FileFormat.PDF_FORMAT) => ({
  integrationId: null,
  type: DeliveryMethodOptions.Email,
  recipients: [],
  title: '',
  message: '',
  attachmentMetadata: {
    fileFormat,
  },
});

export const defaultDashboard: CustomDashboardDto = {
  type: ReportsTypes.Visual,
  active: false,
  name: 'New Dashboard',
  intervalExpression: '0 0 * * *',
  deliveryConfig: defaultDeliveryConfig(),
  description: '',
  apps: [],
  widgets: [],
  customQueries: null,
  dashboardReportPermission: {
    viewers: [],
    editors: [],
    type: PermissionType.private,
  },
};

export const defaultDefinition: WidgetDefinition = {
  title: {
    title: '',
  },
  coords: {
    x: 0,
    y: Infinity,
    h: 4,
    w: 6,
  },
};

export const defaultDrillDownHierarchy = {
  activeIndex: 0,
  fields: [],
  drillDownValues: [],
  notOthersDrillDownValues: [],
};
export const defaultWidget: AllTypeWidgets = {
  id: '',
  definition: { ...defaultDefinition, custom: { rowSize: TableRowSize.Small } },
  category: WidgetCategory.Table,
  drillDownHierarchy: defaultDrillDownHierarchy,
  type: TableSubType.Table,
  requests: [dwQueryDefault],
};

export const tableDefaultSize = 20;
export const chartsDefaultSize = 100;
export const defaultTable = () => ({
  ...defaultWidget,
  requests: defaultWidget.requests.map(r => ({ ...r, ...(r.top ? { top: { ...r.top, size: tableDefaultSize } } : undefined) })),
});
export const defaultPie = (type: PieSubType = PieSubType.Pie): PieWidget => ({
  id: '',
  definition: {
    ...defaultDefinition,
    custom: {
      ...(type === PieSubType.Donut && { showTotals: true }),
      legend: {
        position: PieBasicLegendPosition.Top,
        style: PieLegendStyles.Table,
        showValues: true,
        showPercentage: true,
      },

      fieldsPalette: {},
    },
  },
  category: WidgetCategory.Pie,
  type,
  requests: [dwQueryDefault],
  drillDownHierarchy: defaultDrillDownHierarchy,
});

export const defaultBar: BarVerticalTypeWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
    custom: {
      legend: { position: BarLegendPosition.Top },
      rowsColumnSwitch: false,
      fieldsPalette: {},
      displayMode: DisplayModeOptions.FIT,
      xAxisTickType: BarAxisTickType.Dynamic,
      yAxisTickType: BarAxisTickType.Dynamic,
    },
  },
  category: WidgetCategory.Bar,
  type: BarSubType.BarVertical,
  requests: [dwQueryDefault],
  drillDownHierarchy: defaultDrillDownHierarchy,
};

export const defaultLine: LineWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
    custom: { fieldsPalette: {}, legend: { position: LineLegendPosition.Top } },
  },
  category: WidgetCategory.Line,
  type: LineSubType.Line,
  requests: [dwQueryDefault],
  drillDownHierarchy: defaultDrillDownHierarchy,
};

export const defaultTile: TileTypeWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
    coords: {
      x: 0,
      y: Infinity,
      h: 3,
      w: 4,
    },
    custom: {
      visualization: TileWidgetVisualization.None,
      coloringRule: ColoringRules.UP_IS_GOOD,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Tile,
  requests: [dwQueryDefault],
};
export const defaultTrend: TileTypeWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
    coords: {
      x: 0,
      y: Infinity,
      h: 3,
      w: 4,
    },
    custom: {
      visualization: TileWidgetVisualization.None,
      coloringRule: ColoringRules.UP_IS_GOOD,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Tile,
  requests: [
    {
      ...dwQueryDefault,
      groupBy: [TimeBucket.BUCKET_DAY],
      timeRange: {
        relative: {
          value: 30,
          unit: 'DAYS',
          periodBehaviour: PeriodBehavior.last,
        },
      },
      select: { ...dwQueryDefault.select, dims: [{ name: TimeBucket.BUCKET_DAY }] },
    },
  ],
};

export const defaultText: TextCategoryWidgetTypes = {
  id: '',
  definition: { ...defaultDefinition, custom: { text: '' } },
  category: WidgetCategory.Text,
  type: TextSubType.Text,
  requests: [dwQueryDefault],
};
export enum StackedType {
  None,
  Stacked,
  Stacked100,
}
export enum OrientationType {
  Vertical,
  Horizontal,
}
export const orientationOption = {
  [BarSubType.BarHorizontal]: OrientationType.Horizontal,
  [BarSubType.StackedHorizontal]: OrientationType.Horizontal,
  [BarSubType.Percentage100_Horizontal]: OrientationType.Horizontal,
  [BarSubType.BarVertical]: OrientationType.Vertical,
  [BarSubType.StackedVertical]: OrientationType.Vertical,
  [BarSubType.Percentage100_Vertical]: OrientationType.Vertical,
};
export const stackedOption = {
  [BarSubType.BarVertical]: StackedType.None,
  [BarSubType.BarHorizontal]: StackedType.None,
  [BarSubType.StackedVertical]: StackedType.Stacked,
  [BarSubType.StackedHorizontal]: StackedType.Stacked,
  [BarSubType.Percentage100_Vertical]: StackedType.Stacked100,
  [BarSubType.Percentage100_Horizontal]: StackedType.Stacked100,
};

export const getSubType = (orientation, stacked) => {
  if (orientation === OrientationType.Horizontal) {
    if (stacked === StackedType.None) {
      return BarSubType.BarHorizontal;
    }
    if (stacked === StackedType.Stacked) {
      return BarSubType.StackedHorizontal;
    }
    return BarSubType.Percentage100_Horizontal;
  }
  if (stacked === StackedType.None) {
    return BarSubType.BarVertical;
  }
  if (stacked === StackedType.Stacked) {
    return BarSubType.StackedVertical;
  }
  return BarSubType.Percentage100_Vertical;
};

export const loadingDashboardTempLayout = [
  {
    i: 'a',
    x: 0,
    y: 0,
    w: 8,
    h: 6,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'b',
    x: 8,
    y: 0,
    w: 4,
    h: 6,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'c',
    x: 0,
    y: 6,
    w: 4,
    h: 4,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'd',
    x: 4,
    y: 6,
    w: 4,
    h: 4,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'e',
    x: 8,
    y: 6,
    w: 4,
    h: 4,
    isResizable: false,
    isDraggable: false,
  },
];

export const fieldFormatter = (value, type) => {
  if (isNullOrUndefined(value)) {
    return '';
  }
  switch (type) {
    case FormatterType.number:
      return value.toLocaleString();
    case FormatterType.percentage:
      return `${getPercent(value)}`;
    case FormatterType.date:
      return new Date(value).toLocaleString();
    case FormatterType.array:
      return (Array.isArray(value) ? value : [value]).join(', ');
    default:
      return value.toString();
  }
};

const innerLabelStyle = (label, size) => ({
  ...flex.itemsCenter,
  color: ({ palette }) => palette.colors.neutrals[600],
  position: 'relative',
  right: 6,
  whiteSpace: 'nowrap',
  mr: '-2px',
  fontSize: 12,
  ...(rebranding && { fontWeight: 400 }),
  ':before': {
    ...flex.center,
    content: `"${label}"`,
    backgroundColor: ({ palette }) => palette.colors.neutrals[300],
    width: 'calc(100% + 16px)',
    position: 'absolute',
    top: size === 'xSmall' ? -3 : -6,
    bottom: size === 'xSmall' ? -3 : -6,
    left: size === 'xSmall' ? -4 : -10,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
  },
});

export const inputInnerLabelStartAdornment =
  (label, size = 'xSmall') =>
  () => <Box sx={innerLabelStyle(label, size)}>{label}</Box>;

const common: SizeLimit = { minW: 4, maxW: 12, minH: 4, maxH: 8 };

export const sizeLimitsPerCategory: Record<WidgetCategory, SizeLimit> = {
  [WidgetCategory.Pie]: common,
  [WidgetCategory.Bar]: common,
  [WidgetCategory.Line]: common,
  [WidgetCategory.Table]: common,
  [WidgetCategory.Text]: { ...common, minH: 1, minW: 2 },
  [WidgetCategory.Tile]: { minH: 2, maxH: 4, minW: 2, maxW: 6 },
};

export const downloadDashboardWidgetsAsJson = (widgets, dashboardName) =>
  downloadBlob(
    JSON.stringify(widgets.map(w => ({ ...w, id: generateUUID() }))),
    'application/json',
    `Avalor_${dashboardName}_dashboard_${generateID()}.json`
  );

export const activeIconStyle = theme => ({
  '&.MuiButtonBase-root.MuiIconButton-root:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'content-box',
    borderRadius: '10px',
    backgroundColor: theme.palette.colors.neutrals[300],
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shorter,
    }),
  },
});

export enum ModeType {
  Edit,
  Create,
  SaveAs,
  Share,
}

export const shouldShowTotals = (widget: AllTypeWidgets) =>
  (!!(widget as DonutTypeWidget).definition.custom?.showTotals || !!(widget as TableWidget).definition.custom?.showTotals) &&
  !widget.requests[0].select.dims.find(dim => hasTimeBucket(dim.name)) &&
  !!widget.requests[0].select.metrics.length;

export const isTableWidget = (widget: AllTypeWidgets): widget is TableTypeWidget => widget.type === TableSubType.Table;

export const trendDefaultRequestParams = (request: DwQueryRequest) => ({
  groupBy: [TimeBucket.BUCKET_DAY],
  timeRange: {
    relative: {
      value: 30,
      unit: 'DAYS',
      periodBehaviour: PeriodBehavior.last,
    },
  },
  select: { ...request.select, dims: [{ name: TimeBucket.BUCKET_DAY }] },
});

export const getColumnFormatter = ({
  value,
  type,
  columnFormatter,
  formattingConditions,
  fieldName,
}: {
  value: unknown;
  type?: string;
  columnFormatter?: ColumnFormatter;
  formattingConditions: FormattingCondition[];
  fieldName?: string;
}) => {
  switch (columnFormatter) {
    case ColumnFormatter.Progress:
      return (
        <Box sx={{ ...flex.justifyEndCenter, width: '100%', gap: 1 }}>
          {getPercent(value as number)}
          <LinearProgress
            value={value as number}
            variant="determinate"
            sx={{
              borderRadius: 20,
              height: 8,
              width: 48,
              background: theme => theme.palette.colors.neutrals[350],
              [`& .MuiLinearProgress-bar`]: {
                background: theme =>
                  getColorFromFormattingConditions({
                    value: value as number,
                    formattingConditions,
                    defaultColor: theme.palette.colors.primary[500],
                  }),
                borderRadius: 0,
              },
              transition: 'ease-in',
            }}
          />
        </Box>
      );
    default:
      return generateInnerDefaultFormatter({ value, type, fieldName });
  }
};

export const getAllGroups = (items: string[]): string[][] =>
  items.reduce<string[][]>((acc, item) => [...acc, ...acc.map(group => [...group, item]), [item]], []);

export const formatDataForVennDiagram = (input: Record<string, number>[] | null | undefined): { key: string[]; data: number }[] => {
  if (!input || input.length === 0) {
    return [];
  }

  const result = Object.entries(input[0])
    .filter(([key]) => key !== 'id')
    .map(([key, data]) => ({
      key: key.split(', ').map(item => item.trim()),
      data,
    }));

  // Sort the result by the length of the 'key' array, and by alphabetical order if the length is the same
  return result.toSorted((a, b) => {
    if (a.key.length === b.key.length) {
      return a.key.join(', ').localeCompare(b.key.join(', '));
    }
    return a.key.length - b.key.length;
  });
};

export const noOverlap = 'No overlap';
export type TooltipDataType = {
  [noOverlap]: { value: number; percentage?: string; fill?: string };
  overlaps: Record<string, { value: number; percentage: string; fill?: string }>;
};
export const vennTheme = { base: 'custom-venn-tooltip', disablePointer: '' };

export const calculateNonOverlapping = (data: { key: string[]; data: number }[], keyColorMap) => {
  const keys = Array.from(new Set(data.flatMap(item => item.key)));

  const result: {
    [key: string]: {
      'No overlap': { value: number; percentage?: string; fill?: string };
      overlaps: { [key: string]: { value: number; percentage: string; fill?: string } };
    };
  } = {};

  keys.forEach(key => {
    const totalKeyValue = data.find(item => item.key.length === 1 && item.key[0] === key)?.data || 0;

    result[key] = { [noOverlap]: { value: totalKeyValue }, overlaps: {} };
    const overlapThree = data.find(item => item.key.length === 3 && item.key.includes(key))?.data || 0;

    keys.forEach(otherSource => {
      if (key !== otherSource) {
        const overlap = data.find(item => item.key.length === 2 && item.key.includes(key) && item.key.includes(otherSource))?.data || 0;
        if (overlap > 0) {
          result[key].overlaps[`Overlaps with ${otherSource}`] = {
            value: overlap,
            percentage: getPercent((overlap / totalKeyValue) * 100),
            fill: keyColorMap[otherSource],
          };
        }
      }
    });

    const overlapTwoData = Object.values(result[key].overlaps).reduce((acc, overlap) => acc + overlap.value, 0);
    const noOverlapValue = totalKeyValue - overlapTwoData + overlapThree;
    result[key][noOverlap] = {
      value: noOverlapValue,
      percentage: getPercent((noOverlapValue / totalKeyValue) * 100),
      fill: keyColorMap[key],
    };
  });

  return result;
};
