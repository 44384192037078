import React from 'react';
import { Box, Divider } from '@mui/material';
import { generateUUID } from '../../utils/Utils';
import { endAdornmentMeasurementUnit, FormattingRule } from '../../views/FormattingRules/types';
import { MeasurementUnit } from '../../views/Reports/types';
import { flex } from '../AvThemeProvider';
import { operatorLabels } from '../filters/Utils';
import ColorItem from './ColorItem';

interface ColorDescriptionProps {
  rule: FormattingRule;
  colorSize?: number;
}

const ColorDescription: React.FC<ColorDescriptionProps> = ({ rule, colorSize }) => {
  const conditionWithoutLast = rule.formattingConditions.slice(0, -1);
  const fallbackCondition = rule.formattingConditions.at(-1);
  return (
    <Box sx={{ ...flex.row, gap: 1 }}>
      {conditionWithoutLast.map((v, index) => {
        const condition = Object.keys(v.filter!.expression!.numberCondition || {})[0];
        return (
          <Box key={generateUUID()} sx={{ ...flex.center, gap: 1 }}>
            <Box sx={{ ...flex.row, whiteSpace: 'nowrap' }}>
              {condition === 'between' ? (
                `${getValueWithType(v.filter!.expression!.numberCondition![condition]?.from, rule.type)}-${getValueWithType(
                  v.filter!.expression!.numberCondition![condition]?.to,
                  rule.type
                )}`
              ) : (
                <>
                  {operatorLabels[condition]}
                  {getValueWithType(v.filter!.expression!.numberCondition![condition], rule.type)}
                </>
              )}
            </Box>
            <ColorItem item={v.color} size={colorSize} />
            {index !== rule.formattingConditions.length - 1 && <Divider orientation="vertical" />}
          </Box>
        );
      })}
      {fallbackCondition && (
        <Box sx={{ ...flex.center }}>
          <ColorItem item={fallbackCondition.color} size={colorSize} />
        </Box>
      )}
    </Box>
  );
};

const getValueWithType = (value, type: MeasurementUnit) => `${value}${endAdornmentMeasurementUnit[type] || ''}`;

export default ColorDescription;
