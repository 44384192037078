import { flex } from '../../../components/AvThemeProvider';
import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';

export enum TileSubType {
  Tile = 'TILE',
  Score = 'SCORE',
  Trend = 'TREND',
}

export enum ColoringRules {
  UP_IS_GOOD = 'Up = good, Down = bad',
  DOWN_IS_GOOD = 'Up = bad, Down = good',
}

export const tileTitleSX = {
  fontSize: 32,
  color: theme => theme.palette.colors.neutrals[800],
  fontWeight: 500,
  height: '35%',
  minHeight: 45,
  maxHeight: 60,
  ...flex.justifyStartEnd,
};

export type TileWidget = BaseWidget & {
  category: WidgetCategory.Tile;
  type: TileSubType;
  definition: WidgetDefinition & { custom: SubTileCustomDefinition };
};

export enum TileWidgetVisualization {
  Progress = 'Data Bar',
  None = 'None',
}

export type SubTileCustomDefinition = {
  visualization?: TileWidgetVisualization;
  completionDisplayName?: string;
  coloringRule?: ColoringRules;
  formattingRuleId?: string;
};
export type TileTypeWidget = TileWidget & {
  type: TileSubType.Tile;
};

export type TrendTypeWidget = TileWidget & {
  type: TileSubType.Trend;
};

export type ScoreTypeWidget = TileWidget & {
  type: TileSubType.Score;
};

export type TileCategoryWidgetTypes = TileTypeWidget | ScoreTypeWidget | TrendTypeWidget;
