import { ReactNode } from 'react';
import { Divider } from '@mui/material';
import { DocumentNode } from 'graphql/language';
import AvCheckbox from './AvCheckbox';
import OpenSelect from './OpenSelect';
import RadioButton from './RadioButton';
import Select from './Select';
import UploadFile from './UploadFile';

// eslint-disable-next-line import/prefer-default-export
export const INPUT_TYPES = {
  TEXT: 'text',
  SELECT: Select,
  OPEN_SELECT: OpenSelect,
  BOOL: AvCheckbox,
  RADIO: RadioButton,
  UPLOAD_FILE: UploadFile,
  OAUTH: 'OAuth',
  DIVIDER: Divider,
};

export type FormComponent = {
  label?: string;
  valueName: string;
  required?: boolean;
  params?: any;
  type?: any;
  defaultValue?: any;
  shouldShow?: (data) => boolean;
  text?: ReactNode;
  sendAsArray?: boolean;
  shouldUpdateFieldWith?: (data) => { field: string; value: any };
  onChange?: (value: any) => void;
};

export type Form = {
  title: string;
  description?: string;
  saveButtonText?: string;
  formComponents: FormComponent[];
  query: DocumentNode | string;
  propagateTo?: string;
  handleResponse?: ResponseHandler;
  queryOptions?: Record<string, any>;
  onSuccessNext?: VoidFunction;
  onReset?: VoidFunction;
};

export enum ResponseHandler {
  WORKFLOW = 'Workflow',
  ADD_ACCOUNT = 'addAccount',
  API_ACCESS = 'apiAccess',
  NONE = 'none',
}
