import { gql } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { QueryKey } from '../utils/queryKey';
import { initializePendo, shouldRecordSession } from '../utils/rum.utils';
import { AccountDto } from './types';

export const GET_ACCOUNT = gql`
  query findStartupAccountByContext {
    findStartupAccountByContext {
      name
      logo
      accountType
      createdAt
      apps {
        id
        name
      }
    }
  }
`;

const GET_RECOMMENDATION_SETTINGS = gql`
  query GetRecommendationSettings {
    getRecommendationSettings {
      enabled
    }
  }
`;

export const useAccount = ({ api, demoMode, defaultAccount, enableRequests }) => {
  const nav = useNavigate();
  return useQuery<AccountDto>({
    queryKey: [QueryKey.account],
    queryFn: () =>
      Promise.all([api(GET_ACCOUNT), api(GET_RECOMMENDATION_SETTINGS)]).then(
        ([
          { data, errors },
          {
            data: { getRecommendationSettings },
          },
        ]) => {
          if (errors) {
            console.error(errors[0]?.message);
            if (errors?.[0].extensions?.status === 404) {
              nav('error-process-request');
              return defaultAccount;
            }
            throw new Error('Failed to fetch account data', errors[0]?.message);
          }
          const account = {
            ...data?.findStartupAccountByContext,
            flags: { isRecommendedFieldsEnabled: getRecommendationSettings.enabled },
          };
          if (shouldRecordSession) {
            datadogRum.setUser({
              ...datadogRum.getUser(),
              accountName: account?.name,
              accountType: account?.accountType,
              accountCreated: account?.createdAt,
            });
            initializePendo(datadogRum.getUser() as any);
          }
          return account;
        }
      ),
    enabled: demoMode || enableRequests,
    retry: 10,
    retryDelay: 1000,
  });
};
