import React, { ReactNode, useRef, useState } from 'react';
import { Box, Button, ClickAwayListener, Collapse, IconButton, List, Paper, Popper, Typography, useTheme } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import Select from '../../../components/Select';
import TextInput from '../../../components/TextInput';
import { useAvContext } from '../../../context/AvContextProvider';
import { DescriptorType, EntityTypeID } from '../../../context/context.type';
import { ellipsis } from '../../../utils/Utils';
import { fieldTypeIconsMap } from '../../ModelManagement/hooks';
import InputPopper from '../../Tickets/InputPopper';
import { buildMappingKey, DragItem, FieldType, GENERAL_ENTITY } from './mapping.types';
import { MappingItem } from './MappingItem';
import { ReactComponent as ArrowDown } from '../../../assets/Arrow Down.svg';
import { ReactComponent as Add } from '../../../assets/Plus.svg';

interface EntityProps {
  entityName: string;
  builtIn?: boolean;
  projName?: string;
  expanded?: boolean;
  setExpand(...args: unknown[]): unknown;
  entitiesRefsMap: any;
  collapseProps: any;
  onItemDoubleClick?(...args: unknown[]): unknown;
  onItemClick?(...args: unknown[]): unknown;
  onDragStart(...args: unknown[]): unknown;
  items: any[];
  onAdd?(...args: unknown[]): unknown;
  usedFields?: string[];
  size?: 'medium' | 'small';
}

function Entity({
  entityName = GENERAL_ENTITY.toLowerCase(),
  builtIn = false,
  projName = GENERAL_ENTITY.toLowerCase(),
  expanded,
  setExpand,
  entitiesRefsMap,
  collapseProps,
  onItemClick,
  onItemDoubleClick,
  onDragStart,
  items,
  onAdd,
  usedFields,
  size = 'medium',
}: EntityProps) {
  const theme = useTheme();
  const { fieldTypeMap } = useAvContext().accountEntities;
  const [isAddOpen, setIsAddOpen] = useState(false);
  const ref = useRef(null);
  const getDragItem = field => ({ type: DragItem.Entity, field, entityName, projName });

  const getType = field => {
    const type = projName && fieldTypeMap[`${projName}.${field}`];
    return fieldTypeIconsMap(theme.palette)[Array.isArray(type) ? JSON.stringify(type) : type] || type;
  };

  return (
    <div style={{ ...(entityName === GENERAL_ENTITY && { order: -1 }) }}>
      <div style={{ ...flex.justifyBetweenCenter }}>
        <Button ref={ref} onClick={() => setExpand(projName)} disabled={!items.length} sx={{ flexGrow: 1 }}>
          <ArrowDown
            style={{
              opacity: !items.length ? 0.4 : 1,
              color: theme.palette.colors.neutrals[500],
              transform: `rotate(${expanded ? 0 : -90}deg)`,
              transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.shorter }),
              width: size === 'small' ? 18 : undefined,
              height: size === 'small' ? 18 : undefined,
            }}
          />
          <Typography variant="h7" sx={{ ...flex.row, flexGrow: 1, opacity: !items.length ? 0.4 : 1 }}>
            <AvTooltip>
              {entityName} ({items.length})
            </AvTooltip>
          </Typography>
        </Button>
        {onAdd && (
          <>
            <AvTooltip title={`Add field to ${entityName}`}>
              <IconButton onClick={() => setIsAddOpen(true)}>
                <Add style={{ color: theme.palette.colors.neutrals[500] }} />
              </IconButton>
            </AvTooltip>
            <AddFieldPopper
              open={isAddOpen}
              entityTypeId={{ name: entityName, builtIn }}
              anchorEl={ref.current}
              onAdd={onAdd}
              close={() => setIsAddOpen(false)}
            />
          </>
        )}
      </div>
      <Box sx={{ ...flex.col, gap: 2 }}>
        <Collapse in={!!(expanded && items?.length)} {...collapseProps}>
          <List sx={{ gap: 1, ...flex.col, px: 0 }}>
            {items.map(({ name: field, displayName }) => (
              <MappingItem
                key={field}
                isUsed={usedFields?.includes(`${projName}.${field}`)}
                ref={ref => {
                  // eslint-disable-next-line no-param-reassign
                  entitiesRefsMap.current[
                    buildMappingKey({
                      entityName,
                      modelFieldName: field,
                    })
                  ] = ref;
                }}
                label={
                  <>
                    {getType(field)}
                    <Box sx={{ flex: 1, ...ellipsis }}>
                      <AvTooltip title={displayName ? `${displayName} (${field})` : ''} muiProps={{ placement: 'top' }}>
                        <span>{displayName || field}</span>
                      </AvTooltip>
                    </Box>
                  </>
                }
                size={size}
                onDoubleClick={() => onItemDoubleClick?.(getDragItem(field))}
                onClick={onItemClick && (() => onItemClick(getDragItem(field)))}
                onDragStart={() => onDragStart(getDragItem(field))}
              />
            ))}
          </List>
        </Collapse>
      </Box>
    </div>
  );
}

export default Entity;

interface AddFieldPopperProps {
  open: boolean;
  anchorEl: null;
  onAdd: (...args: any[]) => void;
  close: () => void;
  entityTypeId?: EntityTypeID;
}

interface FieldTypeOption {
  title: string;
  fieldType: string;
  repeated: boolean;
  Icon: ReactNode;
}
const nameOf = (fieldType: DescriptorType) => DescriptorType[fieldType];

const withRepeated = (fieldType, repeated) => (repeated ? JSON.stringify([fieldType]) : fieldType);

const useFieldTypeOptions = () => {
  const { palette } = useTheme();
  const iconMap = fieldTypeIconsMap(palette);
  return [
    { title: 'Text', fieldType: nameOf(DescriptorType.TYPE_STRING), repeated: false },
    { title: 'Number', fieldType: nameOf(DescriptorType.TYPE_DOUBLE), repeated: false },
    { title: 'Boolean', fieldType: nameOf(DescriptorType.TYPE_BOOL), repeated: false },
    { title: 'Date', fieldType: FieldType.Date, repeated: false },
    { title: 'Date & Time', fieldType: FieldType.DateTime, repeated: false },
    { title: 'Repeated Text', fieldType: nameOf(DescriptorType.TYPE_STRING), repeated: true },
    { title: 'Repeated Number', fieldType: nameOf(DescriptorType.TYPE_DOUBLE), repeated: true },
    { title: 'Repeated Boolean', fieldType: nameOf(DescriptorType.TYPE_BOOL), repeated: true },
    { title: 'Repeated Date', fieldType: FieldType.Date, repeated: true },
    { title: 'Repeated Date & Time', fieldType: FieldType.DateTime, repeated: true },
  ].map(item => ({ ...item, Icon: iconMap[withRepeated(DescriptorType[item.fieldType] || item.fieldType, item.repeated)] }));
};

const fieldTypeOption = (option: FieldTypeOption) => (
  <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
    {option.Icon}
    {option.title}
  </Box>
);

const findByTitle = (arr, title) => arr.find(item => item.title === title);

export function AddFieldPopper({ anchorEl, open, onAdd, close, entityTypeId }: AddFieldPopperProps) {
  const fieldTypeOptions = useFieldTypeOptions();
  const [fieldTypeTitle, setFieldTypeTitle] = useState(fieldTypeOptions[0].title);
  const [fieldName, setFieldName] = useState('');
  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ width: 300, zIndex: theme => theme.zIndex.modal }}>
      <ClickAwayListener onClickAway={() => open && close()}>
        <Paper sx={{ p: 2, ...flex.col, gap: 2 }} onClick={e => e.stopPropagation()}>
          <TextInput value={fieldName} onChange={setFieldName} label="Field Name" autoFocus size="small" isRequired />
          <Select
            size="small"
            label="Field Type"
            value={fieldTypeTitle}
            options={fieldTypeOptions}
            onChange={setFieldTypeTitle}
            renderOption={fieldTypeOption}
            startAdornment={option => (option ?? findByTitle(fieldTypeOptions, fieldTypeTitle)).Icon}
            getValueFunc={opt => opt.title}
            muiProps={{ disableClearable: true }}
          />
          <Box sx={{ ...flex.justifyEnd, mt: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={!fieldName}
              onClick={() => {
                const { fieldType, repeated } = findByTitle(fieldTypeOptions, fieldTypeTitle);
                onAdd({ displayName: fieldName, entityTypeId, fieldType, repeated });
                setFieldName('');
                setFieldTypeTitle(fieldTypeOptions[0].title);
                close();
              }}>
              Add
            </Button>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

interface AddEntityPopperProps {
  open: boolean;
  close: () => void;
  anchorEl: HTMLElement;
  onAdd: any;
}
export function AddEntityPopper({ anchorEl, open, onAdd, close }: AddEntityPopperProps) {
  return (
    open && (
      <InputPopper
        anchorEl={anchorEl}
        onApply={({ input }) => onAdd(input)}
        texts={{ input: 'Entity Name', button: 'Add' }}
        onClose={close}
        placement={{
          popper: 'bottom-end',
          transformOrigin: () => 'right top',
        }}
      />
    )
  );
}
