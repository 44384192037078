import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Filter, OperatorType, StringConditionType } from '../../types/filter.types';
import AvAddOperandButton from '../AvAddOperandButton';
import { flex } from '../AvThemeProvider';
import FieldFilterBox from './FieldFilterBox';
import { boxWidth, cleanValueFromExpression, filterOperatorOptions, getEmptyFilterExpression, isIngressExp } from './Utils';

interface RenderOperatorProps {
  filter: Filter;
  setFilter: any;
  fields: any[];
  isLoadingFields?: boolean;
  isVertical: boolean;
  size: 'xSmall' | 'small';
  defaultField: string | null;
  rootOperator: 'or' | 'and';
  level?: number;
  onToggleLogicalOperators: () => void;
  canAddNewFields?: boolean;
  canSelectFieldType?: boolean;
  supportParser?: boolean;
  autocompleteFilter?: Date[];
  canDeleteLast?: boolean;
  operatorOptions?: typeof filterOperatorOptions;
  showIngressToggle?: boolean;
}

const RenderOperator: React.FC<RenderOperatorProps> = ({
  filter,
  setFilter,
  rootOperator,
  size,
  fields,
  isLoadingFields,
  isVertical,
  level = 0,
  defaultField,
  onToggleLogicalOperators,
  canAddNewFields,
  canSelectFieldType,
  supportParser,
  autocompleteFilter,
  canDeleteLast,
  operatorOptions,
  showIngressToggle = false,
}) => {
  const { palette } = useTheme();

  const operatorColors = {
    and: palette.colors.primary[600],
    or: palette.colors.complementary[600],
  };

  const commonProps = {
    size,
    isVertical,
    fields,
    isLoadingFields,
    canAddNewFields,
    canSelectFieldType,
    supportParser,
    operatorOptions,
  };

  const operandsList = filter[rootOperator]?.operands;
  return operandsList?.map((innerFilter, index) => {
    const oppositeOperator = rootOperator === OperatorType.OR ? OperatorType.AND : OperatorType.OR;
    const setInnerFilter = value => {
      const operands = operandsList.map((v, i) => (i === index ? value : v));
      setFilter({ [rootOperator]: { operands } });
    };

    const onDelete = () => {
      const operands = operandsList.filter((v, i) => i !== index);
      if (operands.length === 0 && level === 0) {
        setFilter(
          canDeleteLast ? undefined : getEmptyFilterExpression(defaultField, supportParser ? StringConditionType.EQUALS : undefined)
        );
      } else if (operands.length === 1 && level !== 0) {
        setFilter(operands[0]);
      } else {
        setFilter({ [rootOperator]: { operands } });
      }
    };

    const getOperands = () => {
      const { operands } = filter[rootOperator]!;
      // @ts-ignore
      const newItem = cleanValueFromExpression(operands[index]);
      if (level === 0) {
        return operands.with(index, { [oppositeOperator]: { operands: [operands[index], newItem] } });
      }
      return operands.toSpliced(index + 1, 0, newItem);
    };
    const addLogicalExpression = () => setFilter({ [rootOperator]: { operands: getOperands() } });
    const isIngressExpression = isIngressExp(innerFilter); // TODO - add projection as prop to the component?
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Box key={index} sx={{ borderLeft: `2px solid ${operatorColors[rootOperator]}`, ml: level * 2 }}>
        {index > 0 && operandsList.length > index && (
          <Box
            sx={{
              ...flex.itemsStart,
              my: '-4px',
              ml: isVertical ? `${boxWidth / 2}px` : `${boxWidth / 4}px`,
            }}>
            <AvAddOperandButton style={{ translate: '-50%' }} size={size} operator={rootOperator} onClick={onToggleLogicalOperators} />
          </Box>
        )}
        {Object.keys(innerFilter)[0] === 'expression' || isIngressExpression ? (
          <FieldFilterBox
            {...commonProps}
            autocompleteFilter={autocompleteFilter}
            rootOperator={rootOperator}
            setFilter={setInnerFilter}
            level={level}
            filter={innerFilter}
            onDelete={onDelete}
            addLogicalExpression={operandsList.length === 1 ? undefined : addLogicalExpression}
            canSelectFieldType={canSelectFieldType}
            showIngressToggle={showIngressToggle}
            isIngressExp={isIngressExpression}
            defaultField={defaultField}
          />
        ) : (
          <RenderOperator
            {...commonProps}
            autocompleteFilter={autocompleteFilter}
            defaultField={defaultField}
            filter={innerFilter}
            setFilter={setInnerFilter}
            rootOperator={oppositeOperator}
            level={level + 1}
            onToggleLogicalOperators={onToggleLogicalOperators}
            showIngressToggle={showIngressToggle}
          />
        )}
      </Box>
    );
  });
};

export default RenderOperator;
