import React, { ReactNode, useRef, useState } from 'react';
import { Box, Button, Popover, SxProps } from '@mui/material';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import { iconSize } from '../../utils/Utils';
import { ReactComponent as QuestionFull } from '../../assets/QuestionFull.svg';

interface AvMessagePopoverProps {
  message?: ReactNode;
  icon: React.JSX.Element;
  includeIconInMessage?: boolean;
  placement?: 'top' | 'bottom';
  sx?: SxProps;
  interactive?: boolean;
  asTooltip?: boolean;
  disableInteractive?: boolean;
}

const AvMessagePopover: React.FC<AvMessagePopoverProps> = ({
  message = '',
  icon,
  includeIconInMessage = true,
  placement = 'bottom',
  sx = {},
  interactive,
  asTooltip,
  ...muiProps
}) => {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = () => setAnchorEl(ref.current);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return asTooltip ? (
    <AvTooltip
      variant="message"
      color="white"
      muiProps={{
        PopperProps: { sx: { '.MuiTooltip-tooltip': { maxWidth: 520 } }, onClick: e => e.stopPropagation() },
        ...muiProps,
      }}
      title={
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {includeIconInMessage && <Box sx={{ ...flex.row, alignSelf: 'start' }}>{icon}</Box>}
          {message}
        </Box>
      }>
      <Box sx={{ ...flex.row, cursor: 'pointer', '&:hover': { color: ({ palette }) => palette.primary.main } }}>{icon}</Box>
    </AvTooltip>
  ) : (
    <div style={flex.itemsCenter}>
      <Button ref={ref} onMouseEnter={handleOpen} onMouseLeave={handleClose} sx={{ minWidth: 'unset' }}>
        {icon}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        slotProps={{
          paper: {
            sx: {
              mt: placement === 'bottom' ? 2 : -2,
              boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
              maxWidth: 500,
              pointerEvents: interactive ? 'all' : undefined,
              ...sx,
            },
          },
        }}
        onClick={e => e.stopPropagation()}
        sx={{ pointerEvents: 'none' }}
        anchorOrigin={{ vertical: placement, horizontal: 'center' }}
        transformOrigin={{ vertical: placement === 'bottom' ? 'top' : 'bottom', horizontal: 'center' }}>
        <Box sx={{ p: 2, ...flex.itemsStart, gap: 1, wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
          {includeIconInMessage && icon}
          {message}
        </Box>
      </Popover>
    </div>
  );
};

interface AvLabelWithHelptipProps {
  label: string;
  message?: ReactNode;
}

export const AvLabelWithHelptip: React.FC<AvLabelWithHelptipProps> = ({ label, message }) => (
  <Box component="span" sx={{ position: 'relative', '> div': { position: 'absolute', ml: 1.5, display: 'inline' } }}>
    {label}
    {message && <AvMessagePopover message={message} icon={<QuestionFull {...iconSize(18)} />} asTooltip />}
  </Box>
);

export default AvMessagePopover;
