import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS, Permission } from '../../types';
import avLazy from '../../utils/AvLazy';
import Risk360BackOfficeActions from '../../views/BackOffice/Risk360BackOfficeActions';
import AccountManagementRoute from '../AccountManagementRoute';
import AccountUsageRoute from '../AccountUsageRoute';
import BackofficeRoute from '../BackofficeRoute';
import BuiltInFactorRulesRoute from '../BuiltInFactorRulesRoute';
import EditMyAccountRoute from '../EditMyAccountRoute';
import EditMyUserRoute from '../EditMyUserRoute';
import PermissionsRoute from '../PermissionsRoute';
import UserManagementRoute from '../UserManagementRoute';

const QueryPage = avLazy(() => import('../../views/BackOffice/QueryPage/QueryPage'));
const LogosPage = avLazy(() => import('../../views/BackOffice/LogosGallery'));

const Settings = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
    isBackOfficeAvailable,
  } = useAvContext();
  const queryPath = PAGE_PATHS.QUERY;
  const iconPath = PAGE_PATHS.ICONS;
  const risk360BackOfficePath = PAGE_PATHS.RISK360_BACKOFFICE_ACTIONS;

  return (
    <Route path={APP_PATHS.SETTINGS}>
      {AccountManagementRoute()}
      {UserManagementRoute(PAGE_PATHS.USER_MANAGEMENT)}
      {UserManagementRoute(PAGE_PATHS.ALL_USERS)}
      {EditMyAccountRoute()}
      {EditMyUserRoute()}
      {BackofficeRoute()}
      {PermissionsRoute()}
      {AccountUsageRoute()}
      {/* TODO: PERMISSIONS */}
      <Route
        path={queryPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: queryPath, permission: Permission.UPDATE })}>
            <QueryPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={iconPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: iconPath, permission: Permission.UPDATE }) || isInternalRole}>
            <LogosPage />
          </ProtectedRoute>
        }
      />
      {BuiltInFactorRulesRoute()}
      <Route
        path={risk360BackOfficePath}
        element={
          <ProtectedRoute
            isAllowed={hasAllowedPermission({ path: risk360BackOfficePath, permission: Permission.UPDATE }) || isBackOfficeAvailable}>
            <Risk360BackOfficeActions />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default Settings;
