import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { Box, Button, Collapse, Skeleton, SxProps, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import { IconVariant, ItemWithLogo } from '../../components/ItemWithLogo';
import { useAvContext } from '../../context/AvContextProvider';
import { EntityTypeID } from '../../context/context.type';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import { ScreenType } from '../../types/savedViews.types';
import { CollapseArrow, isNullOrUndefined, uniqBy } from '../../utils/Utils';
import { ReactComponent as Internal } from '../../assets/Internal.svg';

const GET_USAGE = gql`
  query ($projectionID: ProjectionIDInput!, $fieldName: String!) {
    findEntityFieldUsages(projectionID: $projectionID, fieldName: $fieldName)
  }
`;

interface Props {
  field: string;
  proj: EntityTypeID;
}

const FieldUsage: React.FC<Props> = ({ field, proj }) => {
  const {
    api,
    getPathName,
    typeNameMap: {
      destinationsObj: { destinationsOptions },
    },
    accountEntities: { aggProjs, fieldMap },
  } = useAvContext();

  const { data, isLoading } = useQuery({
    queryKey: [proj.name, field],
    queryFn: () =>
      api(GET_USAGE, {
        options: {
          projectionID: proj,
          fieldName: field,
        },
        onSuccess: ({ data }) => data.findEntityFieldUsages.usage,
      }),
  });

  const { dashboardItems, dashboardCounts, reports } = useMemo(() => {
    const items = data?.[UsageTypes.DashboardReports] || [];
    const counts = items.reduce((acc, item) => ({ ...acc, [item.id]: (acc[item.id] || 0) + 1 }), {});

    return {
      dashboardItems: uniqBy(
        items.filter(f => f.type === 'Dashboard'),
        item => item.id
      ),
      reports: items.filter(f => f.type === 'Report'),
      dashboardCounts: counts,
    };
  }, [data]);

  const spfItems = data?.[UsageTypes.SPF] || [];
  const dsiItems = data?.[UsageTypes.DSI] || [];
  const integrationItems = data?.[UsageTypes.Integrations] || [];
  const viewItems = data?.[UsageTypes.View] || [];
  const uiConfigItems = data?.[UsageTypes.UIConfig] || [];
  const groupingRuleItems = data?.[UsageTypes.VulnGroupingRules] || [];
  const permissionItems = data?.[UsageTypes.PermissionSets] || [];
  const scoreItems = data?.[UsageTypes.VulnScoreSettings] || [];

  if (isLoading) {
    return (
      <Box sx={{ ...flex.col, gap: 1 }}>
        <Skeleton variant="text" width={300} height={21} />
        <Skeleton variant="text" width={230} height={21} />
        <Skeleton variant="text" width={300} height={21} />
      </Box>
    );
  }

  return (
    <Box sx={{ ...flex.col, gap: 2.5, mt: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Usage
      </Typography>
      {reports.length > 0 && (
        <UsageSection title="Reports" count={reports.length}>
          {reports.map(item => (
            <UsageLink path={getPathName(PAGE_PATHS.REPORTS, `/edit/${item.id}`, APP_PATHS.EXPLORE)} label={`${item.name}`} />
          ))}
        </UsageSection>
      )}
      {data?.[UsageTypes.DashboardReports] && (
        <UsageSection title="Dashboards" count={dashboardItems.length}>
          {dashboardItems.map(item => {
            const dashboardCountInstances = dashboardCounts[item.id];
            return (
              <UsageLink
                path={getPathName(PAGE_PATHS.CUSTOM_DASHBOARDS, `/edit/${item.id}`, APP_PATHS.EXPLORE)}
                label={`${item.name} - ${dashboardCountInstances} ${dashboardCountInstances === 1 ? 'instance' : 'instances'}`}
              />
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.View] && (
        <UsageSection title={UsageTypes.View} count={viewItems.length}>
          {viewItems.map(item => {
            const { viewName, path } = resolveViewPath({ viewId: item.id, viewScreenType: item.type, getPathName });
            const label = `${viewName} | ${item.name} - (${item.id})`;
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink path={path} label={label} />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.SPF] && (
        <UsageSection title={UsageTypes.SPF} count={spfItems.length}>
          {spfItems.map(item => {
            const field = `${aggProjs[item.type].pathAlias}.${item.name}`;
            const fieldValue = fieldMap[field];
            const path = getPathName(PAGE_PATHS.MODEL_MANAGEMENT, `/${field}`);
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink
                  path={item.name && item.type ? path : ''}
                  label={fieldValue ? `${fieldValue.entityTypeId.name} | ${fieldValue.displayName}` : `${item.name} ${item.type}`}
                />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.VulnGroupingRules] && (
        <UsageSection title="Vulnerabilities Grouping Rules" count={groupingRuleItems.length}>
          {groupingRuleItems.map(item => {
            const path = getPathName(`settings/${PAGE_PATHS.RULE_SET}`, `/${item.parentId}/${item.id}`, APP_PATHS.VULNERABILITIES);
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink path={item.name && item.type ? path : ''} label={`Rule: ${item.name} - ${item.type}`} />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.VulnScoreSettings] && (
        <UsageSection title="Vulnerabilities Score Settings" count={scoreItems.length}>
          {scoreItems.map(item => {
            const path = getPathName(`settings/${PAGE_PATHS.SCORE}`, '', APP_PATHS.VULNERABILITIES);
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink path={item.name && item.type ? path : ''} label={`Factor type: ${item.type}`} />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.PermissionSets] && (
        <UsageSection title="Content Permissions" count={permissionItems.length}>
          {permissionItems.map(item => {
            const path = getPathName(`${PAGE_PATHS.PERMISSIONS}/${PAGE_PATHS.CONTENT_PERMISSIONS}`, `/edit/${item.id}`, APP_PATHS.SETTINGS);
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink path={path} label={item.name} />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.DSI] && (
        <UsageSection title={`${UsageTypes.DSI} Mapping`} count={dsiItems.length}>
          {dsiItems.map(item => {
            const path = getPathName(PAGE_PATHS.SOURCES, `?name=${item.name}`);
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink
                  path={path}
                  label={
                    <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                      <ItemWithLogo variant={IconVariant.sourcesMapByName} type={item.type} hideText />
                      {item.name}
                    </Box>
                  }
                />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.Integrations] && (
        <UsageSection title="Outegration Mapping" count={integrationItems.length}>
          {integrationItems.map(item => {
            const path = getPathName(PAGE_PATHS.TARGETS, `?name=${item.name}`);
            const iconType = destinationsOptions.find(f => f.name === item.type)?.type;

            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink
                  path={path}
                  label={
                    <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                      <ItemWithLogo variant={IconVariant.destinationsMap} type={iconType} hideText />
                      {item.name}
                    </Box>
                  }
                />
              </Box>
            );
          })}
        </UsageSection>
      )}

      {data?.[UsageTypes.UIConfig] && (
        <UsageSection title={`${UsageTypes.UIConfig}`} count={uiConfigItems.length}>
          {uiConfigItems.map(item => {
            const path = getPathName(`settings/${PAGE_PATHS.UI_CONFIG}`, '', APP_PATHS.VULNERABILITIES);
            return (
              <Box key={`${item.id}${item.type}`}>
                <UsageLink path={path} label={item.type} />
              </Box>
            );
          })}
        </UsageSection>
      )}
    </Box>
  );
};

export default FieldUsage;

export const UsageSection: React.FC<{
  title: string | React.ReactNode;
  children: React.ReactNode;
  count?: number;
  titleEndAdornment?: React.ReactNode;
  sx?: SxProps;
}> = ({ title, children, count, titleEndAdornment, sx = {} }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Box sx={sx}>
      <Box sx={{ ...flex.justifyBetweenCenter }}>
        <Button onClick={() => setIsExpanded(!isExpanded)}>
          <CollapseArrow isExpanded={isExpanded} />
          {isNullOrUndefined(count) ? title : `${title} (${count})`}
        </Button>
        {titleEndAdornment}
      </Box>
      <Collapse unmountOnExit in={isExpanded}>
        <Box sx={{ ...flex.col, gap: 1.5, pl: '28px', mt: 1.5 }}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export const UsageLink: React.FC<{ path: string; label: string | React.ReactNode; alwaysShowIcon?: boolean }> = ({
  path,
  label,
  alwaysShowIcon = false,
}) =>
  path ? (
    <Link to={path} target="_blank">
      <Box
        sx={{
          svg: { opacity: alwaysShowIcon ? 1 : 0, transition: theme => `opacity ${theme.transitions.easing.easeInOut}` },
          ':hover': { svg: { opacity: 1 }, color: theme => theme.palette.primary.main },
        }}>
        <AvTooltip title="Open in new tab">
          <Box sx={{ ...flex.itemsCenter, gap: 0.5 }}>
            {label} <Internal />
          </Box>
        </AvTooltip>
      </Box>
    </Link>
  ) : (
    <Box>{label}</Box>
  );

const resolveViewPath = ({ viewId, viewScreenType, getPathName }) => {
  const search = `?viewId=${viewId}`;
  switch (viewScreenType) {
    case ScreenType.Tickets:
      return { viewName: 'Tickets', path: getPathName(PAGE_PATHS.TICKETS, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.Incidents:
      return { viewName: 'Incidents', path: getPathName(PAGE_PATHS.TICKETS, search, APP_PATHS.INCIDENTS) };
    case ScreenType.FindingsView:
      return { viewName: 'Findings', path: getPathName(PAGE_PATHS.FINDINGS, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.AssetView:
      return { viewName: 'Assets', path: getPathName(PAGE_PATHS.ASSETS, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.Entities:
      return { viewName: 'Entity Explorer', path: getPathName(PAGE_PATHS.ENTITIES, search, APP_PATHS.EXPLORE) };
    case ScreenType.Alerts:
      return { viewName: 'Alerts', path: getPathName(PAGE_PATHS.ALERTS, search, APP_PATHS.INCIDENTS) };
    case ScreenType.RiskDashboard:
      return { viewName: 'Risk Dashboard', path: getPathName(PAGE_PATHS.RISK, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.RemediationDashboard:
      return { viewName: 'Remediation Dashboard', path: getPathName(PAGE_PATHS.REMEDIATION, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.AssetCoverage:
      return { viewName: 'Asset Coverage', path: getPathName(PAGE_PATHS.ASSET_COVERAGE, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.PivotVulnerabilities:
      return { viewName: 'Pivot', path: getPathName(PAGE_PATHS.PIVOT, search, APP_PATHS.VULNERABILITIES) };
    case ScreenType.PivotIncidents:
      return { viewName: 'Pivot', path: getPathName(PAGE_PATHS.PIVOT, search, APP_PATHS.INCIDENTS) };
    default:
      return { label: '', path: '' };
  }
};

enum UsageTypes {
  DashboardReports = 'Dashboard Reports',
  SPF = 'Data Model Fields',
  View = 'View',
  DSI = 'Data Source',
  UIConfig = 'UI Configuration',
  Integrations = 'Integration',
  VulnGroupingRules = 'Grouping Rules for Tickets',
  VulnScoreSettings = 'Score for Tickets',
  PermissionSets = 'Permission Sets',
}
