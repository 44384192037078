import React from 'react';
import { Box } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { Filter, OperatorType, StringConditionType } from '../../types/filter.types';
import { isDeepEqual } from '../../utils/Utils';
import AvAddOperandButton from '../AvAddOperandButton';
import { flex } from '../AvThemeProvider';
import RenderOperator from './RenderOperator';
import {
  cleanValueFromExpression,
  ConditionType,
  filterOperatorOptions,
  getEmptyExpression,
  getEmptyFilterExpression,
  isIngressExp,
} from './Utils';

interface ExpressionBuilderProps {
  filter?: Filter;
  setFilter: any;
  fields: any[];
  isLoadingFields?: boolean;
  isVertical?: boolean;
  size?: 'xSmall' | 'small';
  defaultField?: string | null;
  canAddNewFields?: boolean;
  canSelectFieldType?: boolean;
  supportParser?: boolean;
  autocompleteFilter?: Date[];
  canDeleteLast?: boolean;
  operatorOptions?: typeof filterOperatorOptions;
  showIngressToggle?: boolean;
}

const NewExpressionBuilder: React.FC<ExpressionBuilderProps> = ({
  setFilter,
  fields,
  isLoadingFields = false,
  isVertical = true,
  size = 'small',
  defaultField = null,
  canAddNewFields = false,
  canSelectFieldType,
  supportParser = false,
  autocompleteFilter,
  filter = getEmptyFilterExpression(null, supportParser ? StringConditionType.EQUALS : undefined),
  canDeleteLast = true,
  operatorOptions,
  showIngressToggle = false,
}) => {
  const {
    accountEntities: { fieldMap },
  } = useAvContext();
  const rootOperator = Object.keys(filter)[0] as OperatorType.OR | OperatorType.AND;
  const oppositeOperator = rootOperator === OperatorType.OR ? OperatorType.AND : OperatorType.OR;

  const onAddGlobalOperand = (operator: OperatorType.OR | OperatorType.AND) => {
    const { operands } = filter[rootOperator]!;
    const newItem = operands.at(-1).expression
      ? operands.at(-1)
      : getEmptyExpression(
          defaultField,
          ConditionType.string,
          supportParser ? StringConditionType.EQUALS : undefined,
          fieldMap[defaultField!]?.repeated
        );
    return setFilter({ [operator]: { operands: [...operands, cleanValueFromExpression(newItem)] } });
  };

  const commonProps = {
    size,
    isVertical,
    fields,
    isLoadingFields,
    defaultField,
    canAddNewFields,
    canSelectFieldType,
    supportParser,
    canDeleteLast,
    operatorOptions,
  };

  const onToggleLogicalOperators = () => {
    const toggleLogicalOperators = (globalFilter: Filter): Filter => {
      if (globalFilter.and || globalFilter.or) {
        return {
          [globalFilter.and ? OperatorType.OR : OperatorType.AND]: {
            operands: globalFilter[globalFilter.and ? OperatorType.AND : OperatorType.OR]!.operands.map(subFilter =>
              isIngressExp(subFilter) ? subFilter : toggleLogicalOperators(subFilter)
            ),
          },
        };
      }
      return globalFilter;
    };
    setFilter(toggleLogicalOperators(filter));
  };
  const disableAddOperandButton = isDeepEqual(
    getEmptyFilterExpression(null, supportParser ? StringConditionType.EQUALS : undefined),
    filter
  );
  return (
    <Box sx={{ ...flex.col }}>
      <RenderOperator
        {...commonProps}
        autocompleteFilter={autocompleteFilter}
        rootOperator={rootOperator}
        filter={filter}
        setFilter={setFilter}
        onToggleLogicalOperators={onToggleLogicalOperators}
        showIngressToggle={showIngressToggle}
      />
      <Box sx={{ ...flex.row, gap: 1, my: 1 }}>
        <AvAddOperandButton
          size={size}
          operator={rootOperator}
          isAdd
          onClick={() => onAddGlobalOperand(rootOperator)}
          disabled={disableAddOperandButton}
        />
        {filter[rootOperator].operands.length === 1 && (
          <AvAddOperandButton
            size={size}
            operator={oppositeOperator}
            isAdd
            onClick={() => onAddGlobalOperand(oppositeOperator)}
            disabled={disableAddOperandButton}
          />
        )}
      </Box>
    </Box>
  );
};

export default NewExpressionBuilder;
