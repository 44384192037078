import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../../components/AvThemeProvider';
import AvTable from '../../components/Table/AvTable';
import useQueryObject from '../../hooks/useQueryObjectSql';
import { cleanEmptyFilters } from '../../utils/filterUtils';
import { FoundCountResults, setNestedValue } from '../../utils/Utils';
import { DwQueryRequest } from '../CustomDashboards/types';

const ValuesByQueryPreviewTable = ({ queryObject }: { queryObject: DwQueryRequest }) => {
  const enabled = !!queryObject.select.dims.length;
  const cleanedFilters = cleanEmptyFilters(queryObject.filter);
  const queryObjectCleanedFilter = setNestedValue('filter', queryObject, cleanedFilters || null);
  const { data, totalRowCount, isLoading } = useQueryObject({
    queryObject: queryObjectCleanedFilter,
    totalRowCount: true,
    options: { enabled, gcTime: 0 },
  });

  return (
    <Box sx={{ ...flex.col, gap: 2 }}>
      {enabled && (
        <>
          <FoundCountResults label="Values" count={totalRowCount || 0} loading={isLoading} />
          <AvTable
            rows={data}
            allowEdit={false}
            loading={isLoading}
            pagination
            rowLimit={10}
            headCells={[
              {
                id: queryObject.select.dims[0]?.name,
                label: queryObject.select.dims[0]?.name,
                isKey: true,
              },
            ]}
            size="xSmall"
          />
        </>
      )}
    </Box>
  );
};

export default ValuesByQueryPreviewTable;
