import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import useHandleError from '../../hooks/useHandleError';
import { FeatureFlags } from '../../types';
import { QueryKey } from '../../utils/queryKey';
import { getNetworkErrorCode, uniqBy } from '../../utils/Utils';
import { MeasurementUnit } from '../Reports/types';
import { FormattingRule } from './types';
import { NONE_ID } from './utils';

export const useFormattingRules = () => {
  const { api, featureFlags } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.GETFORMATTINGRULES],
    queryFn: () => api(GET_FORMATTING_RULES, { onSuccess: ({ data }) => data.findFormattingRules }),
    gcTime: 0,
    enabled: featureFlags[FeatureFlags.ShowFormattingRules],
  });
};

export interface EditCreateFormattingRule {
  formattingRule: FormattingRule;
  isCreate: boolean;
  onSuccess?: () => void;
}

export const cleanFormattingConditions = (formattingRule: Omit<FormattingRule, 'id'>) =>
  formattingRule.formattingConditions.map(({ id, filter, ...rest }, index) => ({
    ...rest,
    ...(index === formattingRule.formattingConditions.length - 1 ? {} : { filter }),
  }));

export const useEditFormattingRule = () => {
  const { api, featureFlags } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useFormattingRules();

  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  return useMutation({
    mutationFn: ({ formattingRule: f, isCreate, onSuccess }: EditCreateFormattingRule): Promise<any> => {
      const { id, ...formattingRule } = f;
      return api(isCreate ? CREATE_FORMATTING_RULE : UPDATE_FORMATTING_RULE, {
        options: {
          formattingRule: {
            ...formattingRule,
            ...(!isCreate && { id }),
            formattingConditions: cleanFormattingConditions(formattingRule),
          },
        },
        muteErrors: true,
        onSuccess: ({ data }) => {
          if (!data.errors) {
            enqueueSnackbar('Formatting Rule saved successfully', { variant: 'success' });
            refetch();
            onSuccess?.();
          }
          return true;
        },
      }).catch(e => {
        if (getNetworkErrorCode(e) === 400) {
          console.warn(e);
          enqueueSnackbar(e.message, { variant: 'error', style: { whiteSpace: 'pre-line' } });
        } else {
          handleError(e);
        }
      });
    },
  });
};

export const useDeleteFormattingRules = onSuccess => {
  const { enqueueSnackbar } = useSnackbar();
  const { api } = useAvContext();

  return useMutation({
    mutationFn: (ids: string[]) =>
      api(DELETE_FORMATTING_RULES, {
        options: { ids },
        onSuccess: () => {
          enqueueSnackbar(`Successfully Deleted Formatting Rules`, { variant: 'success' });
          onSuccess();
        },
      }),
  });
};

export const useFormattingRuleOptions = ({ additionalFormattingRule = [] }: { additionalFormattingRule?: FormattingRule[] }) => {
  const { data: formattingRules = [], isLoading: isLoadingFormattingRules } = useFormattingRules();
  const allFormattingRulesOptions = useMemo(
    () => uniqBy([{ name: 'None', id: NONE_ID }, ...additionalFormattingRule, ...formattingRules], v => v.id),
    [formattingRules, additionalFormattingRule]
  );
  const getFormattingRuleByType = (type: MeasurementUnit) => allFormattingRulesOptions.filter(f => f.id === NONE_ID || f.type === type);

  return { formattingRules, isLoadingFormattingRules, allFormattingRulesOptions, getFormattingRuleByType };
};

const GET_FORMATTING_RULES = gql`
  query findFormattingRules {
    findFormattingRules {
      id
      name
      type
      formattingConditions {
        id
        color
        filter
      }
    }
  }
`;

const CREATE_FORMATTING_RULE = gql`
  mutation ($formattingRule: FormattingRuleDtoInput) {
    createFormattingRule(formattingRule: $formattingRule) {
      id
    }
  }
`;
const UPDATE_FORMATTING_RULE = gql`
  mutation ($formattingRule: FormattingRuleDtoInput) {
    updateFormattingRule(formattingRule: $formattingRule) {
      id
    }
  }
`;

const DELETE_FORMATTING_RULES = gql`
  mutation ($ids: [String]) {
    deleteFormattingRules(ids: $ids)
  }
`;
