import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Box, Skeleton, Typography } from '@mui/material';
import ProcessingHistoricalData from '../../../../assets/lottie/ProcessingHistoricalData.lottie?url';
import { flex } from '../../../../components/AvThemeProvider';
import AvTooltip from '../../../../components/AvTooltip';

// TODO: Use MUI theme helpers after update to MUI 6 -
// https://mui.com/material-ui/customization/container-queries/
const containerQueryMaxHeight = '@container (max-height: 144px)';
const containerQueryMinHeight = '@container (min-height: 144px)';

const DEFAULT_LOTTIE_SIZE = 54;
const SMALLER_LOTTIE_SIZE = 40;

const title = 'Processing historical data';
const subtitle = 'Historical data takes time to process, but it’s worth it!';

function AsyncLoadingContent() {
  return (
    <Box
      sx={{
        ...flex.colCenter,
        [containerQueryMaxHeight]: {
          display: 'none',
        },
        '[role=tooltip] &': {
          alignItems: 'flex-start',
          display: 'block',
        },
      }}>
      <Typography
        sx={{
          color: t => t.palette.colors.neutrals[900],
          [`[role=tooltip] &`]: {
            color: 'inherit',
          },
        }}
        variant="h7">
        {title}
      </Typography>
      <Box>{subtitle}</Box>
    </Box>
  );
}

function WidgetLoadingState({ isAsync }: { isAsync?: boolean }) {
  return (
    <Box position="relative" width="100%" height="100%" mb={3.125} sx={{ containerType: 'size' }}>
      <Skeleton
        width="100%"
        height="100%"
        animation="wave"
        variant="rectangular"
        sx={{ background: theme => theme.palette.colors.neutrals[300] }}
      />
      {isAsync ? (
        <Box
          position="absolute"
          sx={{
            ...flex.colCenter,
            inset: 0,
          }}>
          <AvTooltip
            muiProps={{
              PopperProps: {
                disablePortal: true,
                popperOptions: {
                  strategy: 'fixed',
                },
                sx: {
                  [containerQueryMinHeight]: {
                    display: 'none',
                  },
                },
              },
            }}
            title={<AsyncLoadingContent />}>
            <Box
              sx={{
                width: DEFAULT_LOTTIE_SIZE,
                height: DEFAULT_LOTTIE_SIZE,
                mb: 1.5,
                [containerQueryMaxHeight]: {
                  mb: 0,
                  width: SMALLER_LOTTIE_SIZE,
                  height: SMALLER_LOTTIE_SIZE,
                  cursor: 'pointer',
                },
              }}>
              <DotLottieReact src={ProcessingHistoricalData} loop autoplay renderConfig={{ autoResize: true }} />
            </Box>
          </AvTooltip>
          <AsyncLoadingContent />
        </Box>
      ) : null}
    </Box>
  );
}

export default WidgetLoadingState;
