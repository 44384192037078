import { Filter } from '../../../types/filter.types';

export enum SeverityScoringFactorType {
  Risk = 'RISK',
  Mitigation = 'MITIGATION',
  Base = 'BASE',
}

export enum SeverityScoringFactorAction {
  RAISE = 'RAISE',
  REDUCE = 'REDUCE',
}

interface SeverityScoringFilter {
  percentage: number;
  action: SeverityScoringFactorAction;
  filter: Filter;
}

export interface SeverityScoringExpression {
  severityScoringFilters: SeverityScoringFilter[];
  fallbackPercentage: number;
  fallbackAction: SeverityScoringFactorAction;
}

export interface RiskAndMitigationFactorScoring {
  name: string;
  type: SeverityScoringFactorType;
  fieldName: null | string;
  severityScoringExpression: SeverityScoringExpression;
}

export interface BaseScoring {
  name: string;
  fieldName?: null | string;
  percentage: number;
}

export type SeverityScoring = {
  name: string;
  id?: string;
  baseScorings: BaseScoring[];
  riskAndMitigationFactorScorings: RiskAndMitigationFactorScoring[];
};

export type ScoreFactor = {
  name: string;
  percentage: number;
  range: number;
  type: SeverityScoringFactorType;
  value: string;
  change: string;
};
