import React from 'react';
import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import RGL, { WidthProvider } from 'react-grid-layout';
import AvFilters from '../../../components/AvFilters';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { HistoricDataIndicator } from '../../../components/Widgets/layout.components';
import { useAvContext } from '../../../context/AvContextProvider';
import { useCustomSearchParams } from '../../../hooks/UseCustomSearchParams';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { entityViewConfig } from '../../../utils/entityViewConfig';
import { cleanEmptyFilters, filterToExpression } from '../../../utils/filterUtils';
import DraggableWidget from '../../CustomDashboards/components/DraggableWidget';
import renderWidget from '../../CustomDashboards/components/Widgets/RenderWidget';
import { getArrowIndication, getComparisonDifference } from '../../CustomDashboards/components/Widgets/TileWidget/TrendTileWidget';
import { ColoringRules, Field } from '../../CustomDashboards/types';
import { getTimeRangeDescription } from '../../CustomDashboards/Utils';
import { filtersFieldToIgnore } from '../../Tickets/ticket.types';
import TimeRangeComp from '../components/TimeRangeComp';
import { getTimeRangeAndDatePickerValue } from '../utils';
import getAssetsOverTimeQuery, { getSideTileWidgetQuery } from './Queries';
import ASSET_INVENTORY_GRID_LAYOUT, {
  barBreakdownSuffix,
  defaultFilter,
  firstBarBreakdownSuffix,
  firstMultiLineSuffix,
  firstPieBreakdownSuffix,
  multiLineSuffix,
  pieBreakdownSuffix,
  secondBarBreakdownSuffix,
  secondMultiLineSuffix,
  secondPieBreakdownSuffix,
} from './utils';
import { getAssetOverTimeWidget, getTileWidget, getTotalAssetBreakDownBarWidget, getTotalAssetsBrokenDownWidget } from './Widgets';
import WidgetWithToolbar from './WidgetWithToolbar';

const dateFilters = ['dateGap', 'dateObject'];
const newAssetsMetricName = 'new_assets_granular';
const decommissionedMetricName = 'deactivated_assets_granular';

const ReactGridLayout = WidthProvider(RGL);

const AssetInventory = () => {
  const theme = useTheme();

  const {
    accountEntities: { aggProjs, fieldTypeMap },
  } = useAvContext();
  const aggProjFieldList = aggProjs[entityViewConfig.Asset.projectionName].fields;
  const activeProjName = entityViewConfig.Asset.projectionName;

  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter,
    shouldBeArray: id =>
      !dateFilters.includes(id) && !id.endsWith(pieBreakdownSuffix) && !id.endsWith(barBreakdownSuffix) && !id.endsWith(multiLineSuffix),
  });

  const onFilterChange = newFilters => {
    setFilters({ ...filters, ...newFilters });
  };
  const filteredFilters = Object.keys(filters)
    .filter(key => !key.endsWith(pieBreakdownSuffix) && !key.endsWith(barBreakdownSuffix) && !key.endsWith(multiLineSuffix))
    .reduce(
      (acc, field) =>
        ![...filtersFieldToIgnore, ...dateFilters].includes(field) && !!filters[field].length
          ? {
              ...acc,
              [field]: filters[field],
            }
          : acc,
      {}
    );
  const { dateObject, dateGap } = filters;
  const { timeRange, datePickerValue } = getTimeRangeAndDatePickerValue(dateObject);

  const regularFilters = filterToExpression(fieldTypeMap, filteredFilters);
  const cleanFilters = cleanEmptyFilters(regularFilters);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));
  const isXsmallScreen = useMediaQuery(theme.breakpoints.down(1310));

  const newAssetsQuery = getSideTileWidgetQuery(newAssetsMetricName, timeRange, dateGap, cleanFilters);
  const decommissionedQuery = getSideTileWidgetQuery(decommissionedMetricName, timeRange, dateGap, cleanFilters);

  const screenSize = isXsmallScreen ? 'isXsmallScreen' : isSmallScreen ? 'isSmallScreen' : 'defaultScreen';
  const { isLoading: isLoadingNewAssets, data: newAssetsData = [] } = useQueryObject({
    queryObject: newAssetsQuery,
  });
  const { isLoading: isLoadingDecomissionedAssets, data: decomissionedAssetsData = [] } = useQueryObject({
    queryObject: decommissionedQuery,
  });

  return (
    <Box sx={{ ...flex.col, px: 4 }}>
      <Typography variant="h3" sx={{ pl: 2 }}>
        Asset Inventory
      </Typography>
      <Box sx={{ ...flex.justifyBetweenCenter, px: 2, pb: 2 }}>
        <AvFilters
          filters={filters}
          updateFilters={(field, val) => onFilterChange({ [field]: val })}
          setFilters={setFilters}
          activeProjName={activeProjName}
          dims={aggProjs[activeProjName].fieldList.INTERACTIVE}
        />
        <TimeRangeComp datePickerValue={datePickerValue} dateGap={dateGap} onFilterChange={onFilterChange} />
      </Box>
      <Box sx={{ overflow: 'auto', pb: 10 }}>
        <ReactGridLayout
          useCSSTransforms={false}
          className="layout"
          margin={[12, 12]}
          layout={ASSET_INVENTORY_GRID_LAYOUT[screenSize]}
          cols={12}
          rowHeight={35}>
          <Box key="a" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetWithToolbar
              title="Total Reported Assets, broken down by"
              options={aggProjFieldList}
              value={filters[firstBarBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [firstBarBreakdownSuffix]: val })}
              widget={getTotalAssetBreakDownBarWidget(filters[firstBarBreakdownSuffix], cleanFilters)}
              tileWidget={getTileWidget('total_assets_granular', cleanFilters)}
            />
          </Box>
          <Box key="b" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetWithToolbar
              title="Total Assets, broken down by"
              options={aggProjFieldList}
              value={filters[firstPieBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [firstPieBreakdownSuffix]: val })}
              widget={getTotalAssetsBrokenDownWidget(filters[firstPieBreakdownSuffix], cleanFilters)}
            />
          </Box>
          <Box key="c" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <DraggableWidget
              widget={getAssetOverTimeWidget(getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters))}
              customToolbar={
                <Box sx={flex.justifyBetweenCenter}>
                  <Typography sx={{ whiteSpace: 'nowrap', mb: 2 }} variant="h5">
                    Assets Over Time
                  </Typography>
                  <Box sx={{ ...flex.justifyEndCenter, width: 20, height: '100%', mb: 2, ml: 1, mr: 0 }}>
                    <HistoricDataIndicator hideText />
                  </Box>
                </Box>
              }>
              {renderWidget({
                widget: getAssetOverTimeWidget(getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters)),
              })}
            </DraggableWidget>
          </Box>
          <Box key="d" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetWithToolbar
              title="Total Assets, broken down by"
              options={aggProjFieldList}
              value={filters[secondPieBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [secondPieBreakdownSuffix]: val })}
              widget={getTotalAssetsBrokenDownWidget(filters[secondPieBreakdownSuffix], cleanFilters)}
            />
          </Box>

          <Box key="e" sx={{ ...flex.justifyStart, backgroundColor: theme.palette.white.main, gap: 3 }}>
            {isLoadingNewAssets ? (
              <Skeleton width="25%" height="80%" variant="rectangular" />
            ) : (
              <Box sx={{ gap: 0.5, ...flex.colItemsStart, ml: 3, mt: 1.5, width: 150 }}>
                <Box sx={{ fontSize: 32, color: theme => theme.palette.colors.neutrals[800], fontWeight: 500 }}>
                  {newAssetsData[newAssetsData.length - 1][newAssetsMetricName]}
                </Box>
                <Typography sx={{ mb: 1, pl: 0.2 }} variant="h5">
                  New Assets
                </Typography>
                <Box sx={{ ...flex.colItemsStart, gap: 1, fontSize: '12px' }}>
                  {getArrowIndication(ColoringRules.UP_IS_GOOD, getComparisonDifference(newAssetsData, newAssetsMetricName), theme)}
                  <AvTooltip hasWidthLimit sx={{ width: 150 }}>
                    {getTimeRangeDescription(newAssetsQuery.timeRange)}
                  </AvTooltip>
                </Box>
              </Box>
            )}
            <Box sx={{ height: '100%', width: '100%' }}>
              <WidgetWithToolbar
                options={aggProjFieldList}
                value={filters[firstMultiLineSuffix]}
                onChange={(val: Field) => onFilterChange({ [firstMultiLineSuffix]: val })}
                widget={getAssetOverTimeWidget(
                  getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters, filters[firstMultiLineSuffix], newAssetsMetricName)
                )}
                selectAtTheEnd
              />
            </Box>
          </Box>
          <Box key="f" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetWithToolbar
              title="Total Reported Assets, broken down by"
              options={aggProjFieldList}
              value={filters[secondBarBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [secondBarBreakdownSuffix]: val })}
              widget={getTotalAssetBreakDownBarWidget(filters[secondBarBreakdownSuffix], cleanFilters)}
              tileWidget={getTileWidget('total_assets_granular', cleanFilters)}
            />
          </Box>
          <Box key="g" sx={{ ...flex.justifyStart, backgroundColor: theme.palette.white.main, gap: 3 }}>
            {isLoadingDecomissionedAssets ? (
              <Skeleton width="25%" height="80%" variant="rectangular" />
            ) : (
              <Box sx={{ gap: 0.5, ...flex.colItemsStart, ml: 3, mt: 1.5, width: 150 }}>
                <Box sx={{ fontSize: 32, color: theme => theme.palette.colors.neutrals[800], fontWeight: 500 }}>
                  {decomissionedAssetsData[decomissionedAssetsData.length - 1][decommissionedMetricName]}
                </Box>
                <Typography sx={{ mb: 1, pl: 0.2 }} variant="h5">
                  Decommissioned Assets
                </Typography>
                <Box sx={{ ...flex.colItemsStart, gap: 1, fontSize: '12px' }}>
                  {getArrowIndication(
                    ColoringRules.UP_IS_GOOD,
                    getComparisonDifference(decomissionedAssetsData, decommissionedMetricName),
                    theme
                  )}
                  <AvTooltip hasWidthLimit sx={{ width: 150 }}>
                    {getTimeRangeDescription(decommissionedQuery.timeRange)}
                  </AvTooltip>
                </Box>
              </Box>
            )}
            <Box sx={{ height: '100%', width: '100%' }}>
              <WidgetWithToolbar
                options={aggProjFieldList}
                selectAtTheEnd
                value={filters[secondMultiLineSuffix]}
                onChange={(val: Field) => onFilterChange({ [secondMultiLineSuffix]: val })}
                widget={getAssetOverTimeWidget(
                  getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters, filters[secondMultiLineSuffix], decommissionedMetricName)
                )}
              />
            </Box>
          </Box>
        </ReactGridLayout>
      </Box>
    </Box>
  );
};

export default AssetInventory;
