import React, { ReactNode, useMemo } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { iconSize, noop } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import DeleteButtonWithDialog from './DeleteButtonWithDialog';
import { DeleteProps } from './types';
import { ToolbarActionButton } from './Utils';
import { ReactComponent as Delete } from '../../assets/Delete.svg';
import { ReactComponent as Edit } from '../../assets/Edit.svg';
import { ReactComponent as Plus } from '../../assets/Plus.svg';

const plusIcon = <Plus style={{ marginLeft: '-6px' }} />;
const deleteIcon = (size?) => <Delete style={iconSize(size)} />;
const editIcon = (size?) => <Edit style={iconSize(size)} />;

interface TableToolbarProps {
  keyName: string;
  rows: Array<Record<string, any>>;
  setRows?: (rows: Array<Record<string, any>>) => void;
  selected: Array<number | string>;
  deleteProps?: DeleteProps;
  customActions: ((selected, selectedRows, setRows, handleSelected) => ReactNode)[];
  customGlobalActions?: Array<() => ReactNode>;
  allowEdit: boolean;
  allowCreate: boolean;
  activeKey?: string | number;
  customNewButton?: ReactNode;
  innerEditLink?: (v) => string;
  handleSelected?: (v) => void;
}

const TableToolbar: React.FC<TableToolbarProps> = ({
  keyName,
  rows,
  setRows = noop,
  selected,
  deleteProps,
  customActions,
  customGlobalActions = [],
  allowEdit,
  allowCreate,
  activeKey,
  customNewButton,
  innerEditLink,
  handleSelected = noop,
}) => {
  const selectedCount = selected.length;
  const getRow = selectedId => rows.find(({ [keyName]: id }) => id === selectedId);
  const getSelectedRowName = selectedId => deleteProps?.getName?.(getRow(selectedId));
  const message = rows.length && deleteProps?.getName ? selected.map(selectedId => getSelectedRowName(selectedId)).join(', ') : '';
  const deleteButtonFunc = onClick =>
    ToolbarActionButton(
      `Delete${selectedCount > 1 ? ` (${selectedCount})` : ''}`,
      onClick,
      deleteIcon(),
      selectedCount === 0 || !deleteProps?.onDelete
    );
  const selectedRows = useMemo(() => selected.map(id => getRow(id)), [selected, rows]);
  return (
    <Box sx={{ ...flex.row, marginBottom: '24px' }}>
      {customActions.map(action => (
        <Box key={action.name}>{action(selected, selectedRows, setRows, handleSelected)}</Box>
      ))}
      {deleteProps && (
        <DeleteButtonWithDialog
          button={deleteButtonFunc}
          selected={selected}
          handleSelected={handleSelected}
          deleteProps={{ ...deleteProps, message }}
        />
      )}
      {allowEdit && (
        <div>
          <Button
            component={Link}
            variant="filter"
            className="transparent"
            to={innerEditLink?.(selected[0]) || `${activeKey ? '../' : ''}edit/${selected[0]}`}
            disabled={selectedCount !== 1}>
            {editIcon()}
            <span>Edit</span>
          </Button>
        </div>
      )}
      <span style={{ flexGrow: 1 }} />
      <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
        {allowCreate
          ? customNewButton || (
              <div>
                <Button onClick={e => e.stopPropagation()} component={Link} to="create/" variant="contained" size="small">
                  {plusIcon}
                  <span>Create</span>
                </Button>
              </div>
            )
          : null}
        {customGlobalActions.map(action => (
          <React.Fragment key={action.name}>{action()}</React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default TableToolbar;
