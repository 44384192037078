import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { flex } from '../../../components/AvThemeProvider';
import { IconVariant, ItemWithLogo } from '../../../components/ItemWithLogo';
import Select from '../../../components/Select';
import TextInput from '../../../components/TextInput';
import { FilterType, PolicyScenarioType } from '../../../types/executionRules.types';
import { emptyArray } from '../../../utils/Utils';
import DraggableWidget from '../../CustomDashboards/components/DraggableWidget';
import renderWidget from '../../CustomDashboards/components/Widgets/RenderWidget';
import { useGetPolicyScenarioFilter } from './hooks';
import getCoverageGapsDonutWidget from './Widgets';

const FindCoverageGapsWidget = ({
  sourceSelectOptions = emptyArray,
  isLoadingValueOptions,
  onChangeSource,
  sourceSelectValue,
  onChangeLastDays,
  lastDaysNumber,
}: {
  sourceSelectOptions: string[];
  isLoadingValueOptions: boolean;
  onChangeSource: (val) => void;
  onChangeLastDays: (val) => void;
  sourceSelectValue?: string;
  lastDaysNumber?: number;
}) => {
  const policyScenario: PolicyScenarioType | undefined = sourceSelectValue
    ? {
        filterType: FilterType.TOOL_COVERAGE,
        sources: [sourceSelectValue],
        numOfDays: lastDaysNumber || 0,
      }
    : undefined;
  const filter = useGetPolicyScenarioFilter({ policyScenario });
  const donutWidget = getCoverageGapsDonutWidget({ filter });
  const widgetToolbar = (
    <>
      <Typography variant="h5">Explore Coverage Overlaps</Typography>
      <Box sx={{ ...flex.justifyStartCenter, gap: 1, width: '100%' }}>
        Show assets which were not seen by
        <Box sx={{ width: 130 }}>
          <Select
            size="small"
            variant="input"
            renderOption={({ name }) => <ItemWithLogo variant={IconVariant.sourcesMapByName} type={name} />}
            onChange={onChangeSource}
            options={sourceSelectOptions}
            getValueFunc={({ name }) => name}
            getLabelFunc={({ name }) => name}
            label=""
            isMultiple={false}
            value={sourceSelectValue}
            isRequired
            loading={isLoadingValueOptions}
          />
        </Box>
        In the last
        <TextInput
          size="small"
          onChange={onChangeLastDays}
          type="number"
          value={lastDaysNumber === 0 ? undefined : lastDaysNumber}
          disableClearButton
          placeholder="0"
          sx={{ width: 80 }}
        />
        Days
      </Box>
    </>
  );
  return (
    <DraggableWidget customToolbar={widgetToolbar} widget={donutWidget}>
      {renderWidget({ widget: donutWidget })}
    </DraggableWidget>
  );
};

export default FindCoverageGapsWidget;
