import React from 'react';
import { Box, formHelperTextClasses } from '@mui/material';
import { format } from 'date-fns';
import AvKeyValue from '../../components/AvKeyValue';
import { flex } from '../../components/AvThemeProvider';
import { RelativeDatePeriod, RelativeDateType } from '../../components/DatePicker/AvDateRangePicker.constants';
import { Preset } from '../../components/DatePicker/types';
import { customPreset, getEndOfDay, getStartOfDay } from '../../components/DatePicker/utils';
import { ConditionType, getExpression } from '../../components/filters/Utils';
import { IconVariant, ItemWithLogo } from '../../components/ItemWithLogo';
import { dateFilterOptions } from '../../components/SelectDate';
import { FormatterType } from '../../components/Table/types';
import { StringConditionType } from '../../types/filter.types';
import stringToFilter from '../../utils/stringToFIlter';
import { LogsQuery, QueryType, Tab } from './types';

export const generateTempId = () => `tempId_${crypto.randomUUID()}`;
export const isTempId = (id: string) => id.startsWith('tempId_');

export const getEscapedField = field =>
  field.includes(' ') || field.includes('.')
    ? field
        .split('.')
        .map(v => `"${v}"`)
        .join('.')
    : field;

export const sanitizeUnicode = (content: string) => content.replace(/[^(\n|\x20-\x7E)]/g, '');
export const getAliasString = input => {
  const splittedInput = input.split(/[.[\]]+/);
  return `${DocumentObjectsPrefix}${splittedInput.at(-1).replaceAll(' ', SpaceReplacer)}`;
};

export const getExtra = (isValidTime, timeRange: Date[]) =>
  isValidTime ? [`TIME >= '${timeRange[0].toISOString()}' AND TIME <= '${timeRange[1].toISOString()}'`] : undefined;

const regex = /\b(AND|OR)\b/g; // Matches the words "AND" or "OR" as whole words

export const QueryText = ({ query }: { query: string }) =>
  query.split(regex).map((part, i) => {
    if (part === 'AND' || part === 'OR') {
      const key = `${query}-${i}`;
      return (
        <Box
          key={key}
          component="span"
          sx={{ color: theme => (part === 'AND' ? theme.palette.colors.primary[500] : theme.palette.colors.complementary[600]) }}>
          {part}
        </Box>
      );
    }
    return part;
  });

export const topOptions = [5, 10, 15, 20].map(value => ({ title: `${value}`, value }));

export const pieSelectStyle = { mx: '2px', width: 180, [`.${formHelperTextClasses.root}`]: { position: 'absolute', bottom: '-20px' } };

const legalIntervals = [
  { display: '1 Seconds', valueMillis: 1 },
  { display: '1 Seconds', valueMillis: 1000 },
  { display: '2 Seconds', valueMillis: 2000 },
  { display: '3 Seconds', valueMillis: 3000 },
  { display: '5 Seconds', valueMillis: 5000 },
  { display: '10 Seconds', valueMillis: 10000 },
  { display: '20 Seconds', valueMillis: 20000 },
  { display: '30 Seconds', valueMillis: 30000 },
  { display: '1 Minutes', valueMillis: 60000 },
  { display: '2 Minutes', valueMillis: 120000 },
  { display: '3 Minutes', valueMillis: 180000 },
  { display: '5 Minutes', valueMillis: 300000 },
  { display: '10 Minutes', valueMillis: 600000 },
  { display: '20 Minutes', valueMillis: 1200000 },
  { display: '30 Minutes', valueMillis: 1800000 },
  { display: '1 Hours', valueMillis: 3600000 },
  { display: '2 Hours', valueMillis: 7200000 },
  { display: '3 Hours', valueMillis: 10800000 },
  { display: '8 Hours', valueMillis: 28800000 },
  { display: '12 Hours', valueMillis: 43200000 },
  { display: '1 Days', valueMillis: 86400000 },
  { display: '2 Days', valueMillis: 172800000 },
  { display: '3 Days', valueMillis: 259200000 },
  { display: '7 Days', valueMillis: 604800000 },
  { display: '30 Days', valueMillis: 2592000000 },
];

export const getInterval = (maxHistogramBuckets, timeRange) => {
  const rangeMillis = timeRange[1].getTime() - timeRange[0].getTime();
  const interval = legalIntervals.find(interval => rangeMillis / interval.valueMillis <= maxHistogramBuckets);
  return interval || legalIntervals[legalIntervals.length - 1];
};

const now = new Date();

export type PieSlicer = { color: string; index: number; value: string }[];

export const maxTabs = 10;
export const ANY_VALUE = 'DOCUMENT';
export const defaultQuery = 'SELECT DOCUMENT, source, TIME as timestamp FROM logs LIMIT 100';
export const defaultDateRange = [new Date(now.getTime() - dateFilterOptions[1].value), now];
export const newDefaultDateRange = [getStartOfDay(now), getEndOfDay(now)];

export const defaultPreset: Preset = { type: RelativeDateType.last, count: 1, period: RelativeDatePeriod.days };

export const defaultHeadCells = [
  {
    id: 'avalorRowId',
    label: 'ID',
    hidden: true,
    isKey: true,
    disableSortBy: true,
  },
  {
    id: 'timestamp',
    type: FormatterType.date,
    label: 'Time',
    initWidth: 142,
    style: { '> div': { whiteSpace: 'nowrap' } },
    formatter: (v, { data: { timestamp } }) => (
      <Box sx={{ ...flex.row, fontSize: '13px', gap: '8px' }}>{format(new Date(timestamp), 'MMM dd kk:mm:ss')}</Box>
    ),
    disableSortBy: true,
  },
  {
    id: 'source',
    label: 'Source',
    formatter: (v, { data: { source } }) => <ItemWithLogo variant={IconVariant.sourcesMapByName} type={source} />,
    disableSortBy: true,
  },
  {
    id: 'data',
    label: 'Logs',
    sx: { width: '100%' },
    style: { '> div': { maxWidth: 'none' } },
    formatter: value => <AvKeyValue value={value} linesLimit={2} />,
    disableSortBy: true,
  },
];

export const dynamicHeadCellsPrefix = 'DY_';
export const defaultHeadCellsBasicQuery = defaultHeadCells.map(headcell => headcell.id);

export const defaultTab: Tab = {
  tabId: '-1',
  queryType: QueryType.Basic,
  page: 0,
  title: '',
  lastSearch: undefined,
  headCells: defaultHeadCellsBasicQuery,
};
export const defaultFilter = {
  ...defaultTab,
  date: defaultDateRange,
  headCells: [],
};
export const newDefaultFilter = {
  ...defaultTab,
  date: newDefaultDateRange,
};
const Untitled = 'Untitled';

export const getHeadCellToExport = (flatData: any[]) =>
  [...new Set(flatData.reduce((acc, v) => [...acc, ...Object.keys(v)], []))].map(key => ({ id: key, label: key }));
export const getNextUntitledVal = (arr, prefixName = Untitled) => {
  const next = (arr || [])
    .reduce((acc, v) => {
      const newVal = Number(v.name.split(prefixName)[1]);
      if (v.name.includes(prefixName) && newVal) {
        return [...acc, newVal];
      }
      return acc;
    }, [])
    .sort((a, b) => a - b);
  const nextVal =
    next.reduce((acc, v, i, array) => {
      if (acc !== null) {
        return acc;
      }

      if (v !== i + 1) {
        return i + 1;
      }
      if (i === array.length - 1) {
        return i + 2;
      }
      return null;
    }, null) || 1;

  return { id: prefixName + nextVal, name: prefixName + nextVal };
};

export type QueryObj = { name: string; source: string; query: string };
export const queriesList: QueryObj[] = [
  {
    name: 'Exploitable CVEs',
    source: 'Threat Intel',
    query: 'source = "Threat Intel" AND has_exploits = "true"',
  },
  {
    name: 'Failed Okta logins',
    source: 'Okta',
    query: 'source = "Okta" AND outcome.result = "FAILURE"',
  },
  {
    name: 'Tenable open vulnerabilities',
    source: 'Tenable Vulnerabilities',
    query: 'source = "Tenable Vulnerabilities" AND state = "OPEN"',
  },
  {
    name: 'Wiz active findings',
    source: 'Wiz - Vulnerability Findings',
    query: 'source = "Wiz - Vulnerability Findings" AND Status = "Active"',
  },
  {
    name: 'Crowdstrike critical vulnerabilities',
    source: 'Crowdstrike Vulnerabilities',
    query: 'source = "Crowdstrike Vulnerabilities" AND cve.severity = "CRITICAL"',
  },
  {
    name: 'Okta logins with warnings severity',
    source: 'Okta',
    query: 'source = "Okta" AND severity = "WARN"',
  },
  {
    name: 'Tenable reopened vulnerabilities',
    source: 'Tenable Vulnerabilities',
    query: 'source = "Tenable Vulnerabilities" AND state = "REOPENED"',
  },
  {
    name: 'Tenable open and reopened issues',
    source: 'Tenable Issues',
    query: 'source = "Tenable Issues" AND (state = "REOPENED" OR state = "OPEN")',
  },
  {
    name: 'Snyk high and critical issues',
    source: 'Snyk',
    query: 'source = "Snyk" AND (issue.severity = "critical" OR issue.severity = "high")',
  },
  {
    name: 'Snyk fixable issues',
    source: 'Snyk',
    query: 'source = "Snyk" AND is_fixable = "true"',
  },
  {
    name: 'Legit critical vulnerabilities',
    source: 'Legit',
    query: 'source = "Legit" AND severity = "Critical"',
  },
  {
    name: 'Crowdstrike high vulnerabilities',
    source: 'Crowdstrike Vulnerabilities',
    query: 'source = "Crowdstrike Vulnerabilities" AND cve.severity = "HIGH"',
  },
];

export const predefinedPresets = [
  { title: 'Last 15 Minutes', definition: { type: RelativeDateType.last, count: 15, period: RelativeDatePeriod.minutes } },
  { title: 'Last 1 Hour', definition: { type: RelativeDateType.last, count: 1, period: RelativeDatePeriod.hours } },
  { title: 'Last 4 Hours', definition: { type: RelativeDateType.last, count: 4, period: RelativeDatePeriod.hours } },
  { title: 'Last 1 Day', definition: { type: RelativeDateType.last, count: 1, period: RelativeDatePeriod.days } },
  { title: 'Last 3 Days', definition: { type: RelativeDateType.last, count: 3, period: RelativeDatePeriod.days } },
  { title: 'Last 7 Days', definition: { type: RelativeDateType.last, count: 7, period: RelativeDatePeriod.days } },
  { title: 'Last 15 Days', definition: { type: RelativeDateType.last, count: 15, period: RelativeDatePeriod.days } },
  { title: customPreset },
];

export const compareDatesWithoutMiliseconds = (date1: Date, date2: Date) =>
  Math.floor(date1.getTime() / 1000) === Math.floor(date2.getTime() / 1000);

export const LibraryDialogPrefix = 'LD_';
export const DocumentObjectsPrefix = 'DOP_';
export const SpaceReplacer = '_SPACE_';
export const addPrefixToFilters = (filters, prefix = LibraryDialogPrefix) =>
  Object.keys(filters).reduce((acc, key) => {
    const newKey = `${prefix}${key}`;
    acc[newKey] = filters[key]; // Add underscore to the key
    return acc;
  }, {});
export const removePrefixFromFilters = (filters, prefix = LibraryDialogPrefix) =>
  Object.keys(filters).reduce((acc, key) => {
    const newKey = key.replace(prefix, '');
    acc[newKey] = filters[key]; // Add underscore to the key
    return acc;
  }, {} as LogsQuery);
export const areSetsEquals = (a, b) => a.size === b.size && a.union(b).size === a.size;

export const getPieSlicerFilter = pieSlicer =>
  pieSlicer.length
    ? pieSlicer.map(({ fieldName, value }) =>
        ['null', null].includes(value)
          ? getExpression({
              fieldName,
              operator: StringConditionType.EMPTY,
              typeCondition: ConditionType.string,
              value: {},
            })
          : getExpression({
              fieldName,
              operator: StringConditionType.EQUALS,
              typeCondition: ConditionType.string,
              value: value.toString(),
            })
      )
    : [];

export const getIsValidQuery = activeDraftTab => {
  const queryObject = stringToFilter(activeDraftTab.query as string);
  return activeDraftTab.queryType === QueryType.Advanced ? activeDraftTab.query !== '' : queryObject || activeDraftTab.query === undefined;
};
