import React from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import * as d3 from 'd3';
import { Link } from 'react-router-dom';
import { rebranding } from '../../rebranding';
import AvTag from '../AvTag';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { ReactComponent as ErrorWidgetNew } from '../../assets/colorful2/NoDataErrorWidget.svg';
import { ReactComponent as ErrorWidget } from '../../assets/ErrorInWidget.svg';
import { ReactComponent as Forward } from '../../assets/Forward.svg';
import { ReactComponent as HistoryData } from '../../assets/History.svg';
import { ReactComponent as NoData } from '../../assets/WidgetNoData.svg';

const historyIcon = <HistoryData strokeWidth={0.5} style={{ height: 16, width: 16 }} />;

interface WidgetTitleProps {
  children: React.ReactNode;
  marginBottom: number;
}
export const WidgetTitle: React.FC<WidgetTitleProps> = ({ children, marginBottom }) => (
  <Box sx={{ marginBottom, fontSize: 18, fontWeight: 600 }}>{children}</Box>
);
interface WidgetDescriptionProps {
  showImage?: boolean;
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}
export const NoDataFoundWidget: React.FC<WidgetDescriptionProps> = ({
  showImage = true,
  title = 'No Data Found',
  description = '',
  icon,
}) => (
  <Box sx={{ ...flex.colCenter, textAlign: 'center', flex: 1, svg: { width: 'auto', height: 'auto' } }}>
    {showImage && (icon || <NoData style={{ width: '8%', maxWidth: 68 }} />)}
    <Typography variant="h5" sx={{ color: theme => theme.palette.colors.neutrals[600], pt: 3, pb: 2 }}>
      {title}
    </Typography>
    <Box>{description}</Box>
  </Box>
);

interface ErrorLoadingWidgetProps {
  text?: string;
  onClick?: () => void;
}
export const ErrorLoadingWidget: React.FC<ErrorLoadingWidgetProps> = ({ text = 'Try to reload the page', onClick }) => {
  const content =
    typeof onClick === 'function' ? (
      <Button sx={{ color: theme => theme.palette.primary.main, fontWeight: 600 }} variant="text" onClick={onClick}>
        {text}
      </Button>
    ) : (
      <Box>{text}</Box>
    );
  return (
    <Box sx={{ ...flex.colCenter, textAlign: 'center', flex: 1, svg: { width: 'auto', height: 'auto' } }}>
      {rebranding ? <ErrorWidgetNew style={{ width: '8%', maxWidth: 68 }} /> : <ErrorWidget style={{ width: '8%', maxWidth: 68 }} />}
      <Typography variant="h5" sx={{ color: theme => theme.palette.colors.neutrals[600], pt: 3, pb: 2 }}>
        Error Fetching Data
      </Typography>
      {content}
    </Box>
  );
};

const actionStyle = theme => ({
  ...flex.itemsCenter,
  gap: 1,
  fontWeight: 600,
  color: theme.palette.primary.main,
});

interface ExploreLinkProps {
  actionHref: string;
  title: string;
  sx?: React.CSSProperties;
}
export const ExploreLink: React.FC<ExploreLinkProps> = ({ actionHref, title, sx = {} }) => (
  <Link to={actionHref}>
    <Box sx={theme => ({ ...actionStyle(theme), ...sx })}>
      Explore {title} <Forward width={20} />
    </Box>
  </Link>
);

export const tickFormat = d3.format('.2~s');

export const getHorizontalWidth = data =>
  100 -
  Math.min(
    80,
    Math.max(
      20,
      100 -
        data
          .map(datum => datum.name)
          .reduce((max, currentString?: string) => (currentString?.length && currentString.length > max ? currentString.length : max), 0) *
          5
    )
  );

export function HistoricDataIndicator({ hideText, title = 'Historical Data', sx = {} }: { hideText: boolean; title?: string; sx? }) {
  return (
    <AvTooltip title={hideText ? title : ''}>
      <div>
        <AvTag
          text={
            <>
              {historyIcon}
              {!hideText && title}
            </>
          }
          sx={{ ...flex.itemsCenter, gap: '6px', ...sx }}
        />
      </div>
    </AvTooltip>
  );
}
