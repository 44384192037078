import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { decodeJWT } from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../components/amplifyProvider';
import { appsOptions } from '../components/newNavigation/appbar.constants';
import { useUserActivityStatus } from '../hooks/auth';
import useHandleError from '../hooks/useHandleError';
import { useTypeNameMap } from '../hooks/UseTypeNameMap';
import { APP_PATHS, getRoleNameByValue, PAGE_PATHS, Permission, ROLES } from '../types';
import { ErrorTypes } from '../types/query.types';
import { getAccountTokenFromLS, sharedWorkerAuthFlow } from '../utils/auth.utils';
import { entityViewConfig } from '../utils/entityViewConfig';
import { AuthStorageKeys } from '../utils/localStorageAuthUtils.utils';
import { useLogoutWhenUserIsIdle } from '../utils/logout.utils';
import { QueryKey } from '../utils/queryKey';
import { getAppIdFromPathName, getBranchPrefix, getPathNameByAccount, useAccountId } from '../utils/router.utils';
import useApi from '../utils/useApi';
import { emptyObject } from '../utils/Utils';
import { sharedWorkerService } from '../workers/sharedWorkerService';
import { useHasAppInAccount, usePermissions } from './AvContext.hooks';
import { AccountType } from './AvContext.types';
import { DescriptorType } from './context.type';
import { GET_MEASUREMENT, GET_UI_CONFIG, GET_USER_ADDITIONAL_DATA } from './gql';
import { AccountDto, AvContextType } from './types';
import { useAccount } from './useAccount';
import useEntities, { createFieldMap, createFieldMapType } from './useEntities';
import { useForceRender } from './utils';

export const AvContext = React.createContext<AvContextType>({
  UIConfig: undefined,
  accessToken: '',
  accountData: { id: '', name: '', apps: [], logo: null, accountType: AccountType.POC, flags: { isRecommendedFieldsEnabled: false } },
  refetchAccountData(): void {},
  accountId: '',
  selectedApp: '',
  setSelectedApp(): void {},
  api: undefined,
  demoMode: false,
  featureFlags: undefined,
  accountEntities: {} as AvContextType['accountEntities'],
  getPathName: () => '',
  isAuthenticated: undefined,
  isLoading: false,
  measurements: { all: [], visible: [], asObject: emptyObject },
  refetchUIConfig(): void {},
  setAccountId(): void {},
  typeNameMap: undefined,
  user: {
    accountId: '',
    accounts: [{ accountId: '', accountName: '', userRoleId: '' }],
    permissions: [],
    roleId: '',
    userId: '',
    favoriteApps: [],
    email: '',
    userName: '',
  },
  refetchAdditionalData: undefined,
  isBackOfficeAvailable: false,
  userPermissions: {
    hasAllowedPermission: () => false,
    hasAllowedPermissionToResource: () => false,
    hasAllowedEditResourcePermission: () => false,
    allowedPermissionsToResource: () => ({}),
    allowedPermissionsToPath: () => ({}),
    hasOnlyPermission: () => false,
    allowedPermissions: {},
    isInternalRole: false,
    isAvalorAdmin: false,
  },
  userIsActive: true,
  hasAppInAccount: () => false,
});

const useUserAdditionalInfo = ({ authUser, api, enableRequests }) =>
  useQuery({
    queryKey: [QueryKey.UserAdditionalInfo, JSON.stringify(authUser)],
    queryFn: () =>
      api(GET_USER_ADDITIONAL_DATA).then(({ data }) => ({
        ...authUser,
        ...data?.getUserAdditionalData,
      })),
    enabled: enableRequests && !!authUser.userId,
  });

const useFeatureFlags = ({ api, enableRequests, onSuccess = d => d }) =>
  useQuery({
    queryKey: [QueryKey.FeatureFlags],
    queryFn: () => api('feature-flags', { isJson: false, onError: () => false, onSuccess }),
    enabled: enableRequests,
    retry: 10,
    retryDelay: 1000,
  });

export const useMeasurements = ({ api, enableRequests }: { api; enableRequests? }) =>
  useQuery({
    queryKey: [QueryKey.MeasurementList],
    queryFn: () =>
      api(GET_MEASUREMENT).then(({ data }) => {
        const all = data?.findMeasurementsByAccountId.map(v => {
          const { measurement, ...rest } = v;
          return {
            ...measurement,
            ...rest,
            isRecommended: true,
            type: DescriptorType.TYPE_DOUBLE,
          };
        });

        return {
          all,
          visible: all.filter(({ hidden }) => !hidden),
          asObject: all.reduce((acc, item) => {
            const { systemName, ...rest } = item;
            acc[systemName] = rest;
            return acc;
          }, {}),
        };
      }),
    enabled: enableRequests,
  });

const useUiConfig = ({ api, enableRequests }) =>
  useQuery({
    queryKey: [QueryKey.UiConfig],
    queryFn: () => api(GET_UI_CONFIG, { onSuccess: ({ data }) => data.getUIConfigurationByProjection }),
    enabled: enableRequests,
    retry: 10,
    retryDelay: 1000,
  });

export default function AvContextProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleError = useHandleError();
  const { user: authUser, isLoading: loadingAuth, isAuthenticated, refreshAccessToken } = useAuth();
  const accountId = useAccountId();
  const client = useQueryClient();
  const userIsActive = useUserActivityStatus();
  const accessToken = getAccountTokenFromLS(accountId);
  const [searchParams] = useSearchParams();
  const demoMode = !!searchParams.get('demoMode');
  const [featureFlags, setFeatureFlags] = useState({});
  const api = useApi(demoMode, accountId, refreshAccessToken, featureFlags);
  const selectedAppId = getAppIdFromPathName(location.pathname) || '';
  const [selectedApp, setSelectedApp] = useState(selectedAppId || '');
  const forceRender = useForceRender();
  const defaultAccount: AccountDto = {
    id: '',
    name: '',
    apps: [],
    logo: null,
    accountType: AccountType.POC,
    flags: { isRecommendedFieldsEnabled: false },
  };
  const enableRequests = !!accountId && !!accessToken && accountId === decodeJWT(accessToken).payload!.account_id;
  const {
    data: accountData = defaultAccount,
    refetch: refetchAccountData,
    isPending: isLoadingAccount,
    error: accountError,
  } = useAccount({ api, demoMode, defaultAccount, enableRequests });

  useEffect(() => {
    if (sharedWorkerAuthFlow() && accountId) {
      if (userIsActive) {
        sharedWorkerService.notifyWorkerOnActiveTabEvent(accountId);
        refreshAccessToken(accountId);
      } else {
        sharedWorkerService.notifyWorkerOnInactiveTabEvent(accountId);
      }
    }
  }, [userIsActive, accountId]);

  const supportOldTicketRoute = () => {
    const { search } = window.location;
    const pathname = window.location.pathname?.split('/')?.slice(2)?.join('/');
    const ticketCategory = searchParams.get('ticketCategory');
    const ticketApp = ticketCategory && ticketCategory !== entityViewConfig.Ticket.app ? APP_PATHS.DETECTIONS : APP_PATHS.VULNERABILITIES;
    setSelectedApp(ticketApp);
    navigate(getPathNameByAccount(accountId, ticketApp)(pathname, search));
  };

  useEffect(() => {
    if (selectedAppId) {
      if (selectedAppId === PAGE_PATHS.TICKETS) {
        supportOldTicketRoute();
      } else {
        setSelectedApp(selectedAppId);
      }
    }
  }, [selectedAppId]);

  const previousAccount = useRef(accountId);

  useEffect(() => {
    if (previousAccount.current !== accountId) {
      switchAccountClearCache(accountId);
      forceRender();
    }

    if (sharedWorkerAuthFlow()) {
      const handleBeforeUnload = () => {
        sharedWorkerService.notifyWorkerOnUnloadEvent(accountId);
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      // Cleanup the event listener on component unmount or when accountId changes
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }

    return () => {};
  }, [accountId]);

  const switchAccountClearCache = id => {
    if (sharedWorkerAuthFlow()) {
      sharedWorkerService.notifyWorkerOnSwitchAccountEvent(previousAccount.current);
    }
    previousAccount.current = id;
    client.clear();
  };

  const setAccountId = id => {
    if (getBranchPrefix()) {
      localStorage.setItem(AuthStorageKeys.accountId, accountId);
    }
    if (location.pathname === '/') {
      navigate(`${getBranchPrefix() ? `${getBranchPrefix()}/` : ''}${id}`);
    } else {
      switchAccountClearCache(id);
    }
  };

  const {
    data: user = { permissions: '' },
    isPending: isLoadingUser,
    refetch: refetchAdditionalData,
    error: userError,
  } = useUserAdditionalInfo({ authUser, api, enableRequests });
  const { isPending: isLoadingFeatureFlags, error: ffError } = useFeatureFlags({ api, enableRequests, onSuccess: setFeatureFlags });
  useLogoutWhenUserIsIdle();
  const {
    data: UIConfig = {},
    isPending: isLoadingUIConfig,
    refetch: refetchUIConfig,
    error: uiConfigError,
  } = useUiConfig({ api, enableRequests });
  const { data: typeNameMap, isPending: isLoadingSources, error: connectorsError } = useTypeNameMap({ api, enableRequests });
  const {
    data: measurements,
    isPending: isLoadingMeasurements,
    error: measurmentsError,
  } = useMeasurements({
    api,
    enableRequests,
  });

  const { data: accountEntities, isLoading: loadingFields, error: entitiesError } = useEntities({ api, enableRequests });
  const userPermissions = usePermissions({ featureFlags, user });
  const hasAppInAccount = useHasAppInAccount({ accountData });
  const isBackOfficeAvailable = userPermissions.hasAllowedPermission({
    path: PAGE_PATHS.BACKOFFICE_ACTIONS,
    permission: Permission.UPDATE,
  });

  useEffect(() => {
    if (user?.roleName && user.roleName === getRoleNameByValue(ROLES.PRE_POV)) {
      setSelectedApp(APP_PATHS.PLATFORM);
    } else if (accountData.apps.length && !selectedApp && user?.roleName) {
      const userApps = appsOptions.find(
        ({ rolesPermission, id }) =>
          rolesPermission.includes(ROLES[user?.roleName]) && accountData.apps.map(({ name }) => APP_PATHS[name]).includes(id)
      );
      setSelectedApp(userApps?.id);
    }
  }, [accountData.apps, user.roleName]);

  const error = accountError || ffError || entitiesError || uiConfigError || connectorsError || measurmentsError || userError;
  if (error) {
    handleError(error);
    throw new Error(ErrorTypes.AvContext);
  }

  const value = useMemo(
    () => ({
      accountId,
      setAccountId,
      selectedApp,
      setSelectedApp,
      demoMode,
      accessToken,
      featureFlags,
      isLoading:
        isLoadingMeasurements ||
        isLoadingUser ||
        isLoadingFeatureFlags ||
        !Object.keys(featureFlags).length ||
        isLoadingAccount ||
        isLoadingUIConfig ||
        isLoadingSources ||
        (!demoMode && loadingAuth) ||
        loadingFields,
      isAuthenticated: demoMode || isAuthenticated,
      user,
      refetchAdditionalData,
      accountEntities,
      typeNameMap,
      measurements,
      accountData,
      refetchAccountData,
      UIConfig,
      api,
      getPathName: getPathNameByAccount(accountId, selectedApp),
      refetchUIConfig,
      isBackOfficeAvailable,
      userPermissions,
      hasAppInAccount,
      userIsActive,
    }),
    [
      accountId,
      accessToken,
      featureFlags,
      loadingAuth,
      isLoadingUser,
      loadingFields,
      isLoadingAccount,
      accountEntities,
      typeNameMap,
      measurements,
      isLoadingFeatureFlags,
      isLoadingUIConfig,
      isAuthenticated,
      user,
      accountData,
      UIConfig,
      selectedApp,
      userPermissions,
    ]
  );
  return <AvContext.Provider value={value}>{children}</AvContext.Provider>;
}
AvContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const AvContextWithExternalProjectionsProvider = ({ children }) => {
  const { accountEntities, ...rest } = useAvContext();
  const value = React.useMemo(
    () => ({
      ...rest,
      accountEntities: {
        ...accountEntities,
        aggProjs: { ...accountEntities.aggProjs, ...accountEntities.externalProjs },
        fieldMap: { ...accountEntities.fieldMap, ...createFieldMap(accountEntities.externalProjs) },
        fieldTypeMap: { ...accountEntities.fieldTypeMap, ...createFieldMapType(accountEntities.externalProjs) },
      },
    }),
    [accountEntities, rest]
  );

  return <AvContext.Provider value={value}>{children}</AvContext.Provider>;
};

export const WithExternalProjections = () => (
  <AvContextWithExternalProjectionsProvider>
    <Outlet />
  </AvContextWithExternalProjectionsProvider>
);

export const useAvContext = () => useContext(AvContext);
