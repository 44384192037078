import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { dotStyle } from '../../utils/Utils';

interface ColorItemProps {
  item: string;
  isActive?: boolean;
  onClick?: () => void;
  size?: number;
}
const ColorItem = forwardRef<HTMLInputElement, ColorItemProps>(({ item: color, onClick, isActive = false, size = 20 }, ref) => (
  <Box
    sx={{
      ...(onClick && { p: '2px' }),
      borderRadius: '50%',
      border: theme => `2px solid ${isActive ? theme.palette.colors.neutrals[600] : 'transparent'}`,
      transition: theme =>
        theme.transitions.create('border-color', {
          duration: theme.transitions.duration.shortest,
        }),
    }}>
    <Box
      ref={ref}
      sx={{
        ...dotStyle(color, size),
        ...(onClick && { cursor: 'pointer' }),
      }}
      onClick={onClick}
    />
  </Box>
));

export default ColorItem;
