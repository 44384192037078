import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { LayoutType } from 'recharts/types/util/types';
import AvComposedWidget from '../../../../../components/Widgets/AvComposedWidget';
import { getHorizontalWidth } from '../../../../../components/Widgets/layout.components';
import { ChartClickInteraction } from '../../../../../components/Widgets/types';
import { isValidSeverity, severityBackgroundColorOrUndefined } from '../../../../../utils/severity.utils';
import { generateInnerDefaultFormatter, uniqBy } from '../../../../../utils/Utils';
import { NO_VALUE } from '../../../constants';
import { getDateFormatFromWidget } from '../../../EditCustomDashboard/utils';
import { getCustomTickFormatter, useCustomPalette, useGetDisplayName, useGetFieldType } from '../../../hooks';
import { BarCategoryWidgetTypes, BarLegendPosition, DisplayModeOptions } from '../../../types';
import { getRepeatedValueToString, orientationOption, OrientationType, stackedOption, StackedType } from '../../../Utils';

interface BarWidgetProps {
  widget: BarCategoryWidgetTypes;
  data?: Record<string, any>[];
  isLoading?: boolean;
  selected?: any[];
  onSelect?: (v) => void;
  clickInteractions?: ChartClickInteraction[];
}

const flexDirectionMap = {
  [BarLegendPosition.Top]: 'column',
  [BarLegendPosition.Bottom]: 'column-reverse',
};

export const getBarQueryType = ({ dims, metrics }) =>
  dims.length === 1 && metrics.length === 1
    ? BarVisualizationType.Normal
    : dims.length === 1 && metrics.length > 1
      ? BarVisualizationType.MultiMetric
      : BarVisualizationType.MultiDims;

export enum BarVisualizationType {
  Normal,
  MultiMetric,
  MultiDims,
}

const BarWidget: React.FC<BarWidgetProps> = ({
  widget,
  data,
  selected: selectedValues = [],
  onSelect: onSelectValues,
  clickInteractions,
}) => {
  const getDisplayName = useGetDisplayName(widget.requests[0].projectionId.name);
  const getHeadCellType = useGetFieldType(widget.requests[0].projectionId.name);
  const isVertical = orientationOption[widget.type] === OrientationType.Vertical;
  const isScroll = widget.definition.custom.displayMode === DisplayModeOptions.SCROLL;
  const customPalette = useCustomPalette(data);
  const { dims } = widget.requests[0].select;
  const dimName = dims[0].alias || dims[0].name;
  const {
    hideLabel,
    legend: { margin },
  } = widget.definition.custom;
  const { barData, series, tooltipProps, tooltipComponentProps, onSelect, selected } = useFormatBarData({
    data,
    widget,
    customPalette,
    getDisplayName,
    getHeadCellType,
    selected: selectedValues,
    onSelect: onSelectValues,
    isActiveIndex: !!widget.drillDownHierarchy?.activeIndex,
  });
  const shouldRotateTicks = isScroll ? (!isVertical ? barData.length >= 6 : barData.length > 6) : barData.length > 6;
  const flexDirection = flexDirectionMap[widget.definition.custom.legend.position];
  const horizontalWidth = useMemo(() => getHorizontalWidth(barData), [barData]);

  const commonProps: React.ComponentPropsWithoutRef<typeof AvComposedWidget> = {
    series,
    data: barData,
    tooltipProps: {
      ...tooltipProps,
      widgetData: barData,
    },
    xAxisKey: 'name',
    onSelect,
    selected,
    showLegend: widget.definition.custom.legend.position !== BarLegendPosition.None,
    className: 'draggableCancel',
    chartProps: {
      margin: margin || { top: 0, bottom: isVertical ? 30 : 10, left: isVertical ? 0 : horizontalWidth },
      layout: (isVertical ? 'horizontal' : 'vertical') as LayoutType,
      xAxisTickType: widget.definition.custom.xAxisTickType,
      yAxisTickType: widget.definition.custom.yAxisTickType,
    },
    ...(isVertical
      ? {
          defaultAxisProps: {
            xLabel: { dy: shouldRotateTicks ? 50 : 30 },
            ...(isScroll
              ? {
                  x: {
                    ...(isVertical ? { interval: 0 } : {}),
                    customTickFormatter: getCustomTickFormatter(widget.definition.custom.xAxisFormatter),
                  },
                  y: { ...(isVertical ? {} : { interval: 0 }) },
                }
              : { x: { customTickFormatter: getCustomTickFormatter(widget.definition.custom.xAxisFormatter) } }),
          },
        }
      : {}),
    labels: {
      ...(!hideLabel ? (isVertical ? { x: getDisplayName(dimName) } : { y: getDisplayName(dimName) }) : {}),
    },
    sx: {
      flexDirection,
      pb: flexDirection === flexDirectionMap[BarLegendPosition.Bottom] ? 2 : 1,
      pt: '10px',
    },
    tooltipComponentProps,
    clickInteractions,
    dateFormat: getDateFormatFromWidget(widget),
    ...(isScroll
      ? {
          responsiveContainerProps: {
            width: isVertical && barData.length > 11 ? `${barData.length * 8}%` : '100%',
            height: !isVertical && barData.length > 6 ? `${barData.length * 8}%` : '100%',
            sx: {
              overflowX: isVertical ? 'scroll' : 'hidden',
              overflowY: !isVertical ? 'scroll' : 'hidden',
            },
          },
        }
      : {}),
  };

  return (
    <Box sx={barContainerStyle}>
      <AvComposedWidget {...commonProps} />
    </Box>
  );
};

export default BarWidget;

const useFormatBarData = ({
  data,
  widget,
  customPalette,
  getDisplayName,
  getHeadCellType,
  selected = [],
  onSelect,
  isActiveIndex = false,
}: {
  data: any;
  widget: BarCategoryWidgetTypes;
  customPalette: string[];
  getDisplayName: any;
  getHeadCellType: any;
  selected?: any;
  onSelect?: (v) => void;
  isActiveIndex: boolean;
}) => {
  const theme = useTheme();
  const { dims, metrics } = widget.requests[0].select;
  const dimName = dims[0].alias || dims[0].name;

  const barQueryType = getBarQueryType({ dims, metrics });
  const xAxis0Values = [...new Set(data?.map(v => getRepeatedValueToString(v[dimName])))];
  const isStacked = stackedOption[widget.type] === StackedType.Stacked;
  const isStacked100 = stackedOption[widget.type] === StackedType.Stacked100;
  const isOneBarForXValue = isStacked || isStacked100 || barQueryType === BarVisualizationType.Normal;
  const relevantDim = barQueryType === BarVisualizationType.Normal || isActiveIndex ? dims[0]?.alias || dims[0]?.name : dims[1]?.name;

  const shouldDisplayNull = true;

  const headCellType = getHeadCellType({ entityFieldKey: dimName });
  const barDataMultiMetrics = data?.map(v => ({
    ...v,
    name: generateInnerDefaultFormatter({
      value: v[dimName],
      type: headCellType,
      fieldName: dimName,
      shouldDisplayNull,
    }),
  }));

  const barData = [BarVisualizationType.MultiDims, BarVisualizationType.Normal].includes(barQueryType)
    ? xAxis0Values?.map((x0Val: any) => {
        const row = data
          ?.filter(v => getRepeatedValueToString(v[dimName]) === x0Val)
          .reduce(
            (acc, v) => ({
              ...acc,
              [v[relevantDim] === null ? NO_VALUE : getRepeatedValueToString(v[relevantDim])]: v[metrics[0].alias || 0] || 0,
            }),
            {}
          );
        return {
          name: generateInnerDefaultFormatter({
            value: x0Val,
            type: headCellType,
            fieldName: dimName,
            shouldDisplayNull,
          }),
          ...row,
        };
      })
    : barDataMultiMetrics;

  const getDataKey = value => {
    const parsedVal = getRepeatedValueToString(value);

    // Boolean values are not supported as boolean in bar charts
    if (typeof parsedVal === 'boolean') {
      return `${parsedVal}`;
    }

    return parsedVal || NO_VALUE;
  };

  const getSeries = () =>
    uniqBy(
      data?.map(datum => {
        const headCellType = getHeadCellType({ entityFieldKey: relevantDim });
        return {
          name: generateInnerDefaultFormatter({
            value: datum[relevantDim],
            type: headCellType,
            fieldName: relevantDim,
            shouldDisplayNull,
          }),
          dataKey: getDataKey(datum[relevantDim]),
          stackId: isOneBarForXValue ? 'one' : undefined,
          type: 'bar',
          is100Percent: isStacked100,
        };
      }),
      v => v.name
    ).map((v, i) => ({
      ...v,
      color: isValidSeverity(v.name) ? severityBackgroundColorOrUndefined(v.name, theme) : customPalette[i],
    }));

  const getMultiMetricsSeries = () =>
    uniqBy(
      metrics?.map(({ alias: yKey }, i) => ({
        name: getDisplayName(yKey),
        dataKey: yKey,
        stackId: isOneBarForXValue ? 'none' : undefined,
        color: isValidSeverity(yKey) ? severityBackgroundColorOrUndefined(yKey, theme) : customPalette[i],
        type: 'bar',
        is100Percent: isStacked100,
      })),
      v => v.name
    );

  const series = barData
    ? [BarVisualizationType.MultiDims, BarVisualizationType.Normal].includes(barQueryType)
      ? getSeries()
      : getMultiMetricsSeries()
    : [];

  return {
    barData,
    series,
    onSelect,
    selected,
    tooltipComponentProps: {
      shared: false,
    },
    tooltipProps: {
      titleFormatter: t => t || NO_VALUE,
      metricFormatter: v => {
        const headCellType = getHeadCellType({ entityFieldKey: relevantDim });
        return barQueryType === BarVisualizationType.MultiMetric
          ? getDisplayName(v)
          : generateInnerDefaultFormatter({
              value: v,
              type: headCellType,
              fieldName: relevantDim,
              shouldDisplayNull,
            });
      },
    },
  };
};

const barContainerStyle = {
  flex: 1,
  overflow: 'hidden',
  pr: 1,
  height: '100%',
  '.recharts-surface': {
    pointerEvents: 'auto',
    height: 'auto',
    width: 'auto',
    gap: 2,
    'text:not(.custom-chart-text)': {
      fontSize: '12px',
      fill: theme => theme.palette.colors.neutrals[500],
    },
    '.recharts-xAxis': {
      transform: 'translate(0,5px)',
    },
    '.recharts-yAxis': {
      transform: 'translate(10px,-3px)',
    },
  },
};
