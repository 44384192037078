import React, { ReactElement, useMemo, useRef } from 'react';
import { Box, Button, Divider, IconButton, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useAvContext } from '../context/AvContextProvider';
import { FieldListObject } from '../context/context.type';
import { rebranding } from '../rebranding';
import { FeatureFlags } from '../types';
import { ErrorTypes } from '../types/query.types';
import { FilterTypes } from '../utils/mapping';
import { emptyArray, emptyObject, generateOption, iconSize, prettyFieldName, rearrange, rearrangeObject } from '../utils/Utils';
import { clickthroughPrefix } from '../views/CustomDashboards/components/Clickthrough/consts';
import { FieldTypes } from '../views/Reports/types';
import { typeLabelMap } from '../views/Sources/Mapping/mapping.types';
import { filtersFieldToIgnore, getFieldLabel } from '../views/Tickets/ticket.types';
import { extractMeasurementDescription } from './AvFilters.utils';
import { flex } from './AvThemeProvider';
import { FieldFilter } from './FieldFilter';
import FiltersEmptyState from './FiltersEmptyState';
import FiltersListToolbar, { ButtonTypes } from './FiltersListToolbar';
import Select from './Select';
import useFilterFields, { getVisibleDims } from './useFilterFields';
import WithDescriptionTooltip from './WithDescriptionTooltip/WithDescriptionTooltip';
import { ReactComponent as FilterActive } from '../assets/colorful/FilterActive.svg';
import { ReactComponent as FilterActiveNew } from '../assets/colorful2/FilterActive.svg';
import { ReactComponent as DragSmall } from '../assets/DragSmall.svg';
import { ReactComponent as Filter } from '../assets/Filter.svg';
import { ReactComponent as Plus } from '../assets/Plus.svg';
import { ReactComponent as X } from '../assets/X.svg';

const dragIcon = <DragSmall />;
const filterIcon = <Filter style={{ width: 20, height: 20 }} />;
const filterActiveIcon = rebranding ? (
  <FilterActiveNew style={{ width: 20, height: 20 }} />
) : (
  <FilterActive style={{ width: 20, height: 20 }} />
);
const filtersDropdownWidth = '360px';

const getSelectedFiltersMap = (selectedFilters: string[]) => selectedFilters.reduce((acc, filter) => ({ ...acc, [filter]: true }), {});
const flatArrayOfOptions = data => [...new Set(data?.map(({ value }) => value).flat(1))].map(generateOption);

const flatArrayOfOptionsWithCustomTitle = (data, label, titleGenerator = d => d) =>
  [...new Set(data?.map(({ value }) => value).flat(1))].map(value => ({ title: titleGenerator?.(value) || value, value }));

interface AvFiltersProps {
  currentTypeLabels?: { [key: string]: string };
  filters: { headCells?: string[]; [key: string]: any };
  staticFilters?: string[];
  updateFilters: (field: string, val: any) => void;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  onApplyFilter?: () => void;
  size?: 'xSmall' | 'small' | 'medium' | 'large';
  disabled?: boolean;
  isActiveFilter?: boolean;
  onClearFilters?: () => void;
  dims?: Partial<FieldListObject>[];
  metrics?: any[];
  usage?: 'SPF' | undefined;
  overrideExtra?: string[];
  activeProjName?: string;
  moreFiltersLabel?: React.ReactNode;
  displayNameMapFormatter?: { (key: string): (val: any) => string };
  children?: ReactElement<any, any>;
  queryKey?: string;
  isFilterExpression?: boolean;
  isGlobalFilter?: boolean;
  showIcon?: boolean;
  isDraggable?: boolean;
  removeFieldButton?: boolean;
  ignoreVisibilityConfig?: boolean;
  displayRecommendedFields?: boolean;
}

const AvFilters: React.FC<AvFiltersProps> = ({
  filters,
  staticFilters = emptyArray,
  updateFilters,
  setFilters,
  onApplyFilter,
  disabled,
  activeProjName,
  isActiveFilter,
  onClearFilters,
  dims = emptyArray,
  metrics = emptyArray,
  usage,
  overrideExtra,
  displayNameMapFormatter = emptyObject,
  size = 'xSmall',
  isFilterExpression = false,
  moreFiltersLabel = (
    <>
      <Plus /> More
    </>
  ),
  isDraggable,
  removeFieldButton,
  children,
  queryKey,
  isGlobalFilter = false,
  showIcon = false,
  displayRecommendedFields = true,
  ignoreVisibilityConfig = false,
}) => {
  const theme = useTheme();
  const {
    accountData: {
      flags: { isRecommendedFieldsEnabled },
    },
    featureFlags,
    measurements = { visible: [], asObject: {}, all: [] },
    accountEntities: { fieldMap },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const filtersRefsMap = useRef({});

  const isTopFields = displayRecommendedFields && featureFlags[FeatureFlags.TopFields] && isRecommendedFieldsEnabled;
  const isPopulatedFields = displayRecommendedFields && featureFlags[FeatureFlags.PopulatedFields] && isRecommendedFieldsEnabled;

  const visibleDims = useMemo(
    () => getVisibleDims({ dims, dimsToRemove: new Set(staticFilters), isUnpopulatedFieldsActive: true, ignoreVisibilityConfig }),
    [dims]
  );

  const fieldMeasurementsMap = useMemo(
    () => ({
      ...fieldMap,
      ...measurements.visible.reduce(
        (acc, { systemName, displayName, type }) => ({
          ...acc,
          [systemName]: {
            value: systemName,
            pathAlias: systemName,
            title: displayName,
            group: FieldTypes.Measurement,
            entityTypeId: { builtin: true },
            type,
          },
        }),
        []
      ),
    }),
    [measurements, fieldMap]
  );

  const allSelectedFilters = useMemo(() => {
    const setupErrorFields: string[] = [];
    const allFieldsList = new Set([...visibleDims, ...metrics].map(({ value }) => value));
    const filteredFilters = [
      ...new Set(
        Object.keys(filters).filter(originalField => {
          const field = originalField.replace('amp;', '');
          const isIncludedInAllFieldsList = !isGlobalFilter || allFieldsList.has(field);
          const canIgnoreFilter = filtersFieldToIgnore.includes(field) || field.includes(clickthroughPrefix);
          const isStaticFilter = staticFilters.includes(field);

          if (!isIncludedInAllFieldsList && !canIgnoreFilter && !isStaticFilter) {
            setupErrorFields.push(field);
          }

          return !isStaticFilter && !canIgnoreFilter && !!fieldMeasurementsMap[originalField] && isIncludedInAllFieldsList;
        })
      ),
    ];
    if (setupErrorFields.length) {
      const error = `${ErrorTypes.Setup}: unknown columns: ${setupErrorFields.join(', ')}`;
      console.warn(error);
      if (featureFlags[FeatureFlags.ShowServerErrorInToast]) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
    return filteredFilters;
  }, [filters, visibleDims, metrics]);

  const allSelectedFiltersSet = useMemo(() => new Set(allSelectedFilters), [allSelectedFilters]);

  const { fields, initFilters, resetFilters, ...filtersProps } = useFilterFields({
    dims,
    metrics,
    topFieldsEnabled: isTopFields,
    populatedFieldsEnabled: isPopulatedFields,
    selectedFields: allSelectedFiltersSet,
    ignoreVisibilityConfig,
  });

  const selectedFiltersByFilterType = useMemo(() => {
    const filteredFieldsList = new Set(fields.map(({ value }) => value));

    return allSelectedFilters.filter(field => filteredFieldsList.has(field));
  }, [filters, fields]);

  const allSelectedFiltersMap = getSelectedFiltersMap(allSelectedFilters);
  const onSelectedFiltersSelect = val => {
    const newFilter = val.find(f => !selectedFiltersByFilterType.includes(f));
    const deletedFilter = selectedFiltersByFilterType.find(f => !val.includes(f));
    if (newFilter) {
      updateFilters(newFilter, []);
      setTimeout(() => filtersRefsMap.current[newFilter]?.querySelector('button')?.click?.());
    } else if (deletedFilter) {
      removeField(deletedFilter);
    }
  };

  const commonProps = { size, disabled };
  const activeFilter = allSelectedFilters.some(field => filters[field]?.length) || isActiveFilter;

  const removeField = field => {
    const { [field]: deleted, ...rest } = filters;
    setFilters(rest);
  };
  const onDragEnd = ({ source, destination }) =>
    destination &&
    setFilters(
      rearrangeObject(
        filters,
        rearrange(
          allSelectedFilters,
          allSelectedFilters[source.index],
          destination.index,
          undefined,
          source.droppableId !== destination.droppableId
        )
      )
    );

  const getElement = (field: string) =>
    function dragItem(providedDrag, snapshotDrag) {
      return (
        <Box
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
          sx={{ position: 'relative', ':hover .dragItem': { opacity: 1 }, ...providedDrag.draggableProps.style }}>
          {isDraggable && !disabled && (
            <Box
              className="dragItem"
              sx={{
                opacity: snapshotDrag.isDragging ? 1 : 0,
                ...flex.center,
                // @ts-ignore
                ...(theme.components?.MuiPaper?.styleOverrides?.rounded || {}),
                height: '100%',
                position: 'absolute',
                zIndex: theme => theme.zIndex.modal,
                pr: 1,
                backgroundImage: `linear-gradient(to right, ${theme.palette.colors.neutrals[350]} 50%, transparent )`,
              }}>
              {dragIcon}
            </Box>
          )}
          <FieldFilter
            setRef={ref => (filtersRefsMap.current[field] = ref)}
            filters={filters}
            getOptions={displayNameMapFormatter[field] ? flatArrayOfOptionsWithCustomTitle : getOptionsByField[field]}
            key={field}
            {...commonProps}
            onChange={updateFilters}
            field={field}
            isEnabled={allSelectedFiltersMap[field]}
            overrideExtra={overrideExtra}
            label={getFieldLabel({
              field,
              fieldMap: fieldMeasurementsMap,
              projectionName: activeProjName,
            })}
            fieldType={typeLabelMap[fieldMeasurementsMap[field]?.type]}
            activeProjName={isGlobalFilter ? visibleDims.find(f => f.value === field).mainProjId.name : activeProjName}
            queryKey={queryKey}
            getValue={displayNameMapFormatter[field]}
            isFilterExpression={isFilterExpression}
            usage={usage}
            isGlobalFilter={isGlobalFilter}
            selectIcon={
              removeFieldButton ? (
                disabled ? (
                  <Box />
                ) : (
                  <IconButton
                    sx={iconSize(16)}
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      removeField(field);
                    }}>
                    <X />
                  </IconButton>
                )
              ) : undefined
            }
          />
        </Box>
      );
    };

  return (
    <Box
      sx={{
        ...flex.itemsCenter,
        flexWrap: 'wrap',
        gap: 1,
        '> svg': {
          mr: 1,
          color: theme => (activeFilter ? theme.palette.colors.primary[400] : theme.palette.colors.neutrals[500]),
          opacity: disabled ? 0.4 : 1,
        },
      }}>
      {(showIcon || !!Object.keys(filters).length) && (activeFilter ? filterActiveIcon : filterIcon)}
      {children}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!isDraggable}>
          {provided => (
            <Box
              ref={provided.innerRef}
              sx={{ ...flex.itemsCenter, flexWrap: 'wrap', gap: '5px', overflow: 'auto' }}
              {...provided.droppableProps}>
              {allSelectedFilters.map((field, index) => (
                <Draggable key={field} draggableId={field} index={index} isDragDisabled={allSelectedFilters.length === 1 || !isDraggable}>
                  {getElement(field)}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        {!disabled && (visibleDims.length > 0 || metrics.length > 0) && (
          <Select
            variant="text"
            transparent
            showSelection={false}
            {...commonProps}
            label={
              <Box sx={{ ...flex.itemsCenter, gap: 1, fontWeight: 600 }}>
                {allSelectedFilters.length ? (
                  moreFiltersLabel
                ) : (
                  <>
                    <Plus />
                    Add Filters
                  </>
                )}
              </Box>
            }
            onChange={onSelectedFiltersSelect}
            value={selectedFiltersByFilterType}
            isMultiple
            totalOptionsCount={fields.length}
            filterFunc={(options, { inputValue }) =>
              options
                .filter(
                  o => o.title.toLowerCase().includes(inputValue.toLowerCase()) || o.group?.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, 400)
            }
            groupByFunc={({ group }) => group}
            renderOption={option => (
              <WithDescriptionTooltip
                placement="right"
                title={extractMeasurementDescription(option, measurements.asObject)}
                badgify
                badgeProps={{ sx: { display: 'list-item' } }}>
                {option.title}
              </WithDescriptionTooltip>
            )}
            options={fields}
            inputToolbar={
              featureFlags[FeatureFlags.NewFieldsToolbar] ? (
                <FiltersListToolbar
                  {...filtersProps}
                  hiddenButtons={
                    new Set([
                      ButtonTypes.All,
                      ...(isRecommendedFieldsEnabled && featureFlags[FeatureFlags.TopFields] && featureFlags[FeatureFlags.PopulatedFields]
                        ? []
                        : [ButtonTypes.TopFields, ButtonTypes.Populated]),
                    ])
                  }
                  maxWidth={filtersDropdownWidth}
                />
              ) : undefined
            }
            onClose={isTopFields ? () => initFilters() : undefined}
            filterPopperMinWidth={filtersDropdownWidth}
            emptyState={
              <FiltersEmptyState
                onClick={resetFilters}
                activeFilterType={filtersProps.activeFilterType}
                isTopFieldsFilterActive={filtersProps.topFields.isTopFieldsFilterActive}
                isInUseFilterActive={filtersProps.inUse.isInUseFilterActive}
                isUnpopulatedFieldsActive={filtersProps.unpopulated.isUnpopulatedFieldsActive}
              />
            }
          />
        )}
        {onClearFilters && !!allSelectedFilters.length && (
          <>
            <Divider orientation="vertical" sx={{ height: 16, alignSelf: 'center' }} flexItem />
            <Button variant="text" disabled={disabled} size={size} onClick={() => onClearFilters()}>
              Clear Filters
            </Button>
          </>
        )}
        {onApplyFilter && !!allSelectedFilters.length && (
          <Button variant="contained" size="xSmall" onClick={() => onApplyFilter()}>
            Apply
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AvFilters;

export const getOptionsFromFields = (
  fields: FieldListObject[] = [],
  group: string | null = null,
  entity: string | null = null
): { title: string; value: string; group: string | null }[] =>
  fields
    .filter(({ name, builtIn }) => !builtIn && !blackListFields.includes(`${entity}.${name}`))
    .map(({ name }) => ({
      title: labels[`${entity}.${name}`] || prettyFieldName(name),
      value: entity ? `${entity}.${name}` : name,
      group,
    }));

export const getOptionsByField = {
  [FilterTypes.Technique]: flatArrayOfOptions,
  [FilterTypes.Asset]: flatArrayOfOptions,
  [FilterTypes.Tags]: flatArrayOfOptions,
  [FilterTypes.AssetType]: flatArrayOfOptions,
  [FilterTypes.TechniqueV2]: flatArrayOfOptions,
  [FilterTypes.AssetV2]: flatArrayOfOptions,
  [FilterTypes.TagsV2]: flatArrayOfOptions,
  [FilterTypes.AssetTypeV2]: flatArrayOfOptions,
};

// TODO: remove when cleaning newSchemaEntitiesMode
export const blackListFields = [
  'ticket.locking_key',
  'ticket.dedup_key',
  'ticket.is_fixable',
  'ticket.detection_sources',
  'ticket.finding_count',
  'ticket.asset_count',
  'ticket.vulnerabilities_count',
  'ticket.original_severity_score',
  'ticket.all_finding_count',
  'ticket.last_seen',
  'ticket.comments',
  'ticket.details',
  'ticket.activity_logs',
  'ticket.details_str',
  'ticket.severity_optimization_reasons',
  'ticket.integration_info',
  'ticket.type',
  'ticket.remediation_time',
  'ticket.previous_dedup_key',
  'finding.source',
  'finding.asset_mac',
  'finding.asset_hostname',
  'finding.type',
  'finding.asset_username',
  'finding.details',
  'finding.details_str',
  'finding.details_hash',
  'finding.previous_dedup_key',
  'finding.dedup_key_real',
  'finding.json_details',
  'finding.all_fix_versions',
  'finding.owner',
];

const labels = {
  'ticket.all_vulnerabilities_count': 'Findings',
  'ticket.all_asset_count': 'Assets',
  'ticket.integration_info.key': 'External Ticket ID',
};
