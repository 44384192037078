import React, { ReactNode } from 'react';
import { Box, Button } from '@mui/material';
import AvDialog from './AvDialog';
import { flex } from './AvThemeProvider';

export type ButtonConfiguration = {
  disabled?: boolean;
  text?: string;
  onClick: () => void;
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'warning' | 'error' | 'success';
};

export type AvConfirmDialogProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  body: ReactNode;
  confirmButton: ButtonConfiguration;
  closeButton: ButtonConfiguration;
  titleVariant?: 'error' | 'warning';
};

const AvConfirmDialog: React.FC<AvConfirmDialogProps> = ({
  isOpen,
  title,
  onClose,
  body,
  titleVariant,
  confirmButton: {
    text: confirmText = 'Continue',
    color: confirmColor = 'primary',
    variant: confirmVariant = 'contained',
    disabled: confirmDisabled = false,
    onClick: confirmOnClick,
  },
  closeButton: {
    text: closeText = 'Cancel',
    color: closeColor,
    variant: closeVariant,
    disabled: closeDisabled = false,
    onClick: closeOnClick = onClose,
  },
}) => {
  const modalFooter = (
    <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
      <Button sx={{ pr: 0.5 }} onClick={closeOnClick} disabled={closeDisabled} variant={closeVariant} color={closeColor}>
        {closeText}
      </Button>
      <Button color={confirmColor} variant={confirmVariant} disabled={confirmDisabled} onClick={confirmOnClick}>
        {confirmText}
      </Button>
    </Box>
  );

  return (
    <AvDialog title={title} onClose={onClose} open={isOpen} footer={modalFooter} titleVariant={titleVariant}>
      <Box>{body}</Box>
    </AvDialog>
  );
};

export default AvConfirmDialog;
