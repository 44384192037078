import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonGroupOwnProps,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import AvTooltip from './AvTooltip';
import { ReactComponent as Arrow } from '../assets/Arrow Down.svg';
import { ReactComponent as Checkmark } from '../assets/Checkmark.svg';
import { ReactComponent as X } from '../assets/X.svg';

const statusVariants: Record<string, { text: string; color?: string; icon?: JSX.Element }> = {
  success: { text: 'Success', color: 'success', icon: <Checkmark /> },
  error: { text: 'Failed', color: 'error', icon: <X /> },
  loading: { text: 'Running' },
};
export interface ButtonOption {
  label: string | React.ReactNode;
  clickHandler?: () => void;
  tooltip?: string;
  icon?: JSX.Element;
  body?: React.ReactNode;
  disabled?: boolean;
  groupNumber: number;
  hiddenOption?: boolean;
}

interface AvButtonGroupProps extends ButtonGroupOwnProps {
  options: ButtonOption[];
  disabled?: boolean;
  shouldShowGroups?: boolean;
  status?: 'success' | 'error' | 'loading';
  disableMenu?: boolean;
  shouldChangeMainLabel?: boolean;
  showActiveButton?: boolean;
}

const AvButtonGroup: React.FC<AvButtonGroupProps> = ({
  options,
  disabled,
  status,
  shouldShowGroups,
  disableMenu,
  shouldChangeMainLabel = true,
  showActiveButton = false,
  ...muiProps
}) => {
  const [open, setOpen] = useState(false);
  const [showSelectedOptionBody, setShowSelectedOptionBody] = useState(false);
  const anchorRef = useRef<any>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const sortedOptions = shouldShowGroups
    ? options.sort((a, b) => a.groupNumber - b.groupNumber).map((option, index) => ({ ...option, index }))
    : options;

  const handleMenuItemClick = index => {
    if (shouldChangeMainLabel) {
      setSelectedIndex(index);
    }
    setOpen(false);
    if (sortedOptions[index].body) {
      setShowSelectedOptionBody(true);
    }
    sortedOptions[index].clickHandler?.();
  };

  const setShowOptionBody = bodyOpen => {
    setShowSelectedOptionBody(bodyOpen);
    if (bodyOpen) {
      setOpen(false);
    }
  };

  const selectedOption = sortedOptions[selectedIndex] || {};
  useEffect(() => {
    if (!sortedOptions[selectedIndex]) {
      setSelectedIndex(0);
    } else if (selectedOption.disabled) {
      setShowOptionBody(false);
    }
  }, [sortedOptions]);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const statusColor: any = status && statusVariants[status]?.color;
  const statusText = status && statusVariants[status]?.text;

  if (!sortedOptions?.length && !statusText) {
    return null;
  }

  const splitOptionsIntoGroups = shouldShowGroups
    ? Object.values(
        sortedOptions
          .filter(option => !option.hiddenOption)
          .reduce((acc, curr) => ({ ...acc, [curr.groupNumber]: [...(acc[curr.groupNumber] || []), curr] }), {})
      )
    : null;

  const menuItem = (label, icon, disabled, tooltip, index) => (
    <AvTooltip key={label} title={tooltip}>
      <div>
        <MenuItem
          selected={index === selectedIndex}
          disabled={disabled}
          onClick={() => handleMenuItemClick(index)}
          style={{ paddingLeft: '8px' }}>
          {icon} {label}
        </MenuItem>
      </div>
    </AvTooltip>
  );

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ boxShadow: 'unset', flexShrink: 0, pointerEvents: statusColor ? 'none' : '' }}
        color={statusColor}
        disableRipple
        {...muiProps}>
        <Button
          className={[
            (muiProps as any).variant === 'filter' ? 'transparent' : undefined,
            showActiveButton || showSelectedOptionBody ? 'active-button' : undefined,
          ]
            .filter(d => d)
            .join(' ')}
          onClick={
            statusText
              ? undefined
              : selectedOption.body
                ? () => setShowOptionBody(!showSelectedOptionBody)
                : selectedOption.clickHandler || handleToggle
          }
          disabled={disabled || selectedOption.disabled || status === 'loading'}
          sx={{ flexGrow: 1 }}>
          {selectedOption.icon}
          {status && statusVariants[status]?.icon}
          {statusText || selectedOption.label}
        </Button>
        {sortedOptions.length > 1 && (
          <Button
            className={open ? 'active-button' : undefined}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open}
            aria-label="open menu options"
            aria-haspopup="menu"
            sx={{ '&.MuiButtonBase-root.MuiButton-root.MuiButtonGroup-grouped': { padding: 0 }, svg: { width: 18, height: 18 } }}
            disabled={disabled || status === 'loading' || disableMenu}
            onClick={handleToggle}>
            <Arrow />
          </Button>
        )}
      </ButtonGroup>
      {disabled ? null : selectedOption.disabled || !showSelectedOptionBody ? (
        <Popper open={open} anchorEl={anchorRef.current} transition sx={{ zIndex: ({ zIndex }) => zIndex.modal + 2 }}>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper elevation={5}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {shouldShowGroups && splitOptionsIntoGroups
                      ? splitOptionsIntoGroups.map((optionsGroup: any, groupIndex) => [
                          optionsGroup.map(({ label, icon, disabled, tooltip, index }) => menuItem(label, icon, disabled, tooltip, index)),
                          groupIndex === splitOptionsIntoGroups.length - 1 ? null : <Divider variant="normal" style={{ margin: '4px' }} />,
                        ])
                      : sortedOptions.map(({ label, icon, disabled, tooltip }, index) => menuItem(label, icon, disabled, tooltip, index))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ) : (
        selectedOption.body &&
        React.cloneElement(selectedOption.body as any, {
          anchorEl: anchorRef.current,
          showOptionBody: showSelectedOptionBody,
          onClose: () => setShowOptionBody(false),
        })
      )}
    </>
  );
};

export default AvButtonGroup;
