import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { Box, Button, Collapse, Divider, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { sub } from 'date-fns';
import { DocumentNode } from 'graphql/language';
import { useSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';
import { useAvQuery } from '../API/useAvQuery';
import { useAvContext } from '../context/AvContextProvider';
import { useCustomSearchParams } from '../hooks/UseCustomSearchParams';
import { PresignedUrlType, usePresignedUrl } from '../hooks/usePresignedURL';
import { rebranding } from '../rebranding';
import envVariables from '../shared/projectEnvVariables';
import { FeatureFlags } from '../types';
import { cellContentStyle, ellipsis } from '../utils/Utils';
import { ExpandButton } from '../views/ModelManagement/FieldInfo';
import { borderStyle } from '../views/Settings/ScoreSettings/utils';
import { syncModeMap } from '../views/Sources/utils';
import AvCollapseContent from './AvCollapseContent';
import { flex } from './AvThemeProvider';
import { StatusWithLogo } from './ItemWithLogo';
import NoDataFound from './NoDataFound';
import AvTable from './Table/AvTable';
import { FormatterType, SortType } from './Table/types';
import { ActionButton, expandArrowColumn } from './Table/Utils';
import { ReactComponent as CanceledIcon } from '../assets/colorful/Error_full_gray.svg';
import { ReactComponent as ErrorFull } from '../assets/colorful/Error_full.svg';
import { ReactComponent as PartialFull } from '../assets/colorful/Partial_Full.svg';
import { ReactComponent as SuccessFull } from '../assets/colorful/Success_full.svg';
import { ReactComponent as CanceledIconNew } from '../assets/colorful2/Error_full_gray.svg';
import { ReactComponent as ErrorFullNew } from '../assets/colorful2/Error_full.svg';
import { ReactComponent as PartialFullNew } from '../assets/colorful2/Partial_Full.svg';
import { ReactComponent as SuccessFullNew } from '../assets/colorful2/Success_full.svg';
import { ReactComponent as Copy } from '../assets/Copy.svg';
import { ReactComponent as Export } from '../assets/Export.svg';
import { ReactComponent as External } from '../assets/External.svg';
import NoDataFoundImg from '../assets/NoDataFound.svg';
import { ReactComponent as Sync } from '../assets/Sync.svg';
import { ReactComponent as X } from '../assets/XCircle.svg';

const exportIcon = <Export style={{ width: 20, height: 20 }} />;
const errorFullIcon = rebranding ? <ErrorFullNew /> : <ErrorFull />;
const successFullIcon = rebranding ? <SuccessFullNew /> : <SuccessFull />;
const partialFullIcon = rebranding ? <PartialFullNew /> : <PartialFull />;
const canceledFullIcon = rebranding ? <CanceledIconNew /> : <CanceledIcon />;
const externalIcon = <External />;
const syncIcon = <Sync />;
const cancelIcon = <X />;
const copyIcon = <Copy style={{ width: 20, height: 20 }} />;

const GET_ACTIVITIES = gql`
  query ($runList: [WorkflowInfo]) {
    getListOfActivities(runList: $runList)
  }
`;

const CANCEL_WORKFLOW = gql`
  mutation ($wfExecutions: workflowExecutionInput!) {
    cancelWorkflows(wfExecutions: $wfExecutions)
  }
`;

const temporalLink = (workflowId, runId) =>
  `${envVariables.VITE_TEMPORAL}/namespaces/default/workflows/${workflowId}/${runId}/history/compact`;

const datadogDashboardLink = (accountId, dsiId, startTime, endTime) => {
  const startTimestamp = sub(new Date(startTime), { minutes: 10 }).getTime();
  const endTimestamp = endTime ? Date.parse(endTime) : Date.now();
  return `https://app.datadoghq.com/dashboard/8n2-vue-mpk/etl-debugging?tpl_var_accountId[0]=${accountId}&tpl_var_dsiId[0]=${dsiId}&tpl_var_env[0]=${envVariables.VITE_ENV}&from_ts=${startTimestamp}&to_ts=${endTimestamp}&live=false`;
};

const workflowStatuses = {
  UNSPECIFIED: 'UNSPECIFIED',
  RUNNING: 'RUNNING',
  TIMED_OUT: 'TIMED_OUT',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
};

const statusesWithOrder = {
  [workflowStatuses.UNSPECIFIED]: 0,
  [workflowStatuses.RUNNING]: 1,
  [workflowStatuses.TIMED_OUT]: 2,
  [workflowStatuses.FAILED]: 3,
  [workflowStatuses.CANCELED]: 4,
  [workflowStatuses.COMPLETED]: 5,
};
const getMinDate = dates => (dates.length ? new Date(Math.min(...dates)) : '');
const getMaxDate = dates => (dates.length ? new Date(Math.max(...dates)) : '');

const getWorkflowsStatus = statuses => statuses.reduce((a, b) => (statusesWithOrder[a] < statusesWithOrder[b] ? a : b));

interface RunsPageProps {
  getWorkflowsQuery: DocumentNode | any;
  title: string;
  noDataProps: {
    goBackPath: string;
    runsNameTitle?: string;
    goBackTitle: string;
  };
  id?: string;
  isQueryLoading?: boolean;
  queryError?: any;
  customTexts?: any;
  allowExpandingRows?: boolean;
  inlineActions?: ((row) => React.ReactNode)[];
  presignedUrlType?: PresignedUrlType;
  shouldShowCategory?: boolean;
  shouldShowSyncMode?: boolean;
  queryOptions?: Record<string, any>;
  shouldShowProjectionName?: boolean;
}
export default function RunsPage({
  getWorkflowsQuery,
  title,
  id,
  isQueryLoading = false,
  queryError,
  noDataProps,
  customTexts = {},
  allowExpandingRows = false,
  inlineActions = [],
  presignedUrlType = PresignedUrlType.REGULAR,
  shouldShowCategory = true,
  shouldShowSyncMode = false,
  shouldShowProjectionName = true,
  queryOptions = {},
}: RunsPageProps) {
  const [searchParams, setSearchParams] = useCustomSearchParams({});
  const {
    isLoading,
    api,
    userPermissions: { isInternalRole },
    featureFlags,
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const urlGuidId = searchParams.guidId ? decodeURIComponent(searchParams.guidId) : null;
  const [page, setPage] = useState(0);

  const onErrorSnackBar = (msg, err) => enqueueSnackbar(`${msg}: ${err.message}`, { variant: 'error' });
  const {
    data: rows,
    isLoading: isWorkflowLoading,
    isError: workflowsError,
    refetch: refetchDsiWorkflows,
  } = useAvQuery({
    queryKey: ['getDsiWorkflows', id],
    queryFn: () =>
      api(getWorkflowsQuery, {
        options: { [getWorkflowsQuery.definitions[0].variableDefinitions[0].variable.name.value]: id, ...queryOptions },
        onError: err => onErrorSnackBar('Failed to fetch runs', err),
      }).then(({ data }) => onWorkFlowSuccessGQL(data[getWorkflowsQuery.definitions[0].selectionSet.selections[0].name.value])),
    enabled: !!id,
    select: data => data.map(row => ({ ...row, endTime: row.status === 'RUNNING' ? null : row.endTime })),
    //  Keep refetching every 10 seconds
    refetchInterval: 10000,
    gcTime: 0,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });

  const onWorkFlowSuccessGQL = ({ executionMap }) =>
    sortByStartTimeDesc(Object.keys(executionMap).map(guid => parseSingleRow(guid, executionMap[guid])));

  const parseSingleRow = (id, { workflowsExecutions: executionList, category }) => {
    const syncMode = executionList.find(executionList => executionList.workflowType === 'EtlRetrieveDataWorkflow')?.searchAttributes
      ?.SYNC_MODE;
    return {
      id,
      status: getWorkflowsStatus(executionList.map(exc => exc.status)),
      startTime: getMinDate(executionList.filter(exc => exc.startTime).map(exc => new Date(exc.startTime))),
      endTime: getMaxDate(executionList.filter(exc => exc.closeTime).map(exc => new Date(exc.closeTime))),
      guidRuns: executionList.map(execution => ({ runId: execution.runId, workflowId: execution.workflowId })),
      category,
      syncMode: syncModeMap[syncMode] || 'N/A',
    };
  };
  const onExpandedRow = selectedKey => {
    if (!selectedKey && searchParams.guidId) {
      setSearchParams({});
    } else if (selectedKey && selectedKey !== urlGuidId) {
      setSearchParams({ guidId: encodeURIComponent(selectedKey) });
    }
  };

  const expandedRow = ({ guidRuns, id }, onChange, isItemSelected) => (
    <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
      <Divider sx={{ opacity: 0.3 }} />
      <GuidActivitiesInfo
        guid={id}
        guidRuns={guidRuns}
        customTexts={customTexts}
        presignedUrlType={presignedUrlType}
        shouldShowProjectionName={shouldShowProjectionName}
        isInternalRole={isInternalRole}
      />
    </Collapse>
  );

  const noData = (
    <NoDataFound
      img={NoDataFoundImg}
      headline="No runs in the last 90 days"
      callToAction={
        <Button component={Link} to={noDataProps.goBackPath}>
          Go Back To {noDataProps.goBackTitle} Page
        </Button>
      }
    />
  );

  const { mutateAsync: cancelWorkflow } = useMutation({
    mutationKey: ['cancelWorkflows', id],
    mutationFn: (row: any) =>
      api(CANCEL_WORKFLOW, {
        options: { wfExecutions: { workflowsExecutions: row.guidRuns, dsiId: null } },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to cancel workflow ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Workflow Execution canceled successfully ', { variant: 'success' });
        refetchDsiWorkflows();
      }),
  });

  const cancelWorkflowInlineAction = row =>
    row.status === workflowStatuses.RUNNING ? ActionButton('Cancel Workflow Execution', () => cancelWorkflow(row), cancelIcon) : null;

  return (
    <Box sx={sx}>
      <Box>
        <Typography variant="h3">{title}</Typography>
      </Box>
      {rows?.length === 0 && !(isLoading || isQueryLoading || isWorkflowLoading) ? (
        noData
      ) : (
        <Box className="data-container">
          <AvTable
            storageKey={title}
            resizable
            rows={rows || []}
            hasHeaderFilters={false}
            activeKey={searchParams.guidId}
            loading={!workflowsError && !queryError && (!rows || isLoading || isQueryLoading || isWorkflowLoading)}
            inlineActions={[...inlineActions, ...(isInternalRole ? [cancelWorkflowInlineAction] : [])]}
            pagination
            paginationProps={{ pageNum: page, onPageChange: setPage }}
            headCells={
              featureFlags[FeatureFlags.RunsScreenErrorInfo]
                ? runsHeadCells(shouldShowCategory, shouldShowSyncMode, isInternalRole)
                : oldRunsHeadCells(shouldShowCategory, shouldShowSyncMode)
            }
            {...(featureFlags[FeatureFlags.RunsScreenErrorInfo]
              ? {
                  expandedRowObj: {
                    component: expandedRow,
                    shouldExpand: status => status.status === workflowStatuses.FAILED || isInternalRole,
                  },
                }
              : (isInternalRole || allowExpandingRows) && { expandedRowObj: { component: expandedRow } })}
            onActive={onExpandedRow}
            enableSelectionColor={false}
            allowEdit={false}
            allowKeyboardNavigation
            rowLimit={20}
          />
        </Box>
      )}
    </Box>
  );
}

interface WorkflowMetadataProps {
  workflowMetadata?: string;
  title: string;
}
export function WorkflowMetadata({ workflowMetadata, title }: WorkflowMetadataProps) {
  return (
    !!workflowMetadata && (
      <Box sx={{ display: 'contents' }}>
        <Typography variant="h7">{title}</Typography>
        <Box>{workflowMetadata}</Box>
      </Box>
    )
  );
}

const hasTransformationErrors = row => row.activityMetadata?.transformationErrors?.length > '[]'.length;
const hasParsingErrors = row => row.activityMetadata?.parsingErrors?.length > '[]'.length;

interface GuidActivitiesInfoProps {
  guid: string;
  customTexts: { filePaths: string; transformedFilePath: string };
  guidRuns: {
    runId?: string;
    workflowId?: string;
  }[];
  presignedUrlType: PresignedUrlType;
  shouldShowProjectionName: boolean;
  isInternalRole: boolean;
}
function GuidActivitiesInfo({
  guid,
  guidRuns,
  customTexts,
  presignedUrlType = PresignedUrlType.REGULAR,
  shouldShowProjectionName,
  isInternalRole,
}: GuidActivitiesInfoProps) {
  const { api, accountId, featureFlags } = useAvContext();
  const { id: dsiId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isExpanded, setIsExpanded] = useState(true);

  const {
    isLoading: isActivityLoading,
    data: dataActivities = {},
    error,
  } = useAvQuery({
    queryKey: ['activities', guid],
    queryFn: () =>
      api(GET_ACTIVITIES, {
        options: { runList: guidRuns },
        onError: (err: any) => enqueueSnackbar(`Failed to fetch activities: ${err.message}`, { variant: 'error' }),
      }).then(({ data }) => data.getListOfActivities),
    //  Keep refetching every 5 seconds
    refetchInterval: 5000,
    gcTime: 0,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });

  const { mutate: getPresignedUrl } = usePresignedUrl(presignedUrlType);

  const rows = useMemo(
    () =>
      dataActivities?.activities
        ? sortByStartTimeAsc(dataActivities.activities).map(row => ({
            ...row,
            id: `${row.runId}${row.name}`,
            avalorExpandedRowData: [
              hasParsingErrors(row)
                ? { fieldName: 'Parsing Errors', message: JSON.parse(row.activityMetadata.parsingErrors).join('\n') }
                : undefined,
              ...(row.avalorFailureInfo ? [row.avalorFailureInfo] : []),
              ...(row.failureInfo?.message
                ? [row.failureInfo]
                : hasTransformationErrors(row)
                  ? JSON.parse(row.activityMetadata.transformationErrors)
                  : []),
            ].filter(Boolean),
          }))
        : [],
    [dataActivities]
  );
  const failedActivity = useMemo(
    () => dataActivities?.activities?.find(activity => activity.status === workflowStatuses.FAILED),
    [dataActivities]
  );

  const temporalLinkAction = innerRow =>
    isActivityLoading ? null : ActionButton('Go to Temporal', null, externalIcon, temporalLink(innerRow.workflowId, innerRow.runId), true);
  const datadogLinkAction = innerRow =>
    isActivityLoading || innerRow.name !== 'Data Retrieval'
      ? null
      : ActionButton(
          'Go to Datadog dashboard',
          null,
          externalIcon,
          datadogDashboardLink(accountId, dsiId, innerRow.startTime, innerRow.endTime),
          true
        );

  const expandedRowActivities = ({ avalorExpandedRowData }, onChange, isItemSelected) => (
    <ExpandedRowActivityErrors errors={avalorExpandedRowData} isItemSelected={isItemSelected} />
  );

  const formatNumberWorkflowMetadata = workflowMetadata =>
    Number.isNaN(parseInt(workflowMetadata, 10)) ? undefined : parseInt(workflowMetadata, 10).toLocaleString();

  return (
    <Box sx={{ mx: 10 }}>
      {!!Object.keys(dataActivities?.workflowMetadata || {}).length && isInternalRole && (
        <Box sx={{ ...flex.col, gap: 1, py: 1 }}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'fit-content(200px) 1fr', columnGap: 5, rowGap: 1 }}>
            <WorkflowMetadata workflowMetadata={dataActivities.workflowMetadata.fileReader} title="File Reader" />
            <WorkflowMetadata workflowMetadata={formatNumberWorkflowMetadata(dataActivities.workflowMetadata.fileSize)} title="File Size" />
            <WorkflowMetadata
              workflowMetadata={formatNumberWorkflowMetadata(dataActivities.workflowMetadata.numberOfFiles)}
              title="Number Of Files"
            />
            <WorkflowMetadata
              workflowMetadata={formatNumberWorkflowMetadata(dataActivities.workflowMetadata.totalNumberOfRows)}
              title="Total Number Of Rows"
            />
            <WorkflowMetadata workflowMetadata={dataActivities.workflowMetadata.filePaths} title="File Paths" />
            <WorkflowMetadata workflowMetadata={dataActivities.workflowMetadata.transformedFilePath} title="Transformed File Paths" />
          </Box>
          <Box sx={{ ...flex.row, gap: 2, mt: 1 }}>
            {dataActivities.workflowMetadata.filePaths && (
              <Button
                variant="outlined"
                size="xSmall"
                onClick={() =>
                  getPresignedUrl(
                    presignedUrlType === PresignedUrlType.QUERY
                      ? { wfId: guidRuns[0].workflowId!, runId: guidRuns[0].runId! }
                      : { objectKeys: dataActivities.workflowMetadata.filePaths }
                  )
                }>
                {exportIcon}
                {customTexts.filePaths || 'Download Original Files'}
              </Button>
            )}
            {dataActivities.workflowMetadata.transformedFilePath && (
              <Button
                variant="outlined"
                size="xSmall"
                onClick={() => getPresignedUrl({ objectKeys: dataActivities.workflowMetadata.transformedFilePath })}>
                {exportIcon}
                {customTexts.transformedFilePath || 'Download Transformed File'}
              </Button>
            )}
          </Box>
        </Box>
      )}
      {(featureFlags[FeatureFlags.RunsScreenErrorInfo] || isInternalRole) && failedActivity && (
        <ErrorBox failedActivity={failedActivity} isInternalRole={isInternalRole} />
      )}
      {isInternalRole &&
        (rows?.length === 0 && !isActivityLoading ? (
          <Box sx={{ py: 5 }}>
            <NoDataFound img={NoDataFoundImg} size="small" headline="No Activities Found" />
          </Box>
        ) : (
          <>
            <Box sx={{ ...flex.itemsCenter, mt: 1, mb: 1, gap: 1, fontWeight: 600, fontSize: 14 }}>
              <ExpandButton label="Activities" value={isExpanded} onChange={() => setIsExpanded(prev => !prev)} style={{ ...flex.row }} />
            </Box>
            <Collapse in={isExpanded} timeout="auto">
              <AvTable
                rows={rows || []}
                loading={!error && (!rows || isActivityLoading)}
                headCells={activitiesHeadCells(shouldShowProjectionName)}
                allowEdit={false}
                enableSelectionColor={false}
                {...(!featureFlags[FeatureFlags.RunsScreenErrorInfo] && {
                  expandedRowObj: { component: expandedRowActivities, shouldExpand: ({ avalorExpandedRowData }) => avalorExpandedRowData },
                })}
                inlineActions={[datadogLinkAction, temporalLinkAction]}
                size="xSmall"
              />
            </Collapse>
          </>
        ))}
    </Box>
  );
}

function ErrorBox({ failedActivity, isInternalRole }) {
  const { enqueueSnackbar } = useSnackbar();
  const status = failedActivity.status || workflowStatuses.UNSPECIFIED;
  const {
    fieldName = failedActivity.failureInfo?.fieldName,
    description = failedActivity.failureInfo?.message,
    stackTrace = failedActivity.failureInfo?.stacktrace,
    resolutionSteps,
    httpStatusCode,
    userMessage,
    userFacing,
    responseBody,
  } = failedActivity.avalorFailureInfo || {};

  const copyToClipboardFunction = (data, title) => {
    navigator.clipboard.writeText(data);
    enqueueSnackbar(`${title} Copied To Clipboard`, { variant: 'success' });
  };

  return (
    <Box
      sx={{
        ...borderStyle,
        borderRadius: 2,
        color: (theme: Theme) => theme.palette.colors.neutrals[850],
        ...flex.row,
        my: 1,
        mr: 40,
        pl: 1,
        py: 1,
        position: 'relative',
      }}>
      <Box sx={{ color: (theme: Theme) => theme.palette.colors.neutrals[850] }}>{statusToDisplay[status]?.icon}</Box>
      <Box sx={{ ml: 1, '& > *': { mt: 0.5 } }}>
        <Box sx={{ fontWeight: 600, fontSize: 14, mt: 0 }}>{userMessage || 'Error Undefined'}</Box>
        {resolutionSteps && <Box sx={{ mb: '10px' }}>{resolutionSteps}</Box>}
        <Box
          sx={{
            color: (theme: Theme) => theme.palette.colors.neutrals[600],
            whiteSpace: 'pre-line',
            maxHeight: '200px',
            overflowY: 'auto',
            pr: 4,
            ...flex.col,
            gap: 0.5,
          }}>
          {(userFacing || isInternalRole) && (
            <div>
              <Typography variant="h7">error message</Typography>
              <AvCollapseContent maxLines={2} shouldBreakWords content={description} />
            </div>
          )}
          {responseBody && (
            <div>
              <Typography variant="h7">original source error</Typography>
              <AvCollapseContent maxLines={2} shouldBreakWords content={responseBody} />
            </div>
          )}
          {isInternalRole && (
            <>
              {fieldName && (
                <div>
                  <Typography variant="h7">field name</Typography>
                  <Box sx={{ fontSize: 12 }}>{fieldName}</Box>
                </div>
              )}
              {httpStatusCode && (
                <div>
                  <Typography variant="h7">status code</Typography>
                  <Box sx={{ fontSize: 12 }}>{httpStatusCode}</Box>
                </div>
              )}
              {stackTrace && (
                <div>
                  <Typography variant="h7">stack trace</Typography>
                  <AvCollapseContent maxLines={2} shouldBreakWords content={stackTrace} />
                </div>
              )}
            </>
          )}
        </Box>
      </Box>
      {userFacing && (
        <Button
          sx={{
            opacity: 0,
            'div:hover > &': { opacity: 1 },
            transition: theme => theme.transitions.create(['opacity', 'color'], { duration: theme.transitions.duration.shorter }),
            mr: 1,
            position: 'absolute',
            right: 0,
          }}
          className="transparent"
          onClick={() => copyToClipboardFunction(description, 'Error Message')}>
          {copyIcon}
        </Button>
      )}
    </Box>
  );
}

interface CopyableBoxProps {
  data?: string;
  title: string;
}
function CopyableBox({ data, title }: CopyableBoxProps) {
  const { enqueueSnackbar } = useSnackbar();
  const copyToClipboardFunction = (data, title) => {
    navigator.clipboard.writeText(data);
    enqueueSnackbar(`${title} Copied To Clipboard`, { variant: 'success' });
  };
  return (
    <Box
      onClick={() => copyToClipboardFunction(data, title)}
      sx={{
        pl: 2,
        py: 3,
        ...flex.col,
        ':hover': { '.hoverCopyIcon': { opacity: 1 }, backgroundColor: theme => theme.palette.colors.neutrals[300] },
        position: 'relative',
      }}>
      <Typography variant="h7">{title}</Typography>
      <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
        <Box sx={{ pt: 1, flexGrow: 1, ...ellipsis, ...cellContentStyle, whiteSpace: 'pre-wrap' }}>{data}</Box>
        <Button variant="filter" sx={{ opacity: 0 }} className="transparent hoverCopyIcon">
          {copyIcon}
        </Button>
      </Box>
    </Box>
  );
}

interface ExpandedRowActivityErrorsProps {
  errors: {
    fieldName?: string;
    message?: string;
    stacktrace?: string;
  }[];
  isItemSelected: boolean;
}
function ExpandedRowActivityErrors({ errors, isItemSelected }: ExpandedRowActivityErrorsProps) {
  return (
    <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
      {errors.map((data, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {i > 0 && <Divider />}
          {!!data.fieldName && <CopyableBox title="Field Name" data={data.fieldName} />}
          <CopyableBox title="Error Message" data={data.message} />
          {!!data.stacktrace && <CopyableBox title="Error Stacktrace" data={data.stacktrace} />}
        </React.Fragment>
      ))}
    </Collapse>
  );
}

const statusToDisplay = {
  COMPLETED: { statusText: 'Completed', icon: successFullIcon },
  PARTIAL: { statusText: 'Completed', icon: partialFullIcon },
  FAILED: { statusText: 'Failed', icon: errorFullIcon },
  CANCELED: { statusText: 'Canceled', icon: canceledFullIcon },
  TIMED_OUT: { statusText: 'Timeout', icon: errorFullIcon },
  RUNNING: { statusText: 'In Progress', icon: syncIcon },
};

const categoryNames = {
  NA: 'N/A',
  ETL_MAP: 'Map',
  ETL_AGGREGATE: 'Aggregate',
  ETL_MAP_AND_AGGREGATE: 'Map and Aggregate',
};

const isRowExpandable = (row, isInternalRole) =>
  ![workflowStatuses.COMPLETED, workflowStatuses.CANCELED].includes(row.status) || isInternalRole;
const statusFormatter = status => <StatusWithLogo statusText={statusToDisplay[status].statusText} icon={statusToDisplay[status].icon} />;
const runsHeadCells = (shouldShowCategory, shouldShowSyncMode, isInternalRole) => [
  expandArrowColumn(row => isRowExpandable(row, isInternalRole)),
  {
    id: 'status',
    label: 'Status',
    style: { width: '250px' },
    formatter: statusFormatter,
  },
  {
    id: 'id',
    label: 'ID',
    isKey: true,
    style: { width: '600px' },
  },
  ...(shouldShowCategory
    ? [
        {
          id: 'category',
          label: 'Category',
          formatter: category => categoryNames[category],
        },
      ]
    : []),
  ...(shouldShowSyncMode
    ? [
        {
          id: 'syncMode',
          label: 'Data Retrieval Type',
        },
      ]
    : []),
  {
    id: 'startTime',
    type: FormatterType.date,
    label: 'Start Time',
    style: { width: '300px' },
  },
  {
    id: 'endTime',
    type: FormatterType.date,
    label: 'End Time',
  },
];

const oldRunsHeadCells = (shouldShowCategory, shouldShowSyncMode) => [
  {
    id: 'id',
    label: 'ID',
    isKey: true,
    style: { width: '600px' },
  },
  {
    id: 'status',
    label: 'Status',
    style: { width: '250px' },
    formatter: statusFormatter,
  },
  {
    id: 'startTime',
    type: FormatterType.date,
    label: 'Start Time',
    style: { width: '300px' },
  },
  {
    id: 'endTime',
    type: FormatterType.date,
    label: 'End Time',
  },
  ...(shouldShowCategory
    ? [
        {
          id: 'category',
          label: 'Category',
          formatter: category => categoryNames[category],
        },
      ]
    : []),
  ...(shouldShowSyncMode
    ? [
        {
          id: 'syncMode',
          label: 'Data Retrieval Type',
        },
      ]
    : []),
];

const activitiesHeadCells = shouldShowProjectionName => [
  {
    isKey: true,
    id: 'id',
    hidden: true,
  },
  {
    id: 'runId',
    hidden: true,
  },
  {
    id: 'name',
    label: 'Name',
    style: { width: '300px' },
  },
  {
    id: 'status',
    label: 'Status',
    style: { width: '150px' },
    formatter: (status, row) => {
      const { statusText, icon } = statusToDisplay[hasTransformationErrors(row) && status === 'COMPLETED' ? 'PARTIAL' : status];
      return <StatusWithLogo width={20} statusText={statusText} icon={icon} />;
    },
  },
  ...(shouldShowProjectionName
    ? [
        {
          id: 'activityMetadata',
          label: 'Projection Name',
          getValue: ({ activityMetadata }) => activityMetadata?.projectionName || '',
        },
      ]
    : []),
  {
    id: 'startTime',
    type: FormatterType.date,
    label: 'Start Time',
    style: { width: '200px' },
    initSort: SortType.ASC,
  },
  {
    id: 'endTime',
    type: FormatterType.date,
    label: 'End Time',
  },
];

const sx = theme => ({
  backgroundColor: theme => theme.palette.colors.neutrals[100],
  maxWidth: '1800px',
  ...flex.col,
  gap: 3,
  overflow: 'auto',
  '> div:first-of-type': {
    ...flex.justifyBetweenCenter,
    h3: { margin: 0 },
  },
  '.data-container': {
    ...flex.row,
    overflow: 'auto',
    '.expanded-row, .expanded-row-content, .expanded-row-content .MuiTableCell-head': {
      backgroundColor: theme.palette.colors.neutrals[150],
    },
    '.MuiTableRow-root.expanded-row-content > .MuiTableCell-root': {
      borderBottomColor: theme.palette.colors.neutrals[300],
      paddingX: 1,
      '.MuiTableCell-root': {
        height: 32,
        fontSize: 14,
        '&.MuiTableCell-head': {
          ...theme.typography.h7,
        },
      },
    },
  },
});

const sortByStartTimeDesc = rows => rows.sort((a, b) => b.startTime - a.startTime);
const sortByStartTimeAsc = rows => rows.sort((a, b) => a.startTime - b.startTime);
