import React, { useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { ActionButton } from '../../../components/Table/Utils';
import { dotStyle, ellipsis, iconSize, noop } from '../../../utils/Utils';
import { useValidateMapping } from '../hooks';
import EditorPopper, { dropStyle } from './EditorPopper';
import { itemStyle, MappingType } from './mapping.types';
import { ReactComponent as Function } from '../../../assets/Function.svg';
import { ReactComponent as Key } from '../../../assets/Key.svg';
import { ReactComponent as Minus } from '../../../assets/Minus.svg';
import { ReactComponent as Add } from '../../../assets/Plus.svg';

const key = <Key />;

function hoverIconStyle(theme) {
  return {
    color: theme.palette.colors.neutrals[600],
    ...dotStyle(undefined, 16),
    border: `1.5px solid ${theme.palette.colors.neutrals[600]}`,
  };
}

type MappingPropsType = {
  previewId: string | undefined;
  type: string | undefined;
  instanceId: string | undefined;
  mapping: any;
  reader: any;
  fileReaderConfig: any;
};

interface MappingItemProps {
  label: React.ReactNode;
  isEditable?: boolean;
  popperProps?: {
    type: string;
    modelFieldName: string;
    fieldType: string;
    entityName: string;
    mappingProps: MappingPropsType;
  };
  onMap?(...args: unknown[]): unknown;
  isMissing?: boolean;
  onClick?(...args: unknown[]): unknown;
  onDoubleClick?(...args: unknown[]): unknown;
  onDragStart?(...args: unknown[]): unknown;
  onDragEnd?(...args: unknown[]): unknown;
  draggable?: boolean;
  isUsed?: boolean;
  isSelected?: boolean;
  endAdornment?: React.ReactNode;
  size?: 'medium' | 'small';
}

export const MappingItem = React.forwardRef<HTMLElement, MappingItemProps>(
  (
    {
      label,
      isEditable = false,
      popperProps,
      isMissing = false,
      onMap = noop,
      onClick = noop,
      onDoubleClick = noop,
      onDragStart = noop,
      onDragEnd = noop,
      draggable = true,
      isUsed,
      isSelected,
      endAdornment = isUsed === undefined ? null : isUsed ? <Minus className="hover-item" /> : <Add className="hover-item" />,
      size = 'medium',
    },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Box
        ref={ref}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragCapture={onDragEnd}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        sx={{
          ...itemStyle(size === 'small'),
          ...(draggable && { cursor: 'move', ':active': { cursor: 'grabbing' } }),
          ...(isMissing && { background: theme => theme.palette.colors.negative[100] }),
          ...(popperProps?.type && { padding: '8px 0' }),
          ...(isSelected ? theme.components!.MuiTableRow!.styleOverrides!.root!['&.active-row'] : {}),
          position: 'relative',
          '.hover-item': {
            opacity: 0,
            ...hoverIconStyle(theme),
            transition: theme.transitions.create(['opacity']),
          },
          ':hover .hover-item': { opacity: 1 },
          ...(isUsed ? { background: theme.palette.colors.primary[150] } : {}),
        }}>
        {popperProps ? (
          <ScriptOrLiteralMappingItem label={label} isEditable={isEditable} {...popperProps} onMap={onMap} size={size} />
        ) : (
          <Box sx={{ ...flex.itemsCenter, gap: '12px', width: '100%' }}>
            {label}
            {endAdornment}
          </Box>
        )}
      </Box>
    );
  }
);

interface EntityFieldMappingItemProps {
  label: React.ReactNode;
  isKey?: boolean;
  isEditable?: boolean;
  onKeyChange?(...args: unknown[]): unknown;
  isMissing?: boolean;
  onDragStart?(...args: unknown[]): unknown;
  onDragEnd?(...args: unknown[]): unknown;
  draggable?: boolean;
  size?: 'medium' | 'small';
  hideKeyBtn: boolean;
}

export const EntityFieldMappingItem = React.forwardRef<HTMLElement, EntityFieldMappingItemProps>(
  (
    {
      label,
      isKey = false,
      isMissing = false,
      isEditable = true,
      onKeyChange = noop,
      onDragStart = noop,
      onDragEnd = noop,
      hideKeyBtn = false,
      draggable = true,
      size = 'medium',
    },
    ref
  ) => {
    const [keyStatus, setKeyStatus] = useState(isKey);
    const toggleKey = () => {
      const newStatus = !keyStatus;
      setKeyStatus(newStatus);
      onKeyChange(newStatus);
    };

    const keyButton = ActionButton(keyStatus ? 'Remove Key' : 'Set as Key', toggleKey, key);

    return (
      <Box
        ref={ref}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragCapture={onDragEnd}
        sx={entityFieldStyle(draggable, isMissing, keyStatus, isEditable, size === 'small')}>
        <header>
          {label}
          {!hideKeyBtn && keyButton}
        </header>
      </Box>
    );
  }
);

interface ScriptOrLiteralMappingItemProps {
  label: React.ReactNode;
  isEditable: boolean;
  type: string;
  modelFieldName: string;
  entityName: string;
  fieldType: string;
  onMap(...args: unknown[]): unknown;
  size?: 'medium' | 'small';
  mappingProps: MappingPropsType;
}

const ScriptOrLiteralMappingItem: React.FC<ScriptOrLiteralMappingItemProps> = ({
  label,
  isEditable,
  type,
  modelFieldName,
  entityName,
  fieldType,
  onMap,
  size = 'medium',
  mappingProps,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [editorType, setEditorType] = useState(type);
  const [editorValue, setEditorValue] = useState(label);
  const dropRef = useRef();

  const reset = () => {
    setShowEditor(false);
    setEditorType(type);
    setEditorValue(label);
  };

  const toggleEditor = () => setShowEditor(!showEditor);

  const { mutateAsync: validate, isPending: loadingValidate } = useValidateMapping(mappingProps);

  const buttons = [
    { onActionClick: reset, children: 'Cancel' },
    {
      onActionClick: (mappingValue, mappingType) => validate({ entityName, modelFieldName, mappingValue, mappingType }),
      children: 'Validate',
      variant: 'outlined' as any,
      isDisabled: () => loadingValidate,
      loading: loadingValidate,
      sx: { mr: -1 },
    },
    {
      onActionClick: (value, type) => {
        setEditorValue(value);
        setEditorType(type);
        onMap(value, type, toggleEditor);
      },
      variant: 'contained' as any,
      children: 'Apply',
      isDisabled: value => !value,
    },
  ];

  const functionButton = ActionButton(`${isEditable ? 'Edit' : 'Show'} Function`, toggleEditor, <Function style={iconSize(24)} />);

  return (
    <>
      <Box
        ref={dropRef}
        sx={theme => mappingItemDropStyle(theme, showEditor, 'right', size === 'small')}
        tabIndex={0}
        onClick={toggleEditor}>
        <AvTooltip title={label}>
          <header>
            {type === MappingType.Script && functionButton}
            {type === MappingType.Literal ? `"${label}"` : label}
          </header>
        </AvTooltip>
      </Box>
      {showEditor && (
        <EditorPopper
          handleClose={toggleEditor}
          type={editorType}
          setType={v => setEditorType(v)}
          anchorEl={dropRef.current}
          open={showEditor}
          buttons={buttons}
          modelFieldName={modelFieldName}
          value={editorValue as string}
          onChange={v => setEditorValue(v)}
          editable={isEditable}
          fieldType={fieldType}
        />
      )}
    </>
  );
};

const mappingItemDropStyle = ({ palette }, selected, borderSide, isSmall) => ({
  ...itemStyle(isSmall),
  header: {
    ...ellipsis,
    '.MuiButton-root': {
      minWidth: 'unset',
      mr: 1,
      svg: {
        height: 17,
        width: 17,
        color: selected ? palette.colors.primary[400] : palette.colors.neutrals[400],
      },
    },
  },
  pr: 1,
  pl: '14px',
  cursor: 'pointer',
  border: '2px solid',
  borderColor: 'transparent',
  ...(selected && {
    borderColor: palette.colors.primary[400],
    color: palette.colors.neutrals[800],
    background: palette.colors.primary[100],
  }),
  ...dropStyle(selected, borderSide),
});

const entityFieldStyle = (draggable, isMissing, isKey, isEditable, isSmall) => ({
  ...itemStyle(isSmall),
  header: {
    ...flex.justifyBetweenCenter,
    width: '100%',
    '.MuiButton-root': {
      opacity: !isEditable && !isKey ? 0 : undefined,
      pointerEvents: isEditable ? undefined : 'none',
      svg: {
        width: 18,
        height: 18,
        ...(!isKey && { color: theme => theme.palette.colors.neutrals[600] }),
      },
    },
  },
  ...(!isKey && isEditable && { '&:not(:hover) header .MuiButton-root': { opacity: 0 } }),
  ...(draggable && { cursor: 'move', ':active': { cursor: 'grabbing' } }),
  ...(isMissing && { background: theme => theme.palette.colors.negative[100] }),
});
