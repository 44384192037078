import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { Box, Button, Divider, IconButton, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { addDays, format, isEqual, subDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../context/AvContextProvider';
import useClickOutside from '../hooks/useClickOutside';
import { useDebounceV1 } from '../hooks/useDebounce';
import useQuerySql from '../hooks/useQuerySql';
import { StringConditionType } from '../types/filter.types';
import { sortBySeverity } from '../utils/filterUtils';
import {
  generateOption,
  getEscapedValue,
  getIfValidStringifiedObject,
  getNestedValue,
  isJSON,
  noop,
  orderByKey,
  renameProp,
  setNestedValue,
  uniqBy,
} from '../utils/Utils';
import { SchemaTypes, typeLabelMap } from '../views/Sources/Mapping/mapping.types';
import { useBuildWhereClause } from '../views/Tickets/hooks';
import AvAddOperandButton, { getOperatorColor } from './AvAddOperandButton';
import { flex } from './AvThemeProvider';
import AvDateRangePicker from './DatePicker/AvDateRangePicker';
import { epochDateString, PeriodBehavior } from './DatePicker/AvDateRangePicker.constants';
import {
  booleanOptions,
  ConditionType,
  defaultOperator,
  filterOperatorOptions,
  FilterOption,
  getFilterOptionsWithLegacy,
  getOperator,
  isNullOperator,
  operatorLabels,
} from './filters/Utils';
import { IconVariant, ItemWithLogo, SeverityItem } from './ItemWithLogo';
import OpenSelect from './OpenSelect';
import RadioButton from './RadioButton';
import Select, { FilterStyledPopper, SelectTrigger } from './Select';
import TextInput from './TextInput';
import { ReactComponent as Delete } from '../assets/Delete.svg';

interface FieldFilterProps {
  activeProjName?: string;
  field: string;
  label?: string | Element;
  filters?: Record<string, any>;
  isEnabled: boolean;
  onChange: (field, value) => void;
  onOpen?: () => void;
  onClose?: () => void;
  setRef?: (ref: any) => void;
  getOptions?: (data: any, label: string | Element, v) => any[];
  overrideExtra?: string[];
  size?: string;
  disabled?: boolean;
  trigger?: any;
  fieldType?: keyof typeof typeLabelMap;
  options?: any[];
  isMultipleCondition?: boolean;
  fieldMap?: Record<string, any>;
  queryKey?: string;
  getValue?: (v) => string | ReactNode;
  isFilterExpression?: boolean;
  usage?: 'SPF' | undefined;
  isGlobalFilter?: boolean;
  selectIcon?: ReactNode;
}

const getDisplayValue = value => (isJSON(value) ? (value.from || value.to ? `${value.from || ''}-${value.to || ''}` : '') : value);

export const FieldFilter: React.FC<FieldFilterProps> = ({
  onChange,
  field,
  filters = {},
  isEnabled,
  setRef = noop,
  getOptions = (data, label) =>
    data.map(({ value }) => (value === null || value?.length === 0 ? { title: `No ${label}`, value: null } : generateOption(value))),
  label = '',
  overrideExtra,
  size = 'small',
  disabled,
  trigger,
  fieldType,
  options,
  isMultipleCondition = true,
  onOpen = noop,
  onClose = noop,
  activeProjName,
  queryKey,
  getValue,
  isFilterExpression = false,
  usage,
  isGlobalFilter = false,
  selectIcon,
}) => {
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { aggProjs, fieldMap } = useAvContext().accountEntities;
  const typeBase = fieldType || typeLabelMap[fieldMap[field]?.type] || (field.toLowerCase().endsWith('count') ? 'number' : 'string');
  const type = `${typeBase}${fieldMap[field]?.repeated ? '[]' : ''}`;
  const isSchemaField = !options;
  const isBooleanField = type === 'bool';
  const isDateField = ['date', SchemaTypes.DATETIME].includes(type);
  const supportedType = !['["MESSAGE"]', 'MESSAGE'].includes(fieldMap?.[field]?.type?.toString());
  const isSeverity = field.split('.')[1] === 'severity';
  const isNumberField = type === 'number';
  const filterObject = getIfValidStringifiedObject(filters[field]);

  // TODO: Remove when AVA-12635 is implemented.
  // When null isn't first in the array it is parsed incorrectly
  const value = filterObject || filters[field]?.map(val => (val === 'null' ? null : val)) || [];

  const [mode, setMode] = useState(filterObject ? 'condition' : 'list');
  const initialFilter = emptyOperand[type];
  const [conditionValue, setConditionValue] = useState(filterObject ? value : { [isNumberField ? 'or' : 'and']: [initialFilter] });
  const operator = Object.keys(conditionValue)[0];
  const valueOperator = Object.keys(filterObject)[0];
  const buttonRef = useRef(null);
  const popperInnerRef = useRef(null);
  const [errors, setErrors] = useState<typeof conditionValue>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounceV1(search, 500);
  const searchCondition = debouncedValue ? `value LIKE '%${getEscapedValue(debouncedValue)}%'` : undefined;

  const handleSelectConditionChange = (value: unknown, index: number) => {
    setErrors([]);
    setConditionValue(prev => {
      const nestedPrev = getNestedValue(`${operator}.${index}`, prev);
      const newValue = isNullOperator(value) ? '' : nestedPrev.value;
      return setNestedValue(`${operator}.${index}`, prev, { operator: value, value: newValue });
    });
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useClickOutside(popperInnerRef, handleClose);

  const whereClause =
    isSchemaField && activeProjName
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useBuildWhereClause({
          filters: isGlobalFilter ? { [field]: [] } : { ...filters, [field]: [] },
          // @ts-ignore
          extra: overrideExtra ? [...overrideExtra, searchCondition] : searchCondition,
          entityTypeId: (isGlobalFilter ? fieldMap[field] : aggProjs[activeProjName]).entityTypeId.name,
          fieldMap,
        })
      : '';

  const getFieldQuery = field => {
    const fieldQuery = isGlobalFilter ? `${activeProjName}.${field.split('.').slice(1).join('.')}` : field;
    return fieldMap?.[field]?.repeated ? `EXPLODE(${fieldQuery})` : fieldQuery;
  };

  const fieldQuery = getFieldQuery(field);
  const sql = `SELECT distinct ${fieldQuery} as value
    FROM ${activeProjName}
    ${whereClause} ORDER BY value ASC limit 800`;

  const {
    data = [],
    totals,
    isLoading,
  } = useQuerySql({
    key: queryKey ?? sql,
    sql,
    debounceQueries: false,
    options: {
      enabled: isEnabled && mode === 'list' && open && !isDateField && !isBooleanField && supportedType && !isNumberField && isSchemaField,
      placeholderData: keepPreviousData,
    },
    onSuccess: data => sortBySeverity({ data, severityFieldName: fieldQuery, key: 'value' }),
    usage,
    showTotals: true,
  });

  const memoOptions = useMemo(
    () =>
      options
        ? orderByKey(
            options.filter(o => (isBooleanField ? true : o.value?.toLowerCase?.().includes(search.toLowerCase()))),
            'title'
          )
        : uniqBy(
            getOptions([...(value.map?.(generateOption) || []), ...data], label, getValue).map(({ title, value }) => ({
              value,
              title: value === null ? 'Empty' : title,
            })),
            f => f.value
          ),
    [data, open, search, options]
  );

  const tooltipValue = useMemo(
    () =>
      filterObject ? (
        isDateField ? (
          <Box>{formatDateValue({ ...filterObject })}</Box>
        ) : (
          <Box>{filterObject[valueOperator].map((operand, index, arr) => conditionTooltip(operand, index, arr, operator))}</Box>
        )
      ) : (
        value?.join(', ')
      ),
    [value, conditionValue, filterObject, operator, getValue]
  );
  const calcOptions = field === 'finding.state' ? findingStateOptions : isBooleanField && isSchemaField ? booleanOptions : memoOptions;

  const getErrors = (val, operator) =>
    !operator ? ['Select Operator'] : filterOperatorOptions[type].find(({ value }) => value === operator)?.getErrors?.(val);

  const isSelectTrigger = !trigger || trigger.type.render.name === 'Select';
  const displayValue = useMemo(
    () =>
      filterObject
        ? isDateField
          ? formatDateValue({ ...filterObject })
          : `${operatorLabels[filterObject[valueOperator][0].operator]}  ${getDisplayValue(filterObject[valueOperator][0].value)}`
        : getValue && value[0] !== getValue(value[0])
          ? getValue(value[0])
          : calcOptions.find(o => o.value === value[0])?.title || `${value}`,
    [value, conditionValue, filterObject, operator, getValue]
  );
  const triggerProps = {
    ref: buttonRef,
    ...(isSelectTrigger
      ? {
          hasValidValue: Boolean(value?.length || filterObject[valueOperator]?.length > 0 || filterObject?.value !== undefined),
          processedValue: value,
          displayValue,
          tooltipValue,
          chipCount: isDateField
            ? undefined
            : filterObject
              ? conditionValue[operator].length - 1
              : value.length
                ? value.length - 1
                : undefined,
        }
      : {}),
    open,
    label,
    size,
    disabled,
    selectIcon,
    onClick: () => {
      setOpen(true);
      onOpen();
      if (!supportedType) {
        enqueueSnackbar('Unsupported Filter Type', { variant: 'error' });
      }
    },
  };
  const isTextField = !isNumberField && !isDateField;
  const innerContent = isDateField ? (
    <AvDateRangePicker
      value={filterObject.value}
      activePresetValue={filterObject.preset}
      onChange={value => onChange(field, value.value === undefined ? [] : [encodeURIComponent(JSON.stringify(value))])}
      onClose={handleClose}
      showAsCalendar
      isRange
      showRelativeOptions
      isFilterExpression={isFilterExpression}
    />
  ) : (
    <>
      {initialFilter && !isNumberField && (
        <Box sx={{ py: 1, px: 2 }}>
          <RadioButton options={viewOptions} value={mode} onChange={setMode} />
        </Box>
      )}
      <Divider />
      {(isTextField || isBooleanField) && (
        <Box sx={{ px: 1, mt: 1, pb: 1, display: mode === 'list' ? 'block' : 'none' }}>
          <Select
            showOnlyAutoComplete
            size="small"
            acceptNullValue
            label={label}
            variant="filter"
            loading={isLoading}
            {...(isSeverity && { renderOption: ({ value }) => <SeverityItem value={value} /> })}
            isMultiple
            showInput={isTextField}
            options={calcOptions}
            value={filterObject ? [] : isBooleanField ? value?.map(JSON.parse) : value}
            onChange={val => onChange([field], val)}
            totalOptionsCount={totals}
            renderOption={
              field.endsWith('.source_names')
                ? ({ value }) => <ItemWithLogo variant={IconVariant.sourcesMapByName} type={value} />
                : undefined
            }
            filterFunc={
              isSchemaField
                ? (options, { inputValue }) => {
                    setSearch(inputValue);
                    return options;
                  }
                : undefined
            }
          />
        </Box>
      )}
      <Box
        sx={{
          gap: 1,
          p: 3,
          display: mode === 'condition' || !isTextField ? 'block' : 'none',
          maxHeight: 380,
          overflowY: 'auto',
        }}>
        {initialFilter &&
          conditionValue[operator].map((operand, index, operands) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <Box key={index}>
              <Box
                sx={{
                  ...flex.itemsCenter,
                  p: '12px',
                  border: `1px solid ${palette.colors.neutrals[300]}`,
                  gap: 1,
                  borderRadius: '6px',
                  // @ts-ignore
                  '.MuiPaper-root': theme => ({ ...theme.components.MuiAutocomplete.styleOverrides.paper }),
                }}>
                <Select
                  size="small"
                  style={{ width: 170, input: { fontWeight: 600 } }}
                  isRequired
                  muiProps={{ disablePortal: true }}
                  placeholder="Select Operator"
                  value={getOperator(operand.operator)}
                  onChange={value => handleSelectConditionChange(value, index)}
                  options={getFilterOptionsWithLegacy(filterOperatorOptions[type], operand.operator)}
                />
                <FilterInput
                  value={operand.value}
                  operator={operand.operator}
                  numberField={isNumberField}
                  inputWidth={210}
                  onChange={value => {
                    setErrors([]);
                    setConditionValue(prev => setNestedValue(`${operator}.${index}.value`, prev, value));
                  }}
                  options={filterOperatorOptions[type]}
                  error={errors[index]?.value}
                />
                <IconButton
                  disabled={operands.length === 1 && conditionValue[operator][index].value === ''}
                  onClick={() => {
                    if (operands.length === 1) {
                      setConditionValue(prev => setNestedValue(`${operator}.${index}.value`, prev, ''));
                      onChange(field, []);
                    } else {
                      setConditionValue(prev => ({ [operator]: prev[operator].filter((o, i) => i !== index) }));
                    }
                    setErrors([]);
                  }}>
                  <Delete />
                </IconButton>
              </Box>
              <Box sx={{ ...flex.center, py: 1 }}>
                {operands.length > 1 && index !== operands.length - 1 && (
                  <ToggleButtonGroup
                    color="white"
                    value={operator}
                    onChange={() => setConditionValue(prev => renameProp(operator, operator === 'and' ? 'or' : 'and', prev))}
                    size="xSmall"
                    exclusive>
                    <ToggleButton value="and">
                      <Box
                        sx={theme => ({
                          fontWeight: 600,
                          fontSize: 13,
                          color: operator === 'and' ? getOperatorColor(theme, 'and') : 'inherit',
                        })}>
                        AND
                      </Box>
                    </ToggleButton>
                    <ToggleButton value="or">
                      <Box
                        sx={theme => ({
                          fontWeight: 600,
                          fontSize: 13,
                          color: operator === 'or' ? getOperatorColor(theme, 'or') : 'inherit',
                        })}>
                        OR
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Box>
            </Box>
          ))}
        {isMultipleCondition && (
          <Box sx={flex.center}>
            <AvAddOperandButton
              operator={operator}
              isAdd
              onClick={() => setConditionValue(prev => ({ [operator]: [...prev[operator], initialFilter] }))}
            />
          </Box>
        )}
      </Box>
      <Divider orientation="horizontal" />
      {(mode === 'condition' || isNumberField) && (
        <Box sx={{ p: 2, ...flex.justifyBetweenCenter }}>
          <Button
            onClick={() => {
              setErrors([]);
              setConditionValue({ [operator]: [initialFilter] });
            }}
            size="small">
            Reset
          </Button>
          <Box>
            <Button onClick={() => setOpen(false)} size="small">
              Close
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                const validOperands = conditionValue[operator].filter(
                  ({ value, operator }) => typeof value === 'number' || isNullOperator(operator) || !!value
                );
                if (!validOperands.length) {
                  setConditionValue({ [operator]: [initialFilter] });
                  onChange(field, []);
                  setOpen(false);
                } else {
                  const errorsExp = validOperands.map(({ value, operator }) => ({ operator, value: getErrors(value, operator) }));
                  if (errorsExp.some(({ value }) => value)) {
                    setErrors(errorsExp);
                  } else {
                    const nextConditionValue = { [operator]: validOperands };
                    setConditionValue(nextConditionValue);
                    onChange(field, [encodeURIComponent(JSON.stringify(nextConditionValue))]);
                    setOpen(false);
                  }
                }
              }}>
              Apply
            </Button>
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <span key={field} ref={setRef}>
      {trigger ? React.cloneElement(trigger, triggerProps) : <SelectTrigger {...triggerProps} />}
      <FilterStyledPopper
        open={open}
        anchorEl={buttonRef.current}
        placement="bottom-start"
        sx={{
          minWidth: '400px',
          width: 'fit-content',
          padding: 0,
        }}>
        <Box style={{ display: open ? 'block' : 'none' }} ref={popperInnerRef}>
          {innerContent}
        </Box>
      </FilterStyledPopper>
    </span>
  );
};

export const conditionTooltip = (operand, index, arr, operator) => (
  <span key={index}>
    <span style={{ fontWeight: 600 }}>{operatorLabels[operand.operator]} </span>
    <span>{getDisplayValue(operand.value)}</span>{' '}
    {index === arr.length - 1 ? (
      ''
    ) : (
      <Box
        component="span"
        sx={{
          fontWeight: 600,
          color: ({ palette }) => (operator === 'and' ? palette.colors.primary[400] : palette.colors.complementary[600]),
        }}>
        {operator.toUpperCase()}{' '}
      </Box>
    )}
  </span>
);

const defaultDateFormat = 'MMM dd, yyyy';
export const formatDateValue = ({ value, dateFormat = defaultDateFormat }) => {
  if (value === undefined) {
    return '';
  }
  if (value === null) {
    return 'Not Set';
  }
  if (value.relative) {
    const isRange = [PeriodBehavior.last, PeriodBehavior.previous].includes(value.relative.periodBehaviour);
    const start = isRange ? subDays(new Date(), value.relative.value) : new Date();
    const end = isRange ? new Date() : addDays(new Date(), value.relative.value);
    return `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
  }
  return isEqual(new Date(value.from), new Date(epochDateString))
    ? `Before ${format(new Date(value.to), dateFormat)}`
    : `${format(new Date(value.from), dateFormat)} - ${format(new Date(value.to), dateFormat)}`;
};

const defaultToEmpty = getValue => {
  try {
    return getValue() || '';
  } catch {
    return '';
  }
};
export const FilterInput = ({
  numberField,
  onChange,
  operator,
  value,
  options,
  size = 'small',
  placeholder = 'Type Value',
  width,
  inputWidth,
  disabled = isNullOperator(operator),
  error,
  optionsUseQueryProps,
  skeletonLoading = false,
  endAdornment,
}: {
  numberField: boolean;
  operator: string;
  value: any;
  onChange: (value) => void;
  size?: 'small' | 'medium' | 'xSmall';
  placeholder?: string;
  width?: number;
  inputWidth?: number;
  options: FilterOption[];
  disabled?: boolean;
  error?: any;
  optionsUseQueryProps?: { options: any[]; isLoading: boolean };
  skeletonLoading?: boolean;
  endAdornment?: React.ReactNode;
}) => {
  const inputOptions = operator && options.find(({ value }) => value === getOperator(operator))?.inputOptions;
  return inputOptions ? (
    <Box sx={{ ...flex.itemsCenter, gap: 1, width }}>
      <TextInput
        style={{ width: inputWidth }}
        size={size}
        placeholder={inputOptions.placeholder1 || placeholder}
        value={defaultToEmpty(() => inputOptions.getValue1(value))}
        disableClearButton
        type={numberField ? 'number' : 'text'}
        onChange={newVal => onChange(inputOptions.onChange1(newVal, value))}
        error={error && inputOptions.getValue1(error)}
        inputProps={{ endAdornment }}
      />
      {inputOptions.separator}
      <TextInput
        style={{ width: inputWidth }}
        size={size}
        placeholder={inputOptions.placeholder2 || placeholder}
        value={defaultToEmpty(() => inputOptions.getValue2(value))}
        disableClearButton
        onChange={newVal => onChange(inputOptions.onChange2(newVal, value))}
        error={error && inputOptions.getValue2(error)}
        inputProps={{ endAdornment }}
        type={numberField ? 'number' : 'text'}
      />
    </Box>
  ) : optionsUseQueryProps && [StringConditionType.EQUALS, StringConditionType.NOT_EQUAL].includes(operator as StringConditionType) ? (
    <OpenSelect
      style={{ width: inputWidth }}
      size={size}
      options={optionsUseQueryProps.options}
      loading={optionsUseQueryProps.isLoading}
      skeletonLoading={skeletonLoading}
      value={value || null}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      disableClearButton
      isRequired
      optionsUseQueryProps={optionsUseQueryProps}
    />
  ) : (
    <TextInput
      style={{ width: inputWidth }}
      size={size}
      placeholder={placeholder}
      value={isJSON(value) ? '' : value}
      type={numberField ? 'number' : 'text'}
      disableClearButton={numberField}
      disabled={disabled}
      onChange={onChange}
      error={error}
      inputProps={{ endAdornment }}
    />
  );
};

const emptyOperand = ['string', 'number', 'IP', 'FIX'].reduce(
  (obj, key) => ({
    ...obj,
    [key]: { operator: defaultOperator[ConditionType[key]], value: '' },
    [`${key}[]`]: { operator: `${defaultOperator[ConditionType[key]]}|ANY`, value: '' },
  }),
  {}
);

const findingStateOptions = [
  { title: 'Undetected', value: 'INACTIVE' },
  { title: 'Active', value: 'ACTIVE' },
];
const viewOptions = [
  { title: 'List', value: 'list' },
  { title: 'Condition', value: 'condition' },
];
