import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Skeleton, useTheme } from '@mui/material';
import { cellContentStyle } from '../utils/Utils';

interface AvCollapseContentProps {
  maxLines?: number;
  shouldBreakWords?: boolean;
  content?: string | React.ReactNode;
  loading?: boolean;
}

const AvCollapseContent: React.FC<AvCollapseContentProps> = ({ maxLines = 1, shouldBreakWords = false, content = '', loading = false }) => {
  const { palette } = useTheme();
  const [showMore, setShowMore] = useState(false);
  const buttonText = showMore ? 'Show Less' : 'Show More';
  const textElementRef = useRef<Element>(null);
  const [isOverflowed, setIsOverflow] = useState(false);

  const onPressShowMore = () => setShowMore(prev => !prev);
  const compareSize = () =>
    textElementRef.current && setIsOverflow(textElementRef.current.scrollHeight > textElementRef.current.clientHeight);
  useEffect(() => {
    if (textElementRef.current && content) {
      compareSize();
      window.addEventListener('resize', compareSize);
      return () => window.removeEventListener('resize', compareSize);
    }
    return undefined;
  }, [loading, content]);

  useEffect(() => {
    const onSidePanelResize = () => setShowMore(false);
    window.addEventListener('onSidePanelResize', onSidePanelResize);
    return () => window.removeEventListener('onSidePanelResize', onSidePanelResize);
  }, []);

  return (
    <Box>
      <Box
        ref={textElementRef}
        sx={[
          cellContentStyle,
          shouldBreakWords ? { wordBreak: 'break-word' } : {},
          showMore ? { WebkitLineClamp: 'initial' } : { WebkitLineClamp: maxLines },
        ]}>
        {loading ? <Skeleton variant="text" height={21} width="80%" /> : content}
      </Box>
      {!loading && isOverflowed && (
        <Button sx={{ pl: 0, color: palette.primary.main }} onClick={onPressShowMore} size="xSmall">
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default AvCollapseContent;
