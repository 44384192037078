import React from 'react';
import { Box, Divider, SxProps, Typography, useTheme } from '@mui/material';
import { abbreviateNumber, emptyArray, getPercent } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { ExploreLink } from './layout.components';
import { ReactComponent as DownArrow } from '../../assets/Down.svg';
import { ReactComponent as QuestionCircle } from '../../assets/question_circle.svg';
import { ReactComponent as UpArrow } from '../../assets/Up.svg';

interface StatusDescriptionProps {
  isUpPositive?: boolean;
  isComingSoon?: boolean;
  value?: number;
  description?: string | React.JSX.Element;
  type?: string;
  days: number;
}

const StatusDescription: React.FC<StatusDescriptionProps> = ({
  isUpPositive = false,
  value = 0,
  description = '',
  type = '',
  isComingSoon = false,
  days,
}) => {
  const theme = useTheme();
  const colorsList = [theme.palette.colors.positive[500], theme.palette.colors.negative[500]];
  const colors = isUpPositive ? colorsList : colorsList.toReversed();
  const iconStyle = { width: '100%', height: '100%' };
  const icon =
    type === 'timeComparison' ? (
      value > 0 ? (
        <UpArrow style={{ ...iconStyle, color: colors[0] }} />
      ) : (
        <DownArrow style={{ ...iconStyle, color: colors[1] }} />
      )
    ) : null;
  const absValue = value ? Math.abs(value) : value;
  if (isComingSoon) {
    return (
      <AvTooltip title={`Collecting data.. once ${days} days completed we will show here a comparison trend`}>
        <Box sx={{ ...flex.justifyStartCenter, gap: '5px', whiteSpace: 'nowrap' }}>
          <Box sx={{ fontSize: 13, color: theme.palette.colors.neutrals[500] }}>Coming Soon...</Box>
          <QuestionCircle style={{ height: 16, width: 16, color: theme.palette.colors.neutrals[600] }} />
        </Box>
      </AvTooltip>
    );
  }
  return (
    <Box sx={{ ...flex.justifyStartCenter, gap: '5px', whiteSpace: 'nowrap' }}>
      {absValue !== 0 && icon && <Box sx={{ width: '15px', height: '15px' }}>{icon}</Box>}
      {value && <Box sx={{ fontWeight: 600, color: value > 0 ? colors[0] : colors[1] }}>{absValue}%</Box>}
      <Box sx={{ fontSize: 13, color: theme.palette.colors.neutrals[500] }}>{description}</Box>
    </Box>
  );
};

interface PercentageViewerProps {
  value: number;
  total: number;
}

const PercentageViewer: React.FC<PercentageViewerProps> = ({ value, total }) => (
  <>
    <Divider sx={{ height: 19 }} orientation="vertical" />
    <Box sx={{ ...flex.itemsBaseline, gap: '6px' }}>
      <AvTooltip title={abbreviateNumber(value)}>
        <Typography variant="h4" sx={{ mb: 0 }}>
          {abbreviateNumber(value)}{' '}
        </Typography>
      </AvTooltip>
      <Box sx={{ fontSize: 13, color: theme => theme.palette.colors.neutrals[500], whiteSpace: 'nowrap' }}>
        {' '}
        out of{' '}
        <AvTooltip title={total.toLocaleString()}>
          <Box component="span" sx={{ textTransform: 'uppercase' }}>
            {abbreviateNumber(total)}
          </Box>
        </AvTooltip>
      </Box>
    </Box>
  </>
);

interface StatusProps {
  title?: string;
  icon?: React.JSX.Element;
  data?: any[];
  series?: any[];
  actionHref?: string;
  sx?: SxProps;
  stickDescription?: boolean;
}

const Status: React.FC<StatusProps> = ({
  title,
  icon,
  data = emptyArray,
  series = emptyArray,
  actionHref,
  sx,
  stickDescription = false,
}) => {
  const isPercentage = data.some(s => !!s.percentage);
  return (
    <Box sx={{ ...flex.alignEnd, gap: 2, position: 'relative', height: '100%', py: 2, px: 3, ...sx }}>
      {icon}
      <Box
        sx={{
          ...(stickDescription ? flex.colItemsStart : { ...flex.colJustifyCenter, gap: 1.5 }),
          height: '100%',
        }}>
        {title && <Box sx={{ color: theme => theme.palette.colors.neutrals[500], fontWeight: 600 }}>{title}</Box>}
        <Box sx={{ ...flex.justifyBetweenCenter, gap: isPercentage ? 2 : 4 }}>
          {data.map((item, i) => (
            <React.Fragment key={item.id || item.name}>
              {i > 0 && <Divider sx={{ height: 51 }} orientation="vertical" />}
              <Box key={item.value} sx={{ ...flex.colItemsStart, gap: 1 }}>
                <Box sx={{ ...flex.itemsBaseline, gap: 2 }}>
                  <AvTooltip {...(!item.percentage && { title: item.value?.toLocaleString() })}>
                    <Typography
                      variant="h3"
                      sx={{
                        mb: 0,
                        ...(series[i].valueSize && {
                          fontSize: series[i].valueSize,
                          fontWeight: 400,
                        }),
                      }}>
                      {item.percentage ? getPercent(item.percentage) : abbreviateNumber(item.value)}
                    </Typography>
                  </AvTooltip>
                  {item.percentage && <PercentageViewer value={item.value} total={item.total} />}
                </Box>
                <StatusDescription
                  isUpPositive={series[i].isUpPositive}
                  isComingSoon={item.isComingSoon}
                  value={item.timeComparison || null}
                  days={series[i].timeComparisonDays}
                  type={series[i].type}
                  description={item.timeComparison ? `Last Month` : <Box sx={{ color: series[i].color, fontWeight: 600 }}>{item.name}</Box>}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      {actionHref && <ExploreLink actionHref={actionHref} title="Tickets" sx={{ position: 'absolute', right: 0, bottom: 0 }} />}
    </Box>
  );
};

export default Status;
