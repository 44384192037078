import { DwQueryRequest } from '../../CustomDashboards/types';

const sourcesQuery: DwQueryRequest = {
  select: {
    dims: [
      {
        name: 'explode(asset.source_names)',
        alias: 'name',
      },
    ],
    metrics: [],
  },
  groupBy: ['explode(asset.source_names)'],
  distinct: true,
  projectionId: {
    name: 'uber_assets',
    builtIn: true,
  },
  sorting: [],
};

export default sourcesQuery;
