import React from 'react';
import { Box, Typography } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import Select from '../../../components/Select';
import { HistoricDataIndicator } from '../../../components/Widgets/layout.components';
import DraggableWidget from '../../CustomDashboards/components/DraggableWidget';
import renderWidget from '../../CustomDashboards/components/Widgets/RenderWidget';
import { AllTypeWidgets, Field, TileTypeWidget } from '../../CustomDashboards/types';

const WidgetWithToolbar = ({
  value,
  onChange,
  widget,
  options,
  title,
  tileWidget,
  selectAtTheEnd,
}: {
  title?: string;
  options: Field[];
  value: Field;
  onChange: (v: Field) => void;
  widget: AllTypeWidgets;
  tileWidget?: TileTypeWidget;
  selectAtTheEnd?: boolean;
}) => (
  <DraggableWidget
    widget={widget}
    sx={{ border: 0, p: 0, pb: 1 }}
    customToolbar={
      <Box sx={{ ...flex.justifyStartCenter, gap: '10px', mt: 2.3, ml: 2 }}>
        {tileWidget && (
          <DraggableWidget widget={tileWidget} showToolbar={false} sx={{ border: 0, p: 0, mb: 1, flexShrink: 0 }}>
            {renderWidget({ widget: tileWidget })}
          </DraggableWidget>
        )}
        {title && (
          <Typography sx={{ whiteSpace: 'nowrap', mb: 2, ml: !tileWidget && !selectAtTheEnd ? 1.2 : 0 }} variant="h5">
            {title}
          </Typography>
        )}
        {selectAtTheEnd && <Box sx={{ ml: 'auto', mb: 1.5, ...flex.justifyCenter, fontSize: '13px', fontWeight: 600 }}>Broken down By</Box>}
        <Box width={174} sx={{ ...flex.justifyBetweenCenter, mb: 2 }}>
          <Select options={options} size="xSmall" value={value} onChange={onChange} isRequired />
        </Box>
        {selectAtTheEnd && (
          <Box sx={{ ...flex.justifyEndCenter, width: 20, height: '100%', mb: 2, mx: 2 }}>
            <HistoricDataIndicator hideText />
          </Box>
        )}
      </Box>
    }>
    {renderWidget({ widget })}
  </DraggableWidget>
);

export default WidgetWithToolbar;
