import React, { MouseEventHandler } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, SxProps, Theme, useTheme } from '@mui/material';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import Loading from './Loading';

export enum ButtonKinds {
  TOGGLE = 'TOGGLE',
}

export enum ButtonPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

interface Props {
  loading?: boolean;
  kind?: ButtonKinds;
  value?: any;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  position?: ButtonPosition;
  sx?: SxProps<Theme>;
}

const AvButton = ({ children, loading, kind, isActive, tooltip, position, ...props }: ButtonProps & Props) => {
  const theme = useTheme();

  const commonButtonStyle = {
    [ButtonKinds.TOGGLE]: {
      ...(isActive && {
        background: theme.palette.colors.primary[150],
        color: theme.palette.colors.neutrals[800],
      }),
    },
  };

  // TODO: Figure out if Button is exactly like LoadingButton when `loading=false`

  if (loading !== undefined) {
    return (
      <LoadingButton {...props} loading={loading} loadingIndicator={<Loading height={20} />}>
        {children}
      </LoadingButton>
    );
  }

  if (kind === ButtonKinds.TOGGLE) {
    return (
      <Button
        variant="filter"
        {...props}
        sx={{
          height: '24px',
          padding: '3px 8px',
          backgroundColor: theme.palette.colors.neutrals[300],
          borderRadius:
            position === ButtonPosition.LEFT ? '10px 0px 0px 10px' : position === ButtonPosition.RIGHT ? '0px 10px 10px 0px' : '0px',
          '&, &:hover': commonButtonStyle[ButtonKinds.TOGGLE],
          ...props.sx,
        }}>
        <AvTooltip title={tooltip}>
          <Box sx={{ ...flex.col, fontSize: 12, lineHeight: 1 }}>{children}</Box>
        </AvTooltip>
      </Button>
    );
  }

  return <Button {...props}>{children}</Button>;
};
export default AvButton;
