import React, { useContext } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { GroupHeaderRowType } from '../../components/Table/types';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { DescriptorType, EntityTypeID, FieldVisibilityConfig, VisibilityConfig } from '../../context/context.type';
import { WorkflowType } from '../../context/SnackBar.types';
import useQueryObject from '../../hooks/useQueryObjectSql';
import { QueryKey } from '../../utils/queryKey';
import { flattenKeys, flattenObject, getNetworkErrorCode, isDeepEqual, noop } from '../../utils/Utils';
import { FieldTypes } from '../Reports/types';
import { TFieldRegulation, TFieldRegulationData, TFieldRegulationDataType, TGetRowsProps } from './types';
import { ReactComponent as BooleanSVG } from '../../assets/Boolean.svg';
import { ReactComponent as Calendar } from '../../assets/Calendar.svg';
import { ReactComponent as CurlyBracket } from '../../assets/CurlyBracket.svg';
import { ReactComponent as Fix } from '../../assets/Fix.svg';
import { ReactComponent as IP } from '../../assets/IP.svg';
import { ReactComponent as Measurements } from '../../assets/Measurements.svg';
import { ReactComponent as Number } from '../../assets/Number.svg';
import { ReactComponent as RepeatedBoolean } from '../../assets/Repeated Boolean.svg';
import { ReactComponent as RepeatedCurlyBracket } from '../../assets/Repeated CurlyBracket.svg';
import { ReactComponent as RepeatedDate } from '../../assets/Repeated Date.svg';
import { ReactComponent as RepeatedIP } from '../../assets/Repeated IP.svg';
import { ReactComponent as RepeatedNumber } from '../../assets/Repeated Number.svg';
import { ReactComponent as RepeatedText } from '../../assets/Repeated Text.svg';
import { ReactComponent as Text } from '../../assets/Text.svg';

const GET_ACCOUNT_REGULATIONS_DATA = gql`
  query {
    getFieldRegulationsData {
      fieldRegulations {
        id
        projectionName
        builtInProjection
        fieldName
        config
      }
      availableUpdateRolesPerProjection {
        projectionName
        availableRoles
      }
    }
  }
`;

export const useFieldRegulationsData = (onSuccess: (d: TFieldRegulationData) => TFieldRegulationDataType = d => d.fieldRegulations) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.FieldRegulations, onSuccess.toString()],
    queryFn: () => api(GET_ACCOUNT_REGULATIONS_DATA, { onSuccess: ({ data }) => onSuccess(data.getFieldRegulationsData || {}) }),
  });
};

const CREATE_MANUAL_UPDATE = gql`
  mutation ($fieldRegulation: FieldRegulationDTOInput!) {
    createFieldRegulation(fieldRegulation: $fieldRegulation) {
      projectionName
      builtInProjection
      fieldName
      config
    }
  }
`;
const UPDATE_MANUAL_UPDATE = gql`
  mutation ($fieldRegulation: FieldRegulationDTOInput!) {
    updateFieldRegulation(fieldRegulation: $fieldRegulation) {
      projectionName
    }
  }
`;
export const useSaveManualUpdates = () => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const client = useQueryClient();
  const { mutate, isPending: isLoading } = useMutation<() => Promise<any>, unknown, TFieldRegulation>({
    mutationFn: fieldRegulation => api(fieldRegulation.id ? UPDATE_MANUAL_UPDATE : CREATE_MANUAL_UPDATE, { options: { fieldRegulation } }),
    onSuccess: () => {
      enqueueSnackbar('Saved Successfully', { variant: 'success' });
      return client.resetQueries({ queryKey: ['fieldRegulations'] });
    },
  });
  return { saveManualUpdates: mutate, isLoading };
};

const GET_PROJ_EVALUATIONS = gql`
  query {
    projEvaluations {
      projId {
        name
        builtIn
      }
      config {
        fieldAggregations {
          aggName
          args {
            stringValue
          }
          func
          filter
          builtIn
        }
        fieldScripts {
          field {
            name
            type
            origin
          }
          aggNames
          script
          fallbackStrategy
          evalStrategy
        }
      }
    }
    aggProjections {
      meta {
        id {
          name
          builtIn
        }
      }
      aggregates {
        name
        builtIn
      }
      clusteringPredicate {
        anyofList {
          fieldsList
          ignoreNulls
        }
      }
    }
    aggregationFunctionDefinitions {
      value: funcType
      title: funcType
      argNames
    }
  }
`;

export const useProjEvaluations = (gcTime: number = 0) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.ProjEvaluations],
    queryFn: () =>
      api(GET_PROJ_EVALUATIONS).then(({ data }) => ({
        data: data.projEvaluations.map(projEval => ({
          ...projEval,
          proj: data.aggProjections.find(({ meta: { id } }) => isDeepEqual(id, projEval.projId)),
        })),
        funcTypes: data.aggregationFunctionDefinitions,
      })),
    gcTime,
  });
};

const RUN = gql`
  mutation ($projIds: [ProjectionIDInput!]!, $executionPlan: EtlExecutionPlan) {
    runAggregations(projIds: $projIds, executionPlan: $executionPlan) {
      workflowRun {
        id
        runId
      }
    }
  }
`;

export const useRunAggregation = () => {
  const { api } = useAvContext();
  const { setRunInfo = noop } = useContext(NotificationContext);

  return useMutation<unknown, unknown, { projIds: { name: string; builtIn: boolean }[]; executionPlan?: any }>({
    mutationFn: ({ projIds, executionPlan }) =>
      api(RUN, { options: { projIds, executionPlan } })
        .then(({ data }) =>
          setRunInfo({
            runId: data.runAggregations.workflowRun.runId,
            wfId: data.runAggregations.workflowRun.id, // TODO change id to wfId and remove .workflowRun
            returnStatusOnly: true,
            workflowType: WorkflowType.ReprocessAggProj,
          })
        )
        .catch(error => {
          if (![400, 429].includes(getNetworkErrorCode(error))) {
            throw error;
          }
        }),
  });
};

export const shouldForceComment = (fieldRegulations, fields, projectionName) => {
  if (!fields) {
    return {};
  }
  const fieldsFlatten = flattenKeys(fields);
  const regulations = fieldRegulations
    .filter(r => r.projectionName === projectionName && fieldsFlatten.some(field => field === r.fieldName))
    .filter(f => f.config.forceComment);
  if (regulations.some(r => r.config.forceCommentType === 'ALWAYS')) {
    return { fields: regulations.map(r => r.fieldName), condition: true };
  }
  const customRegulations = regulations.filter(r => r.config.forceCommentType === 'CUSTOM');
  const flattenFields = flattenObject({ obj: fields });
  return {
    fields: regulations.map(r => r.fieldName),
    condition: !!(
      customRegulations.length &&
      customRegulations.some(r => flattenFields[r.fieldName] && r.config.forceCommentValues.includes(flattenFields[r.fieldName]))
    ),
  };
};

export const getFieldRegulation = (fieldRegulations, projectionName, field) =>
  fieldRegulations?.find(regulation => regulation.projectionName === projectionName && regulation.fieldName === field);

export const getContentValidationValues = (field, projectionName, fieldRegulations) => {
  const fieldRegulation = getFieldRegulation(fieldRegulations, projectionName, field);
  return fieldRegulation?.config?.contentValidation ? fieldRegulation?.config?.contentValidationAllowedValues.sort() : [];
};

export const getContentValidationQueryObject = (field, projectionName, fieldRegulations) => {
  const fieldRegulation = getFieldRegulation(fieldRegulations, projectionName, field);
  return {
    contentValidation: fieldRegulation?.config?.contentValidation,
    queryObject: fieldRegulation?.config?.queryObject,
    isQueryInUse: fieldRegulation?.config?.useQueryForContentValidation,
  };
};

export const getIsManualOverrideEnabled = (field, projectionName, fieldRegulations) => {
  const config = getFieldRegulation(fieldRegulations, projectionName, field)?.config;
  return config?.allowManualUpdate && config?.allowedForUser;
};

export const useContentValidationValues = ({ field, projectionName }: { field: string; projectionName: string }) => {
  const { data: fieldRegulations } = useFieldRegulationsData();
  const { contentValidation, queryObject, isQueryInUse } = getContentValidationQueryObject(field, projectionName, fieldRegulations);
  const contentValidationValues = isQueryInUse ? [] : getContentValidationValues(field, projectionName, fieldRegulations);
  const isContentValidation = !!(contentValidation || contentValidationValues?.length);
  const { data: queryObjectValues, isLoading } = useQueryObject({
    queryObject,
    onSuccess: d => d.map(({ id, ...rest }) => Object.values(rest)[0]).filter(v => !!v),
    options: { enabled: !!(isContentValidation && queryObject && isQueryInUse), gcTime: 0 },
  });

  return {
    isContentValidation,
    values: (isQueryInUse && queryObject && isLoading) || !isContentValidation ? [] : queryObjectValues || contentValidationValues,
  };
};

export const getFieldKey = v => (Array.isArray(v) ? JSON.stringify(v) : v);
export const entityType = 'ENTITY';

export const fieldTypeIconsMap = (palette, iconStyle = {}) => {
  const color = palette.colors.neutrals[500];
  const style = { color, ...iconStyle };
  return {
    [FieldTypes.Measurement]: <Measurements style={style} />,
    [DescriptorType.TYPE_STRING]: <Text style={style} />,
    [`[${DescriptorType.TYPE_STRING}]`]: <RepeatedText style={style} />,
    [DescriptorType.TYPE_DOUBLE]: <Number style={style} />,
    [`[${DescriptorType.TYPE_DOUBLE}]`]: <RepeatedNumber style={style} />,
    [DescriptorType.TYPE_BOOL]: <BooleanSVG style={style} />,
    [`[${DescriptorType.TYPE_BOOL}]`]: <RepeatedBoolean style={style} />,
    [DescriptorType.TYPE_INT32]: <Number style={style} />,
    [`[${DescriptorType.TYPE_INT32}]`]: <RepeatedNumber style={style} />,
    [DescriptorType.TYPE_UINT32]: <Number style={style} />,
    [`[${DescriptorType.TYPE_UINT32}]`]: <RepeatedNumber style={style} />,
    [DescriptorType.TYPE_ENUM]: <Text style={style} />,
    [`[${DescriptorType.TYPE_ENUM}]`]: <RepeatedText style={style} />,
    DATE: <Calendar style={style} />,
    '["DATE"]': <RepeatedDate style={style} />,
    DATETIME: <Calendar style={style} />,
    '["DATETIME"]': <RepeatedDate style={style} />,
    MESSAGE: <CurlyBracket style={style} />,
    '["MESSAGE"]': <RepeatedCurlyBracket style={style} />,
    FIX: <Fix style={style} />,
    '["FIX"]': <Fix style={style} />,
    IP: <IP style={style} />,
    '["IP"]': <RepeatedIP style={style} />,
    [entityType]: <div />,
  };
};
export const fieldTypeLabelMap = {
  [DescriptorType.TYPE_STRING]: { label: 'Text', pyType: 'str' },
  [`[${DescriptorType.TYPE_STRING}]`]: { label: 'Repeated Text', pyType: 'list[str]' },
  [DescriptorType.TYPE_DOUBLE]: { label: 'Number', pyType: 'float' },
  [`[${DescriptorType.TYPE_DOUBLE}]`]: { label: 'Repeated Number', pyType: 'list[float]' },
  [DescriptorType.TYPE_BOOL]: { label: 'Boolean', pyType: 'bool' },
  [`[${DescriptorType.TYPE_BOOL}]`]: { label: 'Repeated Boolean', pyType: 'list[bool]' },
  [DescriptorType.TYPE_INT32]: { label: 'Number', pyType: 'int' },
  [`[${DescriptorType.TYPE_INT32}]`]: { label: 'Repeated Number', pyType: 'list[int]' },
  [DescriptorType.TYPE_UINT32]: { label: 'Number', pyType: 'int' },
  [`[${DescriptorType.TYPE_UINT32}]`]: { label: 'Repeated Number', pyType: 'list[int]' },
  [DescriptorType.TYPE_ENUM]: { label: 'Text', pyType: 'str' },
  [`[${DescriptorType.TYPE_ENUM}]`]: { label: 'Repeated Text', pyType: 'list[str]' },
  DATE: { label: 'Date', pyType: 'object' },
  '["DATE"]': { label: 'Repeated Date', pyType: 'list[object]' },
  DATETIME: { label: 'Date', pyType: 'object' },
  '["DATETIME"]': { label: 'Repeated Date', pyType: 'list[object]' },
  MESSAGE: { label: 'Message', pyType: 'dict' },
  '["MESSAGE"]': { label: 'Repeated Message', pyType: 'list[dict]' },
  IP: { label: 'IP', pyType: 'str' },
  '["IP"]': { label: 'Repeated IP', pyType: 'list[str]' },
  [entityType]: { label: 'Entity' },
};
export const getPythonType = type => fieldTypeLabelMap[getFieldKey(type)]?.pyType || 'str';

const GET_MODEL_PREVIEW = gql`
  query ($evals: AggProjectionEvaluationInput!, $selectivityFilter: FilterScalar, $fieldName: String) {
    previewSpf(aggProjectionEvaluation: $evals, selectivityFilter: $selectivityFilter, fieldName: $fieldName) {
      internalError
      scriptError
      previewData
    }
  }
`;
export const useModelPreview = ({ field, evals, selectivityFilter = {} }, onSuccess = d => d) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.ModelPreview, selectivityFilter, field],
    queryFn: () =>
      api(GET_MODEL_PREVIEW, { options: { evals, selectivityFilter, fieldName: field } }).then(
        ({ data }) => data?.previewSpf && onSuccess(data.previewSpf)
      ),
    enabled: !!evals,
    gcTime: 0,
  });
};

export type ModelRowItem = {
  id: string;
  displayName: string;
  fullDisplayName?: string;
  projectionName: string;
  systemName: string;
  entityTypeId: EntityTypeID;
  type: number | string | number[];
  builtIn?: boolean;
  builtInProjection: boolean;
  isMetadataField?: boolean;
  function?: string;
  groupHeaderType?: GroupHeaderRowType;
  hasManualOverride?: boolean;
  tags?: string[];
  visibilityConfig?: VisibilityConfig;
};
export const getRows = ({ projOptions, fieldRegulations, search }: TGetRowsProps) => {
  const rows = projOptions.reduce(
    (acc, entity) => [
      ...acc,
      {
        id: entity.projDisplayName,
        entityTypeId: entity.entityTypeId,
        displayName: entity.projDisplayName,
        projectionName: entity.projId.name,
        systemName: entity.projId.name,
        builtInProjection: entity.projId.builtIn,
        type: entityType,
        groupHeaderType: GroupHeaderRowType.GROUP,
      },
      ...entity.fields.map(({ id, name: field, displayName, builtIn, tags, isMetadata, type, visibilityConfig }) => ({
        id: id || `${entity.projDisplayName}.${field}`.toLowerCase(),
        entityTypeId: entity.entityTypeId,
        displayName: displayName || field,
        fullDisplayName: `${entity.projDisplayName} ${displayName}`,
        visible: !visibilityConfig.config.hidden,
        projectionName: entity.projId.name,
        systemName: field,
        type: ['number', 'string'].includes(typeof type) ? type : entity.typeMap[id || `${entity.projId.name}.${field}`.toLowerCase()],
        builtIn,
        builtInProjection: entity.projId.builtIn,
        isMetadataField: isMetadata,
        visibilityConfig,
        function: '',
        hasManualOverride: fieldRegulations?.find(
          regulation => regulation.projectionName === entity.projId.name && regulation.fieldName === field
        )?.config.allowManualUpdate,
        tags,
      })),
    ],
    [] as ModelRowItem[]
  );
  return search
    ? rows.filter(
        r =>
          !(r.groupHeaderType === GroupHeaderRowType.GROUP) &&
          (r.fullDisplayName!.toLowerCase().includes(search) || r.id.toLowerCase().includes(search))
      )
    : rows;
};

const handleVisibilityError = (enqueueSnackbar, onError?) => e => {
  if ([e?.status, e?.graphQLErrors?.[0]?.extensions?.status, e?.extensions?.status].includes(400)) {
    console.warn(e);
  } else {
    console.error(e);
  }
  onError?.();
  enqueueSnackbar(typeof e?.message === 'string' ? e?.message : 'Something went wrong, Our engineers have been notified', {
    variant: 'error',
  });
};

const CREATE_FIELD_VISIBILITY = gql`
  mutation ($fieldVisibility: FieldVisibilityDtoInput!) {
    createFieldVisibility(fieldVisibility: $fieldVisibility) {
      id
    }
  }
`;

const UPDATE_FIELD_VISIBILITY = gql`
  mutation ($fieldVisibility: FieldVisibilityDtoInput!) {
    updateFieldVisibility(fieldVisibility: $fieldVisibility) {
      id
    }
  }
`;

export const useUpdateFieldVisibility = onError => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (fieldVisibility: VisibilityConfig) =>
      api(fieldVisibility.id ? UPDATE_FIELD_VISIBILITY : CREATE_FIELD_VISIBILITY, {
        options: { fieldVisibility },
        muteErrors: true,
      }).catch(handleVisibilityError(enqueueSnackbar, onError)),
  });
};

const UPDATE_FIELD_VISIBILITIES = gql`
  mutation ($fieldVisibilities: [FieldVisibilityDtoInput!], $config: FieldVisibilityConfigDto!) {
    updateFieldVisibilities(fieldVisibilities: $fieldVisibilities, config: $config) {
      id
    }
  }
`;

export const useUpdateFieldVisibilities = () => {
  const {
    api,
    accountEntities: { refetch },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({
      fieldVisibilities,
      config,
    }: {
      fieldVisibilities: Pick<VisibilityConfig, 'projectionName' | 'builtInProjection' | 'fieldName'>[];
      config: FieldVisibilityConfig;
    }) =>
      api(UPDATE_FIELD_VISIBILITIES, {
        options: { fieldVisibilities, config },
        onSuccess: () => {
          refetch();
          return enqueueSnackbar(`Field visibility updated successfully for ${fieldVisibilities.length} fields`, { variant: 'success' });
        },
        muteErrors: true,
      }).catch(handleVisibilityError(enqueueSnackbar)),
  });
};
