import React from 'react';
import { Route } from 'react-router-dom';
import { APP_PATHS } from '../../types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import CustomDashboardsRoute from '../CustomDashboardsRoute';
import ExploreRoute from '../ExploreRoute';
import GeneralEntityRoute from '../GeneralEntityRoute';
import ReportsRoute from '../ReportsRoute';

const Threats = () => (
  <Route path={APP_PATHS.THREATS}>
    {GeneralEntityRoute(entityViewConfig.Incident.projectionName)}
    {GeneralEntityRoute(entityViewConfig.Alert.projectionName)}
    {ExploreRoute(entityViewConfig.Incident.projectionName, APP_PATHS.THREATS)}
    {ReportsRoute()}
    {CustomDashboardsRoute()}
  </Route>
);
export default Threats;
