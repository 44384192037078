import React, { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';
import AvCheckbox from '../../components/AvCheckbox';
import AvTag from '../../components/AvTag';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import { GroupHeaderRowType } from '../../components/Table/types';
import { useAvContext } from '../../context/AvContextProvider';
import { EntityTypeID } from '../../context/context.type';
import { rebranding } from '../../rebranding';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import { useAddFieldToEntity } from '../Sources/hooks';
import { AddFieldPopper } from '../Sources/Mapping/Entity';
import AvMessagePopover from '../Tickets/AvMessagePopover';
import { ReactComponent as InfoFull } from '../../assets/colorful/Info Full.svg';
import { ReactComponent as WarningFullGrey } from '../../assets/colorful2/Warning_Full.svg';
import { ReactComponent as Edit } from '../../assets/Edit.svg';
import { ReactComponent as ExploreEntity } from '../../assets/Entities_Outlined.svg';
import { ReactComponent as Plus } from '../../assets/Plus.svg';

const plusIcon = <Plus style={{ width: 16, height: 16 }} />;
const editIcon = <Edit style={{ height: 18, width: 18 }} />;

interface AddFieldButtonProps {
  entityTypeId: EntityTypeID;
}

function AddFieldButton({ entityTypeId }: AddFieldButtonProps) {
  const client = useQueryClient();
  const { mutateAsync: handleAddField } = useAddFieldToEntity(() => {
    client.refetchQueries({ queryKey: ['projEvaluations'] });
  });
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  return (
    <>
      <AvTooltip title="Add field">
        {/* @ts-ignore type="icon" */}
        <Button
          onClick={e => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          ref={ref}
          size="xSmall"
          type="icon"
          variant="outlined">
          {plusIcon}
        </Button>
      </AvTooltip>
      <AddFieldPopper
        open={isOpen}
        entityTypeId={entityTypeId}
        anchorEl={ref.current}
        onAdd={handleAddField}
        close={() => setIsOpen(!isOpen)}
      />
    </>
  );
}

interface InvestigateEntityButtonProps {
  projName: string;
}

export function InvestigateEntityButton({ projName }: InvestigateEntityButtonProps) {
  const location = useLocation();
  const {
    getPathName,
    accountEntities: { aggProjs },
  } = useAvContext();

  return (
    <AvTooltip title="Investigate Entity">
      <Button
        onClick={e => e.stopPropagation()}
        component={Link}
        to={`${getPathName(PAGE_PATHS.ENTITIES, '', APP_PATHS.EXPLORE)}?activeEntityId=${aggProjs[projName].entityTypeId.name}`}
        state={{ breadCrumbs: [location.pathname] }}>
        <ExploreEntity />
      </Button>
    </AvTooltip>
  );
}

const innerTagStyle = {
  backgroundColor: ({ palette }) => palette.colors.primary[100],
  color: ({ palette }) => palette.primary.main,
  px: '6px',
  borderRadius: '6px',
  lineHeight: '16px',
  mr: '-6px',
};
export const editedTag = (text = <Box sx={flex.itemsCenter}>{editIcon} Edited</Box>) => (
  <AvTag text={text} isActive sx={{ py: 0, pl: '4px', flexShrink: 0 }} />
);

export const groupRowHead = (
  changes,
  projections,
  projData,
  isLoadingProjEval: boolean,
  checked: (row) => boolean,
  onCheck: (row, id) => void,
  showCheckbox = false
) =>
  function groupRowHead(v, row) {
    const fieldObj = projData?.find(({ projId }) => row.projectionName === projId.name);
    const config = fieldObj?.config;
    const field = config?.fieldScripts.find(({ field }) => field.name === row.systemName);
    const entityChanges = changes
      .find(({ projId }) => projId.name === row.projectionName)
      ?.config.fieldScripts?.map(({ field }) => projections[row.projectionName].nameMap[field.name] || field.name);

    return row.groupHeaderType === GroupHeaderRowType.GROUP ? (
      <Box sx={{ ...flex.itemsCenter, gap: 1, flex: 1, width: 'calc(100% - 26px)' }}>
        <AvTooltip sx={{ fontWeight: 600, width: '100%' }}>{v}</AvTooltip>
        {entityChanges && (
          <AvTooltip title={entityChanges.join(', ')}>
            <Box sx={flex.row}>
              {editedTag(
                <Box sx={{ ...flex.itemsCenter, gap: '4px', height: 20, cursor: 'default' }}>
                  {editIcon}
                  <Box sx={innerTagStyle}>{entityChanges.length}</Box>
                </Box>
              )}
            </Box>
          </AvTooltip>
        )}
        <InvestigateEntityButton projName={row.projectionName} />
        <AddFieldButton entityTypeId={row.entityTypeId} />
      </Box>
    ) : (
      <Box
        sx={{ ...flex.itemsCenter, height: '100%', gap: 1, flex: 1, ml: 3, width: 'calc(100% - 24px)' }}
        onClick={
          showCheckbox
            ? e => {
                e.stopPropagation();
                onCheck(row.id, !checked(row) ? row : null);
              }
            : undefined
        }>
        {showCheckbox && (
          <Box sx={{ mr: 1, ...flex.itemsCenter }}>
            <AvCheckbox value={checked(row)} />
          </Box>
        )}
        <AvTooltip sx={{ width: '100%' }}>{v}</AvTooltip>
        {isLoadingProjEval ? null : entityChanges?.includes(v) ? (
          editedTag()
        ) : !field ? (
          <AvMessagePopover
            message="Field is not populated"
            icon={rebranding ? <WarningFullGrey /> : <InfoFull style={{ rotate: '180deg' }} />}
            asTooltip
          />
        ) : null}
      </Box>
    );
  };
