import React from 'react';
import { Badge, badgeClasses, BadgeProps, Box, BoxProps, SvgIcon, svgIconClasses, tooltipClasses, TooltipProps } from '@mui/material';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { ReactComponent as QuestionFull } from '../../assets/QuestionFull.svg';

export type WithDescriptionTooltipProps = {
  /**
   * @description If `true`, will badgify the children with an icon and attach the tooltip to it. otherwise attaches the tooltip to the children.
   * @default false
   */
  badgify?: boolean;
  badgeProps?: Omit<BadgeProps, 'children'>;
} & TooltipProps;

const iconStyles = { width: 18, height: 18 } as const;
const avTooltipProps: Omit<React.ComponentProps<typeof AvTooltip>, 'children'> = {
  variant: 'message',
  color: 'white',
  muiProps: {
    PopperProps: {
      sx: {
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 'none',
        },
      },
    },
  },
};

const tooltipContentSx: BoxProps['sx'] = {
  ...flex.itemsCenter,
  fontSize: 12,
  gap: 1,
  maxWidth: 428,
  [`& .${svgIconClasses.root}`]: {
    ...flex.row,
    alignSelf: 'flex-start',
    fontSize: 18,
  },
  [`& .${svgIconClasses.root} svg`]: iconStyles,
};

const StyledTooltipTitleWithIcon = ({ children, sx, ...props }: BoxProps) => (
  <Box sx={{ ...tooltipContentSx, ...sx }} {...props}>
    <SvgIcon component="span">
      <QuestionFull viewBox="0 0 20 20" />
    </SvgIcon>
    <Box>{children}</Box>
  </Box>
);

const StyledBadge = ({ children, tooltipProps, sx, ...props }: BadgeProps & { tooltipProps: Omit<TooltipProps, 'children'> }) => {
  const { current: id } = React.useRef(crypto.randomUUID());
  return (
    <Badge
      sx={theme => ({
        [`& .${badgeClasses.badge}`]: {
          top: '50%',
          transform: 'scale(1) translate(0, -50%)',
          transformOrigin: '50% 0',
          cursor: 'pointer',
        },

        [`& .${svgIconClasses.root}`]: {
          opacity: 0,
          transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter }),
          fontSize: 18,
        },

        [`& .${svgIconClasses.root} svg`]: iconStyles,
        [`:is([role=tooltip], &:hover) :is(& .${svgIconClasses.root}), 
          body:has([data-tooltip-id="${id}"]) & .${svgIconClasses.root}`]: {
          opacity: 1,
        },

        ...sx,
      })}
      badgeContent={
        <AvTooltip
          {...avTooltipProps}
          {...tooltipProps}
          muiProps={{
            ...avTooltipProps.muiProps,
            PopperProps: {
              // @ts-expect-error: type does not recognize data attributes but it is a valid prop.
              'data-tooltip-id': id,
              ...avTooltipProps.muiProps?.PopperProps,
              ...tooltipProps.PopperProps,
            },
            placement: 'right',
          }}
          title={<StyledTooltipTitleWithIcon>{tooltipProps.title}</StyledTooltipTitleWithIcon>}>
          <SvgIcon component="span">
            <QuestionFull viewBox="0 0 20 20" />
          </SvgIcon>
        </AvTooltip>
      }
      {...props}>
      {children}
    </Badge>
  );
};

function WithDescriptionTooltip({ badgify = false, children, badgeProps, ...props }: WithDescriptionTooltipProps) {
  if (!props.title) {
    return children;
  }

  if (badgify) {
    return (
      <StyledBadge tooltipProps={props} {...badgeProps}>
        {children}
      </StyledBadge>
    );
  }
  const { title, ...rest } = props;
  return (
    <AvTooltip
      {...avTooltipProps}
      muiProps={{ ...avTooltipProps.muiProps, ...rest }}
      title={<StyledTooltipTitleWithIcon>{title}</StyledTooltipTitleWithIcon>}>
      {children}
    </AvTooltip>
  );
}

export default WithDescriptionTooltip;
