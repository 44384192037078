import React from 'react';
import { Box, useTheme } from '@mui/material';
import AvAddOperandButton from '../../../components/AvAddOperandButton';
import { flex } from '../../../components/AvThemeProvider';
import DataSourceSelect from '../../../components/DataSourceSelect';
import FieldFilterBox from '../../../components/filters/FieldFilterBox';
import { boxWidth, getEmptyExpression } from '../../../components/filters/Utils';
import TextInput from '../../../components/TextInput';
import { useAvContext } from '../../../context/AvContextProvider';
import { FilterType, PolicyType, ToolCoveragePolicyFilterConfig } from '../../../types/executionRules.types';
import { OperatorType } from '../../../types/filter.types';
import { getOpposite } from '../../../utils/stringToFIlter';
import { setNestedValue } from '../../../utils/Utils';

const ToolCoverageSimpleFilter = ({ setNewData, newData }: { setNewData: (newData: PolicyType) => void; newData: PolicyType }) => {
  const { palette } = useTheme();
  const {
    accountEntities: { aggProjs },
    typeNameMap: {
      sourcesObj: { sourceOptions },
    },
  } = useAvContext();
  const {
    filterType,
    filter,
    numOfDays: n,
    sources,
    operand,
    additionalFilter,
  } = newData.clientConfig.policyScenario as ToolCoveragePolicyFilterConfig;
  const assetFields = aggProjs.uber_assets.fields;
  const rootOperator = filter ? (filter.and ? OperatorType.AND : OperatorType.OR) : operand || OperatorType.OR;
  const firstFilter = filter ? filter[rootOperator]?.operands[0] : null;
  const sourcesNames =
    sources ||
    (firstFilter?.not?.and?.operands[0].expression?.arrayCondition?.underlying.stringCondition?.contains || '').split(',').filter(v => v);
  const numOfDays = n || firstFilter?.not?.and?.operands[1]?.expression?.dateCondition?.relative?.value;
  const secondFilter = (filter ? filter[rootOperator]?.operands[1] : additionalFilter) || getEmptyExpression();
  const borderColor = filter ? filter.and : operand === OperatorType.AND ? palette.colors.primary[500] : palette.colors.yellow[500];

  const toolCoverageObj = {
    numOfDays,
    sources: sourcesNames,
    filterType: FilterType.TOOL_COVERAGE,
    operand: rootOperator,
    additionalFilter: secondFilter,
  };
  const onChangeToolCoverage = (key: string) => val =>
    setNewData(setNestedValue(`clientConfig.policyScenario`, newData, { ...toolCoverageObj, [key]: val }));

  return (
    <Box>
      {filterType === FilterType.TOOL_COVERAGE && (
        <Box sx={{ ...flex.colItemsStart }}>
          <Box
            sx={{
              ...flex.colItemsStart,
              gap: 1,
              mt: 1,
              padding: 1.5,
              border: `1px solid ${palette.colors.neutrals[300]}`,
              borderLeft: `2px solid ${borderColor}`,
              borderRadius: 1,
              width: 850,
            }}>
            Asset was not discovered in the following sources:
            <Box sx={{ ...flex.justifyStartCenter, width: '100%', gap: 1 }}>
              <DataSourceSelect
                onChange={onChangeToolCoverage('sources')}
                options={sourceOptions}
                filterValue={sourcesNames}
                variant="input"
                label=""
                selectProps={{ placeholder: 'Select...' }}
              />
              <Box>in the last</Box>
              <TextInput
                inputMode="numeric"
                sx={{ width: 90 }}
                size="small"
                value={numOfDays}
                onChange={onChangeToolCoverage('numOfDays')}
              />
              <Box>day(s)</Box>
            </Box>
          </Box>
          <Box
            sx={{
              ...flex.itemsStart,
              my: '-4px',
              ml: `${boxWidth / 4}px`,
            }}>
            <AvAddOperandButton
              style={{ translate: '-50%' }}
              size="small"
              operator={rootOperator}
              onClick={() => onChangeToolCoverage('operand')(getOpposite(rootOperator))}
            />
          </Box>
          <FieldFilterBox
            setFilter={onChangeToolCoverage('additionalFilter')}
            fields={assetFields}
            isVertical={false}
            filter={secondFilter}
            defaultField="asset._key"
            onDelete={() => {}}
            rootOperator={rootOperator}
            size="small"
            customSx={{ borderLeft: `2px solid ${borderColor}` }}
            fieldDescription="Asset missing coverage indication"
            showDeleteAddFilter={false}
            showDisableToggle
            initialEnable={filter ? filter[rootOperator]?.operands[1] !== undefined : !!secondFilter}
          />
        </Box>
      )}
    </Box>
  );
};
export default ToolCoverageSimpleFilter;
