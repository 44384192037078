import React from 'react';
import { iconSize } from '../utils/Utils';
import { Assignment } from '../views/DataUnification/types';
import { FormattingRule } from '../views/FormattingRules/types';
import { Filter, OperatorType } from './filter.types';
import { QueryObjectProto, Strategy } from './QueryObjectProto.types';
import { ReactComponent as ThirdParty } from '../assets/Applications.svg';
import { ReactComponent as Assets } from '../assets/Assets outline.svg';
import { ReactComponent as Data } from '../assets/Data.svg';
import { ReactComponent as Network } from '../assets/Network.svg';
import { ReactComponent as NotPrivate } from '../assets/NotPrivate.svg';
import { ReactComponent as Applications } from '../assets/risk/Applications.svg';
import { ReactComponent as Sorting } from '../assets/Sorting.svg';
import { ReactComponent as User } from '../assets/user_unfilled.svg';

export const ExecutionRuleTypes = {
  RISK_FACTOR: 'RISK_FACTOR',
};

export type ExecutionType = (typeof ExecutionRuleTypes)[keyof typeof ExecutionRuleTypes];

export type GenericBaseExecutionRuleProperties = {
  id?: string;
  executableUnits: ExecutableUnit[];
  preCheckUnits: ExecutableUnit[];
  type: ExecutionType;
  updatedAt?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
};

export type GenericBaseExecutionRule = GenericBaseExecutionRuleProperties & {
  metadata: FactorLogicExecutionRuleMetadata | PolicyMetadataType;
};

export type BaseExecutionRule = GenericBaseExecutionRuleProperties & { metadata: FactorLogicExecutionRuleMetadata };

export enum PolicyCategoryId {
  CMDB = 'CMDB Hygiene',
  TOOL_COVERAGE = 'Tool Coverage',
  NONE = 'None',
}

export enum ExecutionRuleType {
  RISK_FACTOR = 'RISK_FACTOR',
  ASSET_POLICY = 'ASSET_POLICY',
}

export enum SearchAttributeType {
  ASSET_POLICY = 'EXECUTION_RULE',
}

export const PolicyCategory = {
  [PolicyCategoryId.CMDB]: 'CMDB Hygiene',
  [PolicyCategoryId.TOOL_COVERAGE]: 'Tool Coverage',
  [PolicyCategoryId.NONE]: 'None',
};

export const PolicyCategories = [PolicyCategoryId.CMDB, PolicyCategoryId.TOOL_COVERAGE, PolicyCategoryId.NONE];

export type PolicyMetadataType = {
  policy_name: string;
  policy_description: string;
  severity: string;
  severity_score: number;
  type: string;
};

export type ExecutionRunConfiguration = {
  id?: string;
  active: boolean;
  trigger: {
    type: TriggerType;
    value: string;
  };
  outcomes: any[];
};

export type PolicyType = GenericBaseExecutionRuleProperties & { metadata: PolicyMetadataType } & {
  executionRunConfiguration: ExecutionRunConfiguration;
  clientConfig: PolicyFilterConfig;
};

export type PolicyScenarioType = CommonPolicyFilterConfig | ConflictingValuesPolicyFilterConfig | ToolCoveragePolicyFilterConfig;

export type PolicyFilterConfig = {
  assetPopulationFilter: Filter;
  policyScenario: PolicyScenarioType;
  formattingRule?: FormattingRule;
};

// Exclude FilterType.CONFLICTING_VALUES from FilterType
type FilterTypeWithoutConflictingValues = Exclude<FilterType, FilterType.CONFLICTING_VALUES>;

export type CommonPolicyFilterConfig = {
  filterType: FilterTypeWithoutConflictingValues;
  filter: Filter;
};

export type ConflictingValuesPolicyFilterConfig = {
  filterType: FilterType.CONFLICTING_VALUES;
  fieldName: string;
  uniqueValues: number;
  excludedValues: string[];
};

export type ToolCoveragePolicyFilterConfig = {
  filterType: FilterType.TOOL_COVERAGE;
  sources: string[];
  numOfDays: number;
  operand?: OperatorType.OR | OperatorType.AND;
  additionalFilter?: Filter;
  filter?: Filter; // depracated
};

export enum FilterMode {
  SIMPLE = 'SIMPLE',
  ADVANCED = 'ADVANCED',
}

export enum FilterType {
  ADVANCED = 'ADVANCED',
  MISSING_FIELD = 'MISSING_FIELD',
  CONFLICTING_VALUES = 'CONFLICTING_VALUES',
  TOOL_COVERAGE = 'TOOL_COVERAGE',
}

export enum TriggerType {
  CDC = 'CDC',
  SCHEDULED = 'SCHEDULED',
}

export type ExecutableUnitScript = {
  primaryQuery: PrimaryQuery;
  secondaryQueries: SecondaryQuery[];
} & ExecutionScript;

export type ExecutableUnitConfig = {
  primaryQuery: PrimaryQuery;
  secondaryQueries: SecondaryQuery[];
} & ExecutionConfig;

export type BuiltInFactorLogicExecutionRule = BaseExecutionRule;

export type FactorLogicExecutionRule = BaseExecutionRule;

export type FactorRule = FactorLogicExecutionRule | BuiltInFactorLogicExecutionRule;

export type ExecutableUnit = {
  primaryQuery: PrimaryQuery;
  secondaryQueries: SecondaryQuery[];
} & (ExecutionScript | ExecutionConfig);

export type SecondaryQuery = {
  query: ExecutableQuery;
};
export type PrimaryQuery = {
  query: ExecutableQuery;
  evaluatePerRow: boolean;
};
export type ExecutableQuery = { name: string } & ({ queryObject: QueryObjectProto } | { querySql: string });
export type ExecutableQueryObject = { name: string; queryObject: QueryObjectProto };
export type ExecutableQuerySql = { name: string; querySql: string };
export type ExecutionScript = { executionScript: ExecutionScriptObject };
export type ExecutionScriptObject = {
  script: string;
  evalStrategy: Strategy;
};

export type ExecutionConfig = { executionConfig: ExecutionConfigObject };
export type ExecutionConfigObject = {
  fields: ExecutionField[];
  evalStrategy: Strategy;
};

export type ExecutionField = {
  name: string;
  assignment: Assignment;
};

export type FactorLogicExecutionRuleMetadata = {
  externalId?: string;
  name?: string;
  description?: string;
  categoryId?: FactorCategoryId;
  categoryName?: FactorCategoryNameType;
  groupId?: string;
  groupName?: string;
  builtIn?: boolean;
  recommendation?: string;
  note?: string;
  includeInEffectiveScore?: boolean;
  weight?: number;
  rawScore?: number;
  normalizedScore?: number;
  effectiveNormalizedScore?: number;
  relatedInsightsIds: number[];
  prodSkuIds?: string[];
  executionRuleId?: string;
  researchStatus?: ResearchStatus;
  tags?: string[];
  isGroup?: boolean;
  type: FactorType;
  insightsIds: number[];
  scoreType: ScoreType;
  dependantSources: string[];
};

export enum ScoreType {
  RATIO = 'RATIO',
  VALUE = 'VALUE',
}

export enum FactorType {
  GROUP_FACTOR = 'GROUP_FACTOR',
  SUB_FACTOR = 'SUB_FACTOR',
  STANDALONE_FACTOR = 'STANDALONE_FACTOR',
}

export const FactorTypeNames = {
  [FactorType.GROUP_FACTOR]: 'Group Factor',
  [FactorType.SUB_FACTOR]: 'Sub Factor',
  [FactorType.STANDALONE_FACTOR]: 'Standalone',
};

export enum FactorRelatedEntity {
  '3RD' = '3rd Parties',
  APPLICATIONS = 'Applications',
  ASSETS = 'Assets',
  WORKFORCE = 'Workforce',
}

export const factorRelatedEntitiesIcon = (size = 16) => ({
  [FactorRelatedEntity['3RD']]: <ThirdParty style={iconSize(size)} />,
  [FactorRelatedEntity.APPLICATIONS]: <Applications style={iconSize(size)} />,
  [FactorRelatedEntity.ASSETS]: <Assets style={iconSize(size)} />,
  [FactorRelatedEntity.WORKFORCE]: <User style={iconSize(size)} />,
});

export enum FactorCategoryId {
  EXTERNAL_ATTACK_SURFACE = '1',
  COMPORMISE = '2',
  LATERAL_PROPAGATION = '3',
  DATA_LOSS = '4',
}
export const FactorCategory = {
  [FactorCategoryId.COMPORMISE]: 'Compromise',
  [FactorCategoryId.DATA_LOSS]: 'Data Loss',
  [FactorCategoryId.EXTERNAL_ATTACK_SURFACE]: 'External Attack Surface',
  [FactorCategoryId.LATERAL_PROPAGATION]: 'Lateral Propagation',
};

export type FactorCategoryNameType = (typeof FactorCategory)[keyof typeof FactorCategory];

export const factorCategoryIcon = (size = 16) => ({
  [FactorCategoryId.COMPORMISE]: <NotPrivate style={iconSize(size)} />,
  [FactorCategoryId.DATA_LOSS]: <Data style={iconSize(size)} />,
  [FactorCategoryId.EXTERNAL_ATTACK_SURFACE]: <Network style={iconSize(size)} />,
  [FactorCategoryId.LATERAL_PROPAGATION]: <Sorting style={iconSize(size)} />,
});

export enum ResearchStatus {
  ENABLED = 'ENABLED',
  MODELING = 'MODELING',
  DISABLED = 'DISABLED',
}

export const ResearchStatusName = {
  [ResearchStatus.ENABLED]: 'Enabled',
  [ResearchStatus.MODELING]: 'Modeling',
  [ResearchStatus.DISABLED]: 'Disabled',
};

export const prodSkuIds = [
  'PROD-ZIA',
  'PROD-ZIA-ATP',
  'PROD-ZIA-CLD-APP-CTRL',
  'PROD-ZS-PSTR-CTRL',
  'PROD-ZPA',
  'PROD-DLP-ESS',
  'PROD-DLP-ZIA-CASB-1-APP',
  'PROD-ZS-DP-PVT-APPS',
  'PROD-ZIA-CASB-ALL-APPS',
  'PROD-ZIA-CASB-APP-APPS',
  'PROD-ZIA-SANDBOX',
  'PROD-ZIA-ISO-STD',
  'PROD-ZIA-ISO5',
  'PROD-ZIA-FIREWALL',
  'PROD-ZIA-SSL',
  'PROD-ZDX-UCAAS',
  'PROD-ZIA-IPS-CTRL',
  'PROD-ZPA-PVT-SVC-EDGE',
  'PROD-ZPA-APP-PROT',
  'PROD-ZS-DECEPTION-STD',
  'PROD-ZIA-DLP-EDM',
  'PROD-ZS-DP-INLINE-WEB',
  'PROD-ZPA-ISO-STD',
  'PROD-ZIA-SERVER-GB',
  'ZS-DP-SAAS-3PA-PRE',
  'PROD-ZS-DP-APP-TOTAL',
  'PROD-ZS-DP-SAAS-API',
  'PROD-ZS-DP-CLD-APP-CTRL',
  'PROD-ZS-DP-SAAS-SEC-CASB',
  'PROD-ZS-DP-INLINE',
  'PROD-ZS-DP-ISO-PVT-USERS',
  'PROD-ZIA-CYBER-SANDBOX',
  'PROD-ZIA-CYBER-ISO',
  'PROD-ZIA-CYBER-FIREWALL',
  'PROD-ZIA-CYBER-FIREWALL-RULES',
  'PROD-ZPA-PSE-VIRTUAL',
  'PROD-ZS-RISK-DECEPTION',
  'PROD-ZS-DP-ISO',
  'PROD-ZS-CON-WKLDS-DATA-STD',
  'PROD-ZS-CON-WKLDS-ZIA-DATA',
  'PROD-ZS-DP-SAAS-SEC-APPTOTAL',
];

export const defaultCelPerType = {
  [FactorType.GROUP_FACTOR]: `io.avalor.protocol.entities.v2.Factor {
    name: metadata.name,
    description: metadata.description,
    execution_rule_id: metadata.executionRuleId,
    built_in: metadata.builtIn,
    category_id: metadata.categoryId,
    category_name: metadata.categoryName,
    group_id: metadata.groupId,
    recommendation: metadata.recommendation,
    note: metadata.note,
    type: metadata.type,
    tags: has(metadata.tags) ? metadata.tags : [],
    insights_ids: metadata.insightsIds, // remove if not relevant
    external_id: metadata.externalId,
    prod_sku_ids: has(metadata.prodSkuIds) ? metadata.prodSkuIds : [],
    related_insights_ids: has(metadata.relatedInsightsIds) ? metadata.relatedInsightsIds : [],
    is_licensed: size(licenses) > 0 && has(metadata.prodSkuIds) ? metadata.prodSkuIds.all(x, x in licenses.map(x, x['sku_id'])) : false,
    research_status: metadata.researchStatus,
    score_type: metadata.scoreType,
    dependant_sources: metadata.dependantSources.map(x, x),
    change_event: metadata.changeEvent,
    reason: has(metadata.reason) ? metadata.reason : "",
    include_in_effective_score: metadata.includeInEffectiveScore,
}`,
  [FactorType.SUB_FACTOR]: `io.avalor.protocol.entities.v2.Factor {
    name: metadata.name,
    description: metadata.description,
    execution_rule_id: metadata.executionRuleId,
    built_in: metadata.builtIn,
    category_id: metadata.categoryId,
    category_name: metadata.categoryName,
    group_id: metadata.groupId,
    recommendation: metadata.recommendation,
    tags: has(metadata.tags) ? metadata.tags : [],
    note: metadata.note,
    weight: metadata.weight,
    type: metadata.type,
    insights_ids: metadata.insightsIds, // remove if not relevant
    external_id: metadata.externalId,
    prod_sku_ids: has(metadata.prodSkuIds) ? metadata.prodSkuIds : [],
    related_insights_ids: has(metadata.relatedInsightsIds) ? metadata.relatedInsightsIds : [],
    is_licensed: size(licenses) > 0 && has(metadata.prodSkuIds) ? metadata.prodSkuIds.all(x, x in licenses.map(x, x['sku_id'])) : false,
    research_status: metadata.researchStatus,
    raw_score: 0.0,
    score_type: metadata.scoreType,
    dependant_sources: metadata.dependantSources.map(x, x),
    change_event: metadata.changeEvent,
    reason: has(metadata.reason) ? metadata.reason : "",
    include_in_effective_score: metadata.includeInEffectiveScore,
}`,
  [FactorType.STANDALONE_FACTOR]: `io.avalor.protocol.entities.v2.Factor {
    name: metadata.name,
    description: metadata.description,
    execution_rule_id: metadata.executionRuleId,
    built_in: metadata.builtIn,
    category_id: metadata.categoryId,
    category_name: metadata.categoryName,
    recommendation: metadata.recommendation,
    tags: has(metadata.tags) ? metadata.tags : [],
    note: metadata.note,
    weight: metadata.weight,
    type: metadata.type,
    insights_ids: metadata.insightsIds, // remove if not relevant
    external_id: metadata.externalId,
    prod_sku_ids: has(metadata.prodSkuIds) ? metadata.prodSkuIds : [],
    related_insights_ids: has(metadata.relatedInsightsIds) ? metadata.relatedInsightsIds : [],
    is_licensed: size(licenses) > 0 && has(metadata.prodSkuIds) ? metadata.prodSkuIds.all(x, x in licenses.map(x, x['sku_id'])) : false,
    research_status: metadata.researchStatus,
    raw_score: 0.0,
    score_type: metadata.scoreType,
    dependant_sources: metadata.dependantSources.map(x, x),
    change_event: metadata.changeEvent,
    reason: has(metadata.reason) ? metadata.reason : "",
    include_in_effective_score: metadata.includeInEffectiveScore,
}`,
};

export type AnyQuery = ExecutableQueryObject | ExecutableQuerySql;
export enum FactorKind {
  ACCOUNT = 'executionRuleDto',
  BUILT_IN = 'builtInExecutionRuleDto',
}
export enum FactorLogicType {
  Advanced = 'executionConfig',
  Simple = 'executionScript',
}
export type DefaultExecutionConfigFieldsMapType = {
  recommendation: string;
  note: string;
  raw_score?: string;
  is_licensed: string;
  raw_zscore?: string;
  last_seen?: string;
};
