import { DateCondition, Filter } from '../../../types/filter.types';
import { DwQueryRequest, SortDir } from '../../CustomDashboards/types';

const policyPopProjName = 'uber_policy_populations';

export const getTrendQuery = (breakDown: string, timeRange: DateCondition, filter?: Filter): DwQueryRequest => ({
  filter,
  select: {
    dims: [
      {
        name: breakDown,
      },
      {
        name: 'policy_population.policy_id',
        alias: 'policyId',
      },
      {
        name: 'policy_population.policy_name',
        alias: 'policyName',
      },
    ],
    metrics: [
      {
        name: 'policy_compliance_rate_granular',
        alias: 'policy_compliance_rate_granular',
      },
    ],
  },
  groupBy: [breakDown],
  sorting: [
    {
      name: 'policyId',
      dir: SortDir.ASC,
    },
    {
      name: breakDown,
      dir: SortDir.ASC,
    },
  ],
  top: {
    size: 800,
    groupOthers: false,
    offset: 0,
  },
  projectionId: {
    name: policyPopProjName,
    builtIn: true,
  },
  distinct: true,
  timeRange,
});

export const getProgressQuery = (filter?: Filter): DwQueryRequest => ({
  filter,
  select: {
    dims: [
      {
        name: 'policy_population.policy_id',
        alias: 'policyId',
      },
      {
        name: 'policy_population.policy_name',
        alias: 'policyName',
      },
    ],
    metrics: [
      {
        name: 'policy_compliance_rate_granular',
        alias: 'policy_compliance_rate_granular',
      },
      {
        name: 'policy_pass_count_granular',
        alias: 'policy_pass_count_granular',
      },
      {
        name: 'policy_asset_population_granular',
        alias: 'policy_asset_population_granular',
      },
    ],
  },
  sorting: [],
  top: {
    size: 100,
    groupOthers: false,
    offset: 0,
  },
  projectionId: {
    name: policyPopProjName,
    builtIn: true,
  },
  distinct: true,
  metricsFilterThreshold: 0,
});

export const getTableQuery = (breakdownField?: string, filter?: Filter, sortField?: string, sortDir?: SortDir) => ({
  filter,
  groupBy: breakdownField && breakdownField !== 'null' ? [breakdownField] : [],
  distinct: false,
  source: null,
  metricsFilterThreshold: 0,
  select: {
    metrics: [
      {
        name: 'policy_pass_count_granular',
        alias: 'policy_pass_count_granular',
      },
      {
        name: 'policy_fail_count_granular',
        alias: 'policy_fail_count_granular',
      },
      {
        name: 'policy_asset_population_granular',
        alias: 'policy_asset_population_granular',
      },
      {
        name: 'policy_compliance_rate_granular',
        alias: 'policy_compliance_rate_granular',
      },
    ],
    dims:
      breakdownField && breakdownField !== 'null'
        ? [
            {
              name: breakdownField,
            },
          ]
        : [],
  },
  sorting: sortDir && sortField ? [{ name: sortField, dir: sortDir }] : [],
  top: {
    size: 100,
    groupOthers: false,
    offset: 0,
  },
  projectionId: {
    name: policyPopProjName,
    builtIn: true,
  },
});
