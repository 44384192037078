import { ArrayResolution } from '../../../types/filter.types';
import { AggregationFunction, Metric } from '../../../types/QueryObjectProto.types';

export const getFieldFilterAndAggMetric = (sourceNames: string[]): Metric => ({
  agg: {
    aggName: sourceNames.join(', '),
    args: [{ stringValue: 'asset._key' }],
    function: AggregationFunction.DISTINCT_COUNT,
    filter: {
      and: {
        operands: [
          {
            and: {
              operands: sourceNames.map(sourceName => ({
                expression: {
                  arrayCondition: {
                    resolution: ArrayResolution.ANY,
                    underlying: { fieldName: 'asset.source_names', stringCondition: { equals: sourceName } },
                  },
                },
              })),
            },
          },
        ],
      },
    },
  },
});

export enum ScreenSize {
  xSmall,
  small,
  Medium,
}
export const sizeMap: Record<string, number> = {
  [ScreenSize.Medium]: 350,
  [ScreenSize.small]: 325,
};
export const maxSourcesNum = 3;

export const TOOL_COVERAGE_AND_GAP_GRID_LAYOUT = {
  [ScreenSize.xSmall]: [
    { i: 'a', x: 0, y: 0, w: 6, h: 10, static: true },
    { i: 'b', x: 6, y: 0, w: 6, h: 10, static: true },
  ],
  [ScreenSize.small]: [
    { i: 'a', x: 0, y: 0, w: 6, h: 10, static: true },
    { i: 'b', x: 6, y: 0, w: 6, h: 10, static: true },
  ],
  [ScreenSize.Medium]: [
    { i: 'a', x: 0, y: 0, w: 6, h: 10, static: true },
    { i: 'b', x: 6, y: 0, w: 6, h: 10, static: true },
  ],
};

export const defaultFilter = {
  vennSources: [],
};

export const getTextWidth = (text, fontSize, fontFamily) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context!.font = `${fontSize}px ${fontFamily}`;
  return context?.measureText(text).width;
};
