import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { QueryKey } from '../../utils/queryKey';
import { CustomRole } from './types';

export const useCustomRolesList = (filterFunc = v => v, allowCache = false) => {
  const { api } = useAvContext();
  const { data = [], ...rest } = useQuery<CustomRole[]>({
    queryKey: [QueryKey.CustomRolesList, allowCache.toString()],
      queryFn: () => api(GET_CUSTOM_ROLES, { onSuccess: ({ data, errors }) => (errors ? undefined : data.findAllUserRoles) }),
    gcTime: allowCache ? undefined : 0,
  });
  const filteredRoles = useMemo(() => data.filter(filterFunc), [filterFunc, data]);
  return { ...rest, data: filteredRoles };
};

export const useCustomRole = ({ id, onSuccess }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.FindCustomRoleById, id],
    queryFn: () => api(GET_CUSTOM_ROLE, { options: { id }, onSuccess: ({ data }) => onSuccess(data?.findUserRoleById) || [] }),
    enabled: !!id,
    gcTime: 0,
  });
};

export const useEditCustomRole = ({ isEditMode, onSuccess }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: ({ customRole }: { customRole: CustomRole }) =>
      api(isEditMode ? UPDATE_CUSTOM_ROLE : CREATE_CUSTOM_ROLE, {
        options: { userRoleDto: customRole },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
        onSuccess,
      }),
  });
};

export const useDuplicateRole = onSuccess => {
  const { api } = useAvContext();
  return useMutation({ mutationFn: (id: string) => api(DUPLICATE_ROLE, { options: { id }, onSuccess }) });
};

export const useDeleteRoles = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (ids: string[]) =>
      api(DELETE_CUSTOM_ROLE, {
        options: { ids },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
      }).then(onSuccess),
  });
};

const onErrorSnackBar = (enqueueSnackbar, err) => enqueueSnackbar(`${err.message}`, { variant: 'error' });

const GET_CUSTOM_ROLES = gql`
  query {
    findAllUserRoles {
      id
      name
      accountId
      rolePermissions
      users
      predefined
      createdByUserId
      updatedByUserId
      updatedAt
    }
  }
`;

const CREATE_CUSTOM_ROLE = gql`
  mutation CreateCustomRole($userRoleDto: UserRoleInput!) {
    createUserRole(userRoleDto: $userRoleDto) {
      id
    }
  }
`;

const UPDATE_CUSTOM_ROLE = gql`
  mutation UpdateCustomRole($userRoleDto: UserRoleInput!) {
    updateUserRole(userRoleDto: $userRoleDto) {
      id
    }
  }
`;

export const DELETE_CUSTOM_ROLE = gql`
  mutation DeleteCustomRole($ids: [String!]!) {
    deleteUserRoleByIds(ids: $ids)
  }
`;

const GET_CUSTOM_ROLE = gql`
  query GetCustomRoleById($id: String!) {
    findUserRoleById(id: $id) {
      id
      name
      accountId
      predefined
      rolePermissions
      users
    }
  }
`;

export const DUPLICATE_ROLE = gql`
  mutation ($id: String!) {
    duplicateUserRole(id: $id) {
      name
    }
  }
`;
