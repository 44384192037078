import { DateCondition } from '../../../types/filter.types';
import {
  ColoringRules,
  ColumnFormatter,
  DwQueryRequest,
  SortDir,
  TableRowSize,
  TableSubType,
  TableTypeWidget,
  TileSubType,
  TileTypeWidget,
  TileWidgetVisualization,
  TrendTypeWidget,
  WidgetCategory,
} from '../../CustomDashboards/types';
import { policyPopProjName } from './utils';

export const getPolicyTrendWidget = (breakDown: string, timeRange: DateCondition): TrendTypeWidget => ({
  id: '',
  type: TileSubType.Trend,
  category: WidgetCategory.Tile,
  drillDownHierarchy: {
    fields: [],
    activeIndex: 0,
    drillDownValues: [],
  },
  requests: [
    {
      groupBy: [breakDown],
      distinct: false,
      metricsFilterThreshold: 0,
      select: {
        metrics: [
          {
            name: 'policy_compliance_rate_granular',
            alias: 'policy_compliance_rate_granular',
          },
        ],
        dims: [
          {
            name: breakDown,
          },
        ],
      },
      sorting: [
        {
          name: breakDown,
          dir: SortDir.ASC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
        offset: 0,
      },
      projectionId: {
        name: policyPopProjName,
        builtIn: true,
      },
      timeRange,
    },
  ],
  definition: {
    title: {
      title: '',
    },
    coords: {
      w: 4,
      h: 3,
      x: 4,
      y: 4,
    },
    custom: {
      coloringRule: ColoringRules.UP_IS_GOOD,
      visualization: TileWidgetVisualization.None,
      completionDisplayName: 'Assets in compliance',
    },
  },
});
export const getPolicyProgressWidget = ({
  policyName,
  formattingRuleId,
}: {
  policyName: string;
  formattingRuleId?: string;
}): TileTypeWidget => ({
  id: policyName,
  type: TileSubType.Tile,
  category: WidgetCategory.Tile,
  drillDownHierarchy: {
    drillDownValues: [],
    fields: [],
    activeIndex: 0,
  },
  requests: [
    {
      filter: {
        and: {
          operands: [
            {
              expression: {
                fieldName: 'policy_population.policy_name',
                stringCondition: {
                  equals: policyName,
                },
              },
            },
          ],
        },
      },
      groupBy: [],
      distinct: false,
      metricsFilterThreshold: 0,
      select: {
        metrics: [
          {
            name: 'policy_compliance_rate_granular',
            alias: 'policy_compliance_rate_granular',
          },
        ],
        dims: [],
      },
      sorting: [
        {
          name: 'policy_compliance_rate_granular',
          dir: SortDir.ASC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
        offset: 0,
      },
      projectionId: {
        name: policyPopProjName,
        builtIn: true,
      },
    },
  ],
  definition: {
    title: {
      title: policyName,
    },
    coords: {
      w: 4,
      h: 3,
      x: 4,
      y: 4,
    },
    custom: {
      coloringRule: ColoringRules.UP_IS_GOOD,
      visualization: TileWidgetVisualization.Progress,
      completionDisplayName: 'Assets in compliance ',
      formattingRuleId,
    },
  },
});

export const getTableWidget = (request: DwQueryRequest, formattingRuleId: string | undefined): TableTypeWidget => ({
  id: '',
  type: TableSubType.Table,
  category: WidgetCategory.Table,
  drillDownHierarchy: {
    fields: [],
    activeIndex: 0,
    drillDownValues: [],
  },
  requests: [request],
  definition: {
    title: {
      title: '',
    },
    coords: {
      w: 6,
      h: 4,
      x: 4,
      y: 7,
    },
    custom: {
      rowSize: TableRowSize.Small,
      fieldStyle: {
        policy_compliance_rate_granular: {
          columnFormatter: ColumnFormatter.Progress,
          formattingRuleId,
        },
      },
      crossTableView: false,
    },
  },
});
