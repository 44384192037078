import { Filter } from '../../types/filter.types';
import { MeasurementUnit } from '../Reports/types';

export type FormattingRule = {
  id?: string;
  name: string;
  description?: string;
  type: MeasurementUnit;
  formattingConditions: FormattingCondition[];
};

export type FormattingCondition = {
  id?: string;
  color: string;
  filter?: Filter;
};

export const endAdornmentMeasurementUnit = {
  [MeasurementUnit.Percentage]: '%',
  [MeasurementUnit.Days]: 'D',
  [MeasurementUnit.Hours]: 'H',
};
