import { PeriodBehavior } from '../../../components/DatePicker/AvDateRangePicker.constants';
import { PolicyType } from '../../../types/executionRules.types';
import { TimeBucket } from '../../Reports/types';

export const policyPopProjName = 'uber_policy_populations';
export const expandedSuffix = '_expanded';
export const breakdownSuffix = '_breakdown';
export const sortSuffix = '_sort';

const defaultTimeRange = {
  relative: {
    periodBehaviour: PeriodBehavior.last,
    value: 3,
    unit: 'MONTHS',
  },
};

export const defaultFilter = {
  dateGap: [TimeBucket.BUCKET_WEEK],
  dateObject: [JSON.stringify(defaultTimeRange)],
};

export const getFormattingRuleIdByPolicyId = (policies: PolicyType[], policyId: string) =>
  (policies.find(v => v.id === policyId) as PolicyType)?.clientConfig?.formattingRule?.id;
