import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useCustomSearchParams } from '../../../hooks/UseCustomSearchParams';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import FindCoverageGapsWidget from './FindCoverageGapsWidget';
import InteractiveVenn from './InteractiveVenn';
import sourcesQuery from './Queries';
import { defaultFilter, ScreenSize, TOOL_COVERAGE_AND_GAP_GRID_LAYOUT } from './utils';

const filtersKeys = ['coverageGapsSource', 'lastDaysNumber'];
const commonSx = {
  bgcolor: theme => theme.palette.colors.neutrals[100],
  boxShadow: '0px 0px 7px 0px rgba(57, 58, 86, 0.04)',
};
const ReactGridLayout = WidthProvider(RGL);

const ToolCoverageAndGap = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));
  const isXsmallScreen = useMediaQuery(theme.breakpoints.down(1310));
  const screenSize = isXsmallScreen ? ScreenSize.xSmall : isSmallScreen ? ScreenSize.small : ScreenSize.Medium;

  const colorScheme = [theme.palette.colors.blue[500], theme.palette.colors.purple![400], theme.palette.colors.primary[500]];
  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter,
    shouldBeArray: key => !filtersKeys.includes(key),
  });

  const { isLoading: isLoadingDataSources, data: dataSources = [] } = useQueryObject({
    queryObject: sourcesQuery,
  });

  const onFilterChange = newFilters => {
    setFilters({ ...filters, ...newFilters });
  };

  const { vennSources = [], coverageGapsSource, lastDaysNumber } = filters;

  return (
    <Box sx={{ overflow: 'scroll' }}>
      <Typography variant="h3">Tool Coverage And Gap</Typography>
      <ReactGridLayout
        useCSSTransforms={false}
        className="layout"
        margin={[12, 12]}
        layout={TOOL_COVERAGE_AND_GAP_GRID_LAYOUT[screenSize]}
        cols={12}
        rowHeight={35}>
        <Box key="a" sx={commonSx}>
          <FindCoverageGapsWidget
            sourceSelectOptions={dataSources}
            isLoadingValueOptions={isLoadingDataSources}
            onChangeSource={v => onFilterChange({ coverageGapsSource: v })}
            onChangeLastDays={v => onFilterChange({ lastDaysNumber: v })}
            sourceSelectValue={coverageGapsSource}
            lastDaysNumber={lastDaysNumber !== 'null' ? lastDaysNumber : 0}
          />
        </Box>
        <Box key="b" sx={{ ...commonSx, p: 2 }}>
          <InteractiveVenn
            onChange={onFilterChange}
            interactiveVennSources={vennSources}
            colorScheme={colorScheme}
            valueOptions={dataSources}
            isLoadingValueOptions={isLoadingDataSources}
            screenSize={screenSize}
          />
        </Box>
      </ReactGridLayout>
    </Box>
  );
};

export default ToolCoverageAndGap;
