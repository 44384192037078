import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { loadingPage } from '../App';
import { useAuth } from '../components/amplifyProvider';
import { useNetworkStatus } from '../context/AvQueryProvider';
import useApi from '../utils/useApi';
import { useAccessToken, useUserActivityStatus } from './auth';
import useKnowledgeBase from './useKnowledgeBase';

interface ZendeskAuthenticationProps {
  children: any;
}

const ZendeskAuthentication: React.FC<ZendeskAuthenticationProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('return_to');
  const { isOnline } = useNetworkStatus();
  const { isAuthenticated, getAccessToken: getAccessTokenSilently, user } = useAuth();
  const userIsActive = useUserActivityStatus();
  const { data: accessToken, refetch: refetchAccessToken } = useAccessToken(
    getAccessTokenSilently,
    isAuthenticated,
    user?.accountId,
    isOnline,
    userIsActive
  );
  const api = useApi(false, user?.accountId, refetchAccessToken, {});
  const openKB = useKnowledgeBase({
    redirect: redirect || undefined,
    newPage: false,
    overrideAccountId: true,
    accountId: user?.accountId,
    api,
  });

  useEffect(() => {
    if (accessToken) {
      openKB();
    }
  }, [user?.accountId, accessToken, isAuthenticated]);

  return isAuthenticated ? loadingPage() : children;
};

export default ZendeskAuthentication;
