import { gql } from '@apollo/client';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ResponseHandler } from '../../components/formInputTypes';
import { useAvContext } from '../../context/AvContextProvider';
import { RunInfo } from '../../context/AvSnackBarProvider';
import { QueryKey } from '../../utils/queryKey';
import { DatastoreMappingItem, TGateway, ZscalerAccountConfigObject } from './types';

export const useAccountDatastoreMapping = accountId => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.FindAccountDatastoresMapping, accountId],
    queryFn: () => api(GET_ACCOUNT_DATASTORE_MAPPING).then(({ data }) => data?.findAccountDatastoresMapping),
  });
};

export const useAvailableServiceNames = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.GetAvailableServiceNames],
    queryFn: () => api(GET_SERVICE_NAMES).then(({ data }) => data?.getAvailableServiceNames),
  });
};

export const useAvailableDatastoreCategories = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.GetAvailableDatastoreCategories],
    queryFn: () => api(GET_CATEGORIES_NAMES).then(({ data }) => data?.getAvailableDatastoreCategories),
  });
};

export const UseDatastoreMappingMutation = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: updateDatastoreMapping } = useMutation({
    mutationFn: (accountDatastoreMapping: DatastoreMappingItem) =>
      api(UPDATE_DATASTORE_MAPPING, {
        options: { accountDatastoreMapping },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to update mapping ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Updated mapping successfully ', { variant: 'success' });
        onSuccess();
      }),
  });

  const { mutateAsync: createDatastoreMapping } = useMutation({
    mutationFn: (accountDatastoreMapping: DatastoreMappingItem) =>
      api(CREATE_DATASTORE_MAPPING, {
        options: { accountDatastoreMapping },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to create mapping ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Created mapping successfully ', { variant: 'success' });
        onSuccess();
      }),
  });

  const { mutateAsync: deleteDatastoreMapping } = useMutation({
    mutationFn: (accountDatastoreMappingId: string | undefined) =>
      api(DELETE_DATASTORE_MAPPING, {
        options: { accountDatastoreMappingId },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to delete mapping ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Deleted mapping successfully ', { variant: 'success' });
        onSuccess();
      }),
  });

  return { updateDatastoreMapping, createDatastoreMapping, deleteDatastoreMapping };
};

export const useGetSaml = ({ domain, onSuccess, options }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.SamlDetails, domain],
    queryFn: () =>
      api(GET_SAML_CREDS, { options: { emailDomain: domain } }).then(({ data }) => {
        if (data?.getSamlCreds) {
          onSuccess(data?.getSamlCreds);
        }
      }),
    ...options,
  });
};

export const useEditAccount = ({ isEditMode }) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ accountDto, isFlat, onError, onSuccess }: { accountDto; isFlat; onError; onSuccess }) =>
      api(isEditMode ? UPDATE_ACCOUNT : CREATE_ACCOUNT, { options: { accountDto, isFlat }, muteErrors: true, onSuccess }).catch(onError),
  });
};

export const useZendesk = ({ domain, onSuccess, options }) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: () => api(`auth-config/zendesk?domain=${domain}`, { options: { method: 'POST' } }).then(onSuccess, options),
  });
};

export const useGetAccount = ({
  id,
  onSuccess = data => data,
  options,
}: {
  id: string;
  onSuccess?: (data: any) => any;
  options?: object;
}) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.FindAccountById, id],
    queryFn: () =>
      api(GET_ACCOUNT, { options: { id } })
        .then(({ data }) => data?.findAccountById)
        .then(onSuccess),
    ...options,
  });
};

export const useGetGateways = ({ onSuccess = d => d, options = {} }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.Gateways],
    queryFn: () => api(GET_GATEWAYS).then(({ data }) => onSuccess(data.getGateways)),
    gcTime: 0,
    placeholderData: keepPreviousData,
    ...options,
  });
};

export const useGatewayMutation = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: updateGateway } = useMutation({
    mutationFn: (gatewayDto: TGateway) =>
      api(UPDATE_GATEWAY, {
        options: { gatewayDto },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to update gateway ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Updated gateway successfully ', { variant: 'success' });
        onSuccess();
      }),
  });

  const { mutateAsync: createGateway } = useMutation({
    mutationFn: (gatewayDto: TGateway) =>
      api(CREATE_GATEWAY, {
        options: { gatewayDto },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to create gateway ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Created gateway successfully ', { variant: 'success' });
        onSuccess();
      }),
  });

  const { mutateAsync: deleteGateway } = useMutation({
    mutationFn: (id: string | undefined) =>
      api(DELETE_GATEWAYS, {
        options: { id },
        isWebserver: false,
        onError: () => enqueueSnackbar('Failed to delete gateway ', { variant: 'error' }),
      }).then(() => {
        enqueueSnackbar('Deleted gateway successfully ', { variant: 'success' });
        onSuccess();
      }),
  });

  return { updateGateway, createGateway, deleteGateway };
};

export const validateBeforeSaveZscalerConfig = config => {
  const newConfig: ZscalerAccountConfigObject = { zpaTenantsConfigs: [], ziaTenantsConfigs: [] };
  if (config.ziaTenantsConfigs?.[0]?.cloudName || config.ziaTenantsConfigs?.[0]?.companyId) {
    newConfig.ziaTenantsConfigs = config.ziaTenantsConfigs;
  }
  if (config.zpaTenantsConfigs?.[0]?.cloudName || config.zpaTenantsConfigs?.[0]?.tenantId) {
    newConfig.zpaTenantsConfigs = config.zpaTenantsConfigs;
  }
  if (config.zscmAccountId) {
    newConfig.zscmAccountId = config.zscmAccountId;
  }
  return newConfig;
};

export const validateBeforeCreateZscalerConfig = config => {
  const newConfig = config;
  if (!config) {
    return defaultZscalerConfig;
  }
  if (config.ziaTenantsConfigs?.length === 0) {
    newConfig.ziaTenantsConfigs = defaultZscalerConfig.ziaTenantsConfigs;
  }
  if (config.zpaTenantsConfigs?.length === 0) {
    newConfig.zpaTenantsConfigs = defaultZscalerConfig.zpaTenantsConfigs;
  }
  if (!config.zscmAccountId) {
    newConfig.zscmAccountId = defaultZscalerConfig.zscmAccountId;
  }
  return newConfig;
};

export const defaultZscalerConfig = {
  zscmAccountId: '',
  ziaTenantsConfigs: [{ cloudName: '', companyId: '' }],
  zpaTenantsConfigs: [{ cloudName: '', tenantId: '' }],
};

interface Props {
  reset: VoidFunction;
  handleResponse?: ResponseHandler;
  setRunInfo: (runInfo: RunInfo, shouldDelete?: boolean | undefined) => void;
  onSuccessNext?: VoidFunction;
  setResponseValues?: (data: any) => void;
}

export const useOnSuccessAccountManagement = ({ reset, handleResponse, setRunInfo, onSuccessNext, setResponseValues }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return res => {
    enqueueSnackbar(`Successfully Saved`, { variant: 'success' });
    reset();
    if (handleResponse === ResponseHandler.NONE) {
      return;
    }
    const resKey = Object.keys(res.data)[0];
    if (handleResponse === ResponseHandler.WORKFLOW) {
      if (res.data[resKey].workflowRun.runId && res.data[resKey].workflowRun.id) {
        setRunInfo({
          runId: res.data[resKey].workflowRun.runId,
          wfId: res.data[resKey].workflowRun.id,
          returnStatusOnly: true,
          onSuccess: onSuccessNext,
        });
      }
    } else if (handleResponse === ResponseHandler.ADD_ACCOUNT) {
      queryClient.refetchQueries({ queryKey: ['userAdditionalInfo'] });
    } else if (handleResponse === ResponseHandler.API_ACCESS) {
      if (setResponseValues) {
        setResponseValues(res.data[resKey]);
      }
    }
  };
};

export const GET_ACCOUNT = gql`
  query findAccountById($id: String!) {
    findAccountById(id: $id) {
      id
      name
      logo
      industry
      accountType
      authenticationType
      accountLimits {
        assetCount
        revenueId
        maxDomains
      }
      emailDomain
      xmlMetadata
      identityProviderName
      zendeskOrganizationId
      apps {
        id
        name
      }
      idleTimeForLogout
      zscalerCustomerConfig {
        zscmAccountId
        ziaTenantsConfigs {
          cloudName
          companyId
        }
        zpaTenantsConfigs {
          cloudName
          tenantId
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($accountDto: AccountDtoInput!, $isFlat: Boolean) {
    createAccount(accountDto: $accountDto, isFlat: $isFlat) {
      workflowRun {
        id
        runId
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountDto: AccountDtoInput!) {
    updateAccount(accountDto: $accountDto) {
      workflowRun {
        id
        runId
      }
    }
  }
`;

export const GET_SAML_CREDS = gql`
  query getSamlCreds($emailDomain: String!) {
    getSamlCreds(emailDomain: $emailDomain) {
      entityId
      replyUrl
    }
  }
`;

export const GET_ACCOUNT_DATASTORE_MAPPING = gql`
  query {
    findAccountDatastoresMapping {
      id
      accountId
      datastoreCategory
      datastoreId
      serviceName
      datastoreConfig
    }
  }
`;

export const UPDATE_DATASTORE_MAPPING = gql`
  mutation ($accountDatastoreMapping: AccountDatastoreMappingDto!) {
    updateDatastoreMapping(accountDatastoreMapping: $accountDatastoreMapping) {
      id
      accountId
    }
  }
`;

export const CREATE_DATASTORE_MAPPING = gql`
  mutation ($accountDatastoreMapping: AccountDatastoreMappingDto!) {
    createAccountDatastoreMapping(accountDatastoreMapping: $accountDatastoreMapping) {
      id
      accountId
    }
  }
`;

export const DELETE_DATASTORE_MAPPING = gql`
  mutation ($accountDatastoreMappingId: String!) {
    deleteAccountDatastoreMappingById(accountDatastoreMappingId: $accountDatastoreMappingId)
  }
`;

export const GET_SERVICE_NAMES = gql`
  query {
    getAvailableServiceNames
  }
`;

export const GET_CATEGORIES_NAMES = gql`
  query {
    getAvailableDatastoreCategories
  }
`;

export const GET_FULL_LIST = gql`
  query {
    findAllAccounts {
      id
      name
      logo
      industry
      accountType
    }
  }
`;

export const GET_FILTERED_LIST = gql`
  query {
    findAllAccounts(useAccountRestrictedFilter: true) {
      id
      name
      logo
      industry
      accountType
    }
  }
`;

export const GET_FILTERED_LIST_RISK360 = gql`
  query {
    findAllRisk360Accounts(useAccountRestrictedFilter: true) {
      id
      name
      logo
      industry
      accountType
    }
  }
`;

export const GET_ALL_ETL_MONITORS_LIST = gql`
  query {
    findAllEtlMonitors {
      monitorName
      accountId
      id
    }
  }
`;

export const DELETE_ACCOUNTS = gql`
  mutation ($ids: [String!]!) {
    deleteAccountByIds(ids: $ids) {
      workflowRun {
        id
        runId
      }
    }
  }
`;

const GET_GATEWAYS = gql`
  query getGateways {
    getGateways {
      id
      name
      proxyDnsName
      publicKey
      privateDnsName
    }
  }
`;

export const CREATE_GATEWAY = gql`
  mutation ($gatewayDto: createGatewayDtoInput!) {
    createGateway(gatewayDto: $gatewayDto) {
      id
      name
      proxyDnsName
      publicKey
      privateDnsName
    }
  }
`;
export const UPDATE_GATEWAY = gql`
  mutation ($gatewayDto: GatewayDtoInput!) {
    updateGateway(gatewayDto: $gatewayDto) {
      id
      name
      proxyDnsName
      publicKey
      privateDnsName
    }
  }
`;

export const DELETE_GATEWAYS = gql`
  mutation ($id: String!) {
    deleteGateway(id: $id) {
      id
      name
      proxyDnsName
      publicKey
      privateDnsName
    }
  }
`;
