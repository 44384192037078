import React from 'react';
import { Route } from 'react-router-dom';
import { NotFound } from '../components/ErrorBoundary';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { APP_PATHS, FeatureFlags, PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';
import { entityViewConfig } from '../utils/entityViewConfig';
import { GroupingRuleSetTypes } from '../views/Settings/GroupingRules/types';
import RulesSetRoute from './RulesSetRoute';

const UiConfigSettings = avLazy(() => import('../views/Settings/UiConfigSettings/UiConfigSettings'));
const AuditLogsUiConfig = avLazy(() => import('../views/Settings/UiConfigSettings/AuditLogsUiConfig'));
const ScoreSettings = avLazy(() => import('../views/Settings/ScoreSettings/ScoreSettings'));
const ScoreSettingsAuditLogs = avLazy(() => import('../views/Settings/ScoreSettings/ScoreSettingsAuditLogs'));
const SeveritySla = avLazy(() => import('../views/Settings/SeveritySla'));
const ProjStatuses = avLazy(() => import('../views/Settings/TicketStatuses/ProjStatuses'));

const vulnerabilitesStatusProjectionOptions = [entityViewConfig.Ticket.projectionName, entityViewConfig.Exception.projectionName];

const VulnerabilitiesSettings = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
    featureFlags,
  } = useAvContext();
  const getHasAllowedPermission = path => hasAllowedPermission({ path, app: APP_PATHS.VULNERABILITIES });
  return (
    <>
      <Route path={PAGE_PATHS.SETTINGS}>
        <Route path="" element={<NotFound />} />
        {RulesSetRoute(GroupingRuleSetTypes.Ticket, APP_PATHS.VULNERABILITIES)}
        <Route
          path={PAGE_PATHS.SEVERITY_SLA}
          element={
            <ProtectedRoute isAllowed={getHasAllowedPermission(PAGE_PATHS.SEVERITY_SLA)}>
              <SeveritySla />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.TICKETS_STATUSES}
          element={
            <ProtectedRoute isAllowed={getHasAllowedPermission(PAGE_PATHS.TICKETS_STATUSES)}>
              <ProjStatuses
                projName={entityViewConfig.Ticket.projectionName}
                projOptions={featureFlags[FeatureFlags.Exceptions] ? vulnerabilitesStatusProjectionOptions : undefined}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.UI_CONFIG}
          element={
            <ProtectedRoute isAllowed={getHasAllowedPermission(PAGE_PATHS.UI_CONFIG)}>
              <UiConfigSettings projectionName={entityViewConfig.Ticket.projectionName} />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.SCORE}
          element={
            <ProtectedRoute isAllowed={getHasAllowedPermission(PAGE_PATHS.SCORE)}>
              <ScoreSettings />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={`${PAGE_PATHS.SCORE}/audit/:path`}
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <ScoreSettingsAuditLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${PAGE_PATHS.UI_CONFIG}/audit/:path`}
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <AuditLogsUiConfig />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default VulnerabilitiesSettings;
