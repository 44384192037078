import React, { useMemo } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { ErrorLoadingWidget, NoDataFoundWidget } from '../../../../components/Widgets/layout.components';
import { ChartClickInteraction } from '../../../../components/Widgets/types';
import { Filter } from '../../../../types/filter.types';
import { iconSize } from '../../../../utils/Utils';
import { useGetWidgetData } from '../../hooks';
import { TextSubType, WidgetCategory } from '../../types';
import { AllTypeWidgets } from '../../types/combined.types';
import { canRenderWidget } from '../../Utils';
import { useDrillDown } from '../Interactions/drillDown';
import Preview from '../widgetsPreviews/Preview';
import WidgetLoadingState from './WidgetLoadingState';
import { ReactComponent as Drilldown } from '../../../../assets/Drill down.svg';

interface WidgetProps {
  widget: AllTypeWidgets;
  setWidget?: (w) => void;
  globalFilters?: Filter;
  isEdit?: boolean;
  visible?: boolean;
  children: any;
  selected?: any;
  onSelect?: (v) => void;
  clickInteractions?: ChartClickInteraction[];
}

const defaultClickInteractions: ChartClickInteraction[] = [];

const Widget: React.FC<WidgetProps> = ({
  widget,
  setWidget,
  isEdit = false,
  children,
  visible = true,
  globalFilters,
  selected,
  onSelect,
  clickInteractions = defaultClickInteractions,
}) => {
  const {
    data: originalData,
    totals: originalTotals,
    totalRowCount,
    isLoading: isLoadingOriginalData,
    isRefetching,
    errors,
    isAsyncQuery,
    isDebouncing,
  } = useGetWidgetData({ widget, contextFilter: globalFilters, visible, debounceQueries: isEdit });
  const { drillDownData, isLoadingDrillDown, totalsDrillDown, activeIndex, onSelectedDrillDown, allDrillDownFields, newQueryObject } =
    useDrillDown({
      widget,
      setWidget,
      contextFilter: globalFilters,
      enable: [WidgetCategory.Bar, WidgetCategory.Pie].includes(widget.category),
    });

  const isCrossTable = widget.category === WidgetCategory.Table && widget.definition.custom.crossTableView;
  const isLoading = isLoadingOriginalData || (isLoadingDrillDown && !isCrossTable) || (isAsyncQuery && isDebouncing);
  const isPreview = !canRenderWidget({ widget });

  const data = useMemo(
    () => (activeIndex !== 0 && drillDownData && !isCrossTable ? drillDownData : originalData),
    [drillDownData, originalData, activeIndex]
  );

  const responseTotals = useMemo(
    () => (activeIndex !== 0 && totalsDrillDown && !isCrossTable ? totalsDrillDown : originalTotals),
    [totalsDrillDown, originalTotals, activeIndex]
  );

  if (isPreview) {
    return <Preview type={widget.type} />;
  }

  return (
    <ErrorBoundary fallbackComponent={<ErrorLoadingWidget text="Error loading the widget" />}>
      {(isLoading && !isRefetching) || (isRefetching && isAsyncQuery) ? (
        <WidgetLoadingState isAsync={isAsyncQuery} />
      ) : data.length || widget.type === TextSubType.Text ? (
        React.cloneElement(children, {
          ...(responseTotals && { responseTotals }),
          totalRowCount,
          data,
          isLoading,
          isEdit,
          selected,
          onSelect,
          globalFilters,
          widget:
            [WidgetCategory.Bar, WidgetCategory.Pie].includes(widget.category) && activeIndex !== 0
              ? { ...widget, requests: [newQueryObject] }
              : widget,
          clickInteractions: [
            ...(clickInteractions || []),
            ...(allDrillDownFields.length > 1 && [WidgetCategory.Bar, WidgetCategory.Pie].includes(widget.category)
              ? [
                  {
                    title: 'Drill Down',
                    onClick: onSelectedDrillDown,
                    isEnabledForCell: () =>
                      widget.requests[0].select.dims.length === 1 &&
                      widget.requests[0].select.metrics.length === 1 &&
                      !!allDrillDownFields[activeIndex + 1],
                    icon: <Drilldown style={iconSize(18)} />,
                  },
                ]
              : []),
          ],
        })
      ) : (
        <>
          {!!errors?.length && (
            <ErrorLoadingWidget
              // @ts-expect-error: errors is typed as any. should fix types
              text={errors[0]?.extensions?.message?.includes('query timed out') ? 'Oops, Query took too long to load' : ''}
            />
          )}
          {data?.length === 0 && !isLoading && errors?.length === 0 && <NoDataFoundWidget showImage />}
        </>
      )}
    </ErrorBoundary>
  );
};

export default Widget;
