import { DateCondition, Filter } from '../../../types/filter.types';
import { DwQueryRequest, SortDir } from '../../CustomDashboards/types';

const getAssetsOverTimeQuery = (
  timeRange: DateCondition,
  dateGap,
  filter?: Filter,
  secondDimName?: string,
  metricName: string = 'total_assets_granular'
): DwQueryRequest => ({
  groupBy: [dateGap, ...(secondDimName ? [secondDimName] : [])],
  distinct: false,
  filter,
  select: {
    metrics: [
      {
        name: metricName,
        alias: metricName,
      },
    ],
    dims: [
      {
        name: dateGap,
      },
      ...(secondDimName ? [{ name: secondDimName }] : []),
    ],
  },
  sorting: [
    {
      name: dateGap,
      dir: SortDir.ASC,
    },
  ],
  top: {
    size: 100,
    groupOthers: false,
    offset: 0,
  },
  projectionId: {
    name: 'uber_assets',
    builtIn: true,
  },
  timeRange,
});

export const getSideTileWidgetQuery = (metricName: string, timeRange: DateCondition, dateGap, filter?: Filter): DwQueryRequest => ({
  filter,
  select: {
    dims: [
      {
        name: dateGap,
      },
    ],
    metrics: [
      {
        name: metricName,
        alias: metricName,
      },
    ],
  },
  groupBy: [dateGap],
  sorting: [
    {
      name: dateGap,
      dir: SortDir.ASC,
    },
  ],
  top: {
    size: 100,
    groupOthers: false,
    offset: 0,
  },
  projectionId: {
    name: 'uber_assets',
    builtIn: true,
  },
  distinct: false,
  metricsFilterThreshold: 0,
  timeRange,
});

export default getAssetsOverTimeQuery;
