import { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQueries } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { ErrorTypes } from '../types/query.types';
import { cleanEmptyFilters } from '../utils/filterUtils';
import { generateUUID } from '../utils/Utils';
import { QueryResponse } from '../views/CustomDashboards/types/QueryObject.types';
import { UseQueryObjectProps } from './queryObjectAsync';
import useDebounce from './useDebounce';
import { CANCEL_QUERY } from './useQuerySql';

const QUERY = gql`
  query queryObject($req: Json!) {
    queryObject(value: $req) {
      rows {
        data
        id
      }
      totalRowCount
      totals {
        data
        id
      }
    }
  }
`;

export default function useQueryObject({
  queryObject,
  origin = 'INTERACTIVE_QUERY',
  onSuccess = d => d,
  onError = undefined,
  showTotals = false,
  totalRowCount = false,
  options = {},
  muteErrors = undefined,
  contextFilter,
  debounceQueries = false,
  searchQuery,
  aliasResolution = [],
}: UseQueryObjectProps): {
  isLoading: boolean;
  data: any;
  isPreviousData: boolean;
  isRefetching: boolean;
  totals?: Record<string, number> | (Record<string, number> | undefined)[];
  totalRowCount?: number;
  refetch: () => Promise<Awaited<any>[]>;
  errors: (any[] | undefined)[];
  isDebouncing: boolean;
} {
  const { api } = useAvContext();
  const { debouncedValue, isDebouncing } = useDebounce(
    {
      queriesObject: Array.isArray(queryObject) ? queryObject : [queryObject],
      options,
      showTotals,
      totalRowCount,
      contextFilter,
    },
    debounceQueries ? 500 : 0
  );

  const { mutate: cancel } = useMutation({ mutationFn: queryId => api(CANCEL_QUERY, { options: { queryId } }) });
  const uuids = useMemo(() => debouncedValue.queriesObject.map(generateUUID), [debouncedValue]);

  const cleanedContextFilter = useMemo(() => cleanEmptyFilters(debouncedValue.contextFilter), [debouncedValue.contextFilter]);
  const queries = debouncedValue.queriesObject.map((query, index) => ({
    queryKey: [
      JSON.stringify(debouncedValue.queriesObject[index]),
      JSON.stringify(cleanedContextFilter),
      JSON.stringify(debouncedValue.showTotals),
    ],
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      api(QUERY, {
        options: {
          req: {
            query,
            totals: { totalRow: debouncedValue.showTotals, totalRowCount: debouncedValue.totalRowCount },
            ...(origin && { origin }),
            contextFilter: cleanedContextFilter,
            aliasResolution,
            searchQuery,
            queryId: uuids[index],
          },
        },
        onError,
        signal,
        muteErrors,
      })
        .then(data => {
          const flattenedData = data?.data?.queryObject?.rows?.map(row => ({ ...row.data, id: row.id }));
          if (data === undefined || data.errors) {
            throw data?.errors || new Error(`${ErrorTypes.Network}: ${debouncedValue.queriesObject[index]}`);
          }
          return {
            data: flattenedData?.length ? onSuccess(flattenedData, index) : flattenedData,
            totals: data?.data?.queryObject?.totals?.data,
            totalRowCount: data?.data?.queryObject?.totalRowCount,
          };
        })
        .catch(e => {
          if (signal?.aborted) {
            cancel(uuids[index]);
          }
          if (muteErrors && !signal?.aborted) {
            onError?.(e);
          } else {
            throw e;
          }
        }),
    ...debouncedValue.options,
    enabled:
      (debouncedValue.options.enabled || debouncedValue.options.enabled === undefined) &&
      (!!query?.select?.dims?.length || !!query?.select?.metrics?.length),
  }));

  // @ts-ignore
  const results: {
    data: QueryResponse;
    refetch: () => Promise<any>;
    isLoading: boolean;
    isRefetching: boolean;
    isPreviousData: boolean;
    error?: any[];
  }[] = useQueries({ queries });
  const refetch = useCallback(() => Promise.all(results.map(r => r.refetch())), [results]);
  return useMemo(
    () => ({
      totalRowCount: !results.length ? undefined : results[0].data?.totalRowCount,
      totals: !results.length ? undefined : results.length === 1 ? results[0].data?.totals : results.map(({ data: { totals } }) => totals),
      data:
        !results.length || (queries.length > 1 && results.some(({ data: { data } }) => !data?.length))
          ? undefined
          : results.length === 1
            ? results[0].data?.data
            : results.map(({ data }) => data?.data),
      isLoading: results.some(
        result =>
          result.isLoading ||
          (isDebouncing && debouncedValue.queriesObject.find(query => !query?.select?.dims?.length && !query?.select?.metrics?.length))
      ),
      errors: results.map(result => result.error).filter(e => !!e) || [],
      refetch,
      isRefetching: results.some(
        result =>
          result.isRefetching ||
          (isDebouncing && debouncedValue.queriesObject.find(query => !!query?.select?.dims?.length || !!query?.select?.metrics?.length))
      ),
      isPreviousData: results.some(result => result.isPreviousData),
      isDebouncing,
    }),
    [results, isDebouncing]
  );
}
