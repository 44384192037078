import React from 'react';
import { Box, Link } from '@mui/material';
import AvCollapseContent from '../../../components/AvCollapseContent';
import AvTag from '../../../components/AvTag';
import { flex } from '../../../components/AvThemeProvider';
import { totalCellStyle } from '../../../components/Table/Utils';
import { SeverityLabels, VulCountBySeverity } from '../../../utils/severity.utils';
import { isEquals, isNullOrUndefined, isValidHttpUrl, refetchingStyle } from '../../../utils/Utils';

export const blurLoadingStyle = {
  height: '100%',
  flexShrink: 0,
  ...refetchingStyle,
  '.expanded-row, .expanded-row-content, .expanded-row-content .MuiTableCell-head': {
    backgroundColor: theme => theme.palette.colors.neutrals[200],
  },
  '.expanded-row-content > .MuiTableCell-root > div': {
    borderTop: theme => `${theme.palette.colors.neutrals[300]} 1px solid`,
  },
  '.expanded-row-content > .MuiTableCell-root': {
    pl: 3,
  },
};

export const fixTypeOptionsMap = {
  text: 'TEXT',
  version: 'VERSION',
  kb: 'KB',
};

export const pivotOptionsMap = {
  component: 'component',
  asset: 'asset',
  fix: 'fix',
};

export const filterNonFixes = '(CRITICAL > 0 OR HIGH > 0 OR MEDIUM > 0 OR LOW > 0 OR NONE > 0)';

export const severities = [SeverityLabels.Critical, SeverityLabels.High, SeverityLabels.Medium, SeverityLabels.Low, SeverityLabels.None];

export const ignoreList = ['key', 'fix', 'findings'];

export const getFixesSql = ({ fixType, headCells, where, isExport = false }) => {
  const filteredHeaders = getFilteredHeadCells(headCells).map(header => header.id);
  const fixAlias = isExport ? 'Fix' : 'fixValue';
  const isPivotView = filteredHeaders.length > 0;
  const pivotField = headCells[1].id;
  const isFix = pivotField === pivotOptionsMap.fix;
  const orderBy =
    isFix && !isPivotView && fixType === fixTypeOptionsMap.version
      ? [{ property: 'fix' }]
      : fixType === fixTypeOptionsMap.version
        ? [{ property: headCells[1].id }, { property: headCells[2].id }]
        : severities.map(s => ({ property: s }));
  const orderByClause = orderBy.length ? getOrderByClause({ orderByArray: orderBy, fixAlias }) : '';
  const sqlOrderMap = {
    fix: `finding.optimal_fix.fix as ${fixAlias}`,
    findings: severities
      .map(severity => {
        const fixQuery =
          fixType === fixTypeOptionsMap.version
            ? `COUNT_FIXED_IF(finding._key, ${fixAlias}, finding.severity = '${severity}' AND finding.optimal_fix.type = '${fixType}' AND finding.optimal_fix != null) as ${severity}\n`
            : `COUNT_DISTINCT_IF(finding._key, finding.severity='${severity}' AND finding.optimal_fix.type = '${fixType}' AND finding.optimal_fix != null) as ${severity}\n`;
        const totalQuery =
          isPivotView && !isExport
            ? fixType === fixTypeOptionsMap.version
              ? `,COUNT_FIXED_IF(finding._key, ${fixAlias}, finding.severity = '${severity}' AND finding.optimal_fix.type = '${fixType}',(${
                  isFix ? `${fixAlias}` : pivotField
                })) as TOTAL_${severity}\n`
              : `,COUNT_DISTINCT_IF(finding._key, finding.severity='${severity}',(${
                  isFix ? `${fixAlias}` : pivotField
                })) as TOTAL_${severity}\n`
            : '';
        const notKnownFixesQuery = isExport
          ? ''
          : ` ,COUNT_DISTINCT_IF(finding._key, finding.severity='${severity}' AND finding.optimal_fix.fix = null) AS NOT_KNOWN_${severity}\n`;
        return `${fixQuery} ${totalQuery} ${notKnownFixesQuery}`;
      })
      .join(', '),
    rest: filteredHeaders.length ? `${filteredHeaders.join(', ')}` : '',
  };
  const totalRowCount =
    isPivotView || isExport
      ? ''
      : fixType === fixTypeOptionsMap.version
        ? ',COUNT_FIXED(finding._key, fixValue) as totalRowCount'
        : ',COUNT_DISTINCT(finding._key) as totalRowCount';
  return `SELECT ${headCells
    .filter(h => h.id !== 'key')
    .map(h => sqlOrderMap[h.id] || sqlOrderMap.rest)
    .join(', ')}
    ${totalRowCount}
    FROM tickets
    ${where}
    GROUP BY ${fixAlias} ${filteredHeaders.length ? `, ${filteredHeaders.join(', ')}` : ''}
    ${orderByClause}`;
};

export const getFilteredHeadCells = headCells => headCells.filter(header => !ignoreList.includes(header.id));

export const getTotalRowObject = ({ row, totals, pivotField }) => {
  const isFix = ['version', 'fix'].includes(pivotField);
  return {
    ...row,
    key: `totals_${row.id}-${row[pivotField]}`,
    findings: { data: totals },
    [isFix ? 'fix' : pivotField]: isFix ? { value: 'Total' } : 'Total',
    totalsTableConfig: {
      isTotalRow: true,
    },
  };
};

export const getOrderByClause = ({ orderByArray, fixAlias }) => {
  const result = orderByArray.map(({ property, isAsc }) => {
    const isFix = property === pivotOptionsMap.fix;
    const direction = isAsc ? 'ASC' : 'DESC';
    return isFix ? `SORTABLE_VERSION(${fixAlias}) ${direction}` : `${property} ${direction}`;
  });
  return `ORDER BY ${result.join(', ')}`;
};

interface IVersionCellProps {
  value: string;
  isFixesMost: boolean;
  isFixesAll: boolean;
}

export const VersionCell: React.FC<IVersionCellProps> = ({ value, isFixesMost, isFixesAll }) => (
  <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
    {value} {isFixesMost && <AvTag text="Fixes Most" isActive oneLine />}
    {isFixesAll && <AvTag text="Fixes All" isActive oneLine />}
  </Box>
);

type TFixCellProps = Omit<IVersionCellProps, 'isFixesAll'>;

export const FixCell: React.FC<TFixCellProps> = ({ value, isFixesMost }) => (
  <Box sx={{ ...flex.itemsCenter, gap: 1, button: { fontSize: 12 } }}>
    {isValidHttpUrl(value) ? (
      <Link href={value} target="_blank" underline="none">
        {value}
      </Link>
    ) : (
      <AvCollapseContent shouldBreakWords content={value} maxLines={2} />
    )}
    {isFixesMost && <AvTag text="Fixes Most" isActive oneLine />}
  </Box>
);

export const FixSumCell = ({ data, totals }: { data: Record<string, any>; totals: Record<string, any> }, row: Record<string, any>) => (
  <Box sx={{ ...flex.row }}>
    <VulCountBySeverity data={data} totals={row.totalsTableConfig?.isTotalRow ? data : totals} />
  </Box>
);

const getPivotCellStyle = (theme, { shouldHideValue = false } = {}) => ({
  borderRight: `1px solid ${theme.palette.colors.neutrals[300]}`,
  ...(shouldHideValue ? { color: 'transparent', borderBottom: 'none' } : {}),
});

const formatIfNull = v => (v === 'null' ? '' : v);
const headersFieldsMap = {
  fix: {
    id: 'fix',
    label: 'Fixes',
    sx: { verticalAlign: 'bottom' },
    formatter: (item, row) => {
      if (
        row.totalsTableConfig?.isTotalRow ||
        (row.totalsTableConfig?.shouldHideValue && (row[item.pivotField]?.value || row[item.pivotField]) === item.value)
      ) {
        return <Box>{item.value}</Box>;
      }
      return item.fixType === fixTypeOptionsMap.text ? <FixCell {...item} /> : <VersionCell {...item} />;
    },
    rowStyle: ({ theme, row }) =>
      row.totalsTableConfig?.isTotalRow ? totalCellStyle(theme) : getPivotCellStyle(theme, row.totalsTableConfig),
    disableSortBy: true,
  },
  findings: {
    id: 'findings',
    label: 'Vulnerabilities',
    formatter: FixSumCell,
    rowStyle: ({ theme, row }) => (row.totalsTableConfig?.isTotalRow ? totalCellStyle(theme) : {}),
    disableSortBy: true,
  },
  assetName: {
    id: 'asset.name',
    label: 'Asset Name',
    rowStyle: ({ theme, row }) =>
      row.totalsTableConfig?.isTotalRow ? totalCellStyle(theme) : getPivotCellStyle(theme, row.totalsTableConfig),
    disableSortBy: true,
    formatter: formatIfNull,
  },
  assetOS: {
    id: 'asset.os',
    label: 'Asset OS',
    rowStyle: ({ theme, row }) =>
      row.totalsTableConfig?.isTotalRow ? totalCellStyle(theme) : getPivotCellStyle(theme, row.totalsTableConfig),
    disableSortBy: true,
    formatter: formatIfNull,
  },
  componentName: {
    id: 'component.name',
    label: 'Component Name',
    rowStyle: ({ theme, row }) =>
      row.totalsTableConfig?.isTotalRow ? totalCellStyle(theme) : getPivotCellStyle(theme, row.totalsTableConfig),
    disableSortBy: true,
    formatter: formatIfNull,
  },
};

const headersMap = {
  fix: [
    [{ ...headersFieldsMap.fix, rowStyle: undefined }, headersFieldsMap.findings],
    [
      headersFieldsMap.fix,
      {
        ...headersFieldsMap.assetName,
        rowStyle: ({ theme, row }) =>
          row.totalsTableConfig?.isTotalRow ? { ...totalCellStyle(theme), color: 'transparent' } : getPivotCellStyle(theme),
      },
      headersFieldsMap.findings,
    ],
  ],
  asset: [
    [
      headersFieldsMap.assetName,
      {
        ...headersFieldsMap.fix,
        rowStyle: ({ theme, row }) =>
          row.totalsTableConfig?.isTotalRow ? { ...totalCellStyle(theme), color: 'transparent' } : getPivotCellStyle(theme),
      },
      headersFieldsMap.findings,
    ],
    [
      headersFieldsMap.assetOS,
      {
        ...headersFieldsMap.fix,
        rowStyle: ({ theme, row }) =>
          row.totalsTableConfig?.isTotalRow ? { ...totalCellStyle(theme), color: 'transparent' } : getPivotCellStyle(theme),
      },
      headersFieldsMap.findings,
    ],
  ],
  component: [
    [
      headersFieldsMap.componentName,
      {
        ...headersFieldsMap.fix,
        rowStyle: ({ theme, row }) =>
          row.totalsTableConfig?.isTotalRow ? { ...totalCellStyle(theme), color: 'transparent' } : getPivotCellStyle(theme),
      },
      headersFieldsMap.findings,
    ],
  ],
};

export const versionFixesHeadCells = [
  { id: 'key', isKey: true, hidden: true },
  { id: 'fix', label: 'Fix Version', sx: { verticalAlign: 'bottom' }, formatter: VersionCell },
  { id: 'asset.name', label: 'Asset Name' },
  { id: 'findings', label: 'Vulnerabilities Solved', formatter: FixSumCell },
];

export const textFixesHeadCells = [
  { id: 'key', isKey: true, hidden: true },
  { id: 'fix', label: 'Fix Text', sx: { verticalAlign: 'bottom', minWidth: 350, button: { fontSize: 'unset' } }, formatter: FixCell },
  { id: 'findings', label: 'Vulnerabilities Solved', formatter: FixSumCell, style: { verticalAlign: 'top', minWidth: 300 } },
];

export const getHeadersSet = pivotField => [
  ...headersMap[pivotField].map(headerSet => [{ id: 'key', isKey: true, hidden: true }, ...headerSet]),
];

const getRowKey = (id, type, value) => `${id}-${type}-${value}`;

export const getFixesData = ({ data, fixType, pivotField, isPivotView, allFindingCount }) => {
  const pivotMap =
    !isPivotView &&
    data?.reduce((acc, fix) => {
      if (acc[fix.fixValue]) {
        return acc;
      }
      return { ...acc, [fix.fixValue]: fix };
    }, {});
  const unknownFixes = data?.length ? data.filter(f => !f.fixValue) : [];
  const rows =
    (data?.length &&
      [...unknownFixes, ...data.filter(f => !!f.fixValue)].map((item, index) => {
        const { id, fixValue, totalRowCount } = item;
        // TOOD: change it when adding support sorting
        const isNewestVersion = index === 0;

        const isFixesMost = pivotMap && isNewestVersion && totalRowCount < allFindingCount;
        const isFixesAll = pivotMap && isNewestVersion && totalRowCount === allFindingCount;

        return {
          ...item,
          key: getRowKey(id, fixType, fixValue),
          fix: { value: fixValue || 'Unknown Fix', ...(pivotMap ? { isFixesMost, isFixesAll } : {}), fixType, pivotField },
          findings: {
            data: fixValue
              ? item
              : {
                  ...item,
                  [SeverityLabels.Critical]: item.NOT_KNOWN_CRITICAL,
                  [SeverityLabels.High]: item.NOT_KNOWN_HIGH,
                  [SeverityLabels.Medium]: item.NOT_KNOWN_MEDIUM,
                  [SeverityLabels.Low]: item.NOT_KNOWN_LOW,
                  [SeverityLabels.None]: item.NOT_KNOWN_NONE,
                },
            totals: pivotMap && pivotMap[data[0].fixValue],
            isFixesMost,
            isFixesAll,
          },
        };
      })) ||
    [];
  const result = isPivotView
    ? rows.reduce(
        ({ arr, iteratedRows }, item, index) => {
          const { [pivotField]: pivotFieldValue, fixValue } = item;
          const newTotals = {
            [SeverityLabels.Critical]: item.TOTAL_CRITICAL,
            [SeverityLabels.High]: item.TOTAL_HIGH,
            [SeverityLabels.Medium]: item.TOTAL_MEDIUM,
            [SeverityLabels.Low]: item.TOTAL_LOW,
            [SeverityLabels.None]: item.TOTAL_NONE,
          };

          const newItem = {
            ...item,
            key: getRowKey(item.id, fixType, fixValue),
            [pivotField]: isNullOrUndefined(pivotFieldValue) ? `${pivotFieldValue}` : pivotFieldValue,
            totalsTableConfig: {
              shouldHideValue: !!iteratedRows?.[pivotFieldValue?.value || pivotFieldValue],
            },
            fixType,
          };
          if (
            ((pivotFieldValue?.value || pivotFieldValue) &&
              index < rows.length - 1 &&
              !isEquals(item[pivotField], rows[index + 1][pivotField])) ||
            index === rows.length - 1
          ) {
            return {
              arr: [...arr, newItem, getTotalRowObject({ row: item, totals: newTotals, pivotField })],
              iteratedRows: { ...iteratedRows, [pivotFieldValue?.value || pivotFieldValue]: true },
            };
          }
          return {
            arr: [...arr, newItem],
            iteratedRows: { ...iteratedRows, [pivotFieldValue?.value || pivotFieldValue]: true },
          };
        },
        { arr: [], iteratedRows: {} }
      )
    : { arr: rows };
  return result.arr;
};
