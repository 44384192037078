import React, { CSSProperties, ReactNode, useContext, useMemo, useState } from 'react';
import { Box, IconButton, SortDirection, TableSortLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { uniqBy } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { FieldFilter } from '../FieldFilter';
import { TableContext } from './Context';
import { FormatterType, SortType } from './types';
import { autoWidthStyle, AvalorTableIds, isAuto, isAutoFit, isNumberType } from './Utils';
import { ReactComponent as Filter } from '../../assets/Filter.svg';
import { ReactComponent as Minus } from '../../assets/MinusCircle.svg';
import { ReactComponent as SortDown } from '../../assets/Sort Down.svg';

const filterIcon = <Filter />;
const cancelIcon = <Minus style={{ width: 16, height: 16 }} />;
const emptyIcon = () => <Box sx={{ display: 'none' }} />;

interface TableHeadCellProps {
  type?: FormatterType;
  id: AvalorTableIds | string;
  activeSort?: boolean;
  order?: SortDirection;
  label?: ReactNode;
  sx?: CSSProperties; // Assuming sx is a style object, adjust if necessary
  disableSortBy?: boolean;
  onRequestSort?: (event, id) => void;
  direction?: SortType;
  onClick?: (event) => void;
  titleLabel?: ReactNode;
  initWidth?: number;
  onCancelColumn?: (colId: string) => void;
  index: number;
}

const getDefaultFilterFunc = (colId: AvalorTableIds | string) => row => ({
  value: !!row[colId],
  title: row[colId] ? 'True' : 'False',
});

const TableHeadCell: React.FC<TableHeadCellProps> = ({
  id: colId,
  sx = {},
  type,
  label,
  initWidth,
  order,
  activeSort,
  disableSortBy,
  onRequestSort,
  direction,
  onClick,
  titleLabel,
  onCancelColumn,
  index,
}) => {
  const {
    filters,
    rows,
    headCells,
    setFilters,
    hasHeaderFilters,
    containerRef,
    columnWidths,
    onResizeColumn,
    autoFitCol,
    freeSpaceRef,
    columnRefs,
    resizable,
    resizingColId,
    handleChangePage,
    columnIconButtonProp,
  } = useContext(TableContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filtersOptions = useMemo(
    hasHeaderFilters
      ? () => {
          const head = headCells.find(({ id }) => id === colId);
          const { getValue, getFilterOptionFunc = type === 'bool' ? getDefaultFilterFunc(colId) : undefined } = head || {};
          const flatValues = [...new Set(rows?.map(r => (!getFilterOptionFunc && getValue ? getValue(r, colId) : r[colId])).flat(1))];
          return uniqBy(
            flatValues.map(value => (getFilterOptionFunc ? getFilterOptionFunc({ [colId]: value }) : { value, title: value })),
            r => r.value
          );
        }
      : () => undefined,
    [rows, headCells]
  );
  const isFiltered = filters[colId]?.length;
  const dragLineHeight = containerRef?.current?.clientHeight || 0;
  const dynamicWidth = resizable
    ? colId === AvalorTableIds.InlineActionsId
      ? 0
      : ([AvalorTableIds.ExpandCaretId, AvalorTableIds.FreeSpaceId] as string[]).includes(colId)
        ? 40
        : columnWidths[colId] || initWidth || 'auto'
    : 'initial';
  const filterIconButton = hasHeaderFilters && type !== FormatterType.date && (
    <FieldFilter
      onOpen={() => setIsFilterOpen(true)}
      onClose={() => setIsFilterOpen(false)}
      fieldType={type}
      isMultipleCondition={false}
      isEnabled={false}
      trigger={
        <IconButton
          className="filter"
          sx={{
            opacity: isFiltered || isFilterOpen ? 1 : 0,
            display:
              isFiltered || isFilterOpen || !resizable || isAuto(columnWidths, colId) || isAutoFit(columnWidths, colId) ? 'flex' : 'none',
          }}
          {...(isFiltered ? { color: 'primary' } : {})}>
          {filterIcon}
        </IconButton>
      }
      field={colId}
      filters={filters}
      onChange={(field, value) => {
        handleChangePage(null, 0);
        setFilters({ ...filters, [field]: value });
      }}
      options={filtersOptions}
    />
  );
  return (
    <TableCell
      ref={ref => (AvalorTableIds.FreeSpaceId === colId ? (freeSpaceRef[AvalorTableIds.FreeSpaceId] = ref) : null)}
      align={isNumberType(type) ? 'right' : 'left'}
      sortDirection={activeSort ? order : false}
      padding={label ? undefined : 'none'}
      style={{
        ...(resizable && !isAuto(columnWidths, colId, initWidth)
          ? isAutoFit(columnWidths, colId) && AvalorTableIds.ExpandCaretId !== colId
            ? autoWidthStyle
            : { minWidth: dynamicWidth, width: dynamicWidth, maxWidth: dynamicWidth }
          : {}),
      }}
      sx={[
        sx,
        {
          ':hover .drag-handle, :has(+ :hover) .drag-handle': { opacity: !resizingColId ? 1 : resizingColId === colId ? 1 : 0 },
          ':hover': {
            '.MuiIconButton-root': { opacity: 1 },
            '.sort': { opacity: activeSort ? 1 : resizingColId ? 0 : 0.6, ...flex.row },
            '.filter': { ...flex.row, opacity: resizingColId ? 0 : 1 },
          },
        },
      ]}>
      {!([AvalorTableIds.InlineActionsId, AvalorTableIds.FreeSpaceId] as string[]).includes(colId) && (
        <Box
          sx={{
            ...flex.itemsCenter,
            height: '100%',
            position: 'relative',
            gap: 1,
            ...(isNumberType(type) ? { flexDirection: 'row-reverse', pr: 1 } : {}),
          }}>
          {label && !disableSortBy && onRequestSort ? (
            <TableSortLabel
              active={activeSort}
              direction={direction}
              IconComponent={emptyIcon}
              sx={{ width: '100%', justifyContent: type === 'number' ? 'right' : 'inherit' }}>
              <AvTooltip muiProps={{ onClick }}>{titleLabel}</AvTooltip>
              <Box
                onClick={onClick}
                className="sort"
                sx={[
                  flex.itemsCenter,
                  { pl: isNumberType(type) ? 0 : '4px' },
                  activeSort
                    ? { opacity: 1 }
                    : {
                        opacity: 0,
                        display: !resizable || isAuto(columnWidths, colId) || isAutoFit(columnWidths, colId) ? 'flex' : 'none',
                      },
                ]}>
                <SortDown
                  style={{
                    height: 18,
                    width: 18,
                    ...(direction === 'asc' && { transform: 'rotate(180deg)' }),
                  }}
                />
              </Box>
              {filterIconButton}
              {columnIconButtonProp && !(columnIconButtonProp.disableFirstColumn && index === 0) && (
                <Box
                  onClick={e => {
                    e.stopPropagation();
                    columnIconButtonProp.onClick(colId);
                  }}
                  className="sort"
                  sx={[
                    flex.itemsCenter,
                    { pl: isNumberType(type) ? 0 : '4px' },
                    {
                      opacity: 0,
                      display: !resizable || isAuto(columnWidths, colId) || isAutoFit(columnWidths, colId) ? 'flex' : 'none',
                    },
                  ]}>
                  {columnIconButtonProp.icon}
                </Box>
              )}
            </TableSortLabel>
          ) : (
            colId !== AvalorTableIds.ExpandCaretId && <AvTooltip>{titleLabel}</AvTooltip>
          )}
          {onCancelColumn && (
            <IconButton sx={{ opacity: 0 }} onClick={() => onCancelColumn(colId)} aria-label="Cancel">
              {cancelIcon}
            </IconButton>
          )}
        </Box>
      )}
      {resizable &&
        !([AvalorTableIds.ExpandCaretId, AvalorTableIds.InlineActionsId, AvalorTableIds.FreeSpaceId] as string[]).includes(colId) && (
          <Box
            className="drag-handle"
            ref={ref => (columnRefs[colId] = ref)}
            onMouseDown={e => onResizeColumn(e, colId)}
            onDoubleClick={() => autoFitCol(colId)}
            sx={[
              { ...flex.justifyEnd, position: 'absolute', cursor: 'col-resize', right: 0, width: '10px' },
              resizingColId === colId
                ? { top: 0, height: dragLineHeight, opacity: 1 }
                : {
                    top: 8,
                    bottom: 8,
                    opacity: 0,
                  },
            ]}>
            <Box
              sx={{
                width: '2px',
                height: '100%',
                background: theme => (resizingColId === colId ? theme.palette.colors.primary[300] : theme.palette.colors.neutrals[350]),
              }}
            />
          </Box>
        )}
    </TableCell>
  );
};

export default TableHeadCell;
