import React, { useState } from 'react';
import { Button, ClickAwayListener, Divider, Grow, IconButton, Paper, Popper, SxProps } from '@mui/material';
import { Box } from '@mui/system';
import AvCheckbox from '../../components/AvCheckbox';
import { flex } from '../../components/AvThemeProvider';
import TextInput from '../../components/TextInput';
import { noop } from '../../utils/Utils';
import { ReactComponent as X } from '../../assets/X.svg';

const clearIcon = <X />;

export default function InputPopper({
  anchorEl,
  showOptionBody = true,
  onClose = noop,
  onApply,
  texts,
  checkboxesOptions = [],
  defaultValue = { checkboxes: {} },
  sx = {},
  placement: { popper, transformOrigin } = {
    popper: 'bottom',
    transformOrigin: (placement: string) => (placement === 'bottom' ? 'center top' : 'center bottom'),
  },
}: {
  anchorEl?: HTMLElement;
  showOptionBody?: boolean;
  onClose?: () => void;
  onApply: (value) => void;
  texts: { header?: string | React.ReactNode; input?: string | React.ReactNode; button?: string | React.ReactNode };
  checkboxesOptions?: any[];
  defaultValue?: { checkboxes?: Record<string, boolean | undefined>; input?: any };
  sx?: SxProps;
  placement?: { popper?: any; transformOrigin?: (placement: string) => string };
}) {
  const [value, setValue] = useState<any>(defaultValue);

  const setInput = val => setValue({ ...value, input: val });
  const setCheckboxes = (name, val) => setValue({ ...value, checkboxes: { ...value.checkboxes, [name]: val } });

  const apply = () => {
    onApply(value);
    onClose();
  };

  return (
    <Popper
      open={showOptionBody}
      placement={popper}
      anchorEl={anchorEl}
      transition
      sx={{ width: 360, zIndex: ({ zIndex }) => zIndex.modal, borderRadius: '2px', ...sx }}>
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: transformOrigin?.(placement) }}>
          <Paper sx={{ ...flex.col }}>
            <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
              <Box>
                <Box sx={{ padding: 3, ...flex.col, gap: 2 }}>
                  {texts.header && (
                    <>
                      <IconButton sx={{ position: 'absolute', right: 22, top: 22 }} onClick={onClose}>
                        {clearIcon}
                      </IconButton>
                      <Box sx={{ fontWeight: 600 }}>{texts.header}</Box>
                    </>
                  )}
                  <TextInput
                    label={texts.input}
                    value={value?.input}
                    size="small"
                    onChange={setInput}
                    sx={{ maxWidth: 'inherit' }}
                    autoFocus
                    onFocus={e => e.stopPropagation()}
                  />
                  {value.checkboxes &&
                    checkboxesOptions &&
                    checkboxesOptions.map(({ value: checkboxValue, title }) => (
                      <AvCheckbox
                        key={checkboxValue}
                        label={title}
                        value={value.checkboxes[checkboxValue]}
                        onChange={val => setCheckboxes(checkboxValue, val)}
                      />
                    ))}
                </Box>
                <Divider />
                <Box sx={{ ...flex.justifyEnd, gap: 3, padding: '12px 24px' }}>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button onClick={apply} disabled={!value?.input?.length} variant="contained" size="small">
                    {texts.button}
                  </Button>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
