import React, { useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ResponsiveContainer } from 'recharts';
import AvLegend from '../../../components/AvLegend';
import { flex } from '../../../components/AvThemeProvider';
import { IconVariant, ItemWithLogo } from '../../../components/ItemWithLogo';
import Select from '../../../components/Select';
import useQueryObjectProto from '../../../hooks/useQueryObjectProto';
import { QueryObjectProto } from '../../../types/QueryObjectProto.types';
import VennDiagramWidget from '../../CustomDashboards/components/Widgets/VennDiagramWidget';
import { formatDataForVennDiagram, getAllGroups } from '../../CustomDashboards/Utils';
import { getFieldFilterAndAggMetric, maxSourcesNum, ScreenSize, sizeMap } from './utils';
import { ReactComponent as Plus } from '../../../assets/Plus.svg';
import { ReactComponent as X } from '../../../assets/X.svg';

const InteractiveVenn = ({
  interactiveVennSources,
  colorScheme,
  onChange,
  valueOptions,
  isLoadingValueOptions,
  screenSize,
}: {
  interactiveVennSources: string[];
  onChange: (source) => void;
  colorScheme: string[];
  valueOptions: any[];
  isLoadingValueOptions: boolean;
  screenSize: ScreenSize;
}) => {
  const vennQuery: QueryObjectProto = {
    sourceProjection: {
      name: 'uber_assets',
      builtIn: true,
    },
    dims: [],
    metrics: getAllGroups(interactiveVennSources).map(sourceArray => getFieldFilterAndAggMetric(sourceArray)),
  };
  const { isLoading: isLoadingVennData, data: vennData = [] } = useQueryObjectProto({
    queryObject: vennQuery,
  });

  const series = useMemo(
    () =>
      interactiveVennSources.map((source, index) => ({
        name: source,
        color: colorScheme[index],
        component: (
          <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
            {source}
            {interactiveVennSources.length > 0 && (
              <IconButton
                onClick={() => {
                  onChange({ vennSources: interactiveVennSources.filter(s => s !== source) });
                }}>
                <X />
              </IconButton>
            )}
          </Box>
        ),
      })),
    [interactiveVennSources, colorScheme]
  );

  const formattedData = formatDataForVennDiagram(vennData);
  const isLoading = isLoadingValueOptions || isLoadingVennData;
  const vennSize = sizeMap[screenSize] ?? 300;
  return (
    <Box sx={{ ...flex.colItemsStart, gap: 2, width: '100%', height: '100%' }}>
      <Typography variant="h5">Explore Coverage Overlaps</Typography>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        <Select
          variant="outlined"
          type="icon"
          isRequired
          label={<Plus />}
          options={valueOptions}
          getValueFunc={source => source.name}
          size="xSmall"
          shouldCloseOnChange={newVal => newVal?.length >= maxSourcesNum}
          renderOption={({ name }) => <ItemWithLogo variant={IconVariant.sourcesMapByName} type={name} />}
          isMultiple
          onChange={v => {
            if (v.length > maxSourcesNum) {
              return;
            }
            onChange({ vennSources: v.toSorted() });
          }}
          value={interactiveVennSources}
          showSelection={false}
        />
        <AvLegend series={series} isOutlined isHorizontal />
      </Box>
      <ResponsiveContainer width="100%" height="100%">
        <VennDiagramWidget data={formattedData} series={series} loading={isLoading} width={vennSize} height={vennSize} />
      </ResponsiveContainer>
    </Box>
  );
};

export default InteractiveVenn;
