import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../../../context/AvContextProvider';
import { PolicyScenarioType } from '../../../types/executionRules.types';
import { Filter } from '../../../types/filter.types';
import { QueryKey } from '../../../utils/queryKey';

// eslint-disable-next-line import/prefer-default-export
export const useGetPolicyScenarioFilter = ({ policyScenario }: { policyScenario?: PolicyScenarioType }) => {
  const { api } = useAvContext();
  const { data } = useQuery<Filter>({
    queryKey: [QueryKey.PolicyId, policyScenario],
    queryFn: () => api(GET_POLICY_SCENARIO_FILTER, { options: { policyScenario }, onSuccess: ({ data }) => data.getPolicyScenarioFilter }),
    enabled: !!policyScenario,
  });
  return data;
};

const GET_POLICY_SCENARIO_FILTER = gql`
  query getPolicyScenarioFilter($policyScenario: PolicyScenario!) {
    getPolicyScenarioFilter(policyScenario: $policyScenario)
  }
`;
