import React, { useMemo } from 'react';
import { Collapse, Divider, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { flex } from '../../../../components/AvThemeProvider';
import AvTooltip from '../../../../components/AvTooltip';
import AvTable from '../../../../components/Table/AvTable';
import { FormatterType, HeadCellType } from '../../../../components/Table/types';
import { AvalorTableIds, expandArrowColumn, isNumberType } from '../../../../components/Table/Utils';
import { ChartClickInteraction } from '../../../../components/Widgets/types';
import { styleNoUpperCase } from '../../../../context/utils';
import { Filter } from '../../../../types/filter.types';
import { useGetHeadCellsWithRequestObject } from '../../../../utils/RequestUtils';
import { emptyArray, generateInnerDefaultFormatter, iconSize } from '../../../../utils/Utils';
import { useFormattingRules } from '../../../FormattingRules/hooks';
import { getFormattingConditionById } from '../../../FormattingRules/utils';
import { OTHERS } from '../../constants';
import { useGetDisplayName } from '../../hooks';
import { CommonWidgetProps, Field, SortBy, SortDir, TableCategoryWidgetTypes, TableSubType, TopResults } from '../../types';
import { defaultDrillDownHierarchy, getColumnFormatter } from '../../Utils';
import { useDrillDownData } from '../Interactions/drillDown';
import { ReactComponent as ArrowDown } from '../../../../assets/Arrow Down.svg';

const arrowIcon = theme => <ArrowDown style={{ ...iconSize(16), color: theme.palette.colors.neutrals[600] }} />;

interface TableWidgetProps extends CommonWidgetProps {
  widget: TableCategoryWidgetTypes;
  totalRowCount?: number;
  clickInteractions?: ChartClickInteraction[];
}

const commonTableProps = {
  enableSelectionColor: false,
  hasHeaderFilters: false,
  allowEdit: false,
  disableUseEffect: true,
};

const TableWidget: React.FC<TableWidgetProps> = ({
  widget,
  setWidget,
  data: originalData = emptyArray,
  isLoading = false,
  responseTotals,
  totalRowCount,
  onSelect,
  selected = emptyArray,
  clickInteractions,
  globalFilters,
}) => {
  const theme = useTheme();
  const {
    drillDownHierarchy = defaultDrillDownHierarchy,
    requests: [request],
    definition: {
      custom: { crossTableView },
    },
  } = widget;
  const { data: formattingRules = [] } = useFormattingRules();

  const getDisplayName = useGetDisplayName(request.projectionId.name);
  const {
    data: drillDownData,
    totalRowCountDrillDown,
    isLoadingDrillDown,
    newQueryObject,
    allDrillDownFields,
    setDrillDownSort,
  } = useDrillDownData({
    widget,
    contextFilter: globalFilters,
    enabled: !crossTableView,
  });
  const showExpandedRow = crossTableView && request.select.dims.length === 1;
  const { size: rowLimit = 20 } = widget.requests[0].top || { size: 20 };

  const { activeIndex, drillDownValues = [] } = drillDownHierarchy;
  const nextDrillDownField = allDrillDownFields[activeIndex + 1];
  const queryHeadCells = useGetHeadCellsWithRequestObject(
    activeIndex !== 0 && !crossTableView
      ? {
          ...newQueryObject,
          select: { ...newQueryObject.select, dims: allDrillDownFields[activeIndex] ? [allDrillDownFields[activeIndex]] : [] },
        }
      : widget.requests[0]
  );

  const data = useMemo(
    () => (!!drillDownData && !crossTableView ? drillDownData : originalData),
    [crossTableView, drillDownData, originalData]
  );
  const activeRow = widget.drillDownHierarchy?.expendedIds || [];

  const headCells = useMemo(
    () => [
      { id: 'id', label: 'ID', hidden: true, isKey: true, sx: styleNoUpperCase } as HeadCellType,
      ...(showExpandedRow ? [expandArrowColumn(() => true)] : []),
      ...queryHeadCells.map((cell, index) =>
        !crossTableView && index === 0 && !!nextDrillDownField && request.select.dims.length === 1
          ? {
              sx: styleNoUpperCase,
              ...cell,
              style: {
                ':hover': { background: ({ palette }) => palette.colors.neutrals[300] },
                cursor: 'pointer',
              },
              formatter: (value, row, setRows, index, rows, dimId) => (
                <AvTooltip disabled={!nextDrillDownField} title={`Drill Down to ${getDisplayName(nextDrillDownField?.name)}`}>
                  <Box
                    sx={{
                      ...(isNumberType(cell.type) ? { ...flex.row, flexDirection: 'row-reverse' } : flex.row),
                      height: '100%',
                      width: '100%',
                    }}
                    onClick={() => onActive(value, rows, dimId)}>
                    {getColumnFormatter({
                      value,
                      type: cell.type,
                      columnFormatter: widget.definition.custom.fieldStyle?.[cell.id]?.columnFormatter,
                      formattingConditions: getFormattingConditionById(
                        widget.definition.custom.fieldStyle?.[cell.id]?.formattingRuleId,
                        formattingRules
                      ),
                    })}
                  </Box>
                </AvTooltip>
              ),
            }
          : request.select.metrics.some(({ name }) => name === cell.id) &&
              clickInteractions?.length &&
              clickInteractions.some(v => v.isEnabledForCell({ dataKey: cell.id }))
            ? {
                ...cell,
                sx: styleNoUpperCase,
                rowStyle: ({ row }) => ({
                  ':hover': { background: ({ palette }) => palette.colors.neutrals[300] },
                  background: ({ palette }) =>
                    selected.some(selectedRow =>
                      Object.keys(selectedRow).every(
                        key =>
                          (selectedRow[key] === row[key]?.toString() ||
                            ((selectedRow[key] === 'null' || selectedRow[key] === null) && row[key] === null) ||
                            key === 'metric') &&
                          cell.id === selectedRow.metric
                      )
                    ) && palette.colors.primary[200],
                  cursor: 'pointer',
                }),
                formatter: (value, row) => (
                  <AvTooltip title={`Click to ${clickInteractions[0].title.toLowerCase()}`}>
                    <Box
                      sx={isNumberType(cell.type) ? { ...flex.row, flexDirection: 'row-reverse' } : flex.row}
                      onClick={e =>
                        clickInteractions[0].onClick({ row, cell, currentIndex: crossTableView ? 0 : activeIndex, e, widgetData: data })
                      }>
                      {getColumnFormatter({
                        value,
                        type: cell.type,
                        columnFormatter: widget.definition.custom.fieldStyle?.[cell.id]?.columnFormatter,
                        formattingConditions: getFormattingConditionById(
                          widget.definition.custom.fieldStyle?.[cell.id]?.formattingRuleId,
                          formattingRules
                        ),
                      })}
                    </Box>
                  </AvTooltip>
                ),
              }
            : {
                ...cell,
                sx: styleNoUpperCase,
                formatter: value =>
                  getColumnFormatter({
                    value,
                    type: cell.type,
                    fieldName: cell.id,
                    columnFormatter: widget.definition.custom.fieldStyle?.[cell.id]?.columnFormatter,
                    formattingConditions: getFormattingConditionById(
                      widget.definition.custom.fieldStyle?.[cell.id]?.formattingRuleId,
                      formattingRules
                    ),
                  }),
              }
      ),
    ],
    [queryHeadCells, drillDownHierarchy, crossTableView, request, selected]
  );
  const visibleHeadCells = useMemo(() => headCells.filter(({ hidden }) => !hidden), [headCells]);

  const onSort = (field, direction) => {
    if (setWidget) {
      const isAsc = direction === 'asc';
      const sortBy: SortBy = {
        name: field,
        dir: isAsc ? SortDir.ASC : SortDir.DESC,
      };
      if (!crossTableView && activeIndex !== 0) {
        setDrillDownSort(sortBy);
      } else {
        setWidget({
          ...widget,
          requests: [
            {
              ...widget.requests[0],
              sorting: [sortBy],
              top: widget.requests[0].top ? { ...widget.requests[0].top, offset: 0 } : undefined,
            },
          ],
        });
      }
    }
  };
  const onPaginationChange = page => {
    if (setWidget) {
      const newTop: TopResults = { ...widget.requests[0].top, offset: page * rowLimit } as TopResults;
      setWidget({
        ...widget,
        requests: [
          {
            ...widget.requests[0],
            top: newTop,
          },
        ],
      });
    }
  };

  const onActive = (value, rows, dimId) => {
    if (setWidget) {
      if (nextDrillDownField) {
        setWidget({
          ...widget,
          requests: [{ ...request, top: { ...request.top, offset: 0 } }],
          drillDownHierarchy: {
            ...drillDownHierarchy,
            activeIndex: activeIndex + 1,
            drillDownValues:
              drillDownValues[activeIndex] !== undefined ? drillDownValues.with(activeIndex, value) : [...drillDownValues, value],
            notOthersDrillDownValues: value === OTHERS ? rows.filter(row => row[dimId] !== 'Others').map(row => row[dimId]) : [],
          },
        });
      }
    }
  };

  const onActiveCross = v => {
    if (setWidget && crossTableView) {
      if (!v && activeRow[0]) {
        setWidget({
          ...widget,
          requests: [{ ...request, top: { ...request.top, offset: 0 } }],
          drillDownHierarchy: {
            ...drillDownHierarchy,
            expendedIds: [],
          },
        });
      }
      if (v) {
        const hadVal = drillDownValues[0] !== undefined;
        const value = data.find(({ id }) => id === v)[allDrillDownFields[0].name];
        setWidget({
          ...widget,
          requests: [{ ...request, top: { ...request.top, offset: 0 } }],
          drillDownHierarchy: {
            ...drillDownHierarchy,
            activeIndex: hadVal ? 0 : 1,
            drillDownValues: [value],
            expendedIds: [v],
          },
        });
      }
    }
  };

  const expandedRow = (row, onChange, isItemSelected) => (
    <ExpandedRow
      isItemSelected={isItemSelected || activeRow[0] === row.id}
      widget={widget}
      selected={selected}
      clickInteractions={clickInteractions}
      activeIndex={1}
      setDrillDownValues={v => setWidget({ ...widget, drillDownHierarchy: { ...drillDownHierarchy, drillDownValues: v } })}
      setWidget={setWidget}
      globalFilters={globalFilters}
    />
  );

  const renderTableWidget = useMemo(
    () => ({
      [TableSubType.Table]: () => (
        <Box
          className="draggableCancel"
          sx={{
            ...flex.center,
            flex: 1,
            overflow: 'auto',
            p: 2,
          }}>
          <AvTable
            {...commonTableProps}
            shouldDisplayNull
            headCells={headCells}
            loading={isLoading || (isLoadingDrillDown && !crossTableView)}
            onSort={onSort}
            size={widget.definition.custom.rowSize}
            rows={data}
            activeKey={activeRow[0]}
            sx={{
              height: '100%',
              ...(((!crossTableView && !!nextDrillDownField && request.select.dims.length === 1) || onSelect) && {
                '.MuiTableRow-root.MuiTableRow-hover:hover': { backgroundColor: theme.palette.colors.neutrals[100] },
              }),
            }}
            pagination={!widget.requests[0].top?.groupOthers}
            resizable
            onActive={onActiveCross}
            {...(showExpandedRow && { expandedRowObj: { component: expandedRow } })}
            rowLimit={rowLimit}
            paginationProps={{
              onPageChange: page => onPaginationChange(page),
              totalRows: totalRowCountDrillDown || totalRowCount,
              pageNum: (widget.requests[0].top?.offset || 0) / rowLimit,
            }}
            totalProps={
              responseTotals && widget.definition.custom.showTotals
                ? {
                    row: visibleHeadCells.reduce(
                      (acc, { id }) => ({ ...acc, [id]: id === AvalorTableIds.ExpandCaretId ? '' : responseTotals[id] || '-' }),
                      {}
                    ),
                    cells: visibleHeadCells.map(({ id, type }) => ({
                      id,
                      type: type === FormatterType.date ? 'string' : type,
                    })),
                  }
                : {}
            }
          />
        </Box>
      ),
    }),
    [data, widget, isLoadingDrillDown, activeRow, isLoading, selected]
  );
  return renderTableWidget[widget.type]();
};

export default TableWidget;

interface ExpendedRowDrillDownProps {
  data: any[];
  isLoadingDrillDown: boolean;
  allDrillDownFields: Field[];
  setDrillDownValues: (v) => void;
  widget: TableCategoryWidgetTypes;
  globalFilters: Filter | undefined;
  activeIndex: number;
  setWidget: (v) => void;
  selected: any[];
  clickInteractions?: ChartClickInteraction[];
  isItemSelected: boolean;
}

const ExpendedRowDrillDown: React.FC<ExpendedRowDrillDownProps> = ({
  data,
  allDrillDownFields,
  setDrillDownValues,
  isLoadingDrillDown,
  widget,
  globalFilters,
  setWidget,
  activeIndex: currentIndex,
  selected,
  clickInteractions,
  isItemSelected,
}) => {
  const theme = useTheme();
  const {
    requests: [request],
    drillDownHierarchy = defaultDrillDownHierarchy,
  } = widget;
  const { drillDownValues = [] } = drillDownHierarchy;
  const nextDrillDownField = allDrillDownFields[currentIndex + 1]?.name;
  const queryHeadCells = useGetHeadCellsWithRequestObject({
    ...request,
    select: { ...request.select, dims: allDrillDownFields[currentIndex] ? [allDrillDownFields[currentIndex]] : [] },
  });
  const activeRow = widget.drillDownHierarchy?.expendedIds || [];
  const headCells = useMemo(
    () =>
      [{ id: 'id', label: 'ID', hidden: true, isKey: true } as HeadCellType, ...queryHeadCells].map(cell =>
        cell.id === allDrillDownFields[currentIndex]?.name
          ? {
              ...cell,
              disableSortBy: true,
              sx: styleNoUpperCase,
              formatter: nextDrillDownField
                ? (value, row) => (
                    <Box
                      sx={{
                        ...(isNumberType(cell.type) ? { ...flex.row, flexDirection: 'row-reverse' } : flex.row),
                        gap: '8px',
                        ...(!isItemSelected && activeRow[currentIndex] === row.id
                          ? { svg: { transform: 'rotate(-90deg)' } }
                          : { '.has-expandedRow:not(.expanded-row) & svg': { transform: 'rotate(-90deg)' } }),
                      }}>
                      {arrowIcon(theme)}
                      {generateInnerDefaultFormatter({ value, type: cell.type })}
                    </Box>
                  )
                : undefined,
            }
          : request.select.metrics.some(({ name }) => name === cell.id) &&
              clickInteractions?.length &&
              clickInteractions.some(v => v.isEnabledForCell({ dataKey: cell.id }))
            ? {
                ...cell,
                sx: styleNoUpperCase,
                rowStyle: ({ row }) => ({
                  ':hover': { background: ({ palette }) => palette.colors.neutrals[300] },
                  background: ({ palette }) =>
                    selected.some(selectedRow =>
                      Object.keys(selectedRow).every(
                        key =>
                          (selectedRow[key] === row[key]?.toString() ||
                            ((selectedRow[key] === 'null' || selectedRow[key] === null) && row[key] === null) ||
                            key === 'metric') &&
                          cell.id === selectedRow.metric
                      )
                    ) && palette.colors.primary[200],
                  cursor: 'pointer',
                }),
                // eslint-disable-next-line react/no-unstable-nested-components
                formatter: (value, row) => (
                  <AvTooltip title={`Click to ${clickInteractions[0].title.toLowerCase()}`}>
                    <Box
                      sx={isNumberType(cell.type) ? { ...flex.row, flexDirection: 'row-reverse' } : flex.row}
                      onClick={e => {
                        clickInteractions[0].onClick({ row, cell, currentIndex, e });
                      }}>
                      {generateInnerDefaultFormatter({ value, type: cell.type })}
                    </Box>
                  </AvTooltip>
                ),
                disableSortBy: true,
              }
            : { ...cell, sx: styleNoUpperCase }
      ),
    [queryHeadCells, drillDownHierarchy]
  );

  const expandedRow = (row, onChange, isItemSelected) => (
    <ExpandedRow
      isItemSelected={isItemSelected || activeRow[currentIndex] === row.id}
      widget={widget}
      setDrillDownValues={setDrillDownValues}
      setWidget={setWidget}
      globalFilters={globalFilters}
      activeIndex={currentIndex + 1}
      selected={selected}
      clickInteractions={clickInteractions}
    />
  );
  const onActiveCrossInner = v => {
    const hadVal = drillDownValues[currentIndex] !== undefined;
    if (setWidget && (hadVal ? true : drillDownHierarchy.fields[currentIndex])) {
      if (!v && activeRow[currentIndex]) {
        setWidget({
          ...widget,
          requests: [{ ...request, top: { ...request.top, offset: 0 } }],
          drillDownHierarchy: {
            ...drillDownHierarchy,
            expendedIds: activeRow.slice(0, currentIndex),
          },
        });
      }
      if (v) {
        const value = data.find(({ id }) => id === v)[allDrillDownFields[hadVal ? currentIndex : currentIndex].name];
        const newDrillDownValues = [...drillDownValues.slice(0, currentIndex), value];
        setWidget({
          ...widget,
          requests: [{ ...request, top: { ...request.top, offset: 0 } }],
          drillDownHierarchy: {
            ...drillDownHierarchy,
            activeIndex: hadVal ? currentIndex : currentIndex + 1,
            drillDownValues: newDrillDownValues,
            expendedIds: activeRow[currentIndex] ? activeRow.with(currentIndex, v) : [...activeRow, v],
          },
        });
      }
    }
  };

  return (
    <>
      <Divider />
      <AvTable
        sx={{ '.MuiTableRow-root:last-of-type>.MuiTableCell-root': { borderBottom: 'none' }, pl: 1 }}
        headCells={headCells}
        rows={data}
        resizable
        loading={isLoadingDrillDown}
        activeKey={activeRow[currentIndex]}
        onActive={onActiveCrossInner}
        size={widget.definition.custom.rowSize}
        {...(nextDrillDownField && { expandedRowObj: { component: expandedRow } })}
        {...commonTableProps}
        shouldDisplayNull
      />
    </>
  );
};

const ExpandedRow = ({
  isItemSelected,
  widget,
  globalFilters,
  setDrillDownValues,
  setWidget,
  activeIndex,
  selected,
  clickInteractions,
}: {
  widget: TableCategoryWidgetTypes;
  setDrillDownValues: (v) => void;
  setWidget: (v) => void;
  isItemSelected: boolean;
  activeIndex: number;
  globalFilters: Filter | undefined;
  selected: any[];
  clickInteractions?: ChartClickInteraction[];
}) => {
  const { data, isLoadingDrillDown, allDrillDownFields } = useDrillDownData({
    widget,
    enabled: isItemSelected,
    contextFilter: globalFilters,
    currentIndex: activeIndex,
  });

  return (
    <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
      <ExpendedRowDrillDown
        data={data}
        isItemSelected={isItemSelected}
        selected={selected}
        clickInteractions={clickInteractions}
        isLoadingDrillDown={isLoadingDrillDown}
        allDrillDownFields={allDrillDownFields}
        setDrillDownValues={setDrillDownValues}
        globalFilters={globalFilters}
        widget={widget}
        setWidget={setWidget}
        activeIndex={activeIndex}
      />
    </Collapse>
  );
};
