import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../utils/queryKey';
import { capitalizeSentence } from '../utils/Utils';
import { FieldType, GENERAL_ENTITY } from '../views/Sources/Mapping/mapping.types';
import { AccountEntities, DescriptorType } from './context.type';
import { FIELD_VISIBILITY, GET_ENTITIES } from './gql';
import { FieldNameDescriptor } from './utils';

// TODO: remove this when BE will return these fields
const systemFieldsToHideOnBulkUpdate = [
  'current_status.timestamp',
  'integration_info.type',
  'integration_info.key',
  'integration_info.notification_target_id',
  'exception_integration_info.key',
  'exception_integration_info.type',
  'exception_integration_info.display_key',
  'exception_integration_info.notification_target_id',
];

const removeSPFFromSelfPath = (projId, sourceProj, usages) => (projId.name === sourceProj.name ? usages.filter(u => u !== 'SPF') : usages);
const addEVALToPathExcludingAggregatedIngress = (aggsMap, sourceProj, projId) =>
  aggsMap[sourceProj.name].name === projId.name && !aggsMap[projId.name] ? [] : ['EVAL'];

const getUsagesWithEval = ({ projId, usages }, sourceProj, aggsMap) =>
  usages.includes('SPF')
    ? [...removeSPFFromSelfPath(projId, sourceProj, usages), ...addEVALToPathExcludingAggregatedIngress(aggsMap, sourceProj, projId)]
    : usages;

const flatNestedList = fields =>
  fields.flatMap(field =>
    field.type.fields && !field.repeated
      ? flatNestedList(
          field.type.fields.map(f => ({
            ...f,
            displayName: `${field.displayName || ''} ${f.displayName}`.trim(),
            name: `${field.name}.${f.name}`,
          }))
        )
      : [field]
  );

const createExternalProjectionEntityTypeSet = data => new Set(data.externalProjections.map(({ meta }) => meta.typeId.name));

export const createFieldMap = (projs: AccountEntities) =>
  Object.values(projs).reduce(
    (map, { fieldList }) => ({
      ...map,
      ...Object.values(fieldList).reduce(
        (bigMap, list) => ({
          ...bigMap,
          ...list.reduce((obj, field) => ({ ...obj, [field.value]: field }), {}),
        }),
        {}
      ),
    }),
    {}
  );

export const createFieldMapType = (projs: AccountEntities) =>
  Object.values(projs).reduce((map, { typeMap }) => ({ ...map, ...typeMap }), {});

export const getType = (type, repeated) => {
  const result = type.name?.includes('Timestamp')
    ? FieldType.DateTime
    : type.fields
      ? 'MESSAGE'
      : DescriptorType[type.kind] || FieldNameDescriptor[type.name] || type.name;
  return repeated ? [result] : result;
};

export const processEntities = ({ data, fieldVisibilityData }) => {
  const externalProjectionEntityTypes = createExternalProjectionEntityTypeSet(data);
  const isExternalProjection = proj => externalProjectionEntityTypes.has(proj.entityTypeId.name);
  const entityTypes = data.allEntityTypes.reduce(
    (obj, { id: { name, builtIn }, fields }) => ({
      ...obj,
      [name]: {
        fields: [
          ...data.metadataFields.map(item => ({ ...item, isMetadata: true })),
          ...flatNestedList(fields).map(field =>
            systemFieldsToHideOnBulkUpdate.includes(field.name)
              ? { ...field, additionalInfo: { ...field.additionalInfo, isSystemField: true } }
              : field
          ),
        ],
        name,
        builtIn,
      },
    }),
    {}
  );

  const orderMap = Object.keys(entityTypes).reduce((obj, name, i) => ({ ...obj, [name]: i }), {});

  const oldIngressProjs = [entityTypes[GENERAL_ENTITY], ...data.ingressProjections]
    .sort((a, b) => orderMap[a.meta?.typeId.name] - orderMap[b.meta?.typeId.name])
    .reduce((obj, projection) => {
      const { builtIn, fields, name } = entityTypes[projection.meta?.typeId.name || GENERAL_ENTITY];
      return {
        ...obj,
        [name]: {
          name,
          builtIn,
          entityTypeId: projection.meta?.typeId,
          projId: projection.meta?.id,
          projDisplayName: name,
          nameMap: fields.reduce((obj, { name, displayName }) => ({ ...obj, [name]: capitalizeSentence(displayName) }), {}),
          fields: fields.map(f => ({ ...f, displayName: capitalizeSentence(f.displayName) })),
        },
      };
    }, {});

  const getFields = projName => target => {
    const fieldsRecommendationSet = new Set(target.fieldsRecommendation);
    const fieldsPopulatedSet = new Set(target.fieldsPopulation);

    return entityTypes[target.entityTypeId.name].fields.map(field => {
      const fieldVisibilityConfig = fieldVisibilityData.findFieldVisibilitiesByAccountId.find(dto => {
        const fieldId = `${dto.projectionName}.${dto.fieldName}`;
        return fieldId === `${target.projId.name}.${field.name}`;
      });

      return {
        ...field,
        id: `${projName === target.projId.name ? '' : `${projName}.`}${target.alias}.${field.name}`,
        value: `${target.alias}.${field.name}`,
        ingressValue: `${target.alias}.sources.${field.name}`,
        title: `${target.displayName} ${field.displayName}`,
        group: target.displayName,
        usages: target.usages,
        type: getType(field.type, field.repeated),
        originalType: field.type,
        entityTypeId: target.entityTypeId,
        mainProjId: target.projId,
        visibilityConfig: fieldVisibilityConfig || { config: { hidden: false } },
        isRecommended: fieldsRecommendationSet.has(field.name),
        isPopulated: fieldsPopulatedSet.has(field.name),
      };
    });
  };

  const { ingressProjs, aggProjs, externalProjs } = data.projectionAliasPaths.reduce(
    (acc, proj) => {
      if (isExternalProjection(proj)) {
        acc.externalProjs.push(proj);
        return acc;
      }
      return proj.aggregates ? { ...acc, aggProjs: [...acc.aggProjs, proj] } : { ...acc, ingressProjs: [...acc.ingressProjs, proj] };
    },
    { ingressProjs: [], aggProjs: [], externalProjs: [] }
  );

  const aggsMap = data.projectionAliasPaths.reduce((obj, { projId, aggregates }) => ({ ...obj, [projId.name]: aggregates }), {});

  const parseProj = projs =>
    projs
      .sort((a, b) => orderMap[a.entityTypeId.name] - orderMap[b.entityTypeId.name])
      .reduce((obj, { projId, entityTypeId, projDisplayName, aliases: originalAliases, aggregates }) => {
        const aliases =
          aggregates === null
            ? originalAliases
            : originalAliases.map(item => ({ ...item, usages: getUsagesWithEval(item, projId, aggsMap) }));
        const fields = aliases.flatMap(getFields(projId.name));
        const selfTarget = aliases.find(({ projId: { name } }) => name === projId.name);

        return {
          ...obj,
          [projId.name]: {
            projId,
            projDisplayName,
            entityTypeId,
            aggregates: aggregates || { builtIn: null, name: null },
            pathAlias: selfTarget.alias,
            fields: getFields(projId.name)(selfTarget),
            nameMap: fields.reduce((obj, { name, displayName }) => ({ ...obj, [name]: displayName }), {}),
            typeMap: fields.reduce((obj, { value, type }) => ({ ...obj, [value]: type }), {}),
            fieldList: fields.reduce(
              (usagesObj, { usages, ...field }) => ({
                ...usagesObj,
                ...usages.reduce((obj, usage) => ({ ...obj, [usage]: [...(usagesObj[usage] || []), field] }), {}),
              }),
              {}
            ),
          },
        };
      }, {}) as AccountEntities;

  const parsedAggProjs = parseProj(aggProjs);
  const parsedIngressProjs = parseProj(ingressProjs);
  const parsedExternalProjs = parseProj(externalProjs);

  return {
    aggProjs: parsedAggProjs,
    ingressProjs: parsedIngressProjs,
    externalProjs: parsedExternalProjs,
    oldIngressProjs,
    fieldTypeMap: createFieldMapType(parsedAggProjs),
    fieldMap: createFieldMap(parsedAggProjs),
  };
};

export default function useEntities({ api, enableRequests }) {
  const {
    data = { aggProjs: {}, ingressProjs: {}, oldIngressProjs: {}, fieldTypeMap: {}, fieldMap: {}, externalProjs: {} },
    isPending: isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: [QueryKey.Entities],
    queryFn: () =>
      Promise.all([api(GET_ENTITIES), api(FIELD_VISIBILITY)]).then(([{ data }, { data: fieldVisibilityData }]) =>
        processEntities({ data, fieldVisibilityData })
      ),
    enabled: enableRequests,
    retry: 10,
    retryDelay: 1000,
  });
  return { data: { ...data, isLoading, refetch }, isLoading, error };
}
