import { Filter } from '../../../types/filter.types';
import {
  DonutTypeWidget,
  PieBasicLegendPosition,
  PieLegendStyles,
  PieSubType,
  SortDir,
  WidgetCategory,
} from '../../CustomDashboards/types';

// eslint-disable-next-line import/prefer-default-export
const getCoverageGapsDonutWidget = ({ filter }: { filter?: Filter }): DonutTypeWidget => ({
  id: '',
  definition: {
    title: {
      title: '',
    },
    coords: {
      x: 0,
      y: 0,
      h: 4,
      w: 6,
    },
    custom: {
      showTotals: true,
      legend: {
        position: PieBasicLegendPosition.Top,
        style: PieLegendStyles.Table,
        showValues: true,
        showPercentage: true,
      },
      fieldsPalette: {},
    },
  },
  category: WidgetCategory.Pie,
  type: PieSubType.Donut,
  requests: [
    {
      filter,
      select: {
        dims: [
          {
            name: 'asset.type',
          },
        ],
        metrics: [
          {
            name: 'total_assets_granular',
            alias: 'total_assets_granular',
          },
        ],
      },
      groupBy: ['asset.type'],
      sorting: [
        {
          name: 'total_assets_granular',
          dir: SortDir.DESC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
        offset: 0,
      },
      projectionId: {
        name: 'uber_assets',
        builtIn: true,
      },
      distinct: false,
      metricsFilterThreshold: 0,
    },
  ],
});

export default getCoverageGapsDonutWidget;
