import { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { ConditionType, getExpression } from '../../../../components/filters/Utils';
import { FormatterType } from '../../../../components/Table/types';
import { isNumberType } from '../../../../components/Table/Utils';
import { useAvContext } from '../../../../context/AvContextProvider';
import { useCustomSearchParams } from '../../../../hooks/UseCustomSearchParams';
import { FeatureFlags } from '../../../../types';
import { Filter } from '../../../../types/filter.types';
import { ScreenType } from '../../../../types/savedViews.types';
import { entityViewConfig } from '../../../../utils/entityViewConfig';
import { mapAliasesAndDims, mapAliasesAndMetrics } from '../../../../utils/mapping';
import { deserializeSortItem, getNetworkErrorCode } from '../../../../utils/Utils';
import { defaultColumnsForEntity } from '../../../Entities/Entities.constants';
import { getHeadCellsWithSort, useUserViews } from '../../../Tickets/hooks';
import { tableHeadCellsMap } from '../../../Tickets/ticketTable.utils';
import { NullValues, OTHERS } from '../../constants';
import { useGetClickthroughWidgetData, useGetFieldType } from '../../hooks';
import { AllTypeWidgets, DwQueryRequest, SortDir } from '../../types';
import {
  clickthroughDimsPrefix,
  clickthroughHeadCells,
  clickthroughMetricsPrefix,
  clickthroughNotOthersDimsPrefix,
  clickthroughOrderBy,
  clickthroughTablePage,
  defaultNumberColumns,
  rowLimit,
} from './consts';
import useInitHeadcells from './useInitHeadcells';

export const useGetClickthroughDimsFromFilters = (): Record<string, string[]> => {
  const [filters] = useCustomSearchParams({ shouldBeArray: () => true });
  return Object.keys(filters).reduce(
    (acc, cur) => (cur.startsWith(clickthroughDimsPrefix) ? { ...acc, [cur.replace(clickthroughDimsPrefix, '')]: filters[cur] } : acc),
    {}
  );
};

const useGetClickthroughNotOthersDims = () => {
  const [filters] = useCustomSearchParams({ shouldBeArray: () => true });
  const notOthersDim = Object.keys(filters).find(filter => filter.includes(clickthroughNotOthersDimsPrefix));
  return notOthersDim ? { [notOthersDim.replace(clickthroughNotOthersDimsPrefix, '')]: filters[notOthersDim] } : {};
};

export const useGetClickthroughDimsFilters = ({ projName, clickthroughDims }) => {
  const getFieldType = useGetFieldType(projName);

  return Object.keys(clickthroughDims).reduce((acc: any[], dim: string) => {
    if (clickthroughDims[dim].length === 1 && clickthroughDims[dim].includes(OTHERS)) {
      return acc;
    }

    const type = getFieldType({ entityFieldKey: dim }) || FormatterType.string;

    return [
      ...acc,
      {
        or: {
          operands: clickthroughDims[dim]
            .filter(val => val !== OTHERS)
            .map(val =>
              getExpression({
                fieldName: dim,
                typeCondition: ConditionType[isNumberType(type) ? FormatterType.number : type || FormatterType.string],
                operator:
                  type === FormatterType.date
                    ? 'between'
                    : NullValues.includes(val)
                      ? 'empty'
                      : type === FormatterType.array
                        ? 'in'
                        : 'equals',
                value:
                  type === FormatterType.date
                    ? { values: [val, val] }
                    : NullValues.includes(val)
                      ? {}
                      : type === FormatterType.array
                        ? { values: val.split(',') }
                        : type === FormatterType.boolean
                          ? JSON.parse(val)
                          : type === FormatterType.number
                            ? +val
                            : val,
              })
            ),
        },
      },
    ];
  }, []);
};

const useGetHeadCellsByView = ({ filters, activeProjName, entityConfig, userViews, pathAlias }) => {
  const {
    featureFlags,
    accountEntities: { aggProjs },
  } = useAvContext();

  return () => {
    // Return headcells by filters
    if (filters[clickthroughHeadCells]) {
      return { headCellsIds: filters[clickthroughHeadCells] };
    }
    if (entityConfig) {
      const defaultAccountView = userViews.find(({ accountDefaultView }) => accountDefaultView);

      // Return headcells by account default view
      if (defaultAccountView && defaultAccountView.config?.headCells) {
        return {
          headCellsIds: defaultAccountView.config.headCells
            .filter(id => !mapAliasesAndDims[entityConfig.entityTypeId]?.[id]?.filterFromClickthrough)
            .map(id => mapAliasesAndMetrics[id]?.metric || mapAliasesAndDims[entityConfig.entityTypeId]?.[id]?.dim || id),
        };
      }
      // Return headcells by avalor default view
      const avalorDefaultHeadCells = tableHeadCellsMap(featureFlags[FeatureFlags.KeepSlaWithoutFormatting])[
        entityConfig?.entityTypeId
      ].reduce((acc, headcell) => ({ ...acc, [headcell.id]: { ...headcell } }), {});

      const avalorDefaultHeadCellsIds = Object.values(avalorDefaultHeadCells)
        .filter(({ id }: any) => !mapAliasesAndDims[entityConfig.entityTypeId]?.[id]?.filterFromClickthrough)
        .map(({ id }: any) => mapAliasesAndMetrics[id]?.metric || mapAliasesAndDims[entityConfig.entityTypeId]?.[id]?.dim || id);

      return {
        headCellsIds: avalorDefaultHeadCellsIds,
        headCellsHiddenByView: Object.values(avalorDefaultHeadCells)
          .filter((headcell: any) => headcell.hidden)
          .map(({ id }: any) => id),
      };
    }
    // Return headcells by entity (when no account default or avalor exist)

    if (defaultColumnsForEntity[activeProjName]) {
      return {
        headCellsIds: defaultColumnsForEntity[activeProjName].slice(0, defaultNumberColumns + 1).map(v => `${pathAlias}.${v}`),
      };
    }

    return { headCellsIds: aggProjs[activeProjName].fields.slice(0, defaultNumberColumns + 1).map(({ id }) => id) };
  };
};

export const useGetClickthroughData = ({ widget, globalFilters }: { widget: AllTypeWidgets; globalFilters: Filter }) => {
  const {
    measurements,
    accountEntities: { aggProjs },
  } = useAvContext();
  const [filters] = useCustomSearchParams({ shouldBeArray: () => true });
  const metric = filters[clickthroughMetricsPrefix]?.[0];
  const measurement = measurements.visible.find(({ systemName }) => systemName === metric);
  const { data: measurementFilter, isLoading: isLoadingMeasurementFilter } = useGetMetricFilter({ metric });
  const projId = measurementFilter?.projId || measurement?.drillDown?.projId || aggProjs.tickets.projId;
  const { name: activeProjName } = projId;
  const clickthroughDims = useGetClickthroughDimsFromFilters();
  const dimensionFilters = useGetClickthroughDimsFilters({ projName: activeProjName, clickthroughDims });
  const notOthersClickthroughDims = useGetClickthroughNotOthersDims();
  const notOthersDimFilters = useGetClickthroughDimsFilters({ projName: activeProjName, clickthroughDims: notOthersClickthroughDims });
  const { pathAlias } = aggProjs[activeProjName];
  const getHeadCellType = useGetFieldType(activeProjName);
  const keyField = `${pathAlias}._key`;

  const orderBy =
    filters[clickthroughOrderBy]
      ?.map(deserializeSortItem)
      .filter(({ property }) => !filters[clickthroughHeadCells] || filters[clickthroughHeadCells].includes(property)) || [];
  const page = +(filters[clickthroughTablePage]?.[0] || 0);

  const entityConfig = Object.values(entityViewConfig).find(({ projectionName }) => projectionName === projId.name);

  const { userViews, isLoading: isLoadingUserViews } = useUserViews((entityConfig as { viewScreenType: ScreenType })?.viewScreenType);

  const getHeadCellsByView = useCallback(
    useGetHeadCellsByView({
      filters,
      activeProjName,
      entityConfig,
      userViews,
      pathAlias,
    }),
    [filters, entityConfig, userViews, isLoadingUserViews]
  );
  const { headCellsIds = [], headCellsHiddenByView = [] } = getHeadCellsByView();

  const initHeadCells = useInitHeadcells({
    activeProjName,
    entityConfig,
    headCellsIds,
    projId,
    headCellsHiddenByView,
  });

  const headCells = useMemo(() => {
    const initialHeadCells = initHeadCells();
    return getHeadCellsWithSort(
      filters[clickthroughHeadCells]
        ? [
            ...filters[clickthroughHeadCells].flatMap(id => {
              const cell = initialHeadCells.find(h => h.id === id);
              return [{ ...cell, hidden: false }];
            }),
            ...initialHeadCells.filter(h => !filters[clickthroughHeadCells].includes(h.id)).map(h => ({ ...h, hidden: true })),
          ]
        : initialHeadCells,
      orderBy
    );
  }, [filters[clickthroughHeadCells], filters[clickthroughOrderBy], activeProjName, headCellsIds]);

  const disableEntityPageNavigation = Object.keys(clickthroughDims).some(
    dim => getHeadCellType({ entityFieldKey: dim }) === FormatterType.date && clickthroughDims[dim].length > 1
  );

  const { queryObject } = useGetClickthroughQueryObject({
    widget,
    orderBy,
    headCellsIds,
    rowLimit,
    page,
    dimensionFilters,
    notOthersDimFilters,
    projId,
    measurementFilter,
    keyField,
  });

  const isDisabledClickthroughQuery = !projId || !measurementFilter;

  const {
    data: rows,
    isLoading: isLoadingTable,
    isRefetching: isRefetchingTable,
    totalRowCount,
  } = useGetClickthroughWidgetData({
    queryObject,
    contextFilter: globalFilters,
    enabled: !isDisabledClickthroughQuery && !isLoadingUserViews,
  });

  return {
    activeProjName,
    initHeadCells,
    headCells,
    orderBy,
    page,
    disableEntityPageNavigation,
    queryObject,
    rows,
    isLoadingTable: isLoadingTable || isLoadingMeasurementFilter,
    isRefetchingTable,
    totalRowCount,
    keyField,
    isDisabledClickthroughQuery,
    isLoadingMeasurementFilter,
  };
};

export const useGetClickthroughQueryObject = ({
  widget,
  orderBy,
  headCellsIds,
  rowLimit,
  page,
  dimensionFilters,
  notOthersDimFilters,
  measurementFilter,
  projId,
  keyField,
}): { measurementFilterExpression?: Filter; queryObject: DwQueryRequest } => {
  const { measurements } = useAvContext();
  const measurementFilterExpression = measurementFilter?.filter
    ? measurementFilter.filter.and?.operands
      ? measurementFilter.filter.and.operands
      : [measurementFilter.filter]
    : [];

  const filterOperands = [
    ...(notOthersDimFilters.length ? [] : dimensionFilters),
    ...measurementFilterExpression,
    ...(widget.requests[0].filter ? [widget.requests[0].filter] : []),
    ...(notOthersDimFilters.length ? [{ not: { ...notOthersDimFilters[0] } }] : []),
  ];

  const headCellsWithMeasurements = measurements.visible.filter(({ systemName }) => headCellsIds.includes(systemName));
  const headCellsWithoutMeasurements = headCellsIds.filter(
    systemName => !measurements.visible.find(metric => metric.systemName === systemName)
  );

  const filter = filterOperands.length ? { and: { operands: filterOperands } } : undefined;

  return {
    queryObject: {
      ...widget.requests[0],
      timezoneOffset: new Date().getTimezoneOffset(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      sorting: orderBy.map(({ property, isAsc }) => ({
        name: property,
        dir: isAsc ? SortDir.ASC : SortDir.DESC,
      })),
      select: {
        ...widget.requests[0].select,
        dims: [...headCellsWithoutMeasurements, ...(headCellsWithoutMeasurements.includes(keyField) ? [] : [keyField])].map(v => ({
          name: `${v}`,
        })),
        metrics: headCellsWithMeasurements.map(({ systemName }) => ({ name: systemName, alias: systemName })),
      },
      top: {
        size: rowLimit,
        offset: rowLimit * page,
        groupOthers: false,
      },
      projectionId: projId,
      filter,
      metricsFilterThreshold: undefined,
    },
  };
};

export const useGetMetricFilter = ({ metric, filter }: { metric: string; filter?: Filter }) => {
  const { api } = useAvContext();

  return useQuery({
    queryKey: [metric, JSON.stringify(filter)],
    queryFn: () =>
      api(GET_DRILL_DOWN_FILTER, {
        options: {
          clickThroughRequest: {
            metric,
            filter,
          },
        },
        onSuccess: ({ data }) => data?.clickThrough,
        onError: e => getNetworkErrorCode(e) !== 400 && console.error(`[clickThrough error]: `, e),
      }),
  });
};

export const GET_DRILL_DOWN_FILTER = gql`
  query clickThrough($clickThroughRequest: ClickThroughRequest) {
    clickThrough(clickThroughRequest: $clickThroughRequest) {
      filter
      projId {
        name
        builtIn
      }
    }
  }
`;
