import React, { useState } from 'react';
import { Box, Paper, Popper } from '@mui/material';
import { ellipsis } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { IconVariant, ItemWithLogo, Logo } from './ItemWithLogo';

interface TooltipContentProps {
  variant: IconVariant;
  items: string[];
  limit: number;
  size: IconSize;
  isAvatar?: boolean;
  CustomItemRender?: any;
}

export enum IconSize {
  medium = 'medium',
  small = 'small',
  collapsed = 'collapsed',
  collapsedMedium = 'collapsedMedium',
  collapsedXSmall = 'collapsedXSmall',
}
const TooltipContent: React.FC<TooltipContentProps> = ({ items, limit, variant, size, isAvatar = false, CustomItemRender }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleMouseLeave = () => setAnchorEl(null);

  return (
    <Box
      onMouseLeave={handleMouseLeave}
      onClick={e => e.stopPropagation()}
      sx={{ backgroundColor: theme => theme.palette.white.main, border: theme => `2px solid ${theme.palette.white.main}` }}>
      <Box key="source-overflow" onMouseOver={handleClick}>
        <Logo
          transparent
          style={{
            minWidth: 'max-content',
            backgroundColor: theme => theme.palette.colors.neutrals[300],
            width: size === IconSize.small ? 24 : size === IconSize.collapsedMedium ? 38 : 30,
            height: getIconSize(size),
            borderRadius: 15,
          }}>
          <Box
            sx={theme => ({
              fontSize: size === IconSize.small ? 12 : 13,
              fontWeight: '600',
              color: theme.palette.colors.neutrals[600],
            })}>
            +{items.length - limit}
          </Box>
        </Logo>
      </Box>
      <Popper
        key="source-popover"
        placement="bottom-start"
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{
          maxHeight: '600px',
          overflowY: 'scroll',
          boxShadow: (theme: any) => theme.components?.MuiPaper?.styleOverrides?.root?.boxShadow,
          zIndex: theme => theme.zIndex.modal,
        }}>
        <Paper sx={{ p: 2, ...flex.col, gap: 2 }}>
          {items
            .slice(limit, items.length)
            .map(
              item =>
                (CustomItemRender && <CustomItemRender key={item} item={item} />) || (
                  <ItemWithLogo key={item} type={item} variant={variant} isAvatar={isAvatar} />
                )
            )}
        </Paper>
      </Popper>
    </Box>
  );
};

export const itemWithLogoTableCellStyle = { '&.MuiTableCell-root.MuiTableCell-body': { py: '10px' } };

interface AvItemWithLogoGroupProps {
  variant: IconVariant;
  items?: string[];
  limit?: number;
  size?: IconSize;
  isAvatar?: boolean;
  sx?: Record<string, any>;
  CustomItemRender?: any;
}

const getIconSize = (size: IconSize) =>
  size === IconSize.collapsedXSmall ? 18 : size === IconSize.small ? 20 : size === IconSize.collapsedMedium ? 32 : 24;

const AvItemWithLogoGroup: React.FC<AvItemWithLogoGroupProps> = ({
  items = [],
  variant,
  limit = 2,
  size = IconSize.small,
  isAvatar = false,
  sx = {},
  CustomItemRender,
}) => {
  const hideText = [IconSize.collapsedMedium, IconSize.collapsed, IconSize.collapsedXSmall].includes(size);
  const hasOverflow = items.length > limit + 1;
  const shownItems = [...(hasOverflow ? items.slice(0, limit) : items)];
  const variantSpace =
    size === IconSize.collapsed && variant === IconVariant.color
      ? '8px'
      : size === IconSize.collapsedMedium
        ? '10px'
        : size === IconSize.collapsedXSmall
          ? '6px'
          : '4px';
  return (
    <Box
      sx={{
        ...flex.row,
        ...(hideText
          ? {
              pl: variantSpace,
              '> div': {
                alignSelf: 'center',
                position: 'relative',
                border: theme => `2px solid ${theme.palette.white.main}`,
                borderRadius: '50%',
                ml: `-${variantSpace}`,
              },
            }
          : { gap: 2 }),
        ...sx,
      }}>
      {shownItems.map(
        (value, index) =>
          // eslint-disable-next-line react/no-array-index-key
          (CustomItemRender && <CustomItemRender key={index} item={value} size={getIconSize(size)} />) || (
            <ItemWithLogo
              key={value}
              variant={variant}
              hideText={hideText}
              textStyle={ellipsis}
              logoWidth={getIconSize(size)}
              type={value}
              isAvatar={isAvatar}
            />
          )
      )}
      {hasOverflow && (
        <TooltipContent limit={limit} items={items} variant={variant} size={size} isAvatar={isAvatar} CustomItemRender={CustomItemRender} />
      )}
    </Box>
  );
};

export default AvItemWithLogoGroup;
