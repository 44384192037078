import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import AvTag from '../../components/AvTag';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import { SeverityItem } from '../../components/ItemWithLogo';
import { useAvContext } from '../../context/AvContextProvider';
import { FeatureFlags } from '../../types';
import { formatPercent } from '../../utils/dashboardDataUtils';
import { cellContentStyle } from '../../utils/Utils';
import { ScoreFactor, SeverityScoringFactorType } from '../Settings/ScoreSettings/scoreSettings.types';

interface Props {
  data: ScoreFactor[];
  severityData?: { severity: string; score: number };
  hideTitle?: boolean;
}

const ScoreExplanation: React.FC<Props> = ({ data, severityData, hideTitle = false }) => {
  const showNewFeatures = useAvContext().featureFlags[FeatureFlags.ScoreExplanationUpdatedDesign];
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'fit-content(100%) fit-content(100%) fit-content(100%) fit-content(100%)',
    rowGap: 1,
    alignItems: 'start',
    columnGap: 6,
  };

  const hasChangeColumn = data.some(d => !!d.change);
  const numericHeaders = ['Score Share %', hasChangeColumn ? 'Score Change' : ''];

  const header = (title, sx = {}) => (
    <Box key={title} sx={{ mb: 1, fontWeight: 600, ...sx, ...(numericHeaders.includes(title) ? { justifySelf: 'end' } : {}) }}>
      {title}
    </Box>
  );

  const dataWithRange = data.map(row => ({
    ...row,
    ...(row.value !== 'None' && row.percentage === 0 && row.range && row.range > 0 ? { change: 0, percentage: row.range } : {}),
  }));

  const rows = (showNewFeatures ? dataWithRange : data).reduce(
    (acc, r) => {
      if (r.type === SeverityScoringFactorType.Base) {
        if (r.percentage) {
          acc.base = [...acc.base, r];
        } else {
          acc.emptyBase = [...acc.emptyBase, r.name];
        }
      } else if (r.percentage) {
        acc.riskMiti = [...acc.riskMiti, r];
      } else {
        acc.emptyRiskMiti = [...acc.emptyRiskMiti, r.name];
      }
      return acc;
    },
    { riskMiti: [], emptyRiskMiti: [], base: [], emptyBase: [] } as any
  );

  return (
    <>
      {!hideTitle && (
        <Typography variant="h7" component="div" sx={{ mb: 2, pt: 2 }}>
          Avalor Score was defined considering:
        </Typography>
      )}
      <Box sx={{ ...flex.col, gap: 2, fontSize: '13px' }}>
        <Box sx={gridStyle}>
          {['Base Score', 'Value', ...numericHeaders].map(h => header(h, numericHeaders.includes(h) ? { alignSelf: 'end' } : {}))}
          {rows.base.map(r => (
            <TableRow row={r} />
          ))}
          {rows.emptyBase.length > 0 && (
            <TableRow
              row={{
                name: rows.emptyBase.join(', '),
                percentage: 0,
                value: '',
                change: hasChangeColumn ? '0' : '',
                type: SeverityScoringFactorType.Base,
                range: 0,
              }}
            />
          )}
          {rows.riskMiti.length > 0 && ['Score Adjustments', 'Value', ...numericHeaders].map(h => header(h, { mt: 3 }))}
          {rows.riskMiti.map(r => (
            <TableRow row={r} />
          ))}
          {rows.emptyRiskMiti.length > 0 && (
            <TableRow
              row={{
                name: rows.emptyRiskMiti.join(', '),
                percentage: 0,
                value: '',
                change: hasChangeColumn ? '0' : '',
                type: SeverityScoringFactorType.Risk,
                range: 0,
              }}
            />
          )}
          <Box sx={{ mt: 2, borderTop: ({ palette }) => `1px solid ${palette.colors.neutrals[400]}`, gridColumn: '1/5' }} />
          <Box sx={{ fontWeight: 600 }}>Final Score</Box>
          <Box />
          <Box />
          {severityData?.score && <SeverityItem width={24} value={severityData.severity} numericValue={severityData.score} showText />}
        </Box>
      </Box>
    </>
  );
};

export default ScoreExplanation;

interface RowProps {
  row: ScoreFactor;
}

const TableRow: React.FC<RowProps> = ({ row }) => {
  const { palette } = useTheme() as any;
  const colorProps = {
    color:
      row.change && row.percentage > 0
        ? palette.colors.negative[500]
        : row.percentage < 0
          ? palette.colors.positive[500]
          : palette.colors.neutrals[500],
    bg: row.change && row.percentage > 0 ? palette.colors.negative[100] : row.percentage < 0 ? palette.colors.positive[100] : 'transparent',
  };
  const colors = row.type !== SeverityScoringFactorType.Base || row.percentage === 0 ? colorProps : { color: undefined };
  const rowChange = row.change && +row.change && Number(row.change).toFixed(1);
  return (
    <React.Fragment key={row.name}>
      <Box
        sx={{
          maxWidth: 200,
          ...cellContentStyle,
          WebkitLineClamp: 2,
          ...(row.percentage === 0 && { color: palette.colors.neutrals[500] }),
        }}>
        <AvTooltip>{row.name as any}</AvTooltip>
      </Box>
      <Box sx={{ whiteSpace: 'normal' }}>{row.value}</Box>
      <AvTag
        text={`${row.value !== 'NONE' && row.percentage > 0 ? '+' : ''}${formatPercent(row.percentage, 100)}`}
        {...colors}
        sx={{ justifySelf: 'end', width: 'fit-content' }}
      />
      <Box sx={{ whiteSpace: 'normal', color: colors.color, justifySelf: 'end' }}>
        {rowChange && `${+rowChange > 0 ? '+' : ''}${rowChange}`}
      </Box>
    </React.Fragment>
  );
};
