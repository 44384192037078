import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { keepPreviousData, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../../context/AvContextProvider';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { getAccountTokenFromLS } from '../../../utils/auth.utils';
import { DwQueryRequest, SortDir } from '../../CustomDashboards/types';
import { BucketType, StatusType } from './Utils';

export const useGetStatuses = () => {
  const { accountId } = useAvContext();
  const queryObject: DwQueryRequest = {
    select: {
      dims: [
        {
          name: 'status_buckets._key',
          alias: 'bucketKey',
        },
        {
          name: 'status_buckets.order_by',
          alias: 'bucketOrderBy',
        },
        {
          name: 'status_buckets.name',
          alias: 'name',
        },
        {
          name: 'status_buckets.remediated',
          alias: 'remediated',
        },
        {
          name: 'status_buckets.closed',
          alias: 'closed',
        },
        {
          name: 'status_buckets.approved',
          alias: 'approved',
        },
        {
          name: 'status_buckets.related_proj_name',
          alias: 'projName',
        },
        {
          name: 'status_buckets.statuses._key',
          alias: 'statusKey',
        },
        {
          name: 'status_buckets.statuses.name',
          alias: 'statusName',
        },
        {
          name: 'status_buckets.statuses.order_by',
          alias: 'statusOrderBy',
        },
        {
          name: 'status_buckets.statuses.bucket_key',
          alias: 'statusBucketKey',
        },
        {
          name: 'status_buckets.statuses.related_proj_name',
          alias: 'statusRelatedProjName',
        },
      ],
      metrics: [],
    },
    sorting: [
      {
        name: 'bucketOrderBy',
        dir: SortDir.ASC,
      },
    ],
    projectionId: {
      name: 'status_buckets',
      builtIn: true,
    },
  };
  return useQueryObject({
    queryObject,
    options: { placeholderData: keepPreviousData, enabled: !!getAccountTokenFromLS(accountId) },
  });
};

export const useGetBucketAndStatuses = (proj = 'tickets'): { buckets: BucketType[]; isLoading: boolean; refetch: any } => {
  const { data, isLoading, refetch } = useGetStatuses();
  const buckets = useMemo(
    () =>
      data && !isLoading
        ? data
            .filter(s => s.projName === proj)
            .reduce((acc, { statusName, statusBucketKey, statusOrderBy, statusRelatedProjName, statusKey, ...rest }) => {
              const bucketOrder = rest.bucketOrderBy - 1;
              const newStatus: StatusType | undefined = statusKey
                ? {
                    statusKey,
                    bucket_key: statusBucketKey,
                    name: statusName,
                    order_by: statusOrderBy,
                    related_proj_name: statusRelatedProjName,
                  }
                : undefined;
              acc[bucketOrder] = { ...rest, statuses: acc[bucketOrder]?.statuses ? acc[bucketOrder].statuses : [] };
              if (statusName) {
                const statusOrder = statusOrderBy - 1;
                if (newStatus || acc[bucketOrder].statuses[statusOrder]) {
                  acc[bucketOrder].statuses[statusOrder] = newStatus || acc[bucketOrder].statuses[statusOrder];
                }
              }
              return acc;
            }, [])
        : [],
    [data, isLoading, proj]
  );
  return { isLoading, buckets, refetch };
};

export type DirectEntityProjectionListDtoInput = {
  entities: {
    key: string;
    entity: any;
  }[];
  projectionId: {
    name: string;
    builtIn: boolean;
  };
  relatedProjName?: string;
};

export const useUpdateEntities = () => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({
      isCreate,
      projName,
      entities,
      onSuccess,
      onError,
      relatedProjName,
    }: {
      isCreate?: boolean;
      projName: 'statuses' | 'status_buckets' | 'comments' | 'attachments' | 'exceptions';
      entities: any[];
      onSuccess?: any;
      onError?: any;
      relatedProjName?: string;
    }) => {
      const listDto: DirectEntityProjectionListDtoInput = {
        entities,
        projectionId: { name: projName, builtIn: true },
        relatedProjName,
      };
      return api(isCreate ? CREATE_ENTITIES : UPDATE_ENTITIES, {
        options: { listDto },
        onSuccess: d => {
          onSuccess?.(d.data[isCreate ? 'createBulkDirectEntities' : 'updateBulkDirectEntities']);
          enqueueSnackbar('Saved Successfully', { variant: 'success' });
        },
        onError: e => {
          onError?.(e);
          enqueueSnackbar(e?.message || 'Failed Saving', { variant: 'error' });
        },
      });
    },
  });
};
export const useDeleteEntity = () => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({
      projName,
      entitiesKeys,
      onSuccess,
      relatedProjName,
    }: {
      projName: 'status_buckets' | 'statuses' | 'comments' | 'attachments';
      entitiesKeys: string[];
      onSuccess?: any;
      relatedProjName?: string;
    }) =>
      api(DELETE_ENTITIES, {
        options: { entitiesKeys, projectionId: { name: projName, builtIn: true }, relatedProjName },
        onSuccess: d => {
          onSuccess?.(d.data.deleteBulkDirectEntities);
          enqueueSnackbar('Deleted Successfully', { variant: 'success' });
        },
        muteErrors: true,
      }).catch(e => {
        if ([e?.status, e?.graphQLErrors?.[0]?.extensions?.status, e?.extensions?.status].includes(400)) {
          console.warn(e);
        } else {
          console.error(e);
        }
        enqueueSnackbar(e.message, { variant: 'error' });
      }),
  });
};

const DELETE_ENTITIES = gql`
  mutation ($entitiesKeys: [String]!, $projectionId: ProjectionIDScalar!, $relatedProjName: String!) {
    deleteBulkDirectEntities(entitiesKeys: $entitiesKeys, projectionId: $projectionId, relatedProjName: $relatedProjName)
  }
`;

const CREATE_ENTITIES = gql`
  mutation ($listDto: DirectEntityProjectionListDtoInput!) {
    createBulkDirectEntities(listDto: $listDto) {
      entities {
        key
        entity
      }
      projectionId
      relatedProjName
    }
  }
`;

const UPDATE_ENTITIES = gql`
  mutation ($listDto: DirectEntityProjectionListDtoInput!) {
    updateBulkDirectEntities(listDto: $listDto) {
      entities {
        key
        entity
      }
      projectionId
      relatedProjName
    }
  }
`;
